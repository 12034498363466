import { NgModule, Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { ConfigService } from '@global/config.service';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';


@Component({
	selector: 'milieu-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MilieuSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[required]="required"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class MilieuSelectorComponent extends ControlValueAccessorBase<string|null|undefined> implements ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() nullIfInvalid: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() disabled: boolean;
	@Input() required: boolean;

	options: any[] = [];

	constructor(private configService: ConfigService) {
		super();
	}

	ngOnInit() {
		this.options = this.configService.config.milieux;
		setTimeout(() => this.setInitialValue(), 0);
	}

	setInitialValue() {
		if (this.options.findIndex(one => {return one == this.innerValue; }) < 0) {
			if (this.nullIfInvalid) {
				this.innerValue = null;
			}
			else if (this.options.length){
				this.innerValue = this.options[0];
			}
		}
		this.value = this.innerValue;
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [MilieuSelectorComponent],
	declarations: [MilieuSelectorComponent]
})
export class MilieuSelectorModule { }
