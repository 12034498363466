import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';


import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { ProfilCompetence } from '@app/moa/referentiel-competences/referentiel-competences.model';
import { intersect, simpleComparison } from '@app/_helpers/utils';


@Component({
	selector: 'profil-competence-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ProfilCompetenceSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			optionValue="pco_code"
			optionLabel="pco_libelle"
			appendTo="body"
		>

		</p-dropdown>
	`
})
export class ProfilCompetenceSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() onlyThese: string[];

	filters: {[key: string]: any} = {};
	options: ProfilCompetence[] = [];
	_options : ProfilCompetence[] = [];

	constructor(private referentielCompetencesService: ReferentielCompetencesService) {

	}

	ngOnInit() {
		this.referentielCompetencesService.getProfilsCompetences()
		.subscribe({
			next: (response: ProfilCompetence[]) => {
				this._options = response;
				this.filterOptions();
			}
		})
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	ngOnChanges(changes: any) {
		if (changes.onlyThese && !simpleComparison(changes.onlyThese.currentValue, changes.onlyThese.previousValue)) {
			this.filterOptions();
		}
	}

	filterOptions() {
		if (!this.onlyThese.length) {
			this.options = [...this._options];
		}
		else {
			this.options = intersect(this._options, this.onlyThese.map((one: string) => { return {pco_code: one}; }), 'pco_code');
		}
	}

	get value() {
		return this.innerValue;
	}

	set value(value: string|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [ProfilCompetenceSelectorComponent],
	declarations: [ProfilCompetenceSelectorComponent]
})
export class ProfilCompetenceSelectorModule { }
