<h1 class="sm:flex align-items-center flex-shrink-0">
	<!-- <titre-module>Evaluations collaborateurs</titre-module> -->
	<back-button class="mr-2 flex-shrink-0"></back-button>
	<span class="flex-shrink-0">
		Evaluation collaborateur
	</span>
	<print-button-simple [track]="false"></print-button-simple>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Collaborateur</td>
				<td>
					<!-- {{evaluation.collaborateur | utilisateur}} -->
					{{evaluation.collaborateur | utilisateur}}
				</td>
			</tr>
			<tr>
				<td class="cell-th">Profil évalué</td>
				<td>
					<!-- {{evaluation.profil}} -->
					{{evaluation.pco_code|profilCompetence}}
				</td>
			</tr>
			<tr>
				<td class="cell-th">Période</td>
				<td class="w-20rem">
					<span *ngIf="!canEdit"></span>
					<ng-container *ngIf="canEdit">
						<input
							type="text"
							pInputText
							name="evc_periode"
							[(ngModel)]="evaluation.evc_periode"
							[required]="true"
							placeholder="Période"
							(blur)="submit()"
							class="hide-print w-full"
						>
						<span class="show-print">{{evaluation.evc_periode}}</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		[value]="values"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
		rowGroupMode="subheader"
		groupRowsBy="rowGroup"
	>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center">Compétence</th>
				<th class="text-center">Critère d'évaluation</th>
				<th class="text-center">Niveau</th>
				<th class="text-center">Plan d'action</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="groupheader" let-ligne let-rowIndex="rowIndex">
			<tr class="" *ngIf="rowIndex > 0 && !ligne.sous_categorie">
				<td class="clear-cell p-2" colspan="100"></td>
			</tr>
			<tr pRowGroupHeader>
				<td
					colspan="5"
					[ngStyle]="{
						'background-color': ligne.categorie.couleur,
						'color': ligne.categorie.couleur_texte
					}"
					[ngClass]="{'cell-th text-center': !ligne.sous_categorie}"
				>
					{{ligne.rowGroupLabel}}
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-ligne let-rowIndex="rowIndex">

			<tr class="" *ngIf="ligne.critere_evaluation">
				<td class="text-left white-space-pre" [attr.rowspan]="ligne.competence.criteres_evaluations.length" *ngIf="ligne.firstCritere">
					{{ligne.competence.libelle}}
				</td>
				<td class="text-left">
					{{ligne.critere_evaluation.libelle}}
				</td>
				<td class="nowrap">
					<ng-container *ngIf="!canEdit">
						{{ligne.critere_evaluation.niveau_competence|niveauCompetence}}
					</ng-container>
					<ng-container *ngIf="canEdit">
						<niveau-competence-selector
							[name]="'niveau_competence-' + rowIndex"
							[(ngModel)]="ligne.critere_evaluation.niveau_competence"
							(ngModelChange)="submit()"
							[required]="true"
							class="hide-print"
						></niveau-competence-selector>
						<span class="show-print">{{ligne.critere_evaluation.niveau_competence|niveauCompetence}}</span>
					</ng-container>
				</td>
				<td class="min-w-20rem">
					<span class="white-space-pre" *ngIf="!canEdit">
						{{ligne.critere_evaluation.plan_action}}
					</span>
					<ng-container *ngIf="canEdit">
						<textarea
							pInputTextarea
							[name]="'plan_action-' + rowIndex"
							[(ngModel)]="ligne.critere_evaluation.plan_action"
							[autoResize]="true"
							[rows]="2"
							class="hide-print w-full"
							(blur)="submit()"
						></textarea>
						<span class="white-space-pre show-print">
							{{ligne.critere_evaluation.plan_action}}
						</span>
					</ng-container>
				</td>
			</tr>

		</ng-template>



	</p-table>

<!-- <pre>{{evaluation|json}}</pre> -->
</div>

