import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';


import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { NiveauCompetence } from '@app/moa/referentiel-competences/referentiel-competences.model';


@Component({
	selector: 'niveau-competence-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => NiveauCompetenceSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			optionValue="nic_code"
			optionLabel="nic_libelle"
			appendTo="body"
		>

		</p-dropdown>
	`
})
export class NiveauCompetenceSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string = 'non_acquis';
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;


	filters: {[key: string]: any} = {};
	options: NiveauCompetence[] = [];


	constructor(private referentielCompetencesService: ReferentielCompetencesService) {
		this.referentielCompetencesService.getNiveauxCompetences()
		.subscribe({
			next: (response: NiveauCompetence[]) => {
				this.options = response;
			}
		})
	}

	ngOnInit() {

	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [NiveauCompetenceSelectorComponent],
	declarations: [NiveauCompetenceSelectorComponent]
})
export class NiveauCompetenceSelectorModule { }
