<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[disabled]="loading"
			[useStorage]="true"
			[modes]="['mois','libre']"
			storagePrefix="cockpit-"
			[maxDate]="maxDate"
			[defaultEnd]="maxDate"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			name="magasin"
			[(ngModel)]="mag_id"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[mesMagasins]="false"
			[autoSelectIfSingle]="true"
			[disabled]="loading"
			[silentInitialChangeIfSame]="true"
			placeholder="Tous les magasins"
			(ngModelChange)="paramChanged()"
		></magasin-selector>
	</div>

	<div class="field col-fixed">
		<label for="uti_id">Vendeur</label>
		<utilisateur-selector
			name="vendeur"
			[(ngModel)]="uti_id"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[autoSelectIfSingle]="true"
			[mag_id]="mag_id"
			[periode]="true"
			[date_debut]="dateRange[0]"
			[date_fin]="dateRange[1]"
			[silentInitialChangeIfSame]="true"
			[disabled]="loading"
			placeholder="Tous les vendeurs"
			(ngModelChange)="paramChanged()"
		></utilisateur-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[loading]="loading"
			(click)="paramChanged()"
		></button>
	</div>

</div>

<!-- affichage petits écrans -->
<div class="block md:hidden">

	<div class="col-12">
		<p-blockUI [blocked]="loading" [target]="blocValeur"></p-blockUI>
		<bloc-valeurs
			#blocValeur
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3"
			(onShowDetailVentesClick)="showDetailVentesClick($event)"
		></bloc-valeurs>
	</div>

	<div class="col-12" *ngIf="uti_id == undefined">
		<p-blockUI [blocked]="loading" [target]="blocValeur"></p-blockUI>
		<bloc-rendez-vous
			#blocValeur
			[valeursN]="values['N']"
			[loading]="loading"
			class="block mb-3"
		></bloc-rendez-vous>
	</div>

	<div class="col-12">
		<div>
			<p-blockUI [blocked]="loading" [target]="blocObjectifs"></p-blockUI>
			<bloc-objectifs
				#blocObjectifs
				[valeursN]="objectifs['N']"
				[valeursPast]="objectifs['N-1']"
				class="block mb-3 surface-card p-3"
			></bloc-objectifs>
		</div>
		<div>
			<p-blockUI [blocked]="loading" [target]="blocIndicateurParMilieu"></p-blockUI>
			<bloc-indicateurs-par-milieu
				#blocIndicateurParMilieu
				[valeursN]="values['N']"
				[valeursPast]="values['N-1']"
				class="block mb-3 "
			></bloc-indicateurs-par-milieu>
		</div>
	</div>

	<div class="col-12">
		<p-blockUI [blocked]="loading" [target]="blocRatios"></p-blockUI>
		<bloc-ratios
			#blocRatios
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3 surface-card p-3"
		></bloc-ratios>
	</div>

	<div class="col-12">
		<p-blockUI [blocked]="loading" [target]="blocEvolutionCA"></p-blockUI>
		<bloc-evolution-ca
			#blocEvolutionCA
			[evolutions]="evolutions"
			class="block mb-3 surface-card p-3"
		></bloc-evolution-ca>
	</div>

	<div class="col-12">
		<p-blockUI [blocked]="loading" [target]="blocPanier"></p-blockUI>
		<bloc-panier
			#blocPanier
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3"
		></bloc-panier>
	</div>
</div>

<!-- affichage grands écrans -->
<ngx-masonry class="grid hidden md:block" [updateLayout]="loading">
	<div ngxMasonryItem class="masonry-item col-12 md:col-6 xl:col-6 xxl:col-6">
		<p-blockUI [blocked]="loading" [target]="blocValeurMasonry"></p-blockUI>
		<bloc-valeurs
			#blocValeurMasonry
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3"
			(onShowDetailVentesClick)="showDetailVentesClick($event)"
		></bloc-valeurs>

		<ng-container *ngIf="uti_id == undefined">
			<p-blockUI [blocked]="loading" [target]="blocRendezVousMasonry"></p-blockUI>
			<bloc-rendez-vous
				#blocRendezVousMasonry
				[valeursN]="values['N']"
				[loading]="loading"
				class="block mb-3"
			></bloc-rendez-vous>
		</ng-container>
	</div>

	<div ngxMasonryItem class="masonry-item col-12 md:col-6 xl:col-6 xxl:col-6">
		<p-blockUI [blocked]="loading" [target]="blocEvolutionCAMasonry"></p-blockUI>
		<bloc-evolution-ca
			#blocEvolutionCAMasonry
			[evolutions]="evolutions"
			class="block mb-3 surface-card p-3"
		></bloc-evolution-ca>
	</div>

	<div ngxMasonryItem class="masonry-item col-12 md:col-6 xl:col-6 xxl:col-6">
		<p-blockUI [blocked]="loading" [target]="blocRatiosMasonry"></p-blockUI>
		<bloc-ratios
			#blocRatiosMasonry
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3 surface-card p-3"
		></bloc-ratios>
	</div>

	<div ngxMasonryItem class="masonry-item col-12 md:col-6 xl:col-6 xxl:col-6">
		<div>
			<p-blockUI [blocked]="loading" [target]="blocObjectifsMasonry"></p-blockUI>
			<bloc-objectifs
				#blocObjectifsMasonry
				[valeursN]="objectifs['N']"
				[valeursPast]="objectifs['N-1']"
				class="block mb-3 surface-card p-3"
			></bloc-objectifs>
		</div>
		<div>
			<p-blockUI [blocked]="loading" [target]="blocIndicateurParMilieuMasonry"></p-blockUI>
			<bloc-indicateurs-par-milieu
				#blocIndicateurParMilieuMasonry
				[valeursN]="values['N']"
				[valeursPast]="values['N-1']"
				class="block mb-3 "
			></bloc-indicateurs-par-milieu>
		</div>
	</div>

	<div ngxMasonryItem class="masonry-item col-12 md:col-6 xl:col-6 xxl:col-6">
		<p-blockUI [blocked]="loading" [target]="blocPanierMasonry"></p-blockUI>
		<bloc-panier
			#blocPanierMasonry
			[valeursN]="values['N']"
			[valeursPast]="values['N-1']"
			[loading]="loading"
			class="block mb-3"
		></bloc-panier>
	</div>

</ngx-masonry>


