<p-table
	[value]="values"
	[lazy]="true"
	[lazyLoadOnInit]="false"
	[loading]="loading"
	[autoLayout]="true"
	responsiveLayout="scroll"
	styleClass="p-datatable-sm p-datatable-gridlines table-hover"
>
	<ng-template pTemplate="emptymessage">
		<tr class="text-center">
			<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th colspan="100" class="text-center cell-warning">{{ mainHeader }}</th>
		</tr>
		<tr class="">
			<th class="text-center col-rang">Rang</th>
			<th class="text-center">Code postal</th>
			<th class="text-center">Ville</th>
			<th class="text-center">Pourcentage</th>
			<th class="text-center">{{ headerColNb }}</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
		<tr>
			<td class="text-center col-rang">{{ row.rang }}</td>
			<td class="text-center">{{ row.ope_code_postal_client }}</td>
			<td>{{ row.ope_ville_client }}</td>
			<td class="text-right">
				<number-display [value]="row.taux" type="short_percent"></number-display>
			</td>
			<td class="text-right">{{ row['nb_' + indicateur] }}</td>
		<tr>
	</ng-template>

</p-table>
