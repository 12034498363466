import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import {
	Prestataire,
	AuditPrestataire,
	PointControlePrestataire,
	IncidentPrestataire
} from '@app/moa/suivi-prestataires/prestataire.model';
import { MagasinService } from '@app/magasin/magasin.service';

import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	createDownloadFromHttpResponse,
	numericSort,
	prepareQueryParams,
	prepareQueryParamsForDownload
} from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class PrestataireService {

	constructor(
		private http: HttpClient,
		private magasinService: MagasinService
	) {

	}

	preparePrestataireFromServer(prestataire: Prestataire) {
		convertDateFieldsToDate(prestataire);
		if (prestataire.magasin) {
			this.magasinService.prepareMagasin(prestataire.magasin);
		}
		prestataire.label = `${prestataire.prs_societe}`;
		if (!prestataire.prs_actif) {
			prestataire.label = `${prestataire.label} (inactif)`;
		}
		return prestataire;
	}

	preparePrestatairesFromServer(prestataires: Prestataire[]) {
		prestataires.forEach((one: Prestataire) => {
			one = this.preparePrestataireFromServer(one);
		});
	}

	getPrestataire(prs_id: number) {
		return this.http.get<any>(`/prestataires/${prs_id}`)
		.pipe(
			map((response: any)=> {
				return this.preparePrestataireFromServer(response);
			})
		);
	}

	putPrestataire(prestataire: Prestataire) {
		return this.http.put<any>(`/prestataires/${prestataire.prs_id}`, prestataire);
	}

	getPrestataires(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/prestataires`, tmpParams)
		.pipe(
			map((response: any)=> {
				this.preparePrestatairesFromServer(response.prestataires);
				return response;
			})
		);
	}

	prepareAuditPrestataireFromServer(audit: AuditPrestataire) {
		convertDateFieldsToDate(audit);
		if (audit.magasin) {
			this.magasinService.prepareMagasin(audit.magasin);
		}
		if (audit.prestataire) {
			this.preparePrestataireFromServer(audit.prestataire);
		}
		numericSort(audit.details, 'pcp_ordre');
		// on ne peut éditer que l'année courante ou l'année prochaine
		audit.editable = audit.apr_annee >= new Date().getFullYear();
		audit.nb_statut_ok = 0;
		audit.nb_statut_ko = 0;
		audit.nb_statut_inconnu = 0;
		audit.nb_piece_jointe = 0;
		audit.nb_piece_jointe_possible = 0;
		audit.details.forEach((one: PointControlePrestataire) => {
			if (one.dap_statut_ok === true) {
				audit.nb_statut_ok++;
			}
			else if (one.dap_statut_ok === false) {
				audit.nb_statut_ko++;
			}
			else {
				audit.nb_statut_inconnu++;
			}
			if (one.dap_piece_jointe) {
				audit.nb_piece_jointe++;
			}
			if (one.pcp_piece_jointe_possible) {
				audit.nb_piece_jointe_possible++;
			}
		});

		return audit;
	}

	prepareAuditsPrestataireFromServer(audits: AuditPrestataire[]) {
		audits.forEach((one: AuditPrestataire) => {
			one = this.prepareAuditPrestataireFromServer(one);
		});
	}

	getAuditsPrestataire(prs_id: number) {
		return this.http.get<any>(`/prestataires/${prs_id}/audits`)
		.pipe(
			map((response: any)=> {
				this.prepareAuditsPrestataireFromServer(response);
				return response;
			})
		);

	}

	postAuditPrestataire(audit: AuditPrestataire) {
		return this.http.post<any>(`/prestataires/${audit.prs_id}/audits`, audit);
	}

	generateUrlAudit(audit: AuditPrestataire) {
		return `/prestataires/${audit.prs_id}/audits/${audit.apr_id}`;
	}

	generateUrlDetailAudit(audit: AuditPrestataire, detail: PointControlePrestataire) {
		const urlAudit: string = this.generateUrlAudit(audit);
		return `${urlAudit}/detail/${detail.pcp_id}`;
	}

	putAuditPrestataire(audit: AuditPrestataire) {
		const urlAudit: string = this.generateUrlAudit(audit);
		return this.http.put<any>(urlAudit, audit);
	}

	deleteAuditPrestataire(audit: AuditPrestataire) {
		let urlAudit: string = this.generateUrlAudit(audit);
		return this.http.delete<any>(urlAudit);
	}

	postDetailAuditPrestataire(audit: AuditPrestataire, detail: PointControlePrestataire) {
		const urlDetailAudit: string = this.generateUrlDetailAudit(audit, detail);
		return this.http.post<any>(`${urlDetailAudit}`, detail);
	}

	getPieceUrlUpload(audit: AuditPrestataire, detail: PointControlePrestataire) {
		const urlDetailAudit: string = this.generateUrlDetailAudit(audit, detail);
		return `${urlDetailAudit}/piece_jointe`;
	}

	deletePieceJointeAuditPrestataire(audit: AuditPrestataire, detail: PointControlePrestataire) {
		const urlDetailAudit: string = this.generateUrlDetailAudit(audit, detail);
		return this.http.delete<any>(`${urlDetailAudit}/piece_jointe`);
	}

	downloadPieceJointeAuditPrestataire(audit: AuditPrestataire, detail: PointControlePrestataire) {
		let tmpParams = prepareQueryParamsForDownload();
		const urlDetailAudit: string = this.generateUrlDetailAudit(audit, detail);
		return this.http.get<any>(`${urlDetailAudit}/piece_jointe/dl`, tmpParams)
		.pipe(map(
			(response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			}
		));
	}

	prepareIncidentPrestataireFromServer(incident: IncidentPrestataire) {
		convertDateFieldsToDate(incident, true);
		if (incident.magasin) {
			this.magasinService.prepareMagasin(incident.magasin);
		}
		if (incident.prestataire) {
			this.preparePrestataireFromServer(incident.prestataire);
		}
		return incident;
	}

	prepareIncidentsPrestataireFromServer(incidents: IncidentPrestataire[]) {
		incidents.forEach((one: IncidentPrestataire) => {
			one = this.prepareIncidentPrestataireFromServer(one);
		});
	}

	prepareIncidentPrestataire(incident: IncidentPrestataire) {
		let tmp = clone(incident);
		convertDateFieldsToString(tmp);
		delete tmp.magasin;
		delete tmp.prestataire;
		return tmp;
	}

	getIncidentsPrestataire(prs_id: number, params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/prestataires/${prs_id}/incidents`, tmpParams)
		.pipe(
			map((response: any)=> {
				this.prepareIncidentsPrestataireFromServer(response.incidents);
				return response;
			})
		);
	}

	postIncidentPrestataire(incident: IncidentPrestataire) {
		let tmp = this.prepareIncidentPrestataire(incident);
		return this.http.post<any>(`/prestataires/${incident.prs_id}/incidents`, tmp);
	}

	putIncidentPrestataire(incident: IncidentPrestataire) {
		let tmp = this.prepareIncidentPrestataire(incident);
		return this.http.put<any>(`/prestataires/${incident.prs_id}/incidents/${incident.ipr_id}`, tmp);
	}

	deleteIncidentPrestataire(incident: IncidentPrestataire) {
		return this.http.delete<any>(`/prestataires/${incident.prs_id}/incidents/${incident.ipr_id}`);
	}

}
