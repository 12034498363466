import {Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'monthNumberToDate'})
export class MonthNumberToDatePipe implements PipeTransform {

	constructor() {

	}

	transform(monthNumber: number) {
		let d = new Date();
		d.setMonth(monthNumber -1);
		return d;
	}
}
