<h1 class="flex-shrink-0">
	<titre-module>Parrainage YuccanLead</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[modes]="['libre','mois']"
			label="Période"
			[minDate]="minDate"
			[disabled]="loading"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="region_commerciale">Région</label>
		<region-commerciale-selector
			name="region_commerciale"
			[(ngModel)]="region_commerciale"
			[required]="false"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[autoSelectFirst]="false"
			[disabled]="loading || exportLoading"
			placeholder="France"
			(ngModelChange)="paramChanged()"
		></region-commerciale-selector>
	</div>

	<div class="field col-fixed">
		<label for="regroupement">Regroupement</label>
		<p-selectButton
			name="regroupement"
			[options]="optionsRegroupement"
			[(ngModel)]="regroupement"
			[disabled]="loading || exportLoading"
			(ngModelChange)="paramChanged()"
		></p-selectButton>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="paramChanged()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		#tableParrainage
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-reporting-activite"
		dataKey="key"
		[expandedRowKeys]="expandedRowKeys"
		(onRowExpand)="onRowExpand($event)"
		(onRowCollapse)="onRowCollapse($event)"
	>
		<ng-template pTemplate="emptymessage">
			<tr class="text-center">
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr class="">
				<th class="text-center col-rang not-last-frozen" pFrozenColumn>Rang</th>
				<th class="text-center" pFrozenColumn [ngSwitch]="regroupement">
					<span *ngSwitchCase="'region'">Région/Magasin</span>
					<span *ngSwitchCase="'magasin'">Magasin</span>
					<span *ngSwitchCase="'vendeur'">Vendeur</span>
				</th>
				<th
					class="text-center min-w-5rem max-w-10rem"
					[ngClass]="{'bg-primary-soft': col.ind_code == 'ca_prise_commande_htple'}"
					pSortableColumn="{{col.ind_code}}"
					*ngFor="let col of columnsIndicateur"
				>
					<ng-container>
						<indicateur-label-display [ind_code]="col.ind_code" [tooltipIcon]="true"></indicateur-label-display>
						<p-sortIcon field="{{col.ind_code}}"></p-sortIcon>
					</ng-container>
				</th>
			</tr>
		</ng-template>


		<ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-expanded="expanded">

			<tr [ngClass]="{'row-total': rowIndex == 0, 'row-group': row.group}">
				<td class="text-center col-rang not-last-frozen" pFrozenColumn>
					{{rowIndex +1}}
				</td>
				<td class="text-left cell-th" [ngClass]="{'cell-th': row.children}" pFrozenColumn>
					<div class="flex align-items-center">
						<ng-container *ngIf="row.group == true">
							<button
								type="button"
								pButton
								pRipple
								[pRowToggler]="row"
								class="p-button-text p-button-rounded p-button-sm"
								[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
							></button>
						</ng-container>
						{{ row.label }}
					</div>
				</td>

				<ng-container *ngFor="let col of columnsIndicateur">
					<ng-container *ngIf="col.regroupements.includes(regroupement)">
						<td class="text-center" [ngClass]="{'bg-primary-soft': col.ind_code == 'ca_prise_commande_htple'}">
							<indicateur-value-display
								[value]="row[col.ind_code]"
								[ind_code]="col.ind_code"
								[hideZero]="true"
							></indicateur-value-display>
							<div
								*ngIf="col.ind_code_date && row[col.ind_code_date]"
								title="{{row['label_long_'+ col.ind_code_date]}}"
								class="text-sm"
							>
								{{row['label_short_'+ col.ind_code_date]}}
							</div>
						</td>
					</ng-container>
				</ng-container>

			</tr>

		</ng-template>

		<ng-template pTemplate="rowexpansion" let-group>
			<tr class="" *ngFor="let row of group.children">
				<td class="text-left col-rang not-last-frozen" pFrozenColumn></td>
				<td class="text-left" pFrozenColumn >{{row.label}}</td>

				<ng-container *ngFor="let col of columnsIndicateur">
					<ng-container *ngIf="col.regroupements.includes(regroupement)">
						<td class="text-center" >
							<indicateur-value-display
								[value]="row[col.ind_code]"
								[ind_code]="col.ind_code"
								[hideZero]="true"
							></indicateur-value-display>
							<div
								*ngIf="col.ind_code_date && row[col.ind_code_date]"
								title="{{row['label_long_'+ col.ind_code_date]}}"
								class="text-sm"
							>
								{{row['label_short_'+ col.ind_code_date]}}
							</div>
						</td>
					</ng-container>
				</ng-container>

			</tr>
		</ng-template>

		<ng-template pTemplate="footer">
			<tr class="surface-0" *ngIf="total && values.length">
				<th class="text-center" colspan="2" pFrozenColumn>
					<b>TOTAL</b>
				</th>

				<td class="text-center font-bold" *ngFor="let col of columnsIndicateur">
					<indicateur-value-display [ind_code]="col.ind_code" [value]="total[col.ind_code]"></indicateur-value-display>
				</td>

			</tr>
		</ng-template>

	</p-table>
</div>
