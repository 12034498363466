<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Note Google</titre-module>
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>

<div class="grid">
<p-table
	[value]="[1]"
	responsiveLayout="scroll"
	class=""
	styleClass="p-datatable-sm p-datatable-gridlines table-auto"
>
	<ng-template pTemplate="header">
		<tr>
			<th class="cell-th">Magasin</th>
			<th class="text-left" colspan="3">
				<magasin-selector
					#magasinSelector
					name="magasin"
					[(ngModel)]="mag_id"
					[nullOption]="false"
					[nullIfInvalid]="false"
					[mesMagasins]="false"
					[autoSelectFirst]="true"
					[silentInitialChangeIfSame]="true"
					(ngModelChange)="paramChanged()"
					class="hide-print"
				></magasin-selector>
				<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
			</th>
		</tr>
		<ng-container *ngIf="simulation">
			<tr><th class="surface-ground print-surface-0 border-none" colspan="100"> </th></tr>
			<tr>
				<th class="cell-th cell-success">Note Google actuelle</th>
				<th class="text-right">
					<number-display [value]="simulation.note_google_actuelle" numberFormat=".0-2"></number-display>
				</th>
			</tr>
			<tr>
				<th class="cell-th cell-success">Nombre d'avis Google actuel</th>
				<th class="text-right">
					<number-display [value]="simulation.nb_avis_actuel"></number-display>
				</th>
			</tr>
			<tr><th class="surface-ground print-surface-0 border-none" colspan="100"> </th></tr>
			<tr>
				<th class="cell-th cell-success">Objectif Note Google</th>
				<th class="text-right cell-input">
					<input
						type="text"
						pInputText
						max="5"
						min="0"
						name="objectif_note_google"
						gzInputNumberFormatter
						[(ngModel)]="saisies.objectif_note_google"
						class="text-right hide-print"
						[ngClass]="{'ng-invalid': saisies.objectif_note_google && saisies.objectif_note_google <= simulation.note_google_actuelle || saisies.objectif_note_google && saisies.objectif_note_google >= note_max}"
					>
					<number-display
						[value]="saisies.objectif_note_google"
						class="show-print"
					></number-display>
				</th>
				<td class="clear-cell p-2 text-danger" colspan="100">
					<span *ngIf="saisies.objectif_note_google && saisies.objectif_note_google <= simulation.note_google_actuelle || saisies.objectif_note_google && saisies.objectif_note_google >= note_max">Veuillez saisir une note entre {{simulation.note_google_actuelle |number:'.0-2'}} et 4.99</span>
				</td>
			</tr>
			<tr class="">
				<th class="cell-th cell-warning">Nombre d'avis 5 étoiles nécessaires</th>
				<th class="text-right">
					<number-display [value]="starsNeeded()"></number-display>
				</th>
			</tr>
		</ng-container>

		<tr><td class="surface-ground print-surface-0 border-none" colspan="100"> </td></tr>

		<tr>
			<th class="surface-ground print-surface-0 border-none"></th>
			<th class="text-center relative cell-darker" colspan="100">Plan d'actions</th>
		</tr>
		<tr>
			<th class="surface-ground print-surface-0 border-none"></th>
			<th class="text-center col-mois cell-primary nowrap" *ngFor="let item of [].constructor(nb_mois); let i = index">Mois {{i+1}}</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body">
		<ng-container *ngIf="simulation">
		<tr *ngFor="let vendeur of vendeurs; let i = index">
			<td class="cell-th cell-primary">{{vendeur.uti_prenom}} {{vendeur.uti_nom}}</td>
			<td class="text-right cell-input" *ngFor="let item of [].constructor(nb_mois); let m = index">

				<input
					type="text"
					pInputText
					gzInputNumberFormatter
					[(ngModel)]="saisies.vendeurs[i][m]"
					[ngModelOptions]="{standalone: true}"
					class="text-right hide-print"
				>
				<number-display
					[value]="saisies.vendeurs[i][m]"
					class="show-print"
				></number-display>
			</td>
		</tr>
		<tr class="">
			<td class="cell-th cell-warning">Objectif nombre d'avis 5 étoiles</td>
			<td class="text-center" *ngFor="let item of [].constructor(nb_mois); let i = index">
				{{objectifMois(i)}}
			</td>
		</tr>

		<tr><td class="surface-ground print-surface-0 border-none" colspan="100"> </td></tr>

		<tr class="print-cell-taller">
			<td class="cell-th">Nombre d'avis 5 étoiles récoltés</td>
			<td *ngFor="let item of [].constructor(nb_mois); let i = index"></td>
		</tr>
		<tr><td class="surface-ground print-surface-0 border-none" colspan="100"> </td></tr>
		<tr class="print-cell-taller">
			<td class="cell-th">Evolution Note Google Magasin</td>
			<td *ngFor="let item of [].constructor(nb_mois); let i = index"></td>
		</tr>
	</ng-container>
	</ng-template>
</p-table>
</div>
