import { Component, NgModule, Type, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AuthService } from '@app/auth/auth.service';
import { GlobalModule } from '@global/global.module';
import { LienDocumentationModule } from '@app/lien-documentation';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { Prestataire, TypePrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { PrestataireDialogComponent } from '@app/moa/suivi-prestataires/prestataire-dialog';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { typesPrestataires } from '@app/moa/suivi-prestataires/prestataire.model';
import { TypePrestatairePipe } from '@app/moa/suivi-prestataires/type-prestataire.pipe';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { clone, isEmpty } from '@helpers/utils';

@Component({
	selector: 'prestataires',
	templateUrl: './prestataires.html',
	providers: [],
})
export class PrestatairesComponent {

	loading: boolean = false;

	params: any = {
		search: null,
		mag_id: null,
		prs_type: null
	}

	totalRecords: number = 0;
	prestataires: Prestataire[];

	typesPrestataires: any = typesPrestataires;

	droitsCanEdit: string[] = ['ag', 'chef_ventes', 'manager'];
	canEdit: boolean = false;

	constructor(
		private authService: AuthService,
		private dialogService: DialogService,
		private prestataireService: PrestataireService,
		private storageService: StorageService,
		private typePrestatairePipe: TypePrestatairePipe,
	) {

		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);
	}

	ngOnInit() {

		this.getParamSet();
		// this.load(); // sera lancé par le magasin-selector
	}

	getParamSet() {
		this.params = this.storageService.getForCurrentState('params', this.params);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('params', this.params);
	}

	resetFilter() {
		this.params.search = null;
		this.load();
	}

	load(event?: any) {
		if (this.params.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			let tmpParams = Object.assign({}, event, this.params);

			this.saveParamSet();

			this.prestataireService.getPrestataires(this.params)
			.subscribe({
				next: (response: any) => {
					this.totalRecords = response.total;
					this.prestataires = response.prestataires;
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	showEditPrestataireDialog(prestataire: Prestataire, index: number) {
		let header: string = this.typePrestatairePipe.transform(prestataire.prs_type)
		header = `${header} ${prestataire.label}`;

		const ref = this.dialogService.open(PrestataireDialogComponent, {
			data: {
				prestataire: clone(prestataire),
			},
			header: header,
			styleClass: 'max-w-50rem'
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.prestataires[index] = dialogResult;
			}
		});
	}
}


@NgModule({
	declarations: [
		PrestatairesComponent
	],
	exports: [
		PrestatairesComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		LienDocumentationModule,
		MagasinSelectorModule,
		PrintButtonModule,
		TitreModuleModule,
		UtilisateurSelectorModule,
	],
})
export class PrestatairesModule { }
