import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigService } from '@global/config.service';


@Component({
	selector: 'titre-module',
	template: `
		<ng-container *ngIf="titre">
			{{titre}}
		</ng-container>
		<ng-container *ngIf="!titre">
			<ng-content></ng-content>
		</ng-container>
	`,
})
export class TitreModuleComponent implements OnInit {

	@Input() moduleName: string;
	titre: string;

	constructor(private configService: ConfigService) {

	}

	ngOnInit() {
		if (this.moduleName) {
			this.titre = this.configService.getModuleTitle(this.moduleName);
		}
		else {
			this.titre = this.configService.getCurrentModuleTitle();
		}

	}
}

@NgModule({
	declarations: [
		TitreModuleComponent
	],
	exports: [
		TitreModuleComponent
	],
	imports: [
		CommonModule,
	],
})
export class TitreModuleModule { }
