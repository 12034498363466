import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { ObjectifsService, PlanActions } from '@app/objectifs/objectifs.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';


@Component({
	selector: 'plan-actions-dialog',
	templateUrl: './plan-actions-dialog.html'
})
export class PlanActionsDialogComponent implements OnInit, IEventListener {

	planActions: PlanActions;

	loading: boolean = false;

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private objectifsService: ObjectifsService,
		private eventManager: EventManagerService,
	) {
		this.planActions	= this.config.data.planActions;

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		this.loading = true;
		if (!this.planActions.pla_id) {
			this.objectifsService.postPlanActions(this.planActions)
			.subscribe({
				next: (response: any) => {
					// this.eventManager.emit('toast', {severity: 'success', summary: `Plan d'action créé`});
					this.dialogRef.close(this.planActions);
				}
			})
			.add(() => { this.loading = false; });
		}
		else {
			this.objectifsService.putPlanActions(this.planActions)
			.subscribe({
				next: (response: any) => {
					// this.eventManager.emit('toast', {severity: 'success', summary: `Plan d'action modifié`});
					this.dialogRef.close(this.planActions);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		InputTextModule,
		UtilisateurSelectorModule,
	],
	exports: [PlanActionsDialogComponent],
	declarations: [PlanActionsDialogComponent]
})
export class PlanActionsDialogModule { }

