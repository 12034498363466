import { Component, NgModule, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { SelectButtonModule } from 'primeng/selectbutton';

import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { RegionCommercialeSelectorModule } from '@app/magasin/region-commerciale-selector';
import { ReportingService } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';
import { TimeAgoPipe } from '@helpers/time-ago.pipe';
import { TitreModuleModule } from '@app/titre-module';

import {
	isEmpty,
	simpleDateToString
} from '@helpers/utils';

@Component({
	selector: 'parrainage-yuccanlead',
	templateUrl: './parrainage-yuccanlead.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TimeAgoPipe]
})
export class ParrainageYuccanleadComponent {

	@ViewChild('tableParrainage', {static: true}) table: Table;

	loading: boolean = false;
	exportLoading: boolean = false;
	loaded: {[key: string]: boolean} = {
		region: false,
		magasin: false,
		vendeur: false
	};

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	region_commerciale: string|null = null;
	dateRange: Date[] = [];
	regroupement: 'region'|'magasin'|'vendeur' = 'magasin';

	values: any[];
	total: any;
	// le programme "Parrainage" n'existait pas avant juillet 2023
	minDate: Date = new Date(2023, 6 ,1);

	expandedRowKeys: {[key: string]: any} = {};

	optionsRegroupement: any[] = [
		{label: 'Région', value:'region'},
		{label: 'Magasin', value:'magasin'},
		{label: 'Vendeur', value:'vendeur'},
	];

	optionsAffichage: any[] = [
		{label: 'Indicateurs', value:'indicateur'},
		{label: 'Ratios', value:'ratio'},
	];

	columnsIndicateur: any[] = [
		{
			ind_code: 'nb_ambassadeurs',
			ind_code_label: 'nb_ambassadeurs',
			regroupements: ['region', 'magasin', 'vendeur'],
			ind_code_date: 'date_dernier_ambassadeur',
		},
		{
			ind_code: 'nb_preinscriptions_envoyees',
			ind_code_label: 'nb_preinscriptions_envoyees',
			regroupements: ['region', 'magasin', 'vendeur'],
			ind_code_date: 'date_derniere_preinscription_envoyee',
		},
		{
			ind_code: 'nb_preinscriptions_acceptees',
			ind_code_label: 'nb_preinscriptions_acceptees',
			regroupements: ['region', 'magasin', 'vendeur'],
			// ind_code_date: 'date_derniere_preinscription_acceptee'
		},
		{
			ind_code: 'nb_mises_en_relation',
			ind_code_label: 'nb_mises_en_relation',
			regroupements: ['region', 'magasin', 'vendeur'],
			ind_code_date: 'date_derniere_mise_en_relation',
		},
		{
			ind_code: 'nb_mises_en_relation_non_contacte',
			ind_code_label: 'nb_mises_en_relation_non_contacte',
			regroupements: ['region', 'magasin', 'vendeur']
		},
		{
			ind_code: 'nb_mises_en_relation_prospect_contacte',
			ind_code_label: 'nb_mises_en_relation_prospect_contacte',
			regroupements: ['region', 'magasin', 'vendeur']
		},
		{
			ind_code: 'nb_mises_en_relation_bon_commande_signe',
			ind_code_label: 'nb_mises_en_relation_bon_commande_signe',
			regroupements: ['region', 'magasin', 'vendeur']
		},
		{
			ind_code: 'taux_concretisation',
			ind_code_label: 'taux_concretisation',
			regroupements: ['region', 'magasin', 'vendeur']
		},
	];

	constructor(
		private reportingService: ReportingService,
		private storageService: StorageService,
		private changeDetector: ChangeDetectorRef
	) {
		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.regroupement = this.storageService.getForCurrentState('region_commerciale', null);
		this.regroupement = this.storageService.getForCurrentState('regroupement', 'magasin');
		this.expandedRowKeys = this.storageService.getForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('region_commerciale', this.region_commerciale);
		this.storageService.setForCurrentState('regroupement', this.regroupement);
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onRowExpand(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onRowCollapse(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event?: any) {
		let tmpParams = {
			region_commerciale: this.region_commerciale,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		tmpParams = Object.assign(event, tmpParams);

		this.loading = true;
		this.reportingService.getParrainageYuccanlead(tmpParams)
		.subscribe({
			next: (response: any) => {
				if (!this.loaded[this.regroupement]) {
					if (isEmpty(this.expandedRowKeys)) {
						response.details.forEach((one: any) => {
							this.expandedRowKeys[one.key] = true;
						});
					}
					this.loaded[this.regroupement] = true;
				}
				this.values = response.details;
				this.total = response.total;
				this.saveParamSet();
			},
		})
		.add(() => {
			this.loading = false;
			this.changeDetector.detectChanges();
		})
	}

	prepare(values: any) {
		values.forEach
	}

	export() {
		let tmpParams = {
			region_commerciale: this.region_commerciale,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};

		this.exportLoading = true;
		this.reportingService.exportParrainageYuccanlead(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.changeDetector.detectChanges();
		});
	}

}
@NgModule({
	declarations: [
		ParrainageYuccanleadComponent
	],
	exports: [
		ParrainageYuccanleadComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		RegionCommercialeSelectorModule,
		TitreModuleModule,
	],
})
export class ParrainageYuccanleadModule { }
