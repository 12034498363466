import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AnalyticsService } from '@global/analytics.service';

import { prepareQueryParams } from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class CommandService {

	constructor(
		private analyticsService: AnalyticsService,
		private http: HttpClient,
	) {

	}

	execute(params: any) {
		this.analyticsService.trackEvent('execute_command', params);
		return this.http.post<any>(`/execute`, params)
	}

	getLogs(command: string, params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/logs/${command}`, tmpParams);
	}

}


