import { Component, NgModule, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TreeNode } from 'primeng/api';

import { TreeTableModule, TreeTable } from '@app/primeng-overrides/treetable';

import { AnneeSelectorModule } from '@app/annee-selector';
import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { MagasinService } from '@app/magasin/magasin.service';
import { NumberDisplayModule } from '@helpers/number-display';
import { ObjectifsService, ObjectifMagasin } from '@app/objectifs';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import {
	arrayOfMonths,
	capitalize,
	clone,
	isDefined,
	uid,
} from '@helpers/utils';

export type LigneTotal = {
	header: string;
	oma_ca_prise_commande_htple: number;
	objectifs_magasins: ObjectifMagasin[];
}

@Component({
	selector: 'objectifs-magasin',
	templateUrl: './objectifs-magasin.html',
	providers: [DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectifsMagasinComponent {

	@ViewChild('tableObjectifs') table: TreeTable;

	columns: any[];
	frozen_columns: any[];
	objectifs: TreeNode[] = [];
	magasins: any[];

	rangeMois: Date[] = [];

	loading: boolean = false;
	exportLoading: boolean = false;
	minYear: number;
	maxYear: number;
	annee: number;

	ind_code: string = 'ca_prise_commande_htple';

	valueBeforeEdit: any;

	search: string|undefined;
	searchableAttrs = [
		'mag_nom_court',
		'mag_region_commerciale',
	];

	collapsedRows: {[key: string]: any} = {};

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private configService: ConfigService,
		private datePipe: DatePipe,
		private magasinService: MagasinService,
		private objectifsService: ObjectifsService,
		private storageService: StorageService,
	) {
		this.minYear = configService.config.annee_reference;
		this.maxYear = new Date().getUTCFullYear() +1;
	}

	ngOnInit() {
		this.getParamSet();
	}

	indexTracker(index: number, item: any) {
		if (item.node && item.node.uid) return item.node.uid;
		return item.oma_mois || index;
	}

	saveParamSet() {
		this.storageService.set('annee', this.annee);
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	getParamSet() {
		this.annee = this.storageService.get('annee');
		this.collapsedRows = this.storageService.getForCurrentState('collapsedRows', {});
	}

	filter() {
		let hasVisible: boolean = false;

		this.objectifs.forEach((one :any) => {
			if (one.children) {
				one.data.hidden = !this.setVisibleState(one.children, this.search);
			}
		});
		this.objectifs = [...this.objectifs];
	}

	setVisibleState(nodes: any, search?: string): boolean {
		let hasVisible: boolean = false;
		nodes.forEach((one :any) => {
			if (this.patternMatch(one.data, search)) {
				one.data.hidden = false;
				hasVisible = true;
			}
			else {
				one.data.hidden = true;
			}
		});
		return hasVisible;
	}

	patternMatch(obj: any, search?: string) {
		search = search? search : '';
		const re = new RegExp(`.*${search}.*`, 'i');

		for (let i = 0; i < this.searchableAttrs.length; i++) {
			if (obj[this.searchableAttrs[i]] && re.test(obj[this.searchableAttrs[i]])) {
				return true;
			}
		}
		return false;
	}

	resetFilter() {
		this.search = undefined;
		this.filter();
	}

	onNodeExpand(event: any) {
		let mag_region_commerciale = event.node.data.mag_region_commerciale || undefined;
		if (mag_region_commerciale && isDefined(this.collapsedRows[mag_region_commerciale])) {
			delete this.collapsedRows[mag_region_commerciale];
			this.saveParamSet();
		}
	}

	onNodeCollapse(event: any) {
		let mag_region_commerciale = event.node.data.mag_region_commerciale || undefined;
		if (mag_region_commerciale && !isDefined(this.collapsedRows[mag_region_commerciale])) {
			this.collapsedRows[mag_region_commerciale] = mag_region_commerciale;
			this.saveParamSet();
		}
	}

	load() {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);
		this.saveParamSet();
		this.objectifsService.getObjectifsMagasins({annee: this.annee})
		.subscribe({
			next: (response: any) => {
				this.prepareColumns();
				this.magasins = response;
				let regions = this.magasinService.regroupeMagasinParRegion(response)
				this.prepareRegions(regions);
				this.objectifs = this.convertEntitiesToNodes(regions);
				// this.updateLigneTotal();
				this.filter();
			}
		})
		.add(() => {
			this.loading = false;
			this.changeDetectorRef.detectChanges();
		});
	}

	prepareColumns() {
		this.columns = [];

		this.frozen_columns = [
			{header: ' '}
		]

		this.rangeMois = arrayOfMonths(this.annee, 12);

		let nbMonths = this.rangeMois.length;
		for (let i = 0; i < nbMonths ; i++) {
			let oneMonth = this.rangeMois[i];
			this.columns = this.columns.concat([
				{
					header: capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true),
					code: this.ind_code,
					class: 'col-mois'
				}
			]);
		}
		this.columns.push({
			header: 'TOTAL',
			class: 'col-total'
		});
	}

	prepareRegions(regions: any[]) {
		regions.forEach((region:any) => {

			region.total = 0;
			region.oma_ca_prise_commande_htple = 0;
			region.objectifs_magasins = [];

			for (let i = 0; i < this.rangeMois.length; i++) {
				this.updateMoisRegion(region, region.magasins, i);
			}

			region.magasins.forEach((magasin: any) => {
				this.updateTotalNode(magasin);
			});

			this.updateTotalNode(region);
		});

		// let ligneTotal = this.prepareLigneTotal();
		// magasins.push(ligneTotal);
	}

	prepareLigneTotal() {
		let ligneTotal: LigneTotal = {
			header: 'Total attribué',
			oma_ca_prise_commande_htple: 0,
			objectifs_magasins: []
		};
		for (let i = 0; i < this.rangeMois.length; i++) {
			let tmpObjectif: ObjectifMagasin = {
				oma_mois: i
			}
			ligneTotal.objectifs_magasins.push(tmpObjectif)
		}
		return ligneTotal;
	}

	convertEntitiesToNodes(entities: any[]) {
		return entities.map((entity: any) => {
			let tmpNodeData: {[key: string]: any} = {};
			if (!entity.mag_id) {
				tmpNodeData.children = entity.magasins.map((magasin: any) => {
					return {
						data: clone(magasin),
						leaf: true,
						uid: uid()
					} as TreeNode;
				})
				delete entity.magasins;
			}

			tmpNodeData.data = clone(entity);
			tmpNodeData.leaf = false;
			tmpNodeData.expanded = !isDefined(this.collapsedRows[entity.mag_region_commerciale]);
			tmpNodeData.uid = uid();
			return tmpNodeData as TreeNode;
		});
	}

	updateAll(nodeRegion: any, nodeMagasin: any, indexMois: number) {
		this.updateMoisRegion(
			nodeRegion.data,
			nodeRegion.children.map((one:any) => {return one.data;}),
			indexMois
		);
		this.updateTotalNode(nodeMagasin.data);
		this.updateTotalNode(nodeRegion.data);
		// this.updateLigneTotal();

		this.changeDetectorRef.detectChanges();
	}

	updateAllById(mag_id: number, indexMois: number) {
		const magasin = this.magasins.find((one: any) => {
			return one.mag_id == mag_id;
		});
		// récupération du node de la région
		let nodeRegion = this.objectifs.find((one: any) => {
			return one.data.mag_region_commerciale == magasin.mag_region_commerciale;
		});
		if (nodeRegion && nodeRegion.children) {
			let nodeMagasin = nodeRegion.children.find((one: any) => {return one.data.mag_id == mag_id;});
			this.updateAll(nodeRegion, nodeMagasin, indexMois);
		}
	}

	updateMoisRegion(region: any, magasins: any[], indexMois: number) {
		region.objectifs_magasins[indexMois] = new ObjectifMagasin();
		region.objectifs_magasins[indexMois].oma_ca_prise_commande_htple = null;
		magasins.forEach((magasin: any) => {
			if (
				magasin.objectifs_magasins
				&& magasin.objectifs_magasins[indexMois]
				&& magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple
			) {
				if (magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple) {
					if (!region.objectifs_magasins[indexMois].oma_ca_prise_commande_htple) {
						region.objectifs_magasins[indexMois].oma_ca_prise_commande_htple = 0;
					}
					region.objectifs_magasins[indexMois].oma_ca_prise_commande_htple += magasin.objectifs_magasins[indexMois].oma_ca_prise_commande_htple;
				}
			}
		});
	}

	updateTotalNode(nodeData: any) {
		nodeData.oma_ca_prise_commande_htple = null;
		nodeData.objectifs_magasins.forEach((mois: any) => {
			if (mois.oma_ca_prise_commande_htple) {
				if (!nodeData.oma_ca_prise_commande_htple) {
					nodeData.oma_ca_prise_commande_htple = 0;
				}
				nodeData.oma_ca_prise_commande_htple += mois.oma_ca_prise_commande_htple;
			}
		});
	}

	updateTotalRegion(nodeData: any) {
		nodeData.oma_ca_prise_commande_htple = 0;
		nodeData.objectifs_magasins.forEach((mois: any) => {
			if (mois.oma_ca_prise_commande_htple) nodeData.oma_ca_prise_commande_htple += mois.oma_ca_prise_commande_htple;
		});
	}

	updateTotalMagasin(nodeData: any) {
		nodeData.oma_ca_prise_commande_htple = 0;
		nodeData.objectifs_magasins.forEach((mois: any) => {
			if (mois.oma_ca_prise_commande_htple) {
				nodeData.oma_ca_prise_commande_htple += mois.oma_ca_prise_commande_htple;
			}
		});
	}

	onEditComplete(event: any) {
		let valueBeforeEdit = this.valueBeforeEdit;
		if (event.data[event.field] != this.valueBeforeEdit) {
			this.objectifsService.putObjectifMagasin(event.data)
			.subscribe({
				next: (response: any) => {
					this.updateAllById(event.data.mag_id, event.data.oma_mois -1);
				},
				error: (error: any) => {
					event.data[event.field] = valueBeforeEdit;
					this.updateAllById(event.data.mag_id, event.data.oma_mois -1);
				}
			});
		}
	}

	onEditCancel(event: any) {
		event.data[event.field] = this.valueBeforeEdit;
	}

	onEditInit(event: any) {
		this.valueBeforeEdit = event.data[event.field];
	}

	export() {
		this.exportLoading = true;

		this.objectifsService.exportObjectifsMagasins({annee: this.annee})
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.changeDetectorRef.detectChanges();
		});
	}

}
@NgModule({
	declarations: [
		ObjectifsMagasinComponent
	],
	exports: [
		ObjectifsMagasinComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TreeTableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		AnneeSelectorModule,
		NumberDisplayModule,
		TitreModuleModule,
	],
})
export class ObjectifsMagasinModule { }
