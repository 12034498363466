<form #formIncidentPrestataire="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-fixed">
			<label for="range">Date incident</label>
			<div class="flex">
				<p-calendar
					name="ipr_date"
					[(ngModel)]="incidentPrestataire.ipr_date"
					[showTime]="false"
					[showIcon]="true"
					[showButtonBar]="true"
					[required]="true"
					[maxDate]="today"
					placeholder="jj/mm/aaaa"
					appendTo="body"
					[disabled]="loading"
				></p-calendar>
			</div>
		</div>

		<div class="field col-fixed" >
			<label for="ipr_client">Nom client</label>
			<input
				type="text"
				pInputText
				name="ipr_client"
				[required]="true"
				placeholder="Nom client"
				[(ngModel)]="incidentPrestataire.ipr_client"
				[disabled]="loading"
			>
		</div>

		<div class="field col-fixed" >
			<label for="ipr_option_winner">N° Option Winner</label>
			<input
				type="text"
				pInputText
				name="ipr_option_winner"
				[(ngModel)]="incidentPrestataire.ipr_option_winner"
				[disabled]="loading"
			>
		</div>

		<div class="field col-fixed">
			<label for="uti_vendeur">Vendeur</label>
			<utilisateur-selector
				name="uti_vendeur"
				[(ngModel)]="incidentPrestataire.uti_vendeur"
				[nullIfInvalid]="false"
				[required]="true"
				[mag_id]="prestataire.mag_id"
				[date_debut]="dateDebutVendeur"
				[date_fin]="today"
				[periode]="true"

				[disabled]="loading"
			></utilisateur-selector>
		</div>

		<div class="field col-12">
			<label for="ipr_nature">Nature du problème</label>
			<textarea
				pInputTextarea
				name="ipr_nature"
				[(ngModel)]="incidentPrestataire.ipr_nature"
				[autoResize]="true"
				[rows]="5"
				[required]="true"
				[disabled]="loading"
				placeholder="Nature du problème"
			></textarea>
		</div>

		<div class="field col-12">
			<label for="ipr_consequences">Impact (coût, mise en demeure, avis négatifs, ...)</label>
			<textarea
				pInputTextarea
				name="ipr_consequences"
				[(ngModel)]="incidentPrestataire.ipr_consequences"
				[autoResize]="true"
				[rows]="5"
				[disabled]="loading"
			></textarea>
		</div>

		<div class="field col-fixed">
			<label for="ipr_date_resolution">Date résolution</label>
			<div class="flex">
				<p-calendar
					name="ipr_date_resolution"
					[(ngModel)]="incidentPrestataire.ipr_date_resolution"
					[showTime]="false"
					[showIcon]="true"
					[showButtonBar]="true"
					[minDate]="incidentPrestataire.ipr_date"
					[maxDate]="today"
					appendTo="body"
				></p-calendar>
			</div>
		</div>

	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !formIncidentPrestataire.valid"
			(click)="submit()"
		></button>
	</div>

</form>
