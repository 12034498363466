import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AuthService } from '@app/auth/auth.service';
import { LienDocumentationModule } from '@app/lien-documentation';
import { PrintButtonModule } from '@app/print-button';
import { GlobalModule } from '@global/global.module';
import { MOAService } from '@app/moa/moa.service';
import {
	nbLignesVisionEntrepreneur,
	typesVisionEntrepreneur,
	TypeVisionEntrepreneur,
	VisionEntrepreneur
} from '@app/moa/moa.model';
import { TitreModuleModule } from '@app/titre-module';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { isEmpty } from '@helpers/utils';

@Component({
	selector: 'ma-vision-entrepreneur',
	templateUrl: './ma-vision-entrepreneur.html',
	providers: [],
})
export class MaVisionEntrepreneurComponent {

	loading: boolean;

	uti_id: number|null = null;
	saisies: any;

	nbLignesVisionEntrepreneur: number = nbLignesVisionEntrepreneur;
	typesVisionEntrepreneur: TypeVisionEntrepreneur[] = typesVisionEntrepreneur;

	isManager: boolean;
	userName: string;

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private moaService: MOAService,
	) {
		this.isManager = this.authService.checkIfHasRight('manager');
	}

	ngOnInit() {
		if (this.isManager && this.authService.user) {
			this.uti_id = this.authService.user.uti_id;
			this.userName = this.authService.user.uti_prenom + ' ' + this.authService.user.uti_nom;
			this.load();
		}
	}

	paramChanged() {
		this.load();
	}

	load(event?: any) {
		if (this.uti_id) {

		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			this.moaService.getMaVisionEntrepreneur(this.uti_id)
			.subscribe({
				next: (response: any) => {
					if (!isEmpty(response)) {
						this.saisies = response;
					}
				}
			})
			.add(() => { this.loading = false; });

		}
	}

	saisieChanged() {
		this.submit();
	}


	remove(attr: string, index: number) {
		let message = 'Souhaitez-vous vraiment supprimer cet élément ?';
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
				accept: () => {
					this.saisies[attr].splice(index, 1);
					this.saisies[attr].push(new VisionEntrepreneur());
				}
			}
		);
	}

	move(attr: string, index: number, direction: -1|1): void {
		if (
			this.saisies[attr].length == 1
			|| index == 0 && direction == -1
			|| index + 1 == this.saisies[attr].length && direction == 1
		) {
			return;
		}
		this.saisies[attr].splice(index + direction, 0, this.saisies[attr].splice(index, 1)[0]);
		this.submit();
	}

	up(attr: string, index: number) {
		this.move(attr, index, -1);
	}

	down(attr: string, index: number) {
		this.move(attr, index, 1);
	}

	disabledLigne(attr: string, index: number) {
		return this.saisies[attr][index-1] &&
			isEmpty(this.saisies[attr][index].titre)
	}

	submit() {
		if (this.uti_id) {
			// this.loading = true;

			this.moaService.postMaVisionEntrepreneur(this.uti_id, this.saisies)
			.subscribe({
				next: (response: any) => {
					this.saisies = response;
				}
			})
			// .add(() => { this.loading = false; });
		}
	}


}
@NgModule({
	declarations: [
		MaVisionEntrepreneurComponent
	],
	exports: [
		MaVisionEntrepreneurComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		TableModule,
		GlobalModule,
		UtilisateurSelectorModule,
		LienDocumentationModule,
		PrintButtonModule,
		TitreModuleModule,
	],
})
export class MaVisionEntrepreneurModule { }
