<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Qui fait quoi</titre-module>
	<print-button-simple
		[track]="true"
		[track_mag_id]="mag_id"
	>
	</print-button-simple>
</h1>


<div class="formgrid grid p-fluid flex-shrink-0">
	<p-table
	   [value]="[1]"
	   responsiveLayout="scroll"
	   class="col-fixed mb-2"
	   styleClass="p-datatable-sm p-datatable-gridlines table-auto"
   >
	   <ng-template pTemplate="body">
		   <tr>
			   <td class="cell-th">Magasin</td>
			   <td class="text-right">
				   <magasin-selector
					   #magasinSelector
					   name="magasin"
					   [(ngModel)]="mag_id"
					   [nullOption]="false"
					   [nullIfInvalid]="false"
					   [mesMagasins]="false"
					   [autoSelectFirst]="true"
					   [silentInitialChangeIfSame]="true"
					   (ngModelChange)="load()"
					   class="hide-print"
				   ></magasin-selector>
				   <span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
			   </td>
		   </tr>
	   </ng-template>
   </p-table>
</div>

<div class="min-table-height flex flex-row">

	<!-- Barre sur le côté n'apparaissant qu'en mode impression -->
	<div class="show-print h-full">
		<div class="bandeau-qui-fait-quoi h-full flex flex-column align-items-center justify-content-center pr-2">
			<div class="text-8xl w-min mt-2 text-justify">
				QUI FAIT QUOI
			</div>
		</div>
	</div>

	<p-table
		#quifaitquoi
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		class="w-full"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-qui-fait-quoi"
		dataKey="key"
		rowGroupMode="subheader"
		[expandedRowKeys]="expandedRowKeys"
		(onRowExpand)="onRowExpand($event)"
		(onRowCollapse)="onRowCollapse($event)"
	>
		<ng-template pTemplate="emptymessage">
			<tr class="text-center">
				<th class="text-center p-3" colspan="100">Aucune tâche n'est définie</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr class="surface-0">
				<th class="text-center col-6 orange-text-on-print black-bg-on-print">
					<div class="flex flex-column align-items-center">
						<div class="text-2xl">
							Qui fait quoi ?
						</div>
						<div class="text-sm">
							Rappel: Liste des tâches non exhaustive, doit être complétée en fonction
							de l'organisation du magasin
						</div>
					</div>
				</th>

				<th
					*ngFor="let field of responsibilityFields"
					class="text-center black-bg-on-print white-text-on-print "
				>
					{{field.columnName}}
				</th>
				<th *ngIf="canEdit" class="hide-print"></th>
			</tr>
		</ng-template>

		<!-- Groupe/catégorie -->
		<ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-expanded="expanded">

			<tr
				class="w-full"
				[ngClass]="{'row-total': rowIndex == 0, 'row-group': row.group}"
				[ngStyle]="{'background-color': row.backgroundColor, color: row.textColor}"
			>

				<td class="text-left nowrap"
					[ngClass]="{'cell-th': row.children}"
					[ngStyle]="{'background-color': row.backgroundColor}"
				>
					<div class="flex flex-row align-items-center">
						<div class="w-full text-center">
							{{ row.label }}
						</div>
					</div>
				</td>

				<td
					*ngFor="let field of responsibilityFields"
					class="text-center"
					[ngStyle]="{'background-color': row.backgroundColor, color: row.textColor}"
				>
					<span class="show-print" *ngIf="rowIndex > 0">
						{{field.columnName}}
					</span>
				</td>

				<td class="text-center hide-print" *ngIf="canEdit">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						class="p-button p-button-sm w-max"
						label="Nouvelle tâche"
						(click)="addSingleQuiFaitQuoi(row)"
					></button>
				</td>
		</ng-template>

		<!-- Tâche -->
		<ng-template pTemplate="rowexpansion" let-group>
			<tr class="surface-0" *ngFor="let task of group.children">
				<!-- Libellé -->
				<td class="text-left">
					<div class="flex align-items-baseline">
						<div class="w-full">

							<ng-container *ngIf="!task.editing">
								{{task.qft_libelle}}
							</ng-container>

							<input
								*ngIf="canEdit && task.editing"
								type="text"
								class="w-full hide-print"
								pInputText
								placeholder="Nom de la tâche"
								[(ngModel)]="task.newQftLibelle"
								[ngModelOptions]="{standalone: true}"
								[disabled]="loading"
							>
						</div>

						<!-- Boutons -->
						<ng-container *ngIf="canEdit">
							<!-- Bouton d'édition/sauvegarde -->
							<button
								type="button"
								pButton
								[icon]="task.editing ? 'pi pi-save' : 'pi pi-pencil'"
								class="ml-1 p-button-xs p-button-text hide-print"
								*ngIf="!task.common"
								(click)="editTask(task)"
							></button>

							<!-- Bouton d'annulation d'édition -->
							<button
								type="button"
								pButton
								icon="pi pi-times"
								class="p-button-xs p-button-text hide-print"
								*ngIf="task.editing"
								(click)="cancelTaskEdition(task)"
							></button>

							<!-- Bouton de suppression -->
							<button
								type="button"
								pButton
								icon="pi pi-trash"
								class="p-button-xs p-button-text p-button-danger hide-print"
								*ngIf="!task.common && !task.editing"
								(click)="deleteTask(task)"
							></button>
						</ng-container>
					</div>
				</td>

				<!-- Responsabilité pour chaque poste -->
				<td *ngFor="let field of responsibilityFields"
					class="text-center"
					[ngStyle]="{'background-color': group.backgroundColor, width: '12rem'}"
				>
					<span *ngIf="!canEdit">
						{{formatResponsiblity(task[field.name] ?? task[field.preconisation])}}
					</span>
					<span *ngIf="canEdit" class="show-print">
						<p-tag *ngIf="isValidResponsibility(task[field.name] ?? task[field.preconisation])">
							{{formatResponsiblity(task[field.name] ?? task[field.preconisation])}}
						</p-tag>
					</span>
					<p-dropdown
						class="hide-print"
						styleClass="w-full text-left"
						*ngIf="canEdit"
						[(ngModel)]="task[field.name]"
						[ngModelOptions]="{standalone: true}"
						[disabled]="loading"
						[options]="responsibilityDropdownChoices"
						(onChange)="updateTask(task)"
						optionValue="value"
						optionLabel="label"
						appendTo="body"
					>
						<ng-template let-item pTemplate="selectedItem">
							<div class="selector-item">
								<div [ngClass]="{'inactive-text': item.value === null}">
									{{
										(item.value !== null) ?
											item.label
											: formatResponsiblity(task[field.preconisation] ?? '-')
									}}</div>
								</div>
						</ng-template>

						<ng-template let-item pTemplate="item">
							<div class="selector-item">
								<div>{{item.label}}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</td>

				<td class="text-center hide-print" *ngIf="canEdit"></td>
			</tr>
		</ng-template>

	</p-table>
</div>
