import { NgModule, Component, Input, forwardRef, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable } from 'rxjs';

import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { GlobalModule } from '@global/global.module';
import { clone, intersects, dedup, simpleDateToString } from '@helpers/utils';
import { Utilisateur } from '../utilisateur.model';


@Component({
	selector: 'utilisateur-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => UtilisateurSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			[filter]="options.length > 5"
			filterBy="uti_nom,uti_prenom,uti_email"
			[filterPlaceholder]="filterPlaceholder"
			optionValue="uti_id"
			appendTo="body"
		>
			<ng-template pTemplate="selectedItem" let-selectedItem>
				<div
					class="selector-item selected-item"
					[ngClass]="{'disabled': selectedItem.present === false}"
					[title]="selectedItem.present === false? 'Plus actif' : ''"
				>
					<div>{{selectedItem|utilisateur}}</div>
				</div>
			</ng-template>
			<ng-template pTemplate="item" let-item>
				<div
					class="selector-item"
					[ngClass]="{'disabled': item.present === false}"
					[title]="item.present === false? 'Plus actif' : ''"
				>
					<div>{{item|utilisateur}}</div>
				</div>
			</ng-template>
		</p-dropdown>
	`
})
export class UtilisateurSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: number|null;
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() vendeurOnly: boolean;
	@Input() franchisesOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() mag_id: number|null|undefined;
	@Input() periode: boolean;
	@Input() date_debut: string|Date;
	@Input() date_fin: string|Date;
	@Input() profils: string[] = [];

	@Output() onSelectionChange = new EventEmitter<Utilisateur>();

	filters: {[key: string]: any} = {};
	options: any[] = [];
	initialized: boolean = false;

	loading: boolean = false;
	_selected: Utilisateur;


	constructor(
		private utilisateurService: UtilisateurService,
	) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (
			typeof changes.mag_id != 'undefined' && changes.mag_id.currentValue != changes.mag_id.previousValue
			|| typeof changes.date_debut != 'undefined' && simpleDateToString(changes.date_debut.currentValue) != simpleDateToString(changes.date_debut.previousValue)
			|| typeof changes.date_fin != 'undefined' && simpleDateToString(changes.date_fin.currentValue) != simpleDateToString(changes.date_fin.previousValue)
			|| changes.franchisesOnly
		) {
			this.load();
		}
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: number|null) {
		this.innerValue = value;
		this._selected = this.options.find((one: Utilisateur) => {return one.uti_id  == value});
		this.onSelectionChange.emit(this._selected);
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: number|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	getEntityName() {
		const found = this.options.find(one => { return one.uti_id == this.innerValue; });
		if (found) {
			return `${found.uti_prenom} ${found.uti_nom}`;
		}
		return null;
	}

	load() {
		let call: Observable<any>|undefined = undefined;

		if (
			this.periode
			&& this.date_debut
			&& this.date_fin
			&& this.date_debut <= this.date_fin
		) {
			let params = {
				mag_id: this.mag_id,
				date_debut: simpleDateToString(this.date_debut, true),
				date_fin: simpleDateToString(this.date_fin, true)
			}
			call = this.utilisateurService.getVendeursPeriode(params);
		}
		else if (this.mag_id) {
			if (this.vendeurOnly) {
				call = this.utilisateurService.getVendeurs(this.mag_id);
			}
			else {
				call = this.utilisateurService.getCollaborateurs(this.mag_id);
			}
		}
		else if (this.franchisesOnly) {
			call = this.utilisateurService.getFranchises();
		}

		if (typeof call != 'undefined' && !this.loading) {
			this.loading = true;
			this.innerValue = null; // pour éviter des incohérences, on reset la sélection avant de charger
			call.subscribe({
				next: (response: any) => {
					this.setOptions(response);
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	setOptions(options: any[]) {
		let tmp = options;
		// si on a fourni une liste de profils pour filtrer
		if (this.profils.length) {
			tmp = options.filter((one: Utilisateur) => {
				let oneProfils = dedup(this.utilisateurService.extractProfilsCollaborateur(one));
				return intersects(oneProfils, this.profils);
			})
		}
		this.options = tmp;
		this.setInitialValue();
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (this.options.length) {
			if (this.options.findIndex(one => {return one.uti_id == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectIfSingle && this.options.length === 1 || !this.nullIfInvalid) {
					this.innerValue = this.options[0].uti_id;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
		GlobalModule
	],
	exports: [UtilisateurSelectorComponent],
	declarations: [UtilisateurSelectorComponent]
})
export class UtilisateurSelectorModule { }
