<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Audit de Conformité</titre-module>
	<print-button-simple [track]="true"></print-button-simple>
	<lien-documentation prm_code="documentation_audit_conformite"></lien-documentation>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						[silentInitialChangeIfSame]="true"
						(ngModelChange)="load()"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
				<td class="clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text"
						[disabled]="loading"
						[loading]="loading"
						(click)="load()"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-trash"
						class="p-button label-sm-none"
						[disabled]="loading || creation == true"
						[loading]="deletionLoading"
						label="Supprimer l'audit"
						title="Supprimer l'audit"
						(click)="confirmDeleteAudit()"
						*ngIf="canCreateAudit"
					></button>
				</td>
			</tr>
			<tr *ngIf="audit && audit.mag_date_audit">
				<td class="cell-th">Audit réalisé par</td>
				<td>
					{{audit.uti_auditeur|utilisateur}}
				</td>
			</tr>
			<tr *ngIf="audit && audit.mag_date_audit">
				<td class="cell-th">Date de l'audit</td>
				<td class="text-left">
					{{audit.mag_date_audit|date: 'shortDate'}}
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>

<div>
	<div *ngIf="loaded && !audit">
		<p-message severity="info" text="Ce magasin n'a pas d'audit de conformité disponible"></p-message>
	</div>

	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		[autoLayout]="true"
		responsiveLayout="scroll"

		styleClass="p-datatable-sm p-datatable-gridlines"
		*ngIf="audit"
	>
		<ng-template pTemplate="header">
			<!-- <tr>
				<th></th>
				<th class="text-center">Point</th>
				<th class="text-center">Interlocuteur</th>
				<th></th>
			</tr> -->
		</ng-template>

		<ng-template pTemplate="body">

			<ng-container *ngFor="let groupe of groupesPointsAuditConformite; first as firstGroupe">
				<tr class="" *ngIf="!firstGroupe">
					<td class="clear-cell p-1" colspan="100"></td>
				</tr>
				<tr class="">
					<td class="text-center" colspan="100">
						<h3 class="mb-0">{{groupe.label}}</h3>
					</td>
				</tr>
				<tr>
					<td class="cell-th text-left">Point</td>
					<td class="cell-th text-center">Interlocuteur</td>
					<td class="cell-th">OK</td>
					<td class="cell-th">KO</td>
				</tr>

				<ng-container *ngFor="let point of splitPointsAudit[groupe.pac_groupe]; index as indexPoint">

					<tr class="hide-print" *ngIf="point.pac_est_titre">
						<td class="clear-cell p-1" colspan="100"></td>
					</tr>
					<tr class="" [ngClass]="{'row-separator': point.pac_est_titre}">

						<td [ngClass]="{'text-base': point.pac_est_titre}">{{point.pac_libelle}}</td>
						<td class="text-center">
							<span *ngIf="point.pac_est_titre">Interlocuteur</span>
							<span *ngIf="!point.pac_est_titre">{{point.pac_interlocuteur}}</span>
						</td>

						<td class="text-center nowrap">
							<span *ngIf="point.pac_est_titre">OK</span>
							<p-radioButton
								name="point.pac_id"
								[value]="true"
								[(ngModel)]="point.auc_reponse"
								(ngModelChange)="saisieChanged()"
								[disabled]="!canEdit"
								*ngIf="!point.pac_est_titre"
							></p-radioButton>
						</td>
						<td class="text-center nowrap">
							<span *ngIf="point.pac_est_titre">KO</span>
							<p-radioButton
								name="point.pac_id"
								[value]="false"
								[(ngModel)]="point.auc_reponse"
								(ngModelChange)="saisieChanged()"
								[disabled]="!canEdit"
								*ngIf="!point.pac_est_titre"
							></p-radioButton>
						</td>

					</tr>
				</ng-container>

			</ng-container>

		</ng-template>

	</p-table>

</div>

