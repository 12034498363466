import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { FournisseurExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'fournisseurExposition'})
export class FournisseurExpositionPipe implements PipeTransform {

	constructor(private achatsFournisseursService: AchatsFournisseursService) {}

	transform(code: string): string {
		const found: FournisseurExposition|undefined = this.achatsFournisseursService.fournisseursExpositions.find(
			(one: FournisseurExposition) => {
				return one.foe_code.toLowerCase() == code.toLowerCase();
			}
		);
		if (found) {
			return found.foe_nom;
		}
		return code;
	}
}
