import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'top10',
	templateUrl: './top10.html',
})
export class Top10Component {

	@Input() values: any[] = [];
	@Input() indicateur: 'devis'|'ventes' = 'devis';

	loading: boolean = false;

	mainHeader: string = 'TOP 10';
	headerColNb: string = 'Nb';


	constructor(

	) {

	}

	ngOnInit() {
		switch (this.indicateur) {
			case 'devis':
				this.mainHeader = `${this.mainHeader} Devis`;
				this.headerColNb = `${this.headerColNb} Devis`;
				break;
			case 'ventes':
				this.mainHeader = `${this.mainHeader} Ventes`;
				this.headerColNb = `${this.headerColNb} Ventes`;
				break;
		}

	}
}
@NgModule({
	declarations: [
		Top10Component
	],
	exports: [
		Top10Component
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		GlobalModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		NumberDisplayModule
	],
})
export class Top10Module { }
