import { Component, NgModule, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeNode } from 'primeng/api';
import { TreeTableModule, TreeTable } from '@app/primeng-overrides/treetable';

import { AnneeSelectorModule } from '@app/annee-selector';
import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { ReportingService } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';

import {
	arrayOfMonths,
	capitalize,
	clone,
	isDefined,
	uid,
} from '@helpers/utils';

@Component({
	selector: 'reporting-resultat',
	templateUrl: './reporting-resultat.html',
	providers: [DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingResultatComponent {

	loading: boolean = false;
	ready: boolean = false;
	exportLoading: boolean = false;

	values: any[];
	grandTotal: any = {};

	columns: any[];
	frozen_columns: any[];
	collapsedRows: {[key: string]: any} = {};

	rangeMois: Date[] = [];

	today: Date = new Date();
	minYear: number;
	params: {[key: string]: any} = {
		annee: null,
		mag_id: null
	}

	search: string|undefined;
	searchableAttrs = [
		'mag_nom',
		'mag_region_commerciale',
	];

	indicateurs = [
		// {ind_code: 'ca_prise_commande_htple'},
		// {ind_code: 'ca_prise_commande_htple_b2b'},
		// {ind_code: 'objectif_ca_prise_commande_htple'},
		{ind_code: 'taux_mt_ventes_htple_mt_objectif_htple'},
		{ind_code: 'ca_prise_commande_htple', past: true},
		{ind_code: 'evolution_ca_prise_commande_htple_n_moins_1'},
		{ind_code: 'taux_marge_commerciale'},
		{ind_code: 'taux_marge_commerciale', past: true},

		{separator: 'Indicateurs VENTE'},
		{ind_code: 'nb_entrees'},
		{ind_code: 'nb_entrees', past: true},
		{ind_code: 'nb_retours'},
		{ind_code: 'nb_retours', past: true},
		{ind_code: 'nb_prises_rdv'},
		{ind_code: 'nb_prises_rdv', past: true},
		{ind_code: 'nb_devis'},
		{ind_code: 'nb_devis', past: true},
		{ind_code: 'nb_ventes'},
		{ind_code: 'nb_ventes', past: true},
		{ind_code: 'avg_vente_htple'},
		{ind_code: 'avg_vente_htple', past: true},
		{ind_code: 'evolution_avg_vente_htple_n_moins_1'},
		{ind_code: 'nb_rdv_demandes'},
		{ind_code: 'nb_rdv_demandes', past: true},
		{ind_code: 'evolution_nb_rdv_demandes_n_moins_1'},

		{separator: 'RATIOS'},
		{ind_code: 'taux_nb_devis_nb_entrees'},
		{ind_code: 'taux_nb_devis_nb_entrees', past: true},
		{ind_code: 'taux_nb_ventes_nb_devis'},
		{ind_code: 'taux_nb_ventes_nb_devis', past: true},
		{ind_code: 'taux_nb_ventes_nb_entrees'},
		{ind_code: 'taux_nb_ventes_nb_entrees', past: true},

		{separator: 'Facturation/Achat'},
		{ind_code: 'mt_facture_ht'},
		{ind_code: 'mt_achats_factures'},
		{ind_code: 'mt_achats_commandes_ht'},
		{ind_code: 'ecart_prix_achat'},
		{ind_code: 'mt_marge_facture_ht'},
		{ind_code: 'taux_marge_facture'},
		{ind_code: 'taux_marge_facture', past: true},

		{separator: 'SAV'},
		{ind_code: 'nb_sav'},
		{ind_code: 'taux_nb_sav_nb_ventes'},
		{ind_code: 'mt_sav'},
		{ind_code: 'taux_mt_sav_ca_prise_commande_htple'},
	];

	frozenIndicateurs = [
		{ind_code: 'ca_prise_commande_htple'},
		{ind_code: 'ca_prise_commande_htple_b2b'},
		{ind_code: 'objectif_ca_prise_commande_htple'},
	];

	allIndicateurs: any = [];

	showPast: boolean = false;

	dateDebut: Date = new Date();
	dateFin: Date = new Date();


	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private configService: ConfigService,
		private datePipe: DatePipe,
		private reportingService: ReportingService,
		private storageService: StorageService,
	) {

		this.allIndicateurs = [...this.frozenIndicateurs, ...this.indicateurs];
	}

	ngOnInit() {
		this.minYear = this.configService.config.annee_reference;
		this.getParamSet();
	}

	getParamSet() {
		for (let prop in this.params) {
			this.params[prop] = this.storageService.getForCurrentState(prop, null);
		}
		this.collapsedRows = this.storageService.getForCurrentState('collapsedRows', this.collapsedRows);
	}

	saveParamSet() {
		for (let prop in this.params) {
			this.storageService.setForCurrentState(prop, this.params[prop]);
		}
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	onRowExpand(event: any) {
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	onRowCollapse(event: any) {
		this.storageService.setForCurrentState('collapsedRows', this.collapsedRows);
	}

	indexTracker(index: number, item: any) {
		if (item.node && item.node.uid) return item.node.uid;
		// return item.ove_mois || index;
	}

	filter() {
		let hasVisible: boolean = false;

		this.values.forEach((one :any) => {
			if (one.children) {
				one.data.hidden = !this.setVisibleState(one.children, this.search);
			}
		});
		this.values = [...this.values];
	}

	setVisibleState(nodes: any, search?: string): boolean {
		let hasVisible: boolean = false;
		nodes.forEach((one :any) => {
			if (this.patternMatch(one.data, search)) {
				one.data.hidden = false;
				hasVisible = true;
			}
			else {
				one.data.hidden = true;
			}
		});
		return hasVisible;
	}

	patternMatch(obj: any, search?: string) {
		search = search? search : '';
		const re = new RegExp(`.*${search}.*`, 'i');

		for (let i = 0; i < this.searchableAttrs.length; i++) {
			if (obj[this.searchableAttrs[i]] && re.test(obj[this.searchableAttrs[i]])) {
				return true;
			}
		}
		return false;
	}

	resetFilter() {
		this.search = undefined;
		this.filter();
	}

	onNodeExpand(event: any) {
		let mag_id = event.node.data.mag_id || undefined;
		if (mag_id && isDefined(this.collapsedRows[mag_id])) {
			delete this.collapsedRows[mag_id];
			this.saveParamSet();
		}
	}

	onNodeCollapse(event: any) {
		let mag_id = event.node.data.mag_id || undefined;
		if (mag_id && !isDefined(this.collapsedRows[mag_id])) {
			this.collapsedRows[mag_id] = mag_id;
			this.saveParamSet();
		}
	}

	anneeChange(event: any) {
		this.dateDebut = new Date(event, 0, 1);
		this.dateFin = new Date(event +1, 0, -1);
	}

	paramChanged() {
		this.load();
	}

	load(event?: any) {
		this.saveParamSet();

		this.loading = true;
		this.detectChanges()

		this.reportingService.getReportingResultat(this.params)
		.subscribe({
			next: (response: any) => {
				this.prepareColumns();
				this.values = this.convertEntitiesToNodes(response.magasins);
				this.grandTotal = response.total;
			}
		})
		.add(() => {
			this.loading = false;
			this.detectChanges();
		});
	}

	convertEntitiesToNodes(entities: any[]) {
		return entities.map((entity: any) => {
			let tmpNodeData: {[key: string]: any} = {};
			tmpNodeData.data = clone(entity);
			tmpNodeData.leaf = false;

			let arrayOfIndicateurs = this.arrayOfMonthsToArrayOfIndicateurs(entity);
			tmpNodeData.children = arrayOfIndicateurs.map((indicateur: any) => {
				indicateur.uid = uid();
				return {
					data: indicateur,
					leaf: true,
					uid: uid()
				} as TreeNode;
			});
			delete tmpNodeData.mois;

			tmpNodeData.expanded = !isDefined(this.collapsedRows[entity.mag_id]) || entities.length > 5;
			tmpNodeData.uid = uid();
			return tmpNodeData as TreeNode;
		});
	}

	prepareColumns() {
		this.columns = [];

		this.frozen_columns = [
			{header: ' '}
		]

		this.rangeMois = arrayOfMonths(this.params.annee, 12);
		const todayMonthString = this.datePipe.transform(this.today, 'yyyy-MM');

		let nbMonths = this.rangeMois.length;
		for (let i = 0; i < nbMonths ; i++) {
			let oneMonth = this.rangeMois[i];
			const currentMonth = todayMonthString == this.datePipe.transform(oneMonth, 'yyyy-MM');
			this.columns = this.columns.concat([
				{
					header: capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true),
					class: 'col-mois',
					disabled: oneMonth > this.today,
					currentMonth: currentMonth
				}
			]);
		}
		this.columns.push({
			header: 'TOTAL',
			class: 'col-total'
		});
	}

	arrayOfMonthsToArrayOfIndicateurs(magasin: any) {
		let arrayOfIndicateurs: any[] = [];
		this.indicateurs.forEach((indicateur: any) => {
			if (indicateur.separator) {
				arrayOfIndicateurs.push({
					separator: indicateur.separator
				});
			}
			else {
				const attrPeriode = (indicateur.past)? 'N-1' : 'N';
				let mois = [];
				if (typeof magasin[attrPeriode] == 'undefined') {
					magasin[attrPeriode] = {
						total: {},
						mois: []
					};
				}
				for (let i = 0; i < this.rangeMois.length; i++) {
					let tmp = {};
					if (magasin[attrPeriode].mois[i]) {
						tmp = {
							annee: magasin[attrPeriode].mois[i].annee,
							mois: magasin[attrPeriode].mois[i].mois,
							value: magasin[attrPeriode].mois[i][indicateur.ind_code],
							futur: !!magasin[attrPeriode].mois[i].futur
						}
					}
					mois.push(tmp);
				}

				arrayOfIndicateurs.push(
					Object.assign({}, indicateur, {
						mois: mois,
						total: magasin[attrPeriode].total && magasin[attrPeriode].total[indicateur.ind_code]? magasin[attrPeriode].total[indicateur.ind_code] : null
					})
				);
			}

		});
		return arrayOfIndicateurs;
	}

	export() {
		this.exportLoading = true;
		this.detectChanges();
		this.reportingService.exportReportingResultat(this.params)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
			this.detectChanges();
		});
	}

	detectChanges() {
		this.changeDetectorRef.detectChanges();
	}

}
@NgModule({
	declarations: [
		ReportingResultatComponent
	],
	exports: [
		ReportingResultatComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		TreeTableModule,
		AnneeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		TitreModuleModule,
	],
})
export class ReportingResultatModule { }
