import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { StateService, UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AuthService } from '@app/auth/auth.service';
import { EvaluationCollaborateur } from '@app/moa/referentiel-competences/referentiel-competences.model';
import { EvaluationCollaborateurDialogComponent } from '@app/moa/referentiel-competences/evaluation-collaborateur-dialog';
import { GlobalModule } from '@global/global.module';
import { LienDocumentationModule } from '@app/lien-documentation';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';


@Component({
	selector: 'evaluations-collaborateurs',
	templateUrl: './evaluations-collaborateurs.html',
	providers: [],
})
export class EvaluationsCollaborateursComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;
	loaded: boolean = false;

	mag_id: number;
	showCollaborateursAbsents: boolean = false;

	totalRecords: number = 0;
	values: Utilisateur[];

	droitsCanEdit: string[] = ['animateur', 'manager', 'chef_ventes'];
	canEdit: boolean = false;

	expandedRowKeys: any = {};

	constructor(
		private authService: AuthService,
		private dialogService: DialogService,
		private referentielCompetencesService: ReferentielCompetencesService,
		private storageService: StorageService,
		private stateService: StateService,
		private utilisateurPipe: UtilisateurPipe,
	) {

		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);
	}

	ngOnInit() {
		this.getParamSet();
		// this.load(); // sera lancé par le magasin-selector
	}

	getParamSet() {
		this.mag_id = this.storageService.getForCurrentState('mag_id', this.mag_id);
		this.showCollaborateursAbsents = this.storageService.getForCurrentState('showCollaborateursAbsents', this.showCollaborateursAbsents);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('mag_id', this.mag_id);
		this.storageService.setForCurrentState('showCollaborateursAbsents', this.showCollaborateursAbsents);
	}

	load(event?: any) {
		if (this.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			this.saveParamSet();

			this.referentielCompetencesService.getCollaborateurs(this.mag_id)
			.subscribe({
				next: (response: any) => {
					this.loaded = true;
					this.values = response;
					this.values.forEach((one: Utilisateur) => {
						this.expandedRowKeys[one.uti_id] = true;
					})
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	showEvaluationCollaborateurDialog(collaborateur?: Utilisateur) {
		let header = `Nouvelle évaluation`;

		const ref = this.dialogService.open(EvaluationCollaborateurDialogComponent, {
			data: {
				evaluation: collaborateur,
				collaborateur: collaborateur,
				mag_id: this.mag_id
			},
			header: header,
			styleClass: 'max-w-20rem'
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.stateService.go('evaluation-collaborateur', {mag_id: this.mag_id, evc_id: dialogResult.evc_id});
			}
		});

	}
}


@NgModule({
	declarations: [
		EvaluationsCollaborateursComponent
	],
	exports: [
		EvaluationsCollaborateursComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		UIRouterModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		LienDocumentationModule,
		MagasinSelectorModule,
		TitreModuleModule,
	],
})
export class EvaluationsCollaborateursModule { }
