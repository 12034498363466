import { Component, NgModule, ElementRef, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';


import { BlockableUI } from 'primeng/api';
import { TableModule } from '@app/primeng-overrides/table';

import { ChartModule } from '@app/primeng-overrides/chart';
import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';

import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { clone, cssVarValue, simpleComparison } from '@helpers/utils';
import { doughnutCenterLabelPlugin } from '@helpers/doughnut-center-label-plugin';

@Component({
	selector: 'bloc-ratios',
	templateUrl: './bloc-ratios.html',
	providers: []
})
export class BlocRatiosComponent implements OnChanges, BlockableUI {

	@Input() loading: boolean;
	@Input() valeursN: any;
	@Input() valeursPast: any;

	indicateurs = [
		'taux_nb_devis_nb_entrees',
		'taux_nb_ventes_nb_entrees',
		'taux_nb_ventes_nb_devis',
		'taux_nb_prises_rdv_nb_entrees',
	];

	chartOptions = {
		cutout: '70%',
		centerLabel: true,
		hover: false,
		plugins: {
			tooltip: {
				enabled: false,
			}
		}
	};

	chartPlugins = [

	];

	ratiosN: any;
	ratiosPast: any

	constructor(
		private configService: ConfigService,
		private elementRef: ElementRef,
		private indicateurService: IndicateurService,
	) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (!!changes.valeursN && !simpleComparison(changes.valeursN.previousValue, changes.valeursN.currentValue)) {
			this.ratiosN = this.prepareValeurs(this.valeursN);
		}
		if (!!changes.valeursPast && !simpleComparison(changes.valeursPast.previousValue, changes.valeursPast.currentValue)) {
			this.ratiosPast = this.prepareValeurs(this.valeursPast);
		}
	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

	perc2color(perc: number) {
		let r, g, b = 0;
		if(perc < 50) {
			r = 255;
			g = Math.round(5.1 * perc);
		}
		else {
			if (perc > 100) perc = 100;
			g = 255;
			r = Math.round(510 - 5.10 * perc);
		}
		let h = r * 0x10000 + g * 0x100 + b * 0x1;
		return '#' + ('000000' + h.toString(16)).slice(-6);
	}

	prepareValeurs(valeurs: any) {
		let tmp = clone(valeurs);
		this.indicateurs.forEach((ind_code: string) => {
			let ratio = tmp[ind_code] *100;
			let restant = (ratio < 100)? 100 - ratio : 0;
			let dataset = {
				datasets: [
					{
						data: [
							ratio, restant
						],
						backgroundColor: [
							// cssVarValue('--primary-color'),
							// this.perc2color(ratio),
							this.indicateurService.getColorValue(ind_code, ratio),
							cssVarValue('--surface-400')
						],
					}
				]
			};
			tmp[ind_code + '_dataset'] = dataset;
			tmp[ind_code] = Math.round(ratio + Number.EPSILON);
		});
		return tmp;
	}

}
@NgModule({
	declarations: [
		BlocRatiosComponent
	],
	exports: [
		BlocRatiosComponent
	],
	imports: [
		CommonModule,
		ChartModule,
		TableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
	],
})
export class BlocRatiosModule { }
