import { Component, NgModule, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { BlockableUI } from 'primeng/api';
import { TableModule } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur';

import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'bloc-rendez-vous',
	templateUrl: './bloc-rendez-vous.html',
	providers: []
})
export class BlocRendezVouComponent implements BlockableUI {

	@Input() loading: boolean;
	@Input() valeursN: any;

	indicateurNombreRendezVousWebDemandes = {
		ind_code: 'nb_rdv_web_demandes',
		tooltipIcon: true
	}

	indicateurs = [
		{ind_code: 'nb_rdv_web', tooltipIcon: true},
		{ind_code: 'nb_rdv_web_annules', tooltipIcon: true},
		{ind_code: 'nb_rdv_web_restants', tooltipIcon: true},
		{ind_code: 'nb_rdv_web_confirmes', tooltipIcon: true},
	];

	constructor(private elementRef: ElementRef) {

	}

	ngOnInit() {

	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

}

@NgModule({
	declarations: [
		BlocRendezVouComponent
	],
	exports: [
		BlocRendezVouComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		ButtonModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		NumberDisplayModule,
	],
})
export class BlocRendezVousModule { }
