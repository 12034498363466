<h1 class="flex-shrink-0">
	<titre-module>Indicateurs Digital</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">

	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[disabled]="loading"
			[useStorage]="true"
			[rangeMode]="false"
			[modes]="['annee','libre']"
			[maxDate]="maxDate"
			[defaultEnd]="maxDate"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			name="magasin"
			[(ngModel)]="mag_id"
			[nullOption]="false"
			[nullIfInvalid]="false"
			[mesMagasins]="false"
			[autoSelectFirst]="true"
			[silentInitialChangeIfSame]="true"
			[disabled]="loading || exportLoading"
			(ngModelChange)="paramChanged()"
		></magasin-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

</div>

<div class="min-table-height max-w-70rem">
	<p-table
		#tableIndicateursDigital
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[columns]="columns"
		[frozenColumns]="frozenColumns"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
	>

		<ng-template pTemplate="colgroup" let-columns>
			<colgroup>
				<ng-container *ngFor="let col of frozenColumns; last as isLast">
					<col [ngClass]="{'last-frozen-left': isLast}" class="{{col.styleClass}}" pFrozenColumn>
				</ng-container>
				<ng-container *ngFor="let col of columns">
					<col [ngClass]="col.styleClass">
				</ng-container>
			</colgroup>

		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<ng-container >
				<tr class="">
					<ng-container *ngFor="let col of frozenColumns; last as isLast; first as isFirst">
						<th [ngClass]="{'last-frozen-left': isLast}" class="text-center {{col.class}}" pFrozenColumn>
							{{col.header}}
						</th>
					</ng-container>

					<ng-container *ngFor="let col of columns">
						<th class="text-center" [ngClass]="col.class">
							{{col.header}}
						</th>
					</ng-container>
				</tr>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="emptymessage"let-columns let-frozen="frozen">
			<tr class="cell-lines-3">
				<td class="text-center" colspan="100">
					{{!frozen ? 'Aucun données pour ces critères' : '&nbsp;'}}
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-row>
			<tr
				class="{{row.styleClass}}"
				[ngClass]="{'row-indicateur': !row.separator, 'row-separator': row.separator}"
			>
				<ng-container *ngFor="let col of frozenColumns; last as isLast; first as isFirst">

					<ng-container *ngIf="row.separator">
						<th
							[ngClass]="{'last-frozen-left': isLast, 'text-center': isFirst && row.separator}"
							class="{{col.styleClass}} col-header overflow-hidden white-space-nowrap text-overflow-ellipsis"
							title="{{row[col.key]}}"
							pFrozenColumn
						>
							<div class="flex flex-row align-items-center justify-content-between">
								<div>{{row.header}}</div>
								<ng-container *ngIf="row.logo">
									<div class="{{row.logo}} logo ml-2"></div>
								</ng-container>
							</div>
						</th>
					</ng-container>
					<ng-container *ngIf="!row.separator">
						<td class="col-indicateur" pFrozenColumn>
							<indicateur-label-display [ind_code]="row.ind_code" [tooltip]="true"></indicateur-label-display>
						</td>
					</ng-container>

				</ng-container>

				<ng-container *ngIf="row.separator">
					<td colspan="100"></td>
				</ng-container>

				<ng-container *ngIf="!row.separator">
					<ng-container class="text-right" *ngFor="let col of columns; index as i">

						<td class="text-right">
							<ng-container *ngIf="row.ind_code != 'bim_nb_rdv_confirmes' || row.ind_code == 'bim_nb_rdv_confirmes' && !row.mois[i].value">
								<indicateur-value-display [value]="row.mois[i].value" [ind_code]="row.ind_code"></indicateur-value-display>
							</ng-container>
							<ng-container *ngIf="row.ind_code == 'bim_nb_rdv_confirmes' && row.mois[i].value">
								<span class="link" (click)="showRdvConfirmeDialog(i)">
									<indicateur-value-display [value]="row.mois[i].value" [ind_code]="row.ind_code"></indicateur-value-display>
								</span>
							</ng-container>
						</td>

					</ng-container>
				</ng-container>
			</tr>
		</ng-template>
	</p-table>
</div>
