import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';

import { map } from 'rxjs/operators';

import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	prepareQueryParams,
	contrastingColor,
	numericSort,
} from '@helpers/utils';

import {
	CategorieCompetenceCollaborateur,
	CompetenceCollaborateur,
	EvaluationCollaborateur,
	NiveauCompetence,
	ProfilCompetence,
	QuestionnaireEvaluationCollaborateur,
	SousCategorieCompetenceCollaborateur,
} from '@app/moa/referentiel-competences/referentiel-competences.model';

import { UtilisateurService } from '@app/utilisateur/utilisateur.service';

@Injectable({ providedIn: 'root' })
export class ReferentielCompetencesService {

	niveauxCompetences: NiveauCompetence[] = [];
	profilsCompetences: ProfilCompetence[] = [];

	constructor(
		private http: HttpClient,
		private utilisateurService: UtilisateurService
	) {

	}

	getProfilsCompetences(refresh: boolean = false) {
		if (!refresh && this.profilsCompetences.length) {
			return of(this.profilsCompetences);
		}
		return this.http.get<any>(`/profils_competences`)
		.pipe(
			map((response: any) => {
				this.profilsCompetences = response;
				return this.profilsCompetences;
			})
		);
	}

	getNiveauxCompetences(refresh: boolean = false) {
		if (!refresh && this.niveauxCompetences.length) {
			return of(this.niveauxCompetences);
		}
		return this.http.get<any>(`/niveaux_competences`)
		.pipe(
			map((response: any) => {
				this.niveauxCompetences = response;
				return this.niveauxCompetences;
			})
		);
	}

	prepareEvaluationCollaborateur(data: any) {
		let tmp = clone(data);

		convertDateFieldsToDate(tmp);
		// ordonnancement des catégories, sous-catégories, compétences, critères...
		numericSort(tmp.evc_questionnaire.categories, 'ordre');
		tmp.evc_questionnaire.categories.forEach((categorie: CategorieCompetenceCollaborateur) => {
			categorie.couleur_texte = categorie.couleur? contrastingColor(categorie.couleur) : '';
			numericSort(categorie.sous_categories, 'ordre');
			categorie.sous_categories.forEach((sous_categorie: SousCategorieCompetenceCollaborateur) => {
				numericSort(sous_categorie.competences, 'ordre');
				sous_categorie.competences.forEach((competence: CompetenceCollaborateur) => {
					numericSort(competence.criteres_evaluations, 'ordre');
				});
			});
		});

		return tmp;
	}

	getCollaborateurs(mag_id: number) {
		return this.http.get<any>(`/magasins/${mag_id}/evaluations_collaborateurs`)
		.pipe(map((response: any) => {
			this.utilisateurService.prepareCollaborateurs(response);
			return response;
		}))
	}

	getEvaluationCollaborateur(mag_id: number, evc_id: number) {
		return this.http.get<any>(`/magasins/${mag_id}/evaluations_collaborateurs/${evc_id}`)
		.pipe(map((response: any) => {
			return this.prepareEvaluationCollaborateur(response);
		}))
	}

	postEvaluationCollaborateur(mag_id: number, data: any) {
		return this.http.post<any>(`/magasins/${mag_id}/evaluations_collaborateurs`, data);
	}

	putEvaluationCollaborateur(mag_id: number, data: any) {
		return this.http.put<any>(`/magasins/${mag_id}/evaluations_collaborateurs/${data.evc_id}`, data);
	}

	deleteEvaluationCollaborateur(mag_id: number, evc_id: number) {
		return this.http.delete<any>(`/magasins/${mag_id}/evaluations_collaborateurs/${evc_id}`);
	}

}
