import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { convertDateFields, prepareQueryParams } from '@helpers/utils';
import { Utilisateur } from './utilisateur.model';


@Injectable({ providedIn: 'root' })
export class UtilisateurService {



	constructor(
		private http: HttpClient,
	) {

	}

	prepareCollaborateur(utilisateur: Utilisateur) {
		convertDateFields(utilisateur);
		utilisateur.profils = this.extractProfilsCollaborateur(utilisateur);
		return utilisateur;
	}

	prepareCollaborateurs(utilisateurs: Utilisateur[]) {
		utilisateurs.forEach((one: Utilisateur) => {
			one = this.prepareCollaborateur(one);
		});
	}

	getVendeurs(mag_id: number): Observable<any> {
		return this.http.get<any>(`/magasins/${mag_id}/vendeurs`);
	}

	getCollaborateurs(mag_id: number): Observable<any> {
		return this.http.get<any>(`/magasins/${mag_id}/collaborateurs`);
	}

	getVendeursPeriode(params: any) {
		let tmpParams = prepareQueryParams(params)
		return this.http.get<any>(`/vendeurs`, tmpParams);
	}

	getFranchises() {
		return this.http.get<any>(`/utilisateurs_franchises`);
	}

	extractProfilsCollaborateur(utilisateur: Utilisateur) {
		let profils: string[] = [];
		if (utilisateur.uti_vendeur) profils.push('vendeur');
		if (utilisateur.uti_ag) profils.push('ag');
		if (utilisateur.uti_chef_ventes) profils.push('chef_ventes');
		if (utilisateur.uti_resp_saisie) profils.push('resp_saisie');
		if (utilisateur.uti_manager) profils.push('manager');
		if (utilisateur.uti_direction_reseau) profils.push('direction');
		if (utilisateur.uti_centrale) profils.push('centrale');
		if (utilisateur.uti_informatique) profils.push('informatique');
		if (utilisateur.uti_formation) profils.push('formation');
		if (utilisateur.utm_animateur_region) profils.push('animateur');
		return profils;
	}

}
