import { Injectable, OnDestroy } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { clone, convertDateFieldsToDate } from '@helpers/utils';

export type Challenge = {
	cha_id: number;
	ens_id: number;
	cha_libelle: string;
	cha_date_debut: string|Date;
	cha_date_fin: string|Date;
	cha_reference_externe: string;
}


@Injectable({ providedIn: 'root' })
export class ChallengeService implements IEventListener {

	private challengesSubject = new BehaviorSubject<any>(null);

	constructor(
		private http: HttpClient,
		private eventManager: EventManagerService
	) {

		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.challengesSubject.next(null);
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}

	public get challenges(): any {
		return this.challengesSubject.getValue();
	}

	public prepareChallengesFromServer(challenges: Challenge[]) {
		challenges.forEach((one: Challenge) => {
			one = this.prepareChallengeFromServer(one);
		})
		return challenges;
	}

	public prepareChallengeFromServer(challenge: Challenge) {
		convertDateFieldsToDate(challenge);
		return challenge;
	}

	public getChallenges(): Observable<any> {
		return this.http.get<any>(`/challenges`)
		.pipe(
			map(
				(response: any) => {
					let tmp = this.prepareChallengesFromServer(response.challenges);
					this.challengesSubject.next(tmp);
					return tmp;
				}
			),
			catchError(
				(error: any) => {
					return throwError(error);
				}
			)
		);
	}
}
