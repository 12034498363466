export const menuItems = [
	{
		label: 'Accueil',
		icon: 'pi pi-home',
		state: {
			name: 'cockpit'
		}
	},

	{
		label: 'Activité Magasin',
		state: {
			name: 'activite-magasin'
		}
	},

	// DECLARATIONS
	{
		label: 'Déclaration mensuelle',
		state: {
			abstract: 'declarations'
		},
		items: [
			{
				label: 'Synthèse annuelle',
				state: {
					name: 'synthese-annuelle'
				}
			},
			{
				label: 'Valider ma déclaration',
				state: {
					name: 'declaration-mensuelle-magasin'
				}
			},
			{
				label: 'Contrôle des déclarations',
				state: {
					name: 'controle-declaration-mensuelle-magasin'
				}
			}
		]
	},


	// STATISTIQUES
	{
		label: 'Statistiques',
		state: {
			abstract: 'reporting'
		},
		items: [
			{
				label: 'Reporting activité',
				state: {
					name: 'reporting-activite'
				}
			},
			{
				label: 'Classement magasins',
				state: {
					name: 'classement-magasins'
				}
			},
			{
				label: 'Classement vendeurs',
				state: {
					name: 'classement-vendeurs'
				}
			},
			{
				label: 'Classement SATCLI',
				state: {
					name: 'classement-satcli'
				}
			},
			{
				label: 'Financement',
				state: {
					name: 'stats-financement'
				}
			},
			{
				label: 'Indicateurs Digital',
				state: {
					name: 'indicateurs-digital'
				}
			},
			{
				label: 'Parrainage YuccanLead',
				state: {
					name: 'parrainage-yuccanlead'
				}
			},
		]
	},

	// MOA
	{
		label: 'MOA',
		state: {
			abstract: 'moa'
		},
		keepIfHasSingleChild: true,
		items: [

			// MOA 1 > Ma vision d'entrepreneur
			{
				label: `1 - Ma vision d'entrepreneur`,
				keepIfHasSingleChild: true,
				items: [
					{
						label: `Ma vision d'entrepreneur`,
						state: {
							name: 'ma-vision-entrepreneur'
						}
					},
				]
			},

			// MOA 2 > Mon magasin
			{
				label: `2 - Mon magasin`,
				keepIfHasSingleChild: true,
				items: [
					{
						label: `Audit de conformité`,
						state: {
							name: 'audit-conformite'
						}
					},
				]
			},

			// MOA 3 > Mes fournisseurs
			{
				label: `3 - Mes fournisseurs`,
				keepIfHasSingleChild: true,
				items: [
					{
						label: `Contrats d'amortissements expositions`,
						state: {
							name: 'contrats-amortissements-expositions'
						}
					},
					{
						label: `Répartition des achats meubles`,
						state: {
							name: 'repartition-achats-meubles'
						}
					},
				]
			},

			// MOA 4 > Mes collaborateurs
			{
				label: `4 - Mes collaborateurs`,
				keepIfHasSingleChild: true,
				items: [
					{
						label: `Evaluations collaborateurs`,
						state: {
							name: 'referentiel-competences'
						}
					}
				]
			},

			// MOA 4 > Mes prestataires
			{
				label: `5 - Mes prestataires`,
				keepIfHasSingleChild: true,
				items: [
					{
						label: `Suivi prestataires`,
						state: {
							name: 'suivi-prestataires'
						}
					},
				]
			},

			// MOA 6 > Ma communication
			{
				label: `6 - Ma communication`,
				state: {
					abstract: 'ma-communication'
				},
				keepIfHasSingleChild: true,
				items: [
					{
						label: 'Suivi communication locale',
						state: {
							name: 'suivi-communication-locale'
						}
					},
					{
						label: 'Statistiques communication/codes postaux',
						state: {
							name: 'stats-par-code-postal'
						}
					},
				]
			},

			// MOA 8 > Mon pilotage
			{
				label: `8 - Mon pilotage`,
				state: {
					abstract: 'mon-pilotage'
				},
				keepIfHasSingleChild: true,
				items: [
					{
						label: 'Qui Fait Quoi',
						state: {
							name: 'qui-fait-quoi'
						}
					},
					{
						label: 'Potentiel CA magasin',
						state: {
							name: 'simulateur-potentiel-ca-magasin'
						}
					},
					{
						label: 'Préparation des objectifs',
						state: {
							name: 'simulateur-preparation-objectif-ca-annuel'
						}
					},
					{
						label: 'Objectifs vendeur',
						state: {
							name: 'objectifs-vendeur'
						}
					},
					{
						label: 'Note Google',
						state: {
							name: 'simulateur-note-google'
						}
					},
					{
						label: `Plan d'actions`,
						state: {
							name: 'plans-actions'
						}
					},
					{
						label: `Revue mensuelle d'activité commerciale`,
						state: {
							name: 'revue-mensuelle-activite-commerciale'
						}
					},
				]
			},

			// MOA 10 > Ma finance
			{
				label: `10 - Ma finance`,
				state: {
					abstract: 'ma-finance'
				},
				keepIfHasSingleChild: false,
				items: [
					{
						label: `Historique des encaissements`,
						state: {
							name: 'historique-encaissements'
						}
					},
					{
						label: `Projections des encaissements`,
						state: {
							name: 'projection-encaissements'
						}
					},
				]
			},







		]
	},

	// OBJECTIFS
	{
		label: 'Objectifs',
		state: {
			abstract: 'objectifs'
		},
		items: [
			{
				label: 'Objectifs magasin',
				state: {
					name: 'objectifs-magasin'
				}
			},
			{
				label: 'Objectifs nationaux',
				state: {
					name: 'objectifs-nationaux'
				}
			},
		]
	},

];
