import { NgModule, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { GlobalModule } from '@global/global.module';
import { AuthService } from '@app/auth/auth.service';
import { EventManagerService } from '@global/event-manager.service';

import { environment, enseigne } from '@environments/environment';

@Component({
	template: `
		<div class="service-unavailable h-full flex flex-column justify-content-center align-items-center vertical-container relative">
			<p-card class="text-center">
				<ng-template pTemplate="header">
					<div class="pt-3">
						<div class="logo-enseigne"></div>
					</div>
				</ng-template>

  	  	<h1 class="mt-0">{{title}}</h1>

				<p *ngIf="!error">Service momentanément indisponible</p>

				<ng-container *ngIf="error">
					<ng-container [ngSwitch]="error">

						<p *ngSwitchCase="'maintenance'" class="text-center">Une opération de maintenance est en cours. <br>Toutes nos excuses pour le désagrément. Nous revenons très vite.</p>

						<p *ngSwitchDefault>Aïe. Ceci n'aurait pas dû arriver...</p>

					</ng-container>
				</ng-container>

				<div>
					<a href="/">
						<button
							type="button"
							pButton
							class="mb-3"
							label="Retourner à la page d'accueil"
						></button>
					</a>
				</div>

				<div>
					<button
						type="button"
						pButton
						class="p-button-sm p-button-text p-button-secondary"
						label="Déconnexion"
						(click)="logout()"
						*ngIf="loggedIn"
					></button>
				</div>

			</p-card>

		</div>
	`
})
export class ServiceUnavailableComponent implements OnInit {

	@Input() error: any;

	title: string = environment[enseigne].title;

	loggedIn: boolean = false;

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private stateService: StateService,
	) { }

	ngOnInit() {
		if (!this.error) {
			this.error = this.stateService.params.error;
		}
		this.loggedIn = !!this.authService.token;
	}

	logout() {
		this.eventManager.emit('logout', true);
	}

}

@NgModule({
	imports: [
		CommonModule,
		UIRouterModule,
		ButtonModule,
		CardModule,
		GlobalModule
	],
	exports: [ServiceUnavailableComponent],
	declarations: [ServiceUnavailableComponent]
})
export class ServiceUnavailableModule { }
