<form #formEvaluationCollaborateur="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-12" >
			<label for="uti_id">Collaborateur</label>
			<utilisateur-selector
				name="uti_id"
				[(ngModel)]="evaluation.uti_id"
				[mag_id]="mag_id"
				[profils]="profilsEvaluables"
				[required]="true"
				[disabled]="loading"
				(onSelectionChange)="resetForm($event)"
				*ngIf="!lockCollaborateur"
			></utilisateur-selector>
			<input
				type="text"
				pInputText
				name="collaborateur"
				value="{{collaborateur|utilisateur}}"
				[readonly]="true"
				*ngIf="lockCollaborateur"
			>
		</div>


		<div class="field col-12" >
			<label for="pco_code">Profil</label>
			<profil-competence-selector
				name="pco_code"
				[required]="true"
				[(ngModel)]="evaluation.pco_code"
				[onlyThese]="profilsSelectionnables"
				[autoSelectIfSingle]="true"
				[disabled]="loading"
			></profil-competence-selector>
		</div>

		<div class="field col-12" >
			<label for="evc_periode">Période</label>
			<input
				type="text"
				pInputText
				name="evc_periode"
				[required]="true"
				placeholder="Période"
				[(ngModel)]="evaluation.evc_periode"
				[disabled]="loading"
			>
		</div>


	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !formEvaluationCollaborateur.valid"
			(click)="submit()"
		></button>
	</div>

</form>
