import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AnneeSelectorModule } from '@app/annee-selector';
import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { ReportingService } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';

import { numericSort } from '@helpers/utils';

@Component({
	selector: 'stats-financement',
	templateUrl: './stats-financement.html',
	providers: [],
})
export class StatsFinancementComponent {

	loading: boolean;
	exportLoading: boolean;
	// les stats de financement n'existaient pas avant 2023
	minYear: number = 2023;
	currentYear: number  = (new Date()).getFullYear();
	currentMonth: number = (new Date()).getMonth() + 1;

	annee: number;
	mag_id: number;

	taux_budget_communication: number;
	taux_budget_communication_nationale: number;
	taux_budget_communication_locale: number;

	total: any;
	mois: any[];
	dateDonneesFranfinance: Date;

	indicateurs: any[] = [
		{ ind_code: 'ca_prise_commande_htple', class: 'text-right'},
		{ ind_code: 'nb_dossiers_acceptes_et_en_cours_acceptation', class: 'text-center'},
		{ ind_code: 'mt_credit_accepte_et_en_cours_acceptation', class: 'text-right'},
		{ ind_code: 'taux_rc_accepte_et_en_cours_acceptation', class: 'text-right'},
		{ ind_code: 'nb_dossiers_acceptes', class: 'text-center'},
		{ ind_code: 'mt_credit_accepte', class: 'text-right'},
		{ ind_code: 'taux_rc_accepte', class: 'text-right'},
	];

	constructor(
		private configService: ConfigService,
		private reportingService: ReportingService,
		private storageService: StorageService,
	) {

		this.dateDonneesFranfinance = new Date(this.configService.config.date_donnees_franfinance);
	}

	ngOnInit() {
		this.getParamSet();
		this.load();
	}

	saveParamSet() {
		this.storageService.setForCurrentState('annee', this.annee);
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	getParamSet() {
		this.annee =  this.storageService.getForCurrentState('annee', this.annee);
		this.mag_id =  this.storageService.getForCurrentState('mag_id', this.mag_id);
	}

	paramChanged() {
		this.load();
	}

	load(event?: any) {
		if (this.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			this.saveParamSet();
			this.reportingService.getStatsFinancement(this.mag_id, this.annee)
			.subscribe({
				next: (response: any) => {
					this.total = response.total;
					this.mois = response.mois;
					numericSort(this.mois, 'mois');
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	export() {
		this.exportLoading = true;
		this.reportingService.exportStatsFinancement(this.mag_id, this.annee)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

}
@NgModule({
	declarations: [
		StatsFinancementComponent
	],
	exports: [
		StatsFinancementComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		AnneeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		TitreModuleModule,
	],
})
export class StatsFinancementModule { }
