<div class="flex flex-shrink-0 justify-content-between hide-print">
	<div class="field p-field-checkbox flex flex-column justify-content-end mb-0">
		<div>
			<p-checkbox
				name="showTermines"
				inputId="showTermines"
				[(ngModel)]="showTermines"
				[binary]="true"
				(ngModelChange)="saveParamSet()"
			></p-checkbox>
			<label for="showTermines">Afficher les actions terminées</label>
		</div>
	</div>

	<div class="text-rightg">
		<button
			type="button"
			pButton
			icon="pi pi-plus"
			label="Ajouter un plan d'action"
			class="p-button-sm"
			(click)="showPlanActionsDialog()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-table
		#tablePlanActions
		[value]="values"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="false"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-plan-actions"
		dataKey="pla_id"
		[expandedRowKeys]="expandedRows"
	>
		<ng-template pTemplate="header" >
			<tr class="">
				<th class="text-center nowrap">Date début</th>
				<th class="text-center">Actions</th>
				<th class="text-center nowrap">Personnes impliquées</th>
				<th class="text-center col-label">Objectifs chiffrés</th>
				<th class="text-center">Détail de mise en oeuvre</th>
				<th class="text-center nowrap">Date fin</th>
				<th class="col-print-0"></th>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">
					Aucun plan d'action défini
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-planActions let-rowIndex="rowIndex" let-expanded="expanded">

			<tr
				[ngClass]="{
					'row-primary': planActions.details.length && !isDone(planActions),
					'row-success': planActions.details.length && isDone(planActions)
				}"
			>
				<td class="cell-th" colspan="3" >{{planActions.pla_libelle}}</td>
				<td class="cell-warning">{{planActions.pla_chiffre}}</td>
				<td colspan="2"></td>
				<td class="nowrap col-print-0">
					<div class="hide-print">
						<button
							type="button"
							pButton
							icon="pi pi-pencil"
							class="p-button-xs p-button-text"
							(click)="showPlanActionsDialog(planActions)"
						></button>
						<button
							type="button"
							pButton
							icon="pi pi-trash"
							class="p-button-xs p-button-text p-button-danger"
							(click)="deletePlanActions(planActions)"
						></button>
						<button
							type="button"
							pButton
							icon="pi pi-plus"
							title="Ajouter une action"
							class="p-button-xs p-button-text"
							(click)="newDetailPlanActions(planActions)"
						></button>
					</div>
				</td>
			</tr>

			<ng-container *ngFor="let detail of planActions.details">
				<tr
					*ngIf="!detail.dpa_date_terminee || showTermines"
					[ngClass]="{
						'row-success': detail.dpa_date_terminee,
						'row-error': detail.dpa_date_fin && !detail.dpa_date_terminee && detail.dpa_date_fin <= today
					}"
				>
					<td class="text-center nowrap">{{detail.dpa_date_debut | date:'dd/MM/yyyy'}}</td>
					<td>{{detail.dpa_libelle}}</td>
					<td>{{detail.dpa_personnes_impliquees}}</td>
					<td class="cell-warning">{{detail.dpa_chiffre}}</td>
					<td>{{detail.dpa_detail}}</td>
					<td class="text-center nowrap">{{detail.dpa_date_fin | date:'dd/MM/yyyy'}}</td>
					<td class="nowrap col-print-0">
						<div class="hide-print">
							<button
								type="button"
								pButton
								icon="pi pi-pencil"
								class="p-button-xs p-button-text"
								(click)="showDetailPlanActionsDialog(detail)"
							></button>
							<button
								type="button"
								pButton
								icon="pi pi-trash"
								class="p-button-xs p-button-text p-button-danger"
								(click)="deleteDetailPlanActions(detail)"
							></button>
							<button
								type="button"
								pButton
								[icon]="detail.dpa_date_terminee? 'pi pi-undo' : 'pi pi-check'"
								[ngClass]="{'p-button-success': !detail.dpa_date_terminee}"
								class="p-button-xs p-button-text"
								[title]="detail.dpa_date_terminee? 'Remettre en non-terminée' : 'Marquer comme terminée'"
								(click)="toggleEtatDetailPlanActions(detail)"
							></button>
						</div>
					</td>
				</tr>

			</ng-container>

			<tr class="hide-print">
				<td colspan="100" class="text-right">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						label="Ajouter une action"
						class="p-button-sm p-button-text button-nowrap"
						(click)="newDetailPlanActions(planActions)"
					></button>
				</td>
			</tr>

		</ng-template>

		<!-- <ng-template pTemplate="footer">
			<tr class="hide-print">
				<td colspan="100" class="text-center">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						label="Ajouter un plan d'action"
						class="p-button-sm p-button-text"
						(click)="showPlanActionsDialog()"
					></button>
				</td>
			</tr>
		</ng-template> -->

	</p-table>
</div>
<!-- <pre>{{values|json}}</pre> -->
