<div class="flex flex-column h-full">
	<div class="grid justify-content-between flex-shrink-0">
		<div class="col formgrid grid p-fluid">
			<div class="field col-fixed">
				<daterange-selector
					label="Période libre"
					[dateRange]="dateRange"
					singleMode="libre"
					[useStorage]="false"
					[disabled]="loading"
					(onDateChange)="onDateChange($event)"
				></daterange-selector>
			</div>

			<div class="field col-fixed flex flex-column justify-content-end">
				<div class="p-inputgroup">
					<input
						type="text"
						pInputText
						[(ngModel)]="params.search"
						(onDebounce)="refresh()"
						[readonly]="loading || exportLoading"
						placeholder="Rechercher..."
					/>
					<button
						type="button"
						class="p-inputgroup-addon"
						icon="pi pi-times"
						pButton
						label=""
						[disabled]="!params.search || loading || exportLoading"
						(click)="reset()"
					></button>
				</div>
			</div>

			<div class="field col-fixed flex flex-column justify-content-end">
				<button
					type="button"
					pButton
					icon="pi pi-refresh"
					class="p-button-text"
					[loading]="loading"
					(click)="refresh()"
				></button>
			</div>

		</div>

		<div class="col-fixed formgrid grid p-fluid">

		</div>

	</div>

	<div class="min-table-height">
		<p-table
			#tableRdv
			[value]="values"
			[lazy]="true"
			(onLazyLoad)="load($event)"
			[lazyLoadOnInit]="false"
			[loading]="loading"
			[paginator]="true"
			paginatorDropdownAppendTo="body"
			[rows]="rows"
			[rowsPerPageOptions]="[10,25,50]"
			[showCurrentPageReport]="true"
			[totalRecords]="totalRecords"
			currentPageReportTemplate="{totalRecords} élément(s)"
			[autoLayout]="true"
			responsiveLayout="scroll"
			scrollDirection="both"
			[scrollable]="true"
			scrollHeight="flex"
			styleClass="p-datatable-sm p-datatable-gridlines"
		>
			<ng-template pTemplate="header">
				<ng-container>
					<tr *ngIf="totalRecords != 0">
						<th class="text-left">Client</th>
						<th class="text-center">Date</th>
					</tr>
				</ng-container>
			</ng-template>

			<ng-container>
				<ng-template pTemplate="emptymessage">
					<tr>
						<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-evenement let-rowIndex="rowIndex">
					<tr>
						<td class="">
							{{ evenement.client }}
						</td>

						<td class="text-right nowrap">
							{{ evenement.date | date: 'dd MMM yyyy'}}
						</td>
					</tr>
				</ng-template>
			</ng-container>
		</p-table>
	</div>
</div>
