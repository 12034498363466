import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { TreeTableModule, TreeTable } from '@app/primeng-overrides/treetable';
import { ObjectifsService, ObjectifNational } from '@app/objectifs';

import { GlobalModule } from '@global/global.module';
import { ConfigService } from '@global/config.service';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { NumberDisplayModule } from '@helpers/number-display';
import { AnneeSelectorModule } from '@app/annee-selector';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import {
	arrayOfMonths,
	capitalize,
	clone,
	simpleComparison,
} from '@helpers/utils';
import { Title } from 'chart.js';

@Component({
	selector: 'objectifs-nationaux',
	templateUrl: './objectifs-nationaux.html',
	providers: [DatePipe],
})
export class ObjectifsNationauxComponent {

	@ViewChild('tableObjectifs') table: Table;

	objectifs: any[] = [];
	values: any[] = [];
	columns: any[];
	frozen_columns: any[];

	rangeMois: Date[] = [];

	loading: boolean = false;
	exportLoading: boolean = false;
	minYear: number;
	maxYear: number;
	annee: number;

	valueBeforeEdit: any;

	indicateurs: string[] = [
		'note_wizville',
		'note_google',
		'nb_avis_google',
		'satisfaction_q1',
		'satisfaction_q2',
		'satisfaction_q3',
		'satisfaction_q4',
		'satisfaction_q3_nps',
		'satisfaction_q4_nps',
		'taux_saisies_email',
		'taux_retours',
	];

	constructor(
		private datePipe: DatePipe,
		private objectifsService: ObjectifsService,
		private configService: ConfigService,
		private storageService: StorageService,
	) {
		this.minYear = configService.config.annee_reference;
		this.maxYear = new Date().getUTCFullYear() +1;
	}

	ngOnInit() {
		this.getParamSet();
		this.prepareColumns();
	}

	indexTracker(index: number, item: any) {
		if (item.node && item.node.uid) return item.node.uid;
		return item.ove_mois || index;
	}

	saveParamSet() {
		this.storageService.set('annee', this.annee);
	}

	getParamSet() {
		this.annee = this.storageService.get('annee');
	}

	prepareColumns() {
		this.columns = [];

		this.frozen_columns = [
			{
				header: 'Objectifs',
				class: 'col-indicateur',
				frozen: true,
			}
		];

		this.rangeMois = arrayOfMonths(this.annee, 12);

		let nbMonths = this.rangeMois.length;
		for (let i = 0; i < nbMonths ; i++) {
			let oneMonth = this.rangeMois[i];
			this.columns = this.columns.concat([
				{
					header: capitalize(this.datePipe.transform(oneMonth, 'MMMM'), true),
					class: 'col-mois'
				}
			]);
		}
	}

	load() {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);
		this.saveParamSet();

		this.objectifsService.getObjectifsNationaux({annee: this.annee})
		.subscribe({
			next: (response: any) => {
				this.objectifs = response;
				this.values = this.tiltData(this.objectifs);
			}
		})
		.add(() => { this.loading = false; });
	}

	// transformation de l'array de mois en array d'indicateurs
	tiltData(objectifs: ObjectifNational[]) {
		let tmp: any[] = [];
		this.indicateurs.forEach((ind_code: string, index: number) => {
			if (!tmp[index]) {
				tmp[index] = {
					'ind_code': ind_code,
					objectifs: []
				};
			}

			const key = 'ona_' + ind_code;

			objectifs.forEach((objectifMensuel: any) => {
				let tmpItem: {[key: string]: any} = {
					'ens_id': objectifMensuel.ens_id,
					'ona_annee': objectifMensuel.ona_annee,
					'ona_mois': objectifMensuel.ona_mois,
					'readonly': !!objectifMensuel.readonly
				};
				tmpItem[key] =  objectifMensuel[key];
				tmp[index].objectifs.push(tmpItem);
			});
		});
		return tmp;
	}

	onEditComplete(event: any) {
		const valueBeforeEdit = this.valueBeforeEdit;
		if (!simpleComparison(event.data, this.valueBeforeEdit)) {
			this.objectifsService.putObjectifNational(event.data)
			.subscribe({
				next: (response: any) => {

				},
				error: (error: any) => {
					this.values[event.index].objectifs[event.field] = valueBeforeEdit;
				}
			});
		}
	}

	onEditCancel(event: any) {
		this.values[event.index].objectifs[event.field] = this.valueBeforeEdit
	}

	onEditInit(event: any) {
		this.valueBeforeEdit = clone(event.data);
	}

	export() {
		this.exportLoading = true;

		this.objectifsService.exportObjectifsNationaux({annee: this.annee})
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

}
@NgModule({
	declarations: [
		ObjectifsNationauxComponent
	],
	exports: [
		ObjectifsNationauxComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TreeTableModule,
		TableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		AnneeSelectorModule,
		NumberDisplayModule,
		TitreModuleModule,
	],
})
export class ObjectifsNationauxModule { }
