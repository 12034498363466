import { Pipe, PipeTransform } from '@angular/core';

import { IndicateurService } from '@app/indicateur/indicateur.service';

@Pipe({name: 'indicateur'})
export class IndicateurPipe implements PipeTransform {

	constructor(private indicateurService: IndicateurService) {}

	transform(ind_code: string, attr: 'libelle'|'description'|'suffixe'): string {
		let indicateur = this.indicateurService.find(ind_code);
		if (indicateur) {
			if (attr == 'suffixe') {
				switch (indicateur.ind_format.toLowerCase()) {
					case 'pourcent':
						return '%';
						break;
					case 'monetaire':
						return '€';
						break;
				}
				return '';
			}
			return indicateur['ind_' + attr];
		}
		return ind_code;
	}
}
