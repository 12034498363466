<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module></titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<label for="annee">Année</label>
		<annee-selector
			name="annee"
			[(ngModel)]="annee"
			[nullIfInvalid]="true"
			[autoSelectCurrent]="true"
			[reverseOrder]="true"
			[minYear]="minYear"
			[maxYear]="maxYear"
			[disabled]="loading || exportLoading"
			(ngModelChange)="load()"
		></annee-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-table
		#tableObjectifs
		[value]="values"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="false"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-objectifs"
		(onEditComplete)="onEditComplete($event)"
		(onEditCancel)="onEditCancel($event)"
		(onEditInit)="onEditInit($event)"
	>
		<ng-template pTemplate="header" >
			<tr class="">
				<ng-container *ngFor="let col of frozen_columns">
					<th class="text-center justify-content-center"  pFrozenColumn>{{col.header}}</th>
				</ng-container>
				<ng-container *ngFor="let col of columns">
					<th class="text-center justify-content-center" [ngClass]="col.class">{{col.header}}</th>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">

			<tr	class="">
				<td class="nowrap text-left" pFrozenColumn>
					<indicateur-label-display [ind_code]="rowData.ind_code" [tooltip]="false"></indicateur-label-display>
				</td>

				<ng-container *ngFor="let mois of columns; index as moisIndex">

					<ng-container *ngIf="rowData.objectifs[moisIndex].readonly">
						<td class="text-right justify-content-end col-mois {{mois.class}} readonly">
							<number-display
								[value]="rowData.objectifs[moisIndex]['ona_' + rowData.ind_code]"
								type="other"
							></number-display>
						</td>
					</ng-container>

					<ng-container *ngIf="!rowData.objectifs[moisIndex].readonly">
						<td
							class="text-right justify-content-end col-mois cell-input {{mois.class}}"
							[pEditableColumn]="rowData.objectifs[moisIndex]"
							[pEditableColumnField]="moisIndex"
							[pEditableColumnRowIndex]="rowIndex"
						>
							<p-cellEditor>
								<ng-template pTemplate="input">
									<div class="p-input-icon-right input-container">
										<input
											type="text"
											pInputText
											gzInputNumberFormatter
											[(ngModel)]="rowData.objectifs[moisIndex]['ona_' + rowData.ind_code]"
											[ngModelOptions]="{standalone: true}"
											[decimals]="2"
											[currency]="false"
											class="text-right"
										>
									</div>
								</ng-template>
								<ng-template pTemplate="output">
									<number-display
										[value]="rowData.objectifs[moisIndex]['ona_' + rowData.ind_code]"
										type="other"

									></number-display>
								</ng-template>
							</p-cellEditor>
						</td>
					</ng-container>

				</ng-container>

			</tr>

		</ng-template>

	</p-table>
</div>
