import { NgModule, Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { MagasinService } from '@app/magasin/magasin.service';
import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';

@Component({
	selector: 'region-commerciale-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RegionCommercialeSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			[filter]="options.length > 5"
			filterBy="label"
			[filterPlaceholder]="filterPlaceholder"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class RegionCommercialeSelectorComponent extends ControlValueAccessorBase<number|null|undefined> {

	@Input('value') innerValue: number|null|undefined;
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() mesRegions: boolean;

	filters: {[key: string]: any} = {};
	options: any[] = [];
	initialized: boolean = false;

	constructor(private magasinService: MagasinService) {
		super();
	}

	ngOnInit() {
		if (this.mesRegions)	{
			this.magasinService.getMesRegionsCommerciales()
			.subscribe({
				next: (response: any) => {
					this.setOptions(response);
				}
			});
		}
		else {
			this.magasinService.getRegionsCommerciales()
			.subscribe({
				next: (response: any) => {
					this.setOptions(response);
				}
			});
		}
	}

	setOptions(options: any[]) {
		let tmp = options.map((one: any) => {
			let short = one.replace(/^Région /, '');
			return {label: short, value: one};
		})
		this.options = tmp;
		setTimeout(() => {
			this.setInitialValue();
		}, 0)
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (this.options.length) {
			if (this.options.findIndex(one => {return one.value == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectFirst || this.autoSelectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].value;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [RegionCommercialeSelectorComponent],
	declarations: [RegionCommercialeSelectorComponent]
})
export class RegionCommercialeSelectorModule { }
