<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Suivi prestataires</titre-module>
	<lien-documentation prm_code="documentation_suivi_prestataires"></lien-documentation>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="params.mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						(ngModelChange)="load()"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
				<td class="clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text"
						[disabled]="loading"
						[loading]="loading"
						(click)="load()"
					></button>
				</td>
			</tr>

			<tr>
				<td class="cell-th">Type prestataire</td>
				<td class="text-right">
					<p-dropdown
						#typePrestataireSelector
						name="prs_type"
						[options]="typesPrestataires"
						[(ngModel)]="params.prs_type"
						[showClear]="true"
						placeholder="Tous"
						(ngModelChange)="load()"
						[disabled]="loading"
						appendTo="body"
						class="hide-print"
					>
					</p-dropdown>
					<span class="show-print">{{typePrestataireSelector.selectedOption?.label}}</span>
				</td>
			</tr>

		</ng-template>
	</p-table>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		[value]="prestataires"
		[lazy]="true"
		[loading]="loading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center col-total" pFrozenColumn>Type</th>
				<th class="text-center min-w-15rem" pFrozenColumn>Société</th>
				<th class="text-center min-w-20rem">Adresse postale</th>
				<th class="text-center">Contact</th>
				<th class="text-center min-w-10rem">Tarifs</th>
				<th class="text-center min-w-20rem">Observations</th>
				<th class="text-center">Depuis le</th>
				<th class="text-center">Date mise à jour</th>
				<th class="text-center">Actions</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-prestataire let-rowIndex="rowIndex">
			<tr [ngClass]="{'disabled': !prestataire.prs_actif}">
				<td class="text-center col-total" pFrozenColumn>{{prestataire.prs_type | typePrestataire}}</td>
				<td class="text-left min-w-15rem" pFrozenColumn>{{prestataire.label}}</td>
				<td class="text-left white-space-pre">
					{{prestataire.prs_adresse}}
				</td>
				<td class="nowrap">
					<div class="flex align-items-center">
						<i class="pi pi-user"></i> {{prestataire.prs_interlocuteur}}
					</div>
					<div class="flex align-items-center">
						<i class="pi pi-phone"></i> {{prestataire.prs_telephone}}
					</div>
					<div class="flex align-items-center">
						<i class="pi pi-envelope"></i> {{prestataire.prs_email}}
					</div>
				</td>
				<td class="text-left min-w-20rem max-w-70rem white-space-pre">
					{{prestataire.prs_tarifs}}
				</td>
				<td class="text-left min-w-20em max-w-50rem white-space-pre">
					{{prestataire.prs_observations}}
				</td>

				<td class="nowrap">
					{{prestataire.prs_date_debut_prestation | date:'mediumDate'}}
					<div class="text-sm text-center" *ngIf="prestataire.prs_date_debut_prestation">
						({{prestataire.prs_date_debut_prestation | timeAgo}})
					</div>
				</td>
				<td class="nowrap">
					{{prestataire.aud_date_modification | date:'mediumDate'}}
					<div class="text-sm text-center">
						({{prestataire.aud_date_modification | timeAgo}})
					</div>
				</td>


				<td class="text-center">
					<div>
						<button
							type="button"
							pButton
							class="p-button-sm mb-2"
							icon="pi pi-pencil"
							label="Editer"
							(click)="showEditPrestataireDialog(prestataire, rowIndex)"
							*ngIf="canEdit && prestataire.prs_actif"
						></button>
					</div>
					<div>
						<button
							type="button"
							pButton
							class="p-button-sm mb-2"
							[label]="prestataire.nb_incidents_non_resolus? 'Incidents (' + prestataire.nb_incidents_non_resolus + ')' : 'Incidents'"
							uiSref="incidents-prestataire"
							[uiParams]="{prs_id: prestataire.prs_id}"
						></button>
					</div>
					<div>
						<a
							pButton
							class="p-button-sm"
							label="Contrôle annuel"
							uiSref="controles-annuels-prestataire"
							[uiParams]="{prs_id: prestataire.prs_id}"
						></a>
					</div>

					<!-- <div>{{prestataire.nb_audits}}</div>
					<div>{{prestataire.date_dernier_audit}}</div>
					<div>{{prestataire.nb_incidents}}</div>
					<div>{{prestataire.date_dernier_incident}}</div>
					<div>{{prestataire.nb_incidents_non_resolus}}</div>
					<div>{{prestataire.date_dernier_incident_non_resolu}}</div> -->
				</td>
			</tr>
		</ng-template>

	</p-table>

</div>

