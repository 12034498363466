export const localeFR = {
	// startsWith: 'Commençant par',
	// contains: 'Contenant',
	// notContains: 'Ne contenant pas',
	// endsWith: 'Finissant par',
	// equals: 'Egal',
	// notEquals: 'Différent',
	// noFilter: 'Aucun filtre',
	// lt: 'Inférieur à',
	// lte: 'Inférieur ou égal à',
	// gt: 'Supérieur à ',
	// gte: 'Supérieur ou égal à',
	// is: 'Est',
	// isNot: 'N\'est pas',
	// before: 'Avant',
	// after: 'Après',
	// clear: 'Vider',
	// apply: 'Appliquer',
	// matchAll: 'Match All',
	// matchAny: 'Match Any',
	// addRule: 'Add Rule',
	// removeRule: 'Remove Rule',
	accept: 'Oui',
	reject: 'Non',
	choose: 'Sélectionner',
	upload: 'Envoyer',
	cancel: 'Annuler',
	firstDayOfWeek: 1,
	dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
	dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
	dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
	monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
	monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin','Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
	today: 'Aujourd\'hui',
	clear: 'Vider',
	dateFormat: 'dd/mm/yy',
	weekHeader: 'Sem',
	weak: 'Faible',
	medium: 'Moyen',
	strong: 'Fort',
	emptyMessage: 'Aucun choix disponible',
	emptyFilterMessage: 'Aucun résultat',
	invalidFileLimitMessageDetail: '{0} fichiers maximum.',
	invalidFileLimitMessageSummary: 'Le nombre de fichiers sélectionnés dépasse la limite.',
	invalidFileSizeMessageDetail: 'maximum autorisé : {0}.',
	invalidFileSizeMessageSummary: '{0} : Taille de fichier invalide',
	invalidFileTypeMessageDetail: 'Fichiers autorisés: {0}',
	invalidFileTypeMessageSummary: '{0}: Type de fichier invalide.',
	leavingUnsavedFormConfirmHeader: 'Modifications non sauvegardées',
	leavingUnsavedFormConfirmMessage: 'Souhaitez-vous vraiment quitter la page sans sauvegarder les modifications ?',
};
