<div class="grid grid-nogutter">

	<div class="col-12 grid xl:hidden">
		<div class="col-3"></div>
		<div class="col-5 text-center">
			<label class="font-bold">Année N</label>
		</div>
		<div class="col-4 text-center">
			<label class="font-bold">Année N-1</label>
		</div>
	</div>

	<div class="grid col-12 xl:col-6 " *ngFor="let ind_code of indicateurs">
		<div class="col-12 grid grid-nogutter hidden xl:flex">
			<div class="col-3"></div>
			<div class="col-5 text-center">
				<label class="font-bold">Année N</label>
			</div>
			<div class="col-4 text-center">
				<label class="font-bold">Année N-1</label>
			</div>
		</div>

		<div class="col-3 flex align-items-center justify-content-end text-right nowrap">
			<label class="font-bold"><indicateur-label-display [ind_code]="ind_code" [tooltipIcon]="false"></indicateur-label-display></label>
		</div>
		<div class="col-5 relative flex align-items-center justify-content-center" *ngIf="ratiosN">
			<p-chart
				type="doughnut"
				[data]="ratiosN[ind_code + '_dataset']"
				[options]="chartOptions"
				[plugins]="chartPlugins"
				[responsive]="true"
				width="100%"
			></p-chart>
			<div class="center-label center-label-n absolute text-center cockpit-large">
				<span><b>{{ratiosN[ind_code]}}%</b></span>
			</div>
		</div>
		<div class="col-4 relative flex align-items-center justify-content-center" *ngIf="ratiosPast">
			<p-chart
				type="doughnut"
				[data]="ratiosPast[ind_code + '_dataset']"
				[options]="chartOptions"
				[plugins]="chartPlugins"
				[responsive]="true"
				width="100%"
			></p-chart>
			<div class="center-label center-label-past absolute text-center">
				<span >{{ratiosPast[ind_code]}}%</span>
			</div>
		</div>
	</div>

</div>
