import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { statutsPointControlePrestataire } from '@app/moa/suivi-prestataires/prestataire.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'statutPointControlePrestataire'})
export class StatutPointControlePrestatairePipe implements PipeTransform {

	constructor() {}

	transform(dap_statut_ok: boolean|null): string {
		const found: any|undefined = statutsPointControlePrestataire.find((one: any) => {return one.value == dap_statut_ok; });
		if (found) {
			return found.label;
		}
		return 'dap_statut_ok';
	}
}
