import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { EvenementService, Evenement } from '@app/evenement/evenement.service';
import { MagasinService } from '@app/magasin/magasin.service';
import { NumberDisplayModule } from '@helpers/number-display';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { isDateString, startOfMonth } from '@helpers/utils';

@Component({
	selector: 'terminer-attente-dialog',
	templateUrl: './terminer-attente-dialog.html',
})
export class TerminerAttenteDialogComponent implements OnInit, IEventListener {

	evenement: Evenement;

	minDate: Date = startOfMonth(new Date());
	maxDate: Date = new Date();

	eve_date_attente_fin: Date;

	loading: boolean = false;

	constructor(
		private config: DynamicDialogConfig,
		private confirmationService: ConfirmationService,
		private dialogRef: DynamicDialogRef,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
		private magasinService: MagasinService,
	) {
		this.evenement = config.data.evenement;
		this.eve_date_attente_fin = new Date();
		this.setMinDate();
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	setMinDate() {
		this.loading = true;

		this.minDate = new Date(this.evenement.eve_date_evenement);

		this.magasinService.getDateButoir(this.evenement.mag_id)
		.subscribe({
			next: (response: any) => {
				if (isDateString(response)) {
					const dateButoir = new Date(response);

					if (this.minDate < dateButoir) {
						this.minDate = dateButoir;
					}
				}
			}
		})
		.add(() => { this.loading = false; });
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		let message = `Souhaitez-vous vraiment valider cette option ?`;
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: message,
			accept: () => {
				this.terminerAttente();
			}
		});
	}

	terminerAttente() {
		this.loading = true;
		this.evenementService.attenteEvenement(this.evenement, 'terminer', this.eve_date_attente_fin)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Option validée'});
				this.dialogRef.close(response);
			}
		})
		.add(() => { this.loading = false; });
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		InputTextModule,
		NumberDisplayModule,
	],
	exports: [TerminerAttenteDialogComponent],
	declarations: [TerminerAttenteDialogComponent]
})
export class TerminerAttenteDialogModule { }

