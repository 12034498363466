import { Component, NgModule, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { BlockableUI } from 'primeng/api';
import { TableModule } from '@app/primeng-overrides/table';

import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { GlobalModule } from '@global/global.module';
import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'bloc-valeurs',
	templateUrl: './bloc-valeurs.html',
	providers: []
})
export class BlocValeursComponent implements BlockableUI {

	@Input() loading: boolean;
	@Input() valeursN: any;
	@Input() valeursPast: any;

	@Output() onShowDetailVentesClick: EventEmitter<any> = new EventEmitter();

	indicateurs = [
		{ind_code: 'nb_entrees', tooltipIcon: false},
		{ind_code: 'nb_prises_rdv', tooltipIcon: false},
		{ind_code: 'nb_retours', tooltipIcon: false},
		{ind_code: 'nb_devis', tooltipIcon: false},
		{ind_code: 'nb_ventes', tooltipIcon: false},
	];

	constructor(private elementRef: ElementRef) {

	}

	ngOnInit() {

	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

	showDetailVentes() {
		this.onShowDetailVentesClick.emit();
	}


}
@NgModule({
	declarations: [
		BlocValeursComponent
	],
	exports: [
		BlocValeursComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		ButtonModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		GlobalModule,
		NumberDisplayModule,
	],
})
export class BlocValeursModule { }
