import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { TypePrestataire, typesPrestataires } from '@app/moa/suivi-prestataires/prestataire.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'typePrestataire'})
export class TypePrestatairePipe implements PipeTransform {

	constructor() {}

	transform(code: string): string {
		const found: TypePrestataire|undefined = typesPrestataires.find((one: TypePrestataire) => {return one.value == code; });
		if (found) {
			return found.label;
		}
		return code;
	}
}
