<div class="flex flex-column align-items-center">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto mb-2"
	>
		<ng-template pTemplate="body">
			<!-- ca -->
			<tr>
				<td class="clear-cell"></td>
				<td class="cell-th text-center">{{declaration.date_declaration|date:'MMM YYYY'}}</td>
				<td class="cell-th text-center">Objectif&nbsp;<indicateur-tooltip ind_code="objectif_ca_prise_commande_htple"></indicateur-tooltip></td>
				<td class="cell-th text-center"><indicateur-label-display ind_code="taux_mt_ventes_htple_mt_objectif_htple" [tooltip]="true"></indicateur-label-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="ca_prise_commande_htple" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="ca_prise_commande_htple" [value]="declaration.ca_prise_commande_htple"></indicateur-value-display></td>
				<td class="text-right "><indicateur-value-display ind_code="objectif_ca_prise_commande_htple" [value]="declaration.objectif_ca_prise_commande_htple"></indicateur-value-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_mt_ventes_htple_mt_objectif_htple" [value]="declaration.taux_mt_ventes_htple_mt_objectif_htple"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="ca_prise_commande_ht" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="ca_prise_commande_ht" [value]="declaration.dme_ca_prise_commande_ht"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="ca_prise_commande_ttc" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="ca_prise_commande_ttc" [value]="declaration.dme_ca_prise_commande_ttc"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="label_ca_prise_commande_htple_b2b" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="ca_prise_commande_htple_b2b" [value]="declaration.ca_prise_commande_htple_b2b"></indicateur-value-display></td>
			</tr>

			<!-- faux headers -->
			<tr>
				<td class="clear-cell text-center pt-4" colspan="2"><span class="shadow-1 p-1 pl-2 pr-2 surface-card">Indicateurs</span></td>
				<td class="clear-cell"></td>
				<td class="clear-cell text-center pt-4" colspan="2"><span class="shadow-1 p-1 pl-2 pr-2 surface-card">Ratios</span></td>
				<td class="clear-cell"></td>
				<td class="clear-cell text-center pt-4" colspan="2"><span class="shadow-1 p-1 pl-2 pr-2 surface-card">Indicateurs VENTES</span></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="nb_entrees" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="nb_entrees" [value]="declaration.dme_nb_entrees"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_nb_ventes_nb_entrees" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_nb_ventes_nb_entrees" [value]="declaration.taux_nb_ventes_nb_entrees"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="avg_vente_htple" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="avg_vente_htple" [value]="declaration.avg_vente_htple"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="nb_devis" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="nb_devis" [value]="declaration.dme_nb_devis"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_nb_ventes_nb_devis" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_nb_ventes_nb_devis" [value]="declaration.taux_nb_ventes_nb_devis"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_marge_commerciale" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right "><indicateur-value-display ind_code="taux_marge_commerciale" [value]="declaration.taux_marge_commerciale"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="nb_ventes" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="nb_ventes" [value]="declaration.dme_nb_ventes"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_nb_devis_nb_entrees" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_nb_devis_nb_entrees" [value]="declaration.taux_nb_devis_nb_entrees"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="nb_rdv_internet" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="nb_rdv_internet" [value]="declaration.dme_nb_rdv_internet"></indicateur-value-display></td>
			</tr>

			<!-- faux headers -->
			<tr>
				<td class="clear-cell text-center pt-4" colspan="2"><span class="shadow-1 p-1 pl-2 pr-2 surface-card">Facturation/Achat</span></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell text-center pt-4" colspan="2"><span class="shadow-1 p-1 pl-2 pr-2 surface-card">SAV</span></td>
			</tr>

			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="mt_facture_ht" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="mt_facture_ht" [value]="declaration.dme_mt_facture_ht"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="nb_sav" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="nb_sav" [value]="declaration.dme_nb_sav"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="mt_achats_factures" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="mt_achats_factures" [value]="declaration.dme_mt_achats_factures"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="mt_sav" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="mt_sav" [value]="declaration.dme_mt_sav"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="dme_mt_marge_facture_ht" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="dme_mt_marge_facture_ht" [value]="declaration.dme_mt_marge_facture_ht"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_mt_sav_ca_prise_commande_htple" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_mt_sav_ca_prise_commande_htple" [value]="declaration.taux_mt_sav_ca_prise_commande_htple"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="mt_achats_commandes_ht" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="mt_achats_commandes_ht" [value]="declaration.dme_mt_achats_commandes_ht"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="cell-th"><indicateur-label-display ind_code="taux_nb_sav_nb_ventes" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="taux_nb_sav_nb_ventes" [value]="declaration.taux_nb_sav_nb_ventes"></indicateur-value-display></td>
			</tr>
			<tr>
				<td class="cell-th"><indicateur-label-display ind_code="dme_ecart_prix_achat" [tooltip]="true"></indicateur-label-display></td>
				<td class="text-right"><indicateur-value-display ind_code="dme_ecart_prix_achat" [value]="declaration.dme_ecart_prix_achat"></indicateur-value-display></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
				<td class="clear-cell"></td>
			</tr>


		</ng-template>
	</p-table>





	<form class="" #declarationForm="ngForm" (ngSubmit)="declarationForm.valid && submit()">
		<h3>Renseignements à déclarer</h3>
		<p-table
			[value]="[1]"
			responsiveLayout="scroll"
			styleClass="p-datatable-sm p-datatable-gridlines mb-2"
		>
			<ng-template pTemplate="header">
				<tr>
					<th class="text-left">Nb Collaborateurs&nbsp;: {{declaration.effectifs.length}}</th>
					<th class="text-center" *ngFor="let item of attributsEffectif">
						{{item.label}}
					</th>
					<th class="text-center nowrap">Présence ce mois</th>
				</tr>
			</ng-template>

			<ng-template pTemplate="body">
				<tr *ngFor="let collaborateur of declaration.effectifs">
					<td>{{collaborateur.uti_prenom}} {{collaborateur.uti_nom}}</td>

					<td class="text-center" *ngFor="let item of attributsEffectif">
						<p-checkbox
							[(ngModel)]="collaborateur[item.attr]"
							[ngModelOptions]="{standalone: true}"
							[binary]="true"
							[disabled]="loading"
						></p-checkbox>
					</td>
					<td class="text-center nowrap pl-3 pr-3">
						<p-slider
							[(ngModel)]="collaborateur.def_presence"
							[ngModelOptions]="{standalone: true}"
							[step]="1"
							[min]="0"
							[max]="4"
							[required]="true"
							[disabled]="loading"
							styleClass="presence-slider stripe-4 selected-{{collaborateur.def_presence}}"
						></p-slider>
					</td>

				</tr>
			</ng-template>
		</p-table>



		<div class="text-center">
			<button
				type="button"
				pButton
				class="p-button-sm"
				label="Envoyer à mon animateur de région"
				icon="pi pi-save"
				(click)="submit()"
				[disabled]="!declarationForm.valid"
				[loading]="loading"
			></button>
		</div>

	</form>
</div>
