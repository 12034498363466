<h1 class="flex-shrink-0">
	<titre-module>Reporting de résultat</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<label for="annee">Année</label>
		<annee-selector
			name="annee"
			[(ngModel)]="params.annee"
			[nullIfInvalid]="true"
			[autoSelectCurrent]="true"
			[reverseOrder]="true"
			[minYear]="minYear"
			[disabled]="loading || exportLoading"
			(ngModelChange)="anneeChange($event)"
		></annee-selector>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			name="magasin"
			[(ngModel)]="params.mag_id"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[mesMagasins]="false"
			[autoSelectIfSingle]="true"
			[date_debut]="dateDebut"
			[date_fin]="dateFin"
			[disabled]="loading || exportLoading"
			placeholder="Tous les magasins"
			(ngModelChange)="paramChanged()"
		></magasin-selector>
	</div>

	<div class="field p-field-checkbox flex flex-column justify-content-end pt-0 pb-0">
		<div class="mb-2">
			<p-checkbox
				inputId="showPast"
				[(ngModel)]="showPast"
				[binary]="true"
				[disabled]="loading || exportLoading"
				(click)="detectChanges()"
			></p-checkbox>
			<label for="showPast">Afficher N-1</label>
		</div>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

</div>

<div class="min-table-height">
	<p-treeTable
		#tableResultats
		[value]="values"
		[rowTrackBy]="indexTracker"
		[lazy]="true"
		[loading]="loading || exportLoading"
		[columns]="columns"
		[frozenColumns]="frozen_columns"
		[scrollable]="true"
		scrollHeight="800px"
		frozenWidth="300px"
		[virtualScroll]="true"
		[virtualRowHeight]="15"
		(onNodeExpand)="onNodeExpand($event)"
		(onNodeCollapse)="onNodeCollapse($event)"
		styleClass="p-treetable-sm p-datatable-gridlines table-hover table-resultats"
	>

		<ng-template pTemplate="colgroup" let-columns>
			<colgroup>
				<col *ngFor="let col of columns; index as i; trackBy: indexTracker" [ngClass]="col.class">
			</colgroup>
		</ng-template>

		<ng-template pTemplate="frozenheader" let-columns>
			<tr class="">
				<th>
					&nbsp;
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr class="">
				<th
					class="text-center"
					[ngClass]="{'current-month': col.currentMonth}"
					*ngFor="let col of columns; let i = index; trackBy: indexTracker"
				>
					{{col.header}}
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
			<ng-container *ngIf="!rowData.hidden">

				<!-- magasin -->
				<ng-container *ngIf="rowData.mag_id">
					<!-- CA -->
					<tr class="row-group">
						<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{ rowData.mag_nom_court }} ({{rowData.mag_id}})">
							<div class="flex">
								<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
								<div>
									<div>
										<b>{{ rowData.mag_nom_court }} ({{rowData.mag_id}})</b>
									</div>
									<div>
										<small>
											<indicateur-label-display
												[ind_code]="allIndicateurs[0].ind_code"
												[tooltip]="values && values.length == 1"
												[tooltipIcon]="true"
											></indicateur-label-display>
										</small>
									</div>
								</div>
							</div>
						</td>
					</tr>
					<!-- B2B -->
					<tr class="row-indicateur">
						<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
							<indicateur-label-display
								ind_code="label_ca_prise_commande_htple_b2b"
								[tooltip]="values && values.length == 1"
								[tooltipIcon]="true"
							></indicateur-label-display>
						</td>
					</tr>
					<!-- Objectif -->
					<tr class="row-indicateur">
						<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
							<indicateur-label-display
								[ind_code]="frozenIndicateurs[2].ind_code"
								[tooltip]="values && values.length == 1"
								[tooltipIcon]="true"
							></indicateur-label-display>
						</td>
					</tr>
				</ng-container>

				<!-- indicateurs -->
				<ng-container *ngIf="!rowData.mag_id && (!rowData.past || (rowData.past && showPast))">
					<tr
						class=""
						[ngClass]="{
							'n-1': rowData.past,
							'row-indicateur': !rowData.separator,
							'row-separator': rowData.separator
						}"
					>
						<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
							<indicateur-label-display
								[ind_code]="rowData.ind_code"
								[tooltip]="values && values.length == 1"
								[tooltipIcon]="true"
								[nMoins1]="!!rowData.past"
								*ngIf="rowData.ind_code"
							></indicateur-label-display>
							<span *ngIf="rowData.separator">{{rowData.separator}}</span>
						</td>
					</tr>
				</ng-container>

			</ng-container>

		</ng-template>



		<ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData" let-columns="columns">

			<!-- magasin -->
			<ng-container *ngIf="!rowData.hidden">
				<!-- CA -->
				<ng-container *ngIf="rowData.mag_id">
					<tr	class="row-group">
						<td
							class="text-right col-mois"
							*ngFor="let col of columns; index as i; trackBy: indexTracker"
							[ngClass]="{
								'disabled': rowData['N'].mois && rowData['N'].mois[i] && rowData['N'].mois[i].futur,
								'current-month': col.currentMonth
							}"
						>
							<ng-container *ngIf="i < 12">
								<ng-container *ngIf="rowData['N'].mois && rowData['N'].mois[i]">
									<indicateur-value-display
										[color]="true"
										[value]="rowData['N'].mois[i][allIndicateurs[0].ind_code]"
										[ind_code]="allIndicateurs[0].ind_code"
									></indicateur-value-display>
								</ng-container>
							</ng-container>
							<!-- total -->
							<ng-container *ngIf="i == 12">
								<indicateur-value-display
									[color]="true"
									[value]="rowData['N'].total[allIndicateurs[0].ind_code]"
									[ind_code]="allIndicateurs[0].ind_code"
									*ngIf="rowData['N'].total"
								></indicateur-value-display>
							</ng-container>
						</td>
					</tr>

					<!-- B2B -->
					<tr	class="row-indicateur">
						<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker">
							<td
								class="text-right col-mois"
								[ngClass]="{
									'disabled': rowData['N'].mois && rowData['N'].mois[i] && rowData['N'].mois[i].futur,
									'current-month': col.currentMonth
								}"
							>
								<ng-container *ngIf="i < 12">
									<indicateur-value-display
										[color]="true"
										[value]="rowData['N'].mois[i][frozenIndicateurs[1].ind_code]"
										[ind_code]="frozenIndicateurs[1].ind_code"
									></indicateur-value-display>
								</ng-container>
								<!-- total -->
								<ng-container *ngIf="i == 12">
									<indicateur-value-display
										[color]="true"
										[value]="rowData['N'].total[frozenIndicateurs[1].ind_code]"
										[ind_code]="frozenIndicateurs[1].ind_code"
										*ngIf="rowData['N'].total"
									></indicateur-value-display>
								</ng-container>
							</td>
						</ng-container>
					</tr>

					<!-- objectif -->
					<tr	class="row-indicateur">
						<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker">
							<td
								class="text-right col-mois"
								[ngClass]="{
									'disabled': rowData['N'].mois && rowData['N'].mois[i] && rowData['N'].mois[i].futur,
									'current-month': col.currentMonth
								}"
							>
								<ng-container *ngIf="i < 12">
									<indicateur-value-display
										[color]="true"
										[value]="rowData['N'].mois[i][frozenIndicateurs[2].ind_code]"
										[ind_code]="frozenIndicateurs[2].ind_code"
									></indicateur-value-display>
								</ng-container>
								<!-- total -->
								<ng-container *ngIf="i == 12">
									<indicateur-value-display
										[color]="true"
										[value]="rowData['N'].total[frozenIndicateurs[2].ind_code]"
										[ind_code]="frozenIndicateurs[1].ind_code"
										*ngIf="rowData['N'].total"
									></indicateur-value-display>
								</ng-container>
							</td>
						</ng-container>
					</tr>
				</ng-container>

				<!-- indicateur -->
				<ng-container *ngIf="!rowData.mag_id && (!rowData.past || (rowData.past && showPast))">
					<tr
						class=""
						[ngClass]="{
							'n-1': rowData.past,
							'row-indicateur': !rowData.separator,
							'row-separator': rowData.separator
						}"
					>
						<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker">
							<td
								class="text-right col-mois"
								[ngClass]="{
									'disabled': rowData.mois && rowData.mois[i] && rowData.mois[i].futur,
									'current-month': col.currentMonth
								}"
							>
								<ng-container *ngIf="!rowData.separator">
									<ng-container *ngIf="i < 12">
										<indicateur-value-display
											[color]="true"
											[value]="rowData.mois[i].value"
											[ind_code]="rowData.ind_code"
										></indicateur-value-display>
									</ng-container>

									<!-- total -->
									<ng-container *ngIf="i == 12">
										<indicateur-value-display
											[color]="true"
											[value]="rowData.total"
											[ind_code]="rowData.ind_code"
										></indicateur-value-display>
									</ng-container>
								</ng-container>

							</td>

						</ng-container>
					</tr>
				</ng-container>

			</ng-container>

		</ng-template>




		<ng-template pTemplate="frozenfooter">

			<ng-container *ngIf="values && values.length > 1">
				<!-- ca -->
				<tr class="row-group">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<div class="flex">
							<div>
								<div>
									<b>Total</b>
								</div>
								<div>
									<small><indicateur-label-display [ind_code]="allIndicateurs[0].ind_code" [tooltipIcon]="true"></indicateur-label-display></small>
								</div>
							</div>
						</div>
					</td>
				</tr>
				<!-- B2B -->
				<tr class="row-ca">
					<th class="text-left white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<indicateur-label-display ind_code="label_ca_prise_commande_htple_b2b" [tooltipIcon]="true"></indicateur-label-display>
					</th>
				</tr>
				<!-- objectif -->
				<tr class="row-ca">
					<th class="text-left white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<indicateur-label-display [ind_code]="frozenIndicateurs[2].ind_code" [tooltipIcon]="true"></indicateur-label-display>
					</th>
				</tr>

				<!-- indicateurs -->
				<ng-container *ngFor="let indicateur of indicateurs">
					<tr
						class=""
						*ngIf="!indicateur.past || (indicateur.past && showPast)"
						[ngClass]="{
							'n-1': indicateur.past,
							'row-indicateur': !indicateur.separator,
							'row-separator': indicateur.separator
						}"
					>
						<th class="text-left white-space-nowrap overflow-hidden text-overflow-ellipsis">
							<indicateur-label-display
								[ind_code]="indicateur.ind_code"
								[tooltipIcon]="true"
								[nMoins1]="!!indicateur.past"
								*ngIf="indicateur.ind_code"
							></indicateur-label-display>
							<span *ngIf="indicateur.separator">{{indicateur.separator}}</span>
						</th>
					</tr>
				</ng-container>
			</ng-container>

		</ng-template>


		<ng-template pTemplate="footer" let-columns>

			<ng-container *ngIf="values && values.length > 1">
				<ng-container *ngFor="let indicateur of allIndicateurs; index as j">

					<tr
						class=""
						*ngIf="!indicateur.past || (indicateur.past && showPast)"
						[ngClass]="{
							'row-group': j == 0,
							'row-indicateur': j > 0 && !indicateur.separator,
							'n-1': indicateur.past,
							'row-separator': indicateur.separator
						}"
					>
						<ng-container *ngVar="indicateur.past? grandTotal['N-1'] : grandTotal['N'] ; let periode">

							<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker">
								<td
									class="text-right col-mois"
									[ngClass]="{
										'disabled': periode.mois && periode.mois[i] && periode.mois[i].futur,
										'current-month': col.currentMonth
									}"
								>
									<ng-container *ngIf="!indicateur.separator">
										<ng-container *ngIf="i < 12">
											<indicateur-value-display
												[color]="true"
												[value]="periode.mois[i][indicateur.ind_code]"
												[ind_code]="indicateur.ind_code"
											></indicateur-value-display>
										</ng-container>
										<ng-container *ngIf="i == 12">
											<indicateur-value-display
												[color]="true"
												[value]="periode.total[indicateur.ind_code]"
												[ind_code]="indicateur.ind_code"
											></indicateur-value-display>
										</ng-container>
									</ng-container>
								</td>
							</ng-container>

						</ng-container>
					</tr>
				</ng-container>

			</ng-container>

		</ng-template>

	</p-treeTable>
</div>
