<div class="mb-3">
	<div>
		<label class="font-bold">{{auditPrestataire.prestataire.prs_type | typePrestataire}} :</label> {{auditPrestataire.prestataire.prs_societe}}
	</div>
	<div>
		<label class="font-bold">Point de contrôle :</label> {{pointControle.pcp_libelle}}
	</div>
</div>

<p-fileUpload
	#uploader
	name="files[]"
	chooseLabel="Fichier..."
	[url]="urlUpload"
	[accept]="acceptedMimeTypes"
	[fileLimit]="fileLimit"
	[multiple]="fileLimit > 1"
	[maxFileSize]="maxFileSize"
	[showUploadButton]="false"
	[showCancelButton]="false"
	[disabled]="uploader.uploading"
	[invalidFileLimitMessageDetail]="invalidFileLimitMessageDetail"
	[invalidFileLimitMessageSummary]="invalidFileLimitMessageSummary"
	[invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
	[invalidFileSizeMessageSummary]="invalidFileSizeMessageSummary"
	[invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail"
	[invalidFileTypeMessageSummary]="invalidFileTypeMessageSummary"
	(onBeforeUpload)="onBeforeUpload($event)"
	(onUpload)="onUpload($event)"
	(onError)="onUploadError($event)"
>
	<ng-template pTemplate="toolbar">
		<button
			type="button"
			pButton
			icon="pi pi-times"
			label="Vider"
			[disabled]="!uploader.files.length || uploader.uploading"
			(click)="clearUploader()"
		></button>
		<button
			type="button"
			pButton
			icon="pi pi-upload"
			label="Envoyer"
			[disabled]="!uploader.files.length || uploader.files.length > fileLimit || uploader.uploading"
			[loading]="uploader.uploading"
			(click)="upload()"
		></button>
		<button
			type="button"
			pButton
			icon="pi pi-ban"
			label="Annuler"
			[disabled]="!uploader.uploading"
			(click)="abortUpload()"
		></button>
	</ng-template>

	<ng-template pTemplate="content" let-files >
		<div class="text-center clickable" *ngIf="!uploader.files.length" (click)="uploader.choose()">
			<div><i class="pi pi-icon pi-file"></i>Déposez ou sélectionnez un fichier</div>
			<div *ngIf="acceptedExtensions"><small>Fichiers acceptés : {{acceptedExtensions}} </small></div>
			<div *ngIf="maxFileSize"><small>Taille fichier maximum : {{maxFileSize|formatSize}}</small></div>
			<div *ngIf="fileLimit > 1"><small>Nombre de fichiers maximum : {{fileLimit}}</small></div>
		</div>
	</ng-template>

	<ng-template let-file let-index="index" pTemplate="file">
		<div class="flex align-items-center justify-content-stretch p-fileupload-file">
			<div class="p-fileupload-thumb">
				<file-icon [file]="file"></file-icon>
			</div>
			<div class="p-fileupload-file-info">
				<div>
					<div class="overflow-hidden white-space-nowrap text-overflow-ellipsis pr-2">{{file.name}}</div>
					<div><small>{{ file.size | formatSize }}</small></div>
				</div>
			</div>

			<div class="p-fileupload-button ml-auto flex-shrink-0">
				<button
					type="button"
					pButton
					class="p-button-xs"
					icon="pi pi-times"
					[disabled]="uploader.uploading"
					(click)="removeFile(index)"
				></button>
			</div>
		</div>
	</ng-template>

</p-fileUpload>

