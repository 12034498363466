import { Component, NgModule, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalModule } from '@global/global.module';
import { NumberDisplayModule, numberDisplayTypes } from '@helpers/number-display';
import { IndicateurService } from '@app/indicateur/indicateur.service';

@Component({
	selector: 'indicateur-value-display',
	template: `
		<ng-container *ngIf="value !== null">
			<number-display
				[value]="value"
				[type]="indicateurType"
				[numberFormat]="numberFormat"
				[hideZero]="hideZero"
				[labelInfinity]="labelInfinity"
				style="{{style}}"
			></number-display>
		</ng-container>
	`,
	providers: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicateurValueDisplayComponent implements OnInit {

	@Input() value: number|null;
	@Input() ind_code: string;
	@Input() hideZero: boolean;
	@Input() color: boolean;
	@Input() labelInfinity: string;

	indicateur: any;
	indicateurType: numberDisplayTypes;
	numberFormat: string = '.0-0';

	style: string|null;

	constructor(private indicateurService: IndicateurService) {

	}

	ngOnInit() {
		if (!this.ind_code) {
			throw new Error('Need an ind_code');
		}
		else {
			this.indicateur = this.indicateurService.find(this.ind_code);
			if (!this.indicateur) {
				// throw new Error(`Indicateur not found: ${this.ind_code}`);
				console.log(`Indicateur not found: ${this.ind_code}`);
				return
			}
		}

		switch(this.indicateur.ind_format) {
			case 'monetaire' :
				this.indicateurType = 'currency';
				break;

			case 'pourcent':
				this.indicateurType = 'percent';
				break;
		}

		this.numberFormat = `.0-${this.indicateur.ind_nb_decimales}`;
	}

	ngOnChanges(changes: any) {
		if (changes && changes.value && changes.value.currentValue != changes.value.previousValue) {
			if (this.color && this.ind_code) {
				const cssVar = this.indicateurService.getColorVar(this.ind_code, changes.value.currentValue);

				if (!cssVar) {
					this.style = null;
				}
				else {
					this.style = `color: var(${cssVar})`;
				}
			}
		}
	}

}
@NgModule({
	declarations: [
		IndicateurValueDisplayComponent
	],
	exports: [
		IndicateurValueDisplayComponent
	],
	imports: [
		CommonModule,
		GlobalModule,
		NumberDisplayModule
	],
})
export class IndicateurValueDisplayModule { }
