<div class="flex flex-column">
	<form #formContratAmortissementExposition="ngForm" class="formgrid grid p-fluid">

		<div class="field col-fixed">
			<label for="foe_code">Fournisseur</label>
			<div class="flex align-items-center">
				<fournisseur-exposition-selector
					name="foe_code"
					[(ngModel)]="contrat.foe_code"
					[required]="true"
					(onSelectionChange)="onFournisseurSelectionChange($event)"
					[disabled]="loading"
					*ngIf="!contrat.cae_id && canEdit"
				></fournisseur-exposition-selector>
				<div class="p-inputtext fake-input" *ngIf="contrat.cae_id || !canEdit">{{fournisseur.foe_nom}}</div>
			</div>
		</div>

		<div class="field col-fixed">
			<label for="cae_date_debut_contrat">Date début contrat</label>
			<div class="flex">
				<p-calendar
					name="cae_date_debut_contrat"
					[(ngModel)]="contrat.cae_date_debut_contrat"
					[showTime]="false"
					[showIcon]="true"
					[required]="true"
					dateFormat="mm/yy"
					view="month"
					placeholder="mm/aaaa"
					[minDate]="minDate"
					appendTo="body"
					[disabled]="loading || contrat.cae_contrat_termine"
					[readonly]="contrat.cae_contrat_termine || !canEdit"
				></p-calendar>
			</div>
		</div>


		<p-message
			severity="warn"
			text="{{fournisseur.foe_explication}}"
			class="mb-3 p-message-no-icon"
			*ngIf="fournisseur && fournisseur.foe_explication"
		></p-message>

		<div class="field col-12 md:col-6">
			<label for="cae_intitule">Intitulé</label>
			<input
				type="text"
				pInputText
				name="cae_intitule"
				[(ngModel)]="contrat.cae_intitule"
				placeholder="Intitulé"
				[required]="true"
				[disabled]="loading"
				[readonly]="contrat.cae_contrat_termine || !canEdit"
			>
		</div>

		<div class="field p-field-checkbox flex flex-column justify-content-end">
			<div>
				<p-checkbox
					name="cae_premier_contrat"
					inputId="cae_premier_contrat"
					[(ngModel)]="contrat.cae_premier_contrat"
					[binary]="true"
					[disabled]="loading || !!contrat.cae_id || !canEdit"
				></p-checkbox>
				<label for="cae_premier_contrat">Premier contrat</label>
			</div>
		</div>

		<div class="field col-12" *ngIf="optionsSpecifiques.length || contrat.cae_option_specifique">
			<label for="cae_option_specifique">Option spécifique</label>
			<p-dropdown
				name="cae_option_specifique"
				[options]="optionsSpecifiques"
				[(ngModel)]="contrat.cae_option_specifique"
				[disabled]="loading"
				appendTo="body"
				*ngIf="!contrat.cae_id && canEdit"
			>
			</p-dropdown>
			<div class="p-inputtext fake-input" *ngIf="contrat.cae_id || !canEdit">
				<span *ngIf="!contrat.cae_option_specifique">Aucune</span>
				<span *ngIf="contrat.cae_option_specifique">{{contrat.cae_option_specifique}}</span>
			</div>
		</div>

		<div class="field col-fixed md:col-4">
			<label for="cae_mt_a_amortir">Montant à amortir</label>
			<div class="p-input-icon-right input-container">
				<i class="pi ff-reset">€</i>
				<input
					type="text"
					pInputText
					name="cae_mt_a_amortir"
					gzInputNumberFormatter
					[(ngModel)]="contrat.cae_mt_a_amortir"
					[decimals]="2"
					[currency]="true"
					class="text-right"
					[required]="true"
					placeholder="Montant"
					[disabled]="loading"
					[readonly]="contrat.cae_contrat_termine || !canEdit"
				>
			</div>
		</div>

		<p-message
			severity="info"
			text="La durée et le taux d'amortissement seront modifiables après la création du contrat"
			styleClass="mb-3"
			*ngIf="!contrat.cae_id"
		></p-message>

		<ng-container *ngIf="contrat.cae_id">
			<div class="field col-fixed md:col-4" >
				<label for="cae_coef_amortissement">Coef d'amortissement <i class="pi pi-question-circle middle" pTooltip="Nombre de fois le montant du contrat"></i></label>
				<p-inputNumber
					name="cae_coef_amortissement"
					[min]="0.1"
					[max]="50"
					mode="decimal"
					[step]="0.5"
					[maxFractionDigits]="1"
					[(ngModel)]="contrat.cae_coef_amortissement"
					inputStyleClass="text-right"
					[required]="true"
					placeholder="Taux"
					[disabled]="loading"
					[readonly]="contrat.cae_contrat_termine || !canEdit"
				></p-inputNumber>
			</div>

			<div class="field col-fixed md:col-4" >
				<label for="cae_duree_contrat">Durée contrat (en mois)</label>
				<input
					type="number"
					min="1"
					max="36"
					pInputText
					name="cae_duree_contrat"
					gzInputNumberFormatter
					[(ngModel)]="contrat.cae_duree_contrat"
					[decimals]="0"
					class="text-right"
					[required]="true"
					placeholder="En mois"
					[disabled]="loading"
					[readonly]="contrat.cae_contrat_termine || !canEdit"
				>
			</div>

		</ng-container>

		<div class="field col-12">
			<label for="cae_commentaire">Commentaire</label>
			<textarea
				pInputTextarea
				name="cae_commentaire"
				[(ngModel)]="contrat.cae_commentaire"
				[autoResize]="true"
				[rows]="5"
				[disabled]="loading || !canEdit"
				placeholder="Commentaire"
			></textarea>
		</div>


		<div class="field p-field-checkbox flex flex-column justify-content-end" *ngIf="contrat.cae_id">
			<div>
				<p-checkbox
					name="cae_contrat_termine"
					inputId="cae_contrat_termine"
					[(ngModel)]="contrat.cae_contrat_termine"
					[binary]="true"
					[disabled]="loading || !canEdit"
				></p-checkbox>
				<label for="cae_contrat_termine">Contrat terminé</label>
			</div>
		</div>


	</form>

	<div class="p-dialog-footer text-right flex-shrink-0" *ngIf="canEdit">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !formContratAmortissementExposition.valid"
			(click)="submit()"
		></button>
	</div>
</div>
