<h1 class="sm:flex align-items-center flex-shrink-0">
	<!-- <titre-module>Contrôle annuel</titre-module> -->
	<back-button class="mr-2 flex-shrink-0"></back-button>
	<span class="flex-shrink-0">
		Prestataires > Incidents
	</span>
	<print-button-simple [track]="false" [track_mag_id]="prestataire.mag_id"></print-button-simple>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-left">
					{{prestataire.magasin.label}}
				</td>
				<td class="clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text hide-print"
						[disabled]="loading"
						[loading]="loading"
						(click)="refresh()"
					></button>
				</td>
			</tr>
			<tr>
				<td class="cell-th">{{prestataire.prs_type | typePrestataire}}</td>
				<td class="text">
					{{prestataire.label}}
				</td>
			</tr>

			<tr>
				<td class="cell-th">Etat</td>
				<td>
					<p-dropdown
						name="stats"
						[options]="statutsIncidentPrestataire"
						[(ngModel)]="params.resolu"
						[showClear]="true"
						placeholder="Tous"
						(ngModelChange)="refresh()"
						appendTo="body"
						class="hide-print"
					>
					</p-dropdown>
					<div [ngSwitch]="params.resolu" class="show-print">
						<span *ngSwitchCase="null">Tous</span>
						<span *ngSwitchCase="true">Résolu</span>
						<span *ngSwitchCase="false">Non résolu</span>
					</div>
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>


<div class="min-table-height">

	<p-table
		#tableIncidents
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[loading]="loading"
		responsiveLayout="scroll"
		[paginator]="true"
		[rows]="rows"
		[rowsPerPageOptions]="[20,50,{showAll: 'Tous'}]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-incidents-prestataire"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun incident pour ce prestataire</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center nowrap" pSortableColumn="ipr_date">Date incident <p-sortIcon field="ipr_date"></p-sortIcon></th>
				<th class="text-center min-w-10rem" pSortableColumn="ipr_client">Nom client<p-sortIcon field="ipr_client"></p-sortIcon></th>
				<th class="text-center max-w-10rem" pSortableColumn="ipr_option_winner">N° Option Winner <p-sortIcon field="ipr_option_winner"></p-sortIcon></th>
				<th class="text-center min-w-10rem" pSortableColumn="uti_vendeur">Vendeur <p-sortIcon field="uti_vendeur"></p-sortIcon></th>
				<th class="text-center min-w-10rem" pSortableColumn="ipr_nature">Nature du problème <p-sortIcon field="ipr_nature"></p-sortIcon></th>
				<th class="text-center min-w-10rem" pSortableColumn="ipr_consequences">Impact <p-sortIcon field="ipr_consequences"></p-sortIcon></th>
				<th class="text-center" pSortableColumn="ipr_date_resolution">Date résolution <p-sortIcon field="ipr_date_resolution"></p-sortIcon></th>
				<th class="text-center" pSortableColumn="aud_date_modification">Date mise à jour <p-sortIcon field="aud_date_modification"></p-sortIcon></th>
				<th class="text-center hide-print" *ngIf="canEdit && prestataire.prs_actif">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						class="p-button p-button-sm"
						label="Ajouter"
						(click)="showIncidentPrestataireDialog()"
					></button>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-incident let-rowIndex="rowIndex">
			<tr class="row-group" [ngClass]="{'disabled': incident.ipr_date_resolution}">
				<td class="text-center nowrap">
					{{incident.ipr_date | date:'mediumDate'}}
				</td>
				<td class="text-left">{{incident.ipr_client}}</td>
				<td class="text-left">{{incident.ipr_option_winner}}</td>
				<td class="text-left nowrap">{{incident|utilisateur}}</td>

				<td class="text-left white-space-pre">{{incident.ipr_nature}}</td>

				<td class="text-left white-space-pre">{{incident.ipr_consequences}}</td>

				<td class="text-center nowrap">
					{{incident.ipr_date_resolution | date:'mediumDate'}}
				</td>

				<td class="text-center nowrap">
					{{incident.aud_date_modification | date:'medium'}}
				</td>

				<td class="text-center nowrap hide-print" *ngIf="canEdit && prestataire.prs_actif">
					<button
						type="button"
						pButton
						icon="pi pi-pencil"
						class="p-button-xs p-button-rounded p-button-text"
						(click)="showIncidentPrestataireDialog(incident)"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-trash"
						class="p-button-xs p-button-rounded p-button-text p-button-danger"
						(click)="deleteIncidentPrestataire(incident)"
					></button>
				</td>
			</tr>
		</ng-template>



	</p-table>

</div>

