import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { UIRouter, PathNode, Transition } from '@uirouter/core';

import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

import { IEventListener, EventManagerService } from '@global/event-manager.service';
import { localeFR } from '@global/locale-fr';

import { AnalyticsService } from '@global/analytics.service'

import { ConfigService } from '@global/config.service';

import { environment, enseigne } from '@environments/environment';

interface Crumb {
	state: string;
	label: string;
}

@Component({
	selector: 'app',
	templateUrl: './app.component.html',
	providers: [ MessageService ]
})
export class AppComponent implements OnInit, IEventListener {

	private crumbs: Crumb[] = [];

	baseTitle: string;

	constructor(
		private analyticsService: AnalyticsService,
		private configService: ConfigService,
		private eventManager: EventManagerService,
		private messageService: MessageService,
		private primeNGConfig: PrimeNGConfig,
		private titleService: Title,
		private uiRouter: UIRouter,
	) {
		this.baseTitle = environment[enseigne].title;

		// first time
		this.updateTitle(uiRouter.globals.successfulTransitions.peekTail());
		this.updateBodyClass(uiRouter.globals.successfulTransitions.peekTail())

		// when navigating
		this.uiRouter.transitionService.onSuccess({}, (transition: Transition) => {
			this.updateTitle(transition);
			this.updateBodyClass(transition);
			this.analyticsService.trackEvent('page_view');
		});

		this.eventManager.registerEvent('logout', this, (args: any) => {
				this.resetTitle();
			}
		);

		this.eventManager.registerEvent('toast', this, (args: any) => {
				this.toast(args);
			}
		);
	}

	ngOnInit() {
		this.primeNGConfig.setTranslation(localeFR);
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('toast', this);
		this.eventManager.unregisterEvent('logout', this);
	}


	private updateBodyClass(transition: Transition) {
		if (transition.to()) {
			let to: string = transition.to().name!;
			if (transition.from() && transition.from().name) {
				let from: string = transition.from().name!;
				document.body.classList.replace(from, to);
			}
			else {
				document.body.classList.add(to);
			}
		}
	}

	private updateTitle(transition: Transition) {
		this.crumbs = transition.treeChanges('to')
		.filter((node: PathNode) => {return node.state.data && node.state.data.title})
		.map((node: PathNode) => {
			let title = typeof node.state.data.title == 'string' ? node.state.data.title : node.state.data.title(transition);
			return {
				state: node.state.name,
				// label: node.state.breadcrumb(transition)
				label: this.configService.getModuleTitle(node.state.name, title)
			}
		});
		const trail = this.crumbs.map((one: any) => { return one.label; });
		const title = [this.baseTitle, ...trail].join(' > ');

		this.titleService.setTitle(title);
	}

	private resetTitle() {
		this.titleService.setTitle(this.baseTitle);
	}

	private toast(toast: any): void {
		if (toast instanceof Array) {
			toast.forEach(one => this.prepareToast(one));
		}
		else {
			this.prepareToast(toast);
		}
		this.messageService.add(toast);
	}

	private prepareToast(toast: any): void {
		toast.severity = toast.severity || 'info';
		if (toast.severity == 'error') toast.life = 10000;
	}

	logout() {
		this.eventManager.emit('logout', {manual: true});
	}
}
