import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import {
	SaisiePotentielCAMagasin,
	SimulateurService,
	SimulationPotentielCAMagasin
} from '@app/simulateur/simulateur.service';
import { TitreModuleModule } from '@app/titre-module';

import { clone, isEmpty } from '@helpers/utils';

@Component({
	selector: 'potentiel-ca-magasin',
	templateUrl: './potentiel-ca-magasin.html',

})
export class SimulateurPotentielCAMagasinComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;
	ready: boolean = false;
	debouncedSubmit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	simulation: SimulationPotentielCAMagasin;
	saisies: SaisiePotentielCAMagasin = new SaisiePotentielCAMagasin();
	mag_id: number|null = null;

	config: any;

	today: Date = new Date();
	annee: number = this.today.getFullYear();
	anneeMoins1: number = this.annee -1;


	constructor(
		private configService: ConfigService,
		private simulateurService: SimulateurService,
	) {
		this.config = this.configService.getConfigEnseigne('config_simulateurs')['potentiel_ca_magasin'];

		this.debouncedSubmit
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.submit();
			}
		});
	}

	ngOnInit() {
		this.load();
	}

	paramChanged() {
		this.debouncedSubmit.next(true);
	}

	load() {
		this.loading = true;
		this.simulateurService.getPotentielCAMagasin()
		.subscribe({
			next: (response: any) => {
				this.updateDisplay(response);
			}
		})
		.add(() => { this.loading = false; });
	}

	submit() {
		if (this.mag_id) {
			this.loading = true;
			let tmpSaisies = (this.saisies.mag_id != this.mag_id)? new SaisiePotentielCAMagasin() : clone(this.saisies);
			tmpSaisies.mag_id = this.mag_id;
			if (tmpSaisies.ratio_nb_devis_nb_entrees_personnalise) {
				tmpSaisies.ratio_nb_devis_nb_entrees_personnalise = tmpSaisies.ratio_nb_devis_nb_entrees_personnalise/100;
			}
			if (tmpSaisies.ratio_nb_ventes_nb_devis_personnalise) {
				tmpSaisies.ratio_nb_ventes_nb_devis_personnalise = tmpSaisies.ratio_nb_ventes_nb_devis_personnalise/100;
			}
			this.simulateurService.postPotentielCAMagasin(tmpSaisies)
			.subscribe({
				next: (response: any) => {
					this.updateDisplay(response);
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	updateDisplay(values: any) {
		if (values && !isEmpty(values)) {
			this.simulation = values;
			let tmpSaisies = values.saisies;
			if (tmpSaisies.ratio_nb_ventes_nb_devis_personnalise) {
				tmpSaisies.ratio_nb_ventes_nb_devis_personnalise = Math.round(tmpSaisies.ratio_nb_ventes_nb_devis_personnalise*100);
			}
			if (tmpSaisies.ratio_nb_devis_nb_entrees_personnalise) {
				tmpSaisies.ratio_nb_devis_nb_entrees_personnalise = Math.round(tmpSaisies.ratio_nb_devis_nb_entrees_personnalise*100);
			}

			this.saisies = tmpSaisies;
			this.mag_id = this.saisies.mag_id;
		}
	}


}
@NgModule({
	declarations: [
		SimulateurPotentielCAMagasinComponent
	],
	exports: [
		SimulateurPotentielCAMagasinComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		GlobalModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TitreModuleModule
	],
})
export class SimulateurPotentielCAMagasinModule { }
