import { Component, NgModule, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from 'primeng/button';
import { ConfigService } from '@global/config.service';


@Component({
	selector: 'lien-documentation',
	template: `
		<a
			pButton
			class="p-button-text hide-print"
			icon="pi pi-question-circle"
			[label]="label"
			href="{{url}}"
			target="_blank"
			*ngIf="url"
		></a>
	`
})
export class LienDocumentationComponent implements OnInit {

	@Input() prm_code: string;
	@Input() label: string = 'Notice';
	url: string;

	constructor(private configService: ConfigService) {

	}

	ngOnInit() {
		this.url = this.configService.getConfigEnseigne(this.prm_code);
	}
}

@NgModule({
	declarations: [
		LienDocumentationComponent
	],
	exports: [
		LienDocumentationComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
	],
})
export class LienDocumentationModule { }
