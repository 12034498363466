<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Statistiques par code postal</titre-module>
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			#daterangeSelector
			[disabled]="loading"
			[useStorage]="true"
			[rangeMode]="true"
			[modes]="['mois','libre']"
			[minDate]="minDate"
			(onDateChange)="onDateChange($event)"
			class="formgrid grid p-fluid hide-print"
		></daterange-selector>
		<span class="show-print" *ngIf="daterangeSelector.mode == 'mois' && daterangeSelector.sameMonth">{{dateRange[0] | date: 'MMMM yyyy'|titlecase}}</span>
		<span class="show-print" *ngIf="daterangeSelector.mode == 'libre' || !daterangeSelector.sameMonth">Du {{dateRange[0] | date: 'dd/MM/yyyy'}} au {{dateRange[1] | date: 'dd/MM/yyyy'}}</span>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			#magasinSelector
			name="magasin"
			[(ngModel)]="mag_id"
			[nullOption]="false"
			[nullIfInvalid]="false"
			[mesMagasins]="false"
			[date_debut]="dateRange[0]"
			[date_fin]="dateRange[1]"
			[autoSelectFirst]="true"
			[required]="true"
			[disabled]="loading"
			(ngModelChange)="paramChanged()"
			class="hide-print"
		></magasin-selector>
		<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
	</div>


	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text hide-print"
			[disabled]="loading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>


</div>


<div class="grid">
	<top10 class="block col-fixed" indicateur="devis" [values]="top10Devis"></top10>
	<top10 class="block col-fixed" indicateur="ventes" [values]="top10Ventes"></top10>
	<top-par-milieu class="block col-12" [values]="topMilieux"></top-par-milieu>
</div>


