import { TrackedObject, DeletableTrackedObject } from '@helpers/tracked-object';
import { Magasin } from '@app/magasin/magasin.service';

export type TypePrestataire = {
	value: string;
	label: string;
}

export const typesPrestataires: TypePrestataire[] = [
	{ value: 'L', label: 'Livreur'},
	{ value: 'P', label: 'Poseur'}
];

export const statutsPointControlePrestataire: any = [
	{ value: null, label: 'A définir', key: 'nb_statut_inconnu' },
	{ value: true, label: 'OK', key: 'nb_statut_ok' },
	{ value: false, label: 'KO', key: 'nb_statut_ko' },
];

export const statutsIncidentPrestataire: any = [
	{ value: null, label: 'Tous' },
	{ value: true, label: 'Résolu' },
	{ value: false, label: 'Non résolu' },
];

export class PointControlePrestataire extends TrackedObject {
	pcp_id: number;
	pcp_libelle: string;
	pcp_piece_jointe_possible: boolean;
	pcp_actif: boolean;
	pcp_livreur: boolean;
	pcp_poseur: boolean;
	pcp_ordre: number;
	dap_id: number;
	apr_id: number;
	dap_statut_ok: boolean|null;
	dap_piece_jointe: string|null;
}

export class Prestataire extends TrackedObject {
	prs_id: number;
	mag_id: number;
	prs_type: string;
	prs_societe: string;
	prs_adresse: string|null;
	prs_email: string|null;
	prs_telephone: string|null;
	prs_interlocuteur: string|null;
	prs_tarifs: string|null;
	prs_observations: string|null;
	prs_date_debut_prestation: Date|null;
	prs_actif: boolean;
	nb_audits: number;
	date_dernier_audit: Date|null;
	nb_incidents: number;
	date_dernier_incident: Date|null;
	nb_incidents_non_resolus: number;
	date_dernier_incident_non_resolu: Date|null;

	magasin: Magasin;
	label: string;
}

export class AuditPrestataire extends TrackedObject {
	apr_id: number;
	prs_id: number;
	apr_annee: number;
	apr_date_derniere_saisie: Date|string;
	apr_representant: string;
	apr_date_fin_contrat: Date|string|null;
	apr_cause_fin_contrat: string|null;

	prestataire: Prestataire;
	magasin: Magasin;

	details: PointControlePrestataire[];

	editable: boolean;
	nb_statut_ok: number = 0;
	nb_statut_ko: number = 0;
	nb_statut_inconnu: number = 0;
	nb_piece_jointe: number = 0;
	nb_piece_jointe_possible: number = 0;

	constructor(prs_id?: number) {
		super();
		if (prs_id) this.prs_id = prs_id;
	}
}

export class IncidentPrestataire extends DeletableTrackedObject {
	ipr_id: number;
	prs_id: number;
	ipr_date: Date = new Date();
	ipr_client: string;
	ipr_option_winner: string|null;
	uti_vendeur: number;
	ipr_nature: string;
	ipr_consequences: string|null;
	ipr_date_resolution: Date|string|null;

	prestataire: Prestataire;
	magasin: Magasin;

	constructor(prs_id?: number) {
		super();
		if (prs_id) this.prs_id = prs_id;
	}
}
