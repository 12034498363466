import { StateService, Transition } from '@uirouter/core';

import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { AuthService } from '@app/auth/auth.service';
import { ConfigService } from '@global/config.service';
import { MagasinService } from '@app/magasin/magasin.service';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { StateUtilsService } from '@global/state-utils.service';

export const startRefreshTokenTimerResolve = {
	token: 'startRefreshTokenTimer',
	deps: [AuthService],
	resolveFn: (authService: AuthService) => {
		authService.startRefreshTokenTimer()
		return true;
	}
}

export const configResolve = {
	token: 'config',
	deps: [StateUtilsService, AuthService, ConfigService],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, configService: ConfigService): Promise<any>|void =>  {
		if (authService.user)	{
			return stateUtils.successOrLogout(configService.getConfig());
		}
	}
}

export const mesMagasinsResolve = {
	token: 'mes_magasins',
	deps: [StateUtilsService, AuthService, MagasinService],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, magasinService: MagasinService): Promise<any>|void =>  {
		if (authService.user)	{
			return stateUtils.successOrLogout(magasinService.getMesMagasins());
		}
	}
}

export const indicateursResolve = {
	token: 'indicateurs',
	deps: [StateUtilsService, AuthService, IndicateurService, 'config'],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, indicateurService: IndicateurService, config: any): Promise<any>|void =>  {
		if (authService.user) {
			return stateUtils.successOrLogout(indicateurService.getIndicateurs());
		}
	}
}

export const niveauxCompetences = {
	token: 'niveaux_competences',
	deps: [StateUtilsService, AuthService, ReferentielCompetencesService],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, referentielCompetencesService: ReferentielCompetencesService): Promise<any>|void => {
		return stateUtils.successOrLogout(referentielCompetencesService.getNiveauxCompetences());
	}
}

export const profilsCompetences = {
	token: 'profils_competences',
	deps: [StateUtilsService, AuthService, ReferentielCompetencesService],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, referentielCompetencesService: ReferentielCompetencesService): Promise<any>|void => {
		return stateUtils.successOrLogout(referentielCompetencesService.getProfilsCompetences());
	}
}

export const fournisseursExpositions = {
	token: 'fournisseurs_expositions',
	deps: [StateUtilsService, AuthService, AchatsFournisseursService],
	resolveFn: (stateUtils: StateUtilsService, authService: AuthService, achatsFournisseursService: AchatsFournisseursService): Promise<any>|void => {
		return stateUtils.successOrLogout(achatsFournisseursService.getFournisseursExpositions());
	}
}
