import { Component, NgModule, ElementRef, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockableUI } from 'primeng/api';
import { ChartModule } from 'primeng/chart';

import { Settings } from 'luxon';
import 'chartjs-adapter-luxon';

import { NumberDisplayModule } from '@helpers/number-display';
import { cssVarValue, startOfDay, numericSort } from '@helpers/utils';

@Component({
	selector: 'bloc-evolution-ca',
	templateUrl: './bloc-evolution-ca.html',
	providers: []
})
export class BlocEvolutionCAComponent implements OnChanges, BlockableUI {

	@Input() evolutions: any;

	data: any;
	dataPast: any;
	chartOptions: any;


	constructor(
		private elementRef: ElementRef,
	) {
		Settings.defaultLocale = 'fr';
	}

	ngOnInit() {
		this.chartOptions = {
			locale: 'fr-FR',
			zone: 'Europe/Paris',
			// plugins: {
			// 	decimation: {
			// 		enabled: true,
			// 		algorithm: 'lttb',
			// 		samples: 30,
			// 	},
			// 	tooltip: {
			// 		callbacks: {
			// 			label: function(context: any) {
			// 				let label = context.dataset.label || '';
			// 				if (label) {
			// 					label += ': ';
			// 				}
			// 				if (context.parsed.y !== null) {
			// 					label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0, }).format(context.parsed.y);
			// 				}
			// 				return label;
			// 			}
			// 		},
			// 	},
			// },
			scales: {
				x: {
					type: 'time',
					ticks: {
						source: 'auto',
						// Disabled rotation for performance
						// maxRotation: 0,
						// autoSkip: true,
					},
					time: {
						tooltipFormat: 'DD',
						unit: 'day',
					},
				},
				y: {
					ticks: {
						beginAtZero: true,
						callback: function(value: any, index: any, values: any) {
							return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0,}).format(value);
						}
					}
				}
			},
		};
	}

	ngOnChanges(changes: any) {
		if (changes.evolutions && changes.evolutions.currentValue) {
			let dataN = this.splitData(changes.evolutions.currentValue['N']);
			this.setValues(dataN);
			let lastPast = changes.evolutions.currentValue['N-1'][changes.evolutions.currentValue['N-1'].length -1];
			this.dataPast = {
				ca_prise_commande_htple: lastPast.ca_prise_commande_htple,
				objectif: lastPast.objectif_ca_prise_commande_htple,
			}
		}
	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

	splitData(data: any) {
		let dataset: {[key: string]: any[]} = {};
		data.forEach((oneData: any) => {
			for (let prop in oneData) {
				if (prop != 'date') {
					if (!dataset[prop]) dataset[prop] = [];
					dataset[prop].push({
						x: startOfDay(new Date(oneData.date)),
						y: oneData[prop]
					});
				}
			}
		});
		return dataset;
	}

	setValues(data: any) {
		numericSort(data.ca_prise_commande_htple, 'date');
		this.data = {
			datasets: [
				{
					label: 'CA Prise Commande HT HPLE',
					data: data.ca_prise_commande_htple,
					fill: true,
					backgroundColor: cssVarValue('--graph-realise-background'),
					borderColor: cssVarValue('--graph-realise'),
					tension: 0,
					pointBorderWidth: 0,
					spanGaps: true
				},
				{
					label: 'Objectif',
					data: data.objectif_ca_prise_commande_htple,
					fill: true,
					backgroundColor: cssVarValue('--graph-objectif-background'),
					borderColor: cssVarValue('--graph-objectif'),
					tension: 0,
					pointBorderWidth: 0,
					spanGaps: true
				}
			]
		};
	}



}
@NgModule({
	declarations: [
		BlocEvolutionCAComponent
	],
	exports: [
		BlocEvolutionCAComponent
	],
	imports: [
		CommonModule,
		ChartModule,
		NumberDisplayModule
	],
})
export class BlocEvolutionCAModule { }
