import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Utilisateur } from '@app/utilisateur/utilisateur.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'profilCollaborateur'})
export class ProfilCollaborateurPipe implements PipeTransform {

	constructor() {}

	transform(utilisateur: Utilisateur|null): string {
		let profils: string[] = [];

		if (utilisateur) {
			if (utilisateur.uti_vendeur) profils.push('Vendeur');
			if (utilisateur.uti_ag) profils.push('AG');
			if (utilisateur.uti_chef_ventes) profils.push('Chef de ventes');
			if (utilisateur.uti_resp_saisie) profils.push('Responsable de saisies');
			if (utilisateur.uti_manager) profils.push('Manager');
			if (utilisateur.uti_direction_reseau) profils.push('Direction');
			if (utilisateur.uti_centrale) profils.push('Centrale');
			if (utilisateur.uti_informatique) profils.push('Informatique');
			if (utilisateur.uti_formation) profils.push('Formation');
			if (utilisateur.utm_animateur_region) profils.push('Animateur');
			return profils.join(', ');
		}
		return '';
	}
}
