<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Préparation des Objectifs CA Annuel</titre-module>
	<print-button-simple [track]="true" [track_mag_id]="mag_id"></print-button-simple>
</h1>



<div class="">
	<div class="p-fluid formgrid grid">
		<div class="field col-12 md:col-6 ">
			<p-table
				[value]="[1]"
				[autoLayout]="true"
				responsiveLayout="scroll"
				class="mb-4 block"
				styleClass="p-datatable-sm p-datatable-gridlines table-aut"
			>
				<ng-template pTemplate="header">
					<tr>
						<th class="text-right nowrap">Magasin</th>
						<th colspan="3" class="text-right br-1">
							<magasin-selector
								#magasinSelector
								name="magasin"
								[(ngModel)]="mag_id"
								[nullOption]="false"
								[nullIfInvalid]="false"
								[mesMagasins]="false"
								[autoSelectFirst]="true"
								[silentInitialChangeIfSame]="true"
								(ngModelChange)="paramChanged()"
								class="hide-print"
							></magasin-selector>
							<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
						</th>
						<th class="surface-ground print-surface-0 border-none" colspan="100"></th>
					</tr>
				</ng-template>
			</p-table>
		</div>
	</div>

	<ng-container *ngIf="simulation && saisies && saisies.mois.length">
		<div class="p-fluid formgrid mb-2 grid">
			<div class="field col-12 lg:col-6 max-w-50rem print-no-max-w flex flex-column">
				<label class="block font-bold">Contexte</label>
				<textarea
					pInputTextarea
					name="contexte"
					[(ngModel)]="saisies.contexte"
					[autoResize]="true"
					[rows]="2"
					class="flex-grow-1"
					placeholder="Notez les faits marquants pour l'année à venir (recrutement, déménagement, refonte du showroom, dévéloppement…)"
					(blur)="submit()"
				></textarea>
			</div>

			<div class="field col-12 lg:col-6 max-w-50rem print-no-max-w flex flex-column">
				<label class="block font-bold">CA concurrents</label>
				<textarea
					pInputTextarea
					name="ca_concurrents"
					[(ngModel)]="saisies.ca_concurrents"
					[autoResize]="true"
					[rows]="2"
					class="flex-grow-1"
					placeholder="Notez le CA de vos principaux concurrents"
					(blur)="submit()"
				></textarea>
			</div>
		</div>

		<p-table
			[value]="[1]"
			responsiveLayout="scroll"
			class="mb-4 block"
			styleClass="p-datatable-sm p-datatable-gridlines print-table-center"
		>
			<ng-template pTemplate="header">
				<tr>
				</tr>
			</ng-template>

			<ng-template pTemplate="body">
				<tr>
					<td class="clear-cell text-center cell-th" colspan="2">
						<span class="p-1 pl-2 pr-2 surface-card">Données année {{anneeNMoinsUn}}</span>
					</td>
					<td class="clear-cell cell-spacer"></td>
					<td class="clear-cell text-center cell-th" colspan="2">
						<span class="p-1 pl-2 pr-2 surface-card">Données année {{anneeCourante}}</span>
					</td>
				</tr>

				<tr>
					<td class="text-right nowrap">CA cumulé HTPLE</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="ca_prise_commande_htple"
							[value]="simulation['N-1'].ca_prise_commande_htple"
						></indicateur-value-display>
					</td>
					<td class="clear-cell cell-spacer"></td>

					<td class="text-right nowrap">CA cumulé HTPLE</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="ca_prise_commande_htple"
							[value]="simulation['N'].ca_prise_commande_htple"
						></indicateur-value-display>
					</td>
				</tr>

				<tr>
					<td class="text-right nowrap">Entrées</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="nb_entrees"
							[value]="simulation['N-1'].nb_entrees"
						></indicateur-value-display>
					</td>
					<td class="clear-cell cell-spacer"></td>

					<td class="text-right nowrap">Entrées</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="nb_entrees"
							[value]="simulation['N'].nb_entrees"
						></indicateur-value-display>
					</td>
				</tr>

				<tr>
					<td class="text-right nowrap">Panier moyen HTPLE</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="avg_vente_htple"
							[value]="simulation['N-1'].avg_vente_htple"
						></indicateur-value-display>
					</td>
					<td class="clear-cell cell-spacer"></td>

					<td class="text-right nowrap">Panier moyen HTPLE</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="avg_vente_htple"
							[value]="simulation['N'].avg_vente_htple"
						></indicateur-value-display>
					</td>
				</tr>

				<tr>
					<td class="text-right nowrap">Ratio ventes/entrées</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="taux_nb_ventes_nb_entrees"
							[value]="simulation['N-1'].taux_nb_ventes_nb_entrees"
						></indicateur-value-display>
					</td>
					<td class="clear-cell"></td>

					<td class="text-right nowrap">Ratio ventes/entrées</td>
					<td class="text-right">
						<indicateur-value-display
							ind_code="taux_nb_ventes_nb_entrees"
							[value]="simulation['N'].taux_nb_ventes_nb_entrees"
						></indicateur-value-display>
					</td>
				</tr>

			</ng-template>

		</p-table>


		<!-- SIMULATION-->
		<p-table
			[value]="[1]"
			responsiveLayout="scroll"
			class="mb-4 block"
			styleClass="p-datatable-sm p-datatable-gridlines print-table-center"
		>
			<ng-template pTemplate="body">
					<tr>
						<td class="clear-cell text-center cell-th" colspan="100">
							<span class="p-1 pl-2 pr-2 surface-card">Objectif</span>

					</tr>
					<tr>
						<td class="">
							Objectif d'entrées pour l'année prochaine
						</td>
						<td class="text-right cell-input">
							<div class=" input-container hide-print">
								<input
									type="text"
									name="objectif_nb_entrees"
									pInputText
									gzInputNumberFormatter
									[(ngModel)]="saisies.objectif_nb_entrees"
									class="text-right"
									(blur)="submit()"
								>
							</div>
							<indicateur-value-display
								ind_code="nb_entrees"
								[value]="saisies.objectif_nb_entrees"
								class="show-print"
							></indicateur-value-display>
						</td>
					</tr>
					<tr>
						<td class="">
							Objectif de panier moyen pour l'année prochaine
						</td>
						<td class="text-right cell-input">
							<div class="p-input-icon-right input-container hide-print">
								<i class="pi ff-reset">€</i>
								<input
									type="text"
									name="objectif_avg_vente_htple"
									pInputText
									gzInputNumberFormatter
									[(ngModel)]="saisies.objectif_avg_vente_htple"
									class="text-right"
									[currency]="true"
									[decimals]="0"
									(blur)="submit()"
								>
							</div>
							<indicateur-value-display
								ind_code="avg_vente_htple"
								[value]="saisies.objectif_avg_vente_htple"
								class="show-print"
							></indicateur-value-display>
						</td>
					</tr>
					<tr>
						<td class="">
							Objectif de taux de ventes/entrées
						</td>
						<td class="text-right cell-input">
							<div class="p-input-icon-right input-container hide-print">
								<i class="pi ff-reset">%</i>
								<input
									type="text"
									name="objectif_taux_nb_ventes_nb_entrees"
									pInputText
									gzInputNumberFormatter
									[(ngModel)]="saisies.objectif_taux_nb_ventes_nb_entrees"
									[decimals]="0"
									class="text-right"
									(blur)="submit()"
								>
							</div>
							<indicateur-value-display
								ind_code="taux_nb_ventes_nb_entrees"
								[value]="saisies.objectif_taux_nb_ventes_nb_entrees"
								class="show-print"
							></indicateur-value-display>
						</td>
					</tr>
					<tr>
						<td class="">
							Effectif total magasin (ag compris)
						</td>
						<td class="text-right cell-input">
							<div class="input-container hide-print">
								<input
									type="text"
									name="effectif_magasin"
									pInputText
									gzInputNumberFormatter
									[(ngModel)]="saisies.effectif_magasin"
									class="text-right"
									(blur)="submit()"
								>
							</div>
							<indicateur-value-display
								ind_code="nb_vendeurs"
								[value]="saisies.effectif_magasin"
								class="show-print"
							></indicateur-value-display>
						</td>
					</tr>

					<tr>
						<td class="clear-cell" colspan="100"> </td>
					</tr>

					<tr>
						<td class="clear-cell text-center cell-th" colspan="100">
							<span class="p-1 pl-2 pr-2 surface-card">Simulations</span>
						</td>
					</tr>
					<tr>
						<td class="">
							<div>Simulation par l'historique</div>
							<div class="text-sm font-italic">Reprise de la moyenne des deux derniers CA annuels</div>
						</td>
						<td class="text-right">
							<indicateur-value-display
								ind_code="ca_prise_commande_htple"
								[value]="simulation.simulation_par_historique"
							></indicateur-value-display>
						</td>
					</tr>
					<tr>
						<td class="">
							<div>Simulation par les ratios</div>
							<div class="text-sm font-italic">Calcul automatique en fonction de vos objectifs d'entrées, panier moyen, marges</div>
						</td>
						<td class="text-right">
							<indicateur-value-display
								ind_code="ca_prise_commande_htple"
								[value]="simulation.simulation_par_ratios"
							></indicateur-value-display>
						</td>
					</tr>
					<tr>
						<td class="">
							<div>Simulation par les ressources humaines</div>
							<div class="text-sm font-italic">Calcul automatique en fonction de vos ressources</div>
						</td>
						<td class="text-right">
							<indicateur-value-display
								ind_code="ca_prise_commande_htple"
								[value]="simulation.simulation_par_effectif"
							></indicateur-value-display>
						</td>
					</tr>
			</ng-template>
		</p-table>

		<!-- SAISONNALITE -->
		<div class="p-fluid formgrid grid print-justify-content-center">
			<div class="field col-fixed flex-shrink-0">
				<p-table
					[value]="[1]"
					responsiveLayout="scroll"
					class="mb-4 block"
					styleClass="p-datatable-sm p-datatable-gridlines "
				>
					<ng-template pTemplate="header">
						<tr>
							<th class="clear-cell text-center cell-th" colspan="100">
								<div class="inline-block">
									<div class="flex align-items-center">
										<span class="p-1 pl-2 pr-2 surface-card">Saisonnalité</span>
										<button
											type="button"
											pButton
											icon="pi pi-refresh"
											class="p-button-xs p-button-text hide-print"
											title="Restaurer les valeurs par défaut"
											(click)="resetSaisonnalite()"
										></button>
									</div>
								</div>
							</th>
						</tr>
						<tr>
							<th class="" colspan="2">
								Objectif de CA Annuel
							</th>

							<th class="text-right cell-input col-label">
								<div class="p-input-icon-right input-container hide-print">
									<i class="pi ff-reset">€</i>
									<input
										type="text"
										name="objectif_ca_prise_commande_htple"
										pInputText
										gzInputNumberFormatter
										[(ngModel)]="saisies.objectif_ca_prise_commande_htple"
										class="text-right"
										(blur)="submit()"
									>
								</div>
								<indicateur-value-display
									ind_code="ca_prise_commande_htple"
									[value]="saisies.objectif_ca_prise_commande_htple"
									class="show-print"
								></indicateur-value-display>
							</th>
						</tr>
					</ng-template>

					<ng-template pTemplate="body">
						<tr>
							<td class="clear-cell" colspan="100"> </td>
						</tr>
						<tr>
							<td class="" colspan="100">Proposition en fonction de votre saisonnalité {{anneeNMoinsUn}}</td>
						</tr>
						<ng-container *ngFor="let moisString of rangeMoisString; index as i">
							<tr>
								<td class="cell-th">{{moisString}}</td>
								<td class="text-right">
									<number-display
										[value]="saisies.mois[i].saisonnalite"
										type="percent"
									></number-display>
								</td>
								<td class="text-right cell-input">
									<div class="p-input-icon-right input-container hide-print">
										<i class="pi ff-reset">€</i>
										<input
											type="text"
											name="{{'objectif_ca_prise_commande_htple'+[i]}}"
											pInputText
											gzInputNumberFormatter
											[(ngModel)]="saisies.mois[i].ca_prise_commande_htple"
											[currency]="true"
											[decimals]="0"
											class="text-right"
											(blur)="submit()"
										>
									</div>
									<indicateur-value-display
										ind_code="ca_prise_commande_htple"
										[value]="saisies.mois[i].ca_prise_commande_htple"
										class="show-print"
									></indicateur-value-display>
								</td>
							</tr>

						</ng-container>
					</ng-template>

					<ng-template pTemplate="footer">
						<tr>
							<td class="clear-cell"></td>
							<td class="text-right">
								<number-display
										[value]="totalSaisonnalitePercent"
										type="percent"
									></number-display>
							</td>
							<td class="text-right">
								<indicateur-value-display
									ind_code="ca_prise_commande_htple"
									[value]="totalSaisonnaliteCA"
								></indicateur-value-display>
							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>

			<div class="field col-fixed flex-shrink-0">
				<!-- EQUIPE -->
				<p-table
					[value]="[1]"
					responsiveLayout="scroll"
					class="mb-4 block"
					styleClass="p-datatable-sm p-datatable-gridlines "
				>
					<ng-template pTemplate="header">
						<tr>
							<th class="clear-cell text-center cell-th" colspan="100">
								<div class="inline-block">
									<div class="flex align-items-center">
										<span class="p-1 pl-2 pr-2 surface-card">Equipe de vente</span>
									</div>
								</div>
							</th>
						</tr>
						<tr>
							<th class="text-center">Nom</th>
							<th class="text-center nowrap">Statut</th>
							<th class="text-center col-label">CA minimum par statut</th>
							<th class="text-center nowrap">Ma simulation</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body">
						<ng-container *ngFor="let member of saisies.equipe; index as i">
							<tr>
								<td [ngClass]="{'cell-input': !member.uti_id}">
									<span *ngIf="member.uti_id">{{member.nom}}</span>
									<ng-container *ngIf="!member.uti_id">
										<div class="p-input-icon-right input-container w-full hide-print">
											<i class="pi pi-trash text-danger cursor" (click)="removeTeamMember(i)"></i>
											<input
												type="text"
												name="{{'equipe_nom_'+[i]}}"
												pInputText
												[(ngModel)]="saisies.equipe[i].nom"
												class="w-full"
												(blur)="submit()"
											>
										</div>
										<span class="show-print">{{member.nom}}</span>
									</ng-container>
								</td>
								<td class="text-center">
									<statut-vendeur-selector
										name="{{'equipe_statut_'+[i]}}"
										[options]="config.statuts_vendeurs"
										[(ngModel)]="saisies.equipe[i].statut"
										(ngModelChange)="submit()"
										class="hide-print"
									></statut-vendeur-selector>
									<span class="show-print">{{saisies.equipe[i].statut}}</span>
								</td>
								<td class="text-right">
									<indicateur-value-display
										ind_code="ca_prise_commande_htple"
										[hideZero]="true"
										[value]="getCAStatutValue(saisies.equipe[i].statut)"
									></indicateur-value-display>
								</td>
								<td class="text-right cell-input">
									<div class="p-input-icon-right input-container hide-print">
										<i class="pi ff-reset">€</i>
										<input
											type="text"
											name="{{'equipe_simulation'+[i]}}"
											pInputText
											gzInputNumberFormatter
											[(ngModel)]="saisies.equipe[i].simulation"
											class="text-right"
											(blur)="submit()"
										>
									</div>
									<indicateur-value-display
										ind_code="ca_prise_commande_htple"
										[value]="saisies.equipe[i].simulation"
										class="show-print"
									></indicateur-value-display>
								</td>
							</tr>
						</ng-container>
					</ng-template>

					<ng-template pTemplate="footer">
						<tr>
							<td class="clear-cell" colspan="2">
								<button
									type="button"
									pButton
									label="Ajouter un collaborateur"
									icon="pi pi-plus"
									class="p-button-sm p-button-text hide-print"
									(click)="addTeamMember()"
								></button>
							</td>
							<!-- <td></td> -->
							<td class="text-right">
								<indicateur-value-display
									ind_code="ca_prise_commande_htple"
									[value]="totalEquipeStatut"
								></indicateur-value-display>
							</td>
							<td class="text-right">
								<indicateur-value-display
									ind_code="ca_prise_commande_htple"
									[value]="totalEquipeSimulation"
								></indicateur-value-display>
							</td>
						</tr>
					</ng-template>
				</p-table>

			</div>
		</div>

	</ng-container>

</div>
