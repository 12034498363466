import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { AuditPrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { Prestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { clone, simpleComparison } from '@helpers/utils';


@Component({
	selector: 'audit-prestataire-dialog',
	templateUrl: './audit-prestataire-dialog.html'
})
export class AuditPrestataireDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	pristine: AuditPrestataire;
	auditPrestataire: AuditPrestataire;
	field?: 'apr_representant' | 'apr_date_fin_contrat' | 'apr_cause_fin_contrat';

	choixAnnee: number[] = [];

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private prestataireService: PrestataireService,
	) {

		this.auditPrestataire = clone(config.data.auditPrestataire);
		this.pristine = clone(this.auditPrestataire);

		if (config.data.field) {
			this.field = config.data.field;
		}

		if (config.data.choixAnnee) {
			this.choixAnnee = config.data.choixAnnee;
		}

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		if (simpleComparison(this.pristine, this.auditPrestataire)) {
			this.dialogRef.close(null);
		}
		else {
			this.loading = true;
			let apiCall: Observable<any>;

			if (!this.auditPrestataire.apr_id) {
				apiCall = this.prestataireService.postAuditPrestataire(this.auditPrestataire);
			}
			else {
				apiCall = this.prestataireService.putAuditPrestataire(this.auditPrestataire);
			}


			apiCall.subscribe({
				next: (response: any) => {
					this.dialogRef.close(this.auditPrestataire);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
	],
	exports: [AuditPrestataireDialogComponent],
	declarations: [AuditPrestataireDialogComponent]
})
export class AuditPrestataireDialogModule { }

