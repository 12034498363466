import { Utilisateur } from "@app/utilisateur/utilisateur.model";

export class VisionEntrepreneur {
	titre: string|null = null;
	detail: string|null = null;
}

export type TypeVisionEntrepreneur = {
	attr: string,
	headerColumnTitre: string,
	headerColumnDetail: string,
	titre: string
}

export const typesVisionEntrepreneur: TypeVisionEntrepreneur[] = [
	{
		attr: 'motivations',
		titre: 'Pourquoi suis-je devenu entrepreneur ?',
		headerColumnTitre: 'Mes motivations',
		headerColumnDetail: 'Détaillez vos motivations en une phrase',
	},
	{
		attr: 'objectifs',
		titre: 'Où je vais ?',
		headerColumnTitre: `Mes objectifs d'entrepreneur (à 3 ans)`,
		headerColumnDetail: 'Détaillez vos objectifs en une phrase',
	},
];

export const nbLignesVisionEntrepreneur: number = 5;

export type PointAuditConformite = {
	pac_id: number;
	pac_groupe: number;
	pac_libelle: string;
	pac_ordre: number;
	pac_est_titre: boolean;
	pac_interlocuteur: string;

	auc_reponse: boolean|null;
	auc_date: Date|null;
}

export type AuditConformite = {
	mag_id: number;
	mag_date_audit: Date|null;
	uti_id_auditeur: number|null;
	uti_auditeur: Utilisateur|null;
	points_audits_conformite: PointAuditConformite[]
}
