<p-table
	[value]="values"
	[loading]="loading"
	[autoLayout]="true"
	[columns]="columns"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	styleClass="p-datatable-sm p-datatable-gridlines table-hover"
	[sortField]="order_by"
	[sortOrder]="order"
	(onSort)="onSort($event)"
>
	<ng-template pTemplate="emptymessage">
		<tr class="text-center">
			<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th colspan="2" pFrozenColumn></th>
			<th colspan="4" class="text-center cell-warning">Tous milieux</th>
			<th colspan="4" class="text-center border-left-width-thick" *ngFor="let milieu of milieux">{{milieu.label}}</th>
		</tr>
		<tr class="">
			<th class="text-center col-mois" pFrozenColumn>Code postal</th>
			<th class="text-center" pFrozenColumn>Villes</th>

			<th
				class="text-center"
				*ngFor="let col of columns"
				[ngClass]="{'border-left-width-thick': col.ind_code == 'nb_devis'}"
				[pSortableColumn]="col.attr"
			>
				<indicateur-label-display [ind_code]="col.ind_code"></indicateur-label-display>
				<p-sortIcon [field]="col.attr" class="hide-print"></p-sortIcon>
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-row let-rowIndex="rowIndex">
		<tr>
			<td class="text-center col-mois" pFrozenColumn>{{row.ope_code_postal_client}}</td>
			<td class="text-left col-label nowrap" pFrozenColumn>{{row.ope_ville_client}}</td>
			<td
				class="text-right"
				*ngFor="let col of columns"
				[ngClass]="{'border-left-width-thick': col.ind_code == 'nb_devis'}"
			>
				<indicateur-value-display [ind_code]="col.ind_code" [value]="row[col.attr]"></indicateur-value-display>
			</td>
		<tr>
	</ng-template>

</p-table>

