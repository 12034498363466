<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module></titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			#daterangeSelector
			[disabled]="loading"
			[useStorage]="true"
			[rangeMode]="true"
			singleMode="mois"
			[usePivot]="false"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(onDateChange)="onDateChange($event)"
			class="formgrid grid p-fluid hide-print"
		></daterange-selector>
		<span class="show-print" *ngIf="daterangeSelector.sameMonth">{{dateRange[0] | date: 'MMMM yyyy'|titlecase}}</span>
		<span class="show-print" *ngIf="!daterangeSelector.sameMonth">Du {{dateRange[0] | date: 'dd/MM/yyyy'}} au {{dateRange[1] | date: 'dd/MM/yyyy'}}</span>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			#magasinSelector
			name="magasin"
			[(ngModel)]="mag_id"
			[required]="true"
			[mesMagasins]="false"
			[nullIfInvalid]="false"
			[autoSelectFirst]="true"
			[date_debut]="dateRange[0]"
			[date_fin]="dateRange[1]"
			[required]="true"
			[disabled]="loading"
			(ngModelChange)="paramChanged()"
			class="hide-print"
		></magasin-selector>
		<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text hide-print"
			[disabled]="loading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>

<div class="flex flex-column max-w-60rem">
	<div class="text-right flex-shrink-0">
		<div class="p-field-checkbox flex flex-column justify-content-end ml-auto">
			<p-triStateCheckbox
				[(ngModel)]="allToggled"
				label="Tout déplier"
				(onChange)="toggleAll($event.value)"
				checkboxFalseIcon=""
				[highLightFalse]="false"
				checkboxTrueIcon="pi pi-check"
				checkboxIconNull="pi pi-minus"
				[highLightNull]="true"
			></p-triStateCheckbox>
		</div>
	</div>

	<p-table
		[value]="values"
		[loading]="loading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
		rowGroupMode="subheader"
		groupRowsBy="annee_mois"
	>
		<ng-template pTemplate="emptymessage">
			<tr class="text-center">
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th colspan="4" class="font-bold">
					<span *ngIf="mode == 'historique'">
						Total encaissements TTC sur la période sélectionée
					</span>
					<span *ngIf="mode == 'projection'">
						Total encaissements à percevoir TTC sur la période sélectionée
					</span>
				</th>
				<th class="text-right col-label">
					<number-display type="currency" [value]="totalPeriode"></number-display>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="groupheader" let-mois>
			<tr class="row-darker" pRowGroupHeader>
				<td class="nowrap">
					<span class="font-bold">{{mois.date | date: 'MMMM yyyy'|titlecase}}</span>
				</td>
				<td colspan="3" class="cell-th">Total des encaissements TTC</td>
				<td class="text-right col-label">
					<number-display type="currency" [value]="mois['total_encaissements']"></number-display>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-mois let-rowIndex="rowIndex">

				<!-- <tr>
					<td [attr.rowspan]="mois.rowSpan" class="nowrap">
						<span class="font-bold">{{mois.date | date: 'MMMM yyyy'|titlecase}}</span>
					</td>
					<td colspan="3" class="cell-th cell-darker">Total des encaissements TTC</td>
					<td colspan="1" class="text-right cell-darker nowrap">
						<number-display type="currency" [value]="mois['total_encaissements']"></number-display>
					</td>
				</tr> -->


				<ng-container *ngFor="let typeEncaissement of typesEncaissement">

					<tr *ngIf="mode === 'projection'">
						<td class="cell-white"></td>
						<td colspan="3" class="">Total des acomptes à percevoir</td>
						<td class="text-right" *ngIf="mois.projection.readonly">
							<indicateur-value-display
								ind_code="avg_vente_htple"
								[value]="mois.projection.pje_montant"
							></indicateur-value-display>
						</td>

						<td colspan="1" class="text-right cell-input" *ngIf="!mois.projection.readonly">
							<div class="p-input-icon-right input-container hide-print">
								<i class="pi ff-reset">€</i>
								<input
									type="text"
									name="pje_montant"
									pInputText
									gzInputNumberFormatter
									[(ngModel)]="mois.projection.pje_montant"
									class="text-right"
									[currency]="true"
									[decimals]="0"
									(blur)="putProjection(mois.projection)"
								>
							</div>
							<indicateur-value-display
								ind_code="avg_vente_htple"
								[value]="mois.projection.pje_montant"
								class="show-print"
							></indicateur-value-display>
						</td>
					</tr>
					<tr class="row-separator">
						<td class="cell-white"></td>
						<td colspan="3" class="">
							<div class="flex align-items-center">
								<span class="">Total des {{typeEncaissement.label_header}}</span>
								<button
									type="button"
									pButton
									class="p-button-text p-button-xs"
									[icon]="mois['expanded'][typeEncaissement.code] ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
									(click)="toggleRow(mois, typeEncaissement.code)"
								></button>
							</div>
						</td>
						<td class="text-right col-label">
							<number-display type="currency" [value]="mois[typeEncaissement.attr_total]"></number-display>
						</td>
					</tr>


					<ng-container *ngIf="mois[typeEncaissement.code] && mois['expanded'][typeEncaissement.code]">
						<tr class="font-italic text-sm">
							<td class=""></td>
							<td class="text-center">Client</td>
							<td class="text-center nowrap">N° Option WINNER</td>
							<td class="text-center nowrap">Date d'encaissement</td>
							<td class="text-center col-label">Montant</td>
						</tr>

						<tr *ngFor="let item of mois[typeEncaissement.code]">
							<td></td>
							<td class="text-left">
								{{item.ebi_client}}
							</td>
							<td class="text-left">
								{{item.ebi_option}}
							</td>
							<td class="text-center nowrap">
								{{item.ebi_date |date: 'dd/MM/yyyy'}}
							</td>
							<td class="text-right col-label">
								<number-display type="currency" [value]="item.ebi_montant"></number-display>
							</td>
						</tr>
					</ng-container>

				</ng-container>



		</ng-template>
	</p-table>

</div>
