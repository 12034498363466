<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<label>Commande</label>
		<p-dropdown
			name="command"
			[options]="commands"
			[(ngModel)]="selectedCommand"
			placeholder="Commande"
			[disabled]="loading"
			[required]="true"
			appendTo="body"
			(ngModelChange)="selectedCommandChange($event)"
		></p-dropdown>
	</div>

	<div class="field col-fixed flex align-items-end">
		<button
			type="button"
			icon="pi pi-send"
			pButton
			label="Exécuter"
			[disabled]="!selectedCommand"
			[loading]="loading"
			(click)="executeCommand()"
		></button>
	</div>
	<div class="field col-fixed flex align-items-end">
		<button
			type="button"
			icon="pi pi-history"
			pButton
			label="Get logs"
			[disabled]="!selectedCommand"
			[loading]="loading || loadingLogs"
			(click)="refreshLog(false)"
		></button>
	</div>
</div>

<div class="border-1 border-primary p-2 surface-card overflow-auto">
	<pre *ngIf="!!!logs">Aucun log disponible</pre>
	<pre *ngIf="logs">{{logs}}</pre>
</div>

