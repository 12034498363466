import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	Validators,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { ConfigService } from '@global/config.service';


@Component({
	selector: 'statut-option-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StatutOptionSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[required]="required"
			[showClear]="!required"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class StatutOptionSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() required: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() silentInitialChangeIfSame: boolean;

	options: any[] = [];


	constructor(private configService: ConfigService) {

	}

	ngOnInit() {
		this.options = [
			'R1',
			'R2',
			'R3',
			'R+',
		];
		setTimeout(() => {this.setInitialValue();}, 0);
	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string|null|undefined) {
		this.innerValue = value;
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string|null|undefined) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (this.options.findIndex(one => {return one == this.innerValue; }) < 0) {
			if (this.nullIfInvalid) {
				this.innerValue = null;
			}
		}
		// this.value = this.innerValue;
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [StatutOptionSelectorComponent],
	declarations: [StatutOptionSelectorComponent]
})
export class StatutOptionSelectorModule { }
