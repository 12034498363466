import { Component, NgModule, OnInit } from '@angular/core';

import { UIRouterModule } from '@uirouter/angular';
import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { AuthService } from '@app/auth/auth.service';
import { GzMenubarModule } from '@app/gz-menubar';
import { menuItems } from '@global/menu-items';
import { MenuService } from '@global/menu.service';

import { environment, enseigne } from '@environments/environment';

import { EventManagerService } from '@global/event-manager.service';

@Component({
	selector: 'root',
	templateUrl: './root.html',
	providers: []
})
export class RootComponent implements OnInit {

	menuItems: MenuItem[] = [];
	userMenuItems: MenuItem[] = [];
	urlFOC: string = environment[enseigne].foc;
	// @ts-ignore
	version: any = window.APP_VERSION;

	constructor(
		private authService: AuthService,
		private eventManager: EventManagerService,
		private menuService: MenuService,
		private stateService: StateService
	) {

	}

	ngOnInit() {
		this.menuItems = this.menuService.prepareItems(menuItems);

		let userName = '';
		if (this.authService.user) {
			userName = this.authService.user.uti_prenom + ' ' + this.authService.user.uti_nom + '\n' + this.authService.user.uti_email;

		}
		this.userMenuItems = [
			{ label: userName, disabled: true },
		];

		if (this.authService.checkIfHasRight('informatique')) {
			this.userMenuItems = this.userMenuItems.concat([
				{ separator: true },
				{
					label: 'Administration',
					icon: 'pi pi-cog',
					command: (event) => {
						this.stateService.go('admin.commands');
					}
				}
			]);
		}

		this.userMenuItems = this.userMenuItems.concat([
			{ separator: true },
			{
				label: 'Franchise On Cloud',
				icon: 'pi pi-external-link',
				url: this.urlFOC,
				target: '_blank'
			},
			{
				label: 'Se déconnecter',
				icon: 'pi pi-sign-out',
				command: (event) => {this.logout()}
			}
		]);
	}

	logout() {
		this.eventManager.emit('logout', {manual: true});
	}

}

@NgModule({
	declarations: [
		RootComponent
	],
	exports: [
		RootComponent
	],
	imports: [
		UIRouterModule,
		ButtonModule,
		MenuModule,
		GzMenubarModule,
	],
})
export class RootModule { }
