import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TableModule } from '@app/primeng-overrides/table';
import { SelectButtonModule } from 'primeng/selectbutton';

import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { FournisseurExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { RepartitionAchatsMeubles } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { MagasinService } from '@app/magasin/magasin.service';

import {
	endOfMonth,
	isEmpty,
	percentTauxAndEvo,
	simpleDateToString
} from '@helpers/utils';

type Affichage = 'indicateur' | 'rdv_internet' | 'ratio'

@Component({
	selector: 'repartition-achats-meubles',
	templateUrl: './repartition-achats-meubles.html',

})
export class RepartitionAchatsMeublesComponent {

	loading: boolean = false;
	exportLoading: boolean = false;

	totalRecords: number;

	mode: string;
	dateRange: Date[] = [];
	regroupement: 'region'|'magasin' = 'magasin';

	values: any[];
	date_donnees_repartition_achats: Date;

	mag_id: number|null = null;

	expandedRowKeys: {[key: string]: any} = {};

	optionsRegroupement = [
		{label: 'Région', value: 'region'},
		{label: 'Magasin', value: 'magasin'},
	];

	columnsIndicateur = [
		{
			ind_code: 'mt_achats_meubles_factures_ht',
			ind_code_label: 'mt_achats_meubles_factures_ht',
		},
		{
			ind_code: 'taux_achats_meubles_factures',
			ind_code_label: 'taux_achats_meubles_factures',
		},
	];

	columnTotalFournisseursMobiliers = {
		key: 'mt_achats_meubles_factures_ht_fournisseurs_mobilier',
		ind_code: 'mt_achats_meubles_factures_ht_fournisseurs_mobilier',
		ind_code_label: 'mt_achats_meubles_factures_ht_fournisseurs_mobilier',
	}

	fournisseursLeft: any[] = [
		{ key: 'NOBILIA' },
		{ key: 'BURGER' },
		{ key: 'SCHULLER' },
	];

	fournisseursRight: any[] = [
		{ key: 'NOBI_MTK' },
		{ key: 'NOBIDIREKT' },
	];

	columns: any[] = [];


	constructor(
		private achatsFournisseursService: AchatsFournisseursService,
		private magasinService: MagasinService,
		private storageService: StorageService,
	) {
		this.prepareColumns()
	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.regroupement = this.storageService.getForCurrentState('regroupement', 'magasin');
		this.expandedRowKeys = this.storageService.getForCurrentState('expandedRowKeys', this.expandedRowKeys);
		this.mag_id = this.storageService.getForCurrentState('mag_id', this.mag_id);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('regroupement', this.regroupement);
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	onRowExpand(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onRowCollapse(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		let previousMode = this.mode;
		this.mode = event.mode;
		this.load();
	}

	onRegroupementChange() {
		if (this.mag_id != null && this.regroupement == 'region') {
			this.mag_id = null;
		}
		this.load();
	}

	onMagasinChange() {
		if (this.mag_id != null && this.regroupement == 'region') {
			this.regroupement = 'magasin'
		}
		this.load();
	}

	load(event?: any) {

		let tmpParams = {
			mode: this.mode,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true),
			mag_id: this.mag_id
		};

		this.loading = true;
		this.achatsFournisseursService.getRepartitionAchatsMeubles(tmpParams)
		.subscribe({
			next: (response: any) => {
				this.date_donnees_repartition_achats = response.date_donnees_repartition_achats;
				// this.values = response.repartition;
				this.values = this.prepareData(response);
				this.saveParamSet();
			},
		})
		.add(() => {
			this.loading = false;
		})
	}

	prepareColumns() {
		this.columns = [];
		// left
		this.fournisseursLeft.forEach((colFournisseur: any) => {
			this.columnsIndicateur.forEach((colIndicateur: any) => {
				this.columns.push({
					key: colIndicateur.ind_code + '_' + colFournisseur.key.toLowerCase(),
					ind_code: colIndicateur.ind_code,
					ind_code_label: colIndicateur.ind_code_label
				});
			})
		});
		// total
		this.columns.push(this.columnTotalFournisseursMobiliers);
		// right
		this.fournisseursRight.forEach((colFournisseur: any) => {
			this.columnsIndicateur.forEach((colIndicateur: any) => {
				this.columns.push({
					key: colIndicateur.ind_code + '_' + colFournisseur.key.toLowerCase(),
					ind_code: colIndicateur.ind_code,
					ind_code_label: colIndicateur.ind_code_label
				});
			})
		});
	}


	prepareData(data: any) {
		let tmp: any[] = [];

		if (!data.repartition.length) {
			return [];
		}

		data.repartition.forEach((item: any) => {
			percentTauxAndEvo(item);
			if (this.regroupement == 'region') {
				item.label = item.region;
				item.magasins.forEach((mag: any) => {
					mag.label = this.magasinService.getMagasinLabel(mag);
					percentTauxAndEvo(mag);
				})
			}
			else {
				item.label = this.magasinService.getMagasinLabel(item);
			}
		});

		if (data.repartition.length > 1) {
			let total: RepartitionAchatsMeubles = new RepartitionAchatsMeubles();
			total = data.total;
			total.label = 'Total';
			total.isTotal = true;
			percentTauxAndEvo(data.total);
			tmp.push(data.total);
		}

		tmp.push(...data.repartition);

		return tmp;
	}

	export() {
		let tmpParams = {
			mode: this.mode,
			regroupement: this.regroupement,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true),
			mag_id: this.mag_id
		};

		this.exportLoading = true;
		this.achatsFournisseursService.exportRepartitionAchatsMeubles(tmpParams)
		.subscribe()
		.add(() => {
			this.exportLoading = false;
		});
	}

}
@NgModule({
	declarations: [
		RepartitionAchatsMeublesComponent
	],
	exports: [
		RepartitionAchatsMeublesComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		TitreModuleModule,
	],
})
export class RepartitionAchatsMeublesModule { }
