import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { ObjectifsService, ObjectifAction } from '@app/objectifs/objectifs.service';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TablePlanActionsModule } from '@app/plan-actions/table-plan-actions';
import { TitreModuleModule } from '@app/titre-module';
import { UtilisateurSelectorModule, UtilisateurSelectorComponent } from '@app/utilisateur/utilisateur-selector';

import { clone, dividePercentTauxAndEvo, percentTauxAndEvo } from '@helpers/utils';

@Component({
	selector: 'plan-actions',
	templateUrl: './plan-actions.html',

})
export class PlanActionsComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;
	@ViewChild('vendeurSelector') vendeurSelector: UtilisateurSelectorComponent;
	@ViewChild('objectifForm') objectifForm: NgForm;

	loading: boolean = false;
	loaded: boolean = false;

	debouncedSubmit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	objectifs: ObjectifAction;
	mag_id: number|null = null;
	uti_id: number|null = null;

	today: Date = new Date();

	indicateurs: any[] = [
		{
			ind_code: 'obv_ca_prise_commande_htple',
			commentAttr: 'obv_commentaire_ca_prise_commande_htple',
			type: 'currency',
			label: 'CA prise commande annuel',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_nb_devis_nb_entrees',
			commentAttr: 'obv_commentaire_taux_nb_devis_nb_entrees',
			type: 'percent',
			label: 'Ratio Devis/Entrées',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_nb_ventes_nb_devis',
			commentAttr: 'obv_commentaire_taux_nb_ventes_nb_devis',
			type: 'percent',
			label: 'Ratio Ventes/Devis',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_marge_commerciale',
			commentAttr: 'obv_commentaire_taux_marge_commerciale',
			type: 'percent',
			label: 'Marge en % sur CA prise de commande',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'mt_marge_facture',
			commentAttr: null,
			type: 'currency',
			label: 'Marge brute (€) sur CA prise de commande',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_marge_facture',
			commentAttr: null,
			type: 'percent',
			label: 'Marge en % sur CA Facturé',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_parrainage',
			commentAttr: 'obv_commentaire_taux_parrainage',
			type: 'percent',
			label: 'Taux de parrainage',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_recours_credit',
			commentAttr: 'obv_commentaire_taux_recours_credit',
			type: 'percent',
			label: 'Taux de recours au crédit (TRC)',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_note_google',
			commentAttr: 'obv_commentaire_note_google',
			type: 'short_decimal',
			label: 'Note Google',
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_nb_avis_google',
			commentAttr: 'obv_commentaire_nb_avis_google',
			type: 'quantity',
			label: `Nombre d'avis Google`,
			modes: ['magasin', 'vendeur']
		},
		{
			ind_code: 'obv_taux_turn_over',
			commentAttr: 'obv_commentaire_taux_turn_over',
			type: 'percent',
			label: 'Taux de turn over',
			modes: ['magasin']
		},
	];

	constructor(
		private objectifsService: ObjectifsService,
		private storageService: StorageService,
	) {
		this.debouncedSubmit
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				if (!this.loaded) {
					this.loaded = true;
				}
				else {
					this.submit();
				}
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.mag_id = this.storageService.getForCurrentState('mag_id');
		this.uti_id = this.storageService.getForCurrentState('uti_id');
	}

	saveParamSet() {
		this.storageService.setForCurrentState('mag_id', this.mag_id);
		this.storageService.setForCurrentState('uti_id', this.uti_id);
	}

	paramChanged() {
		this.load();
	}

	saisieChanged() {
		this.debouncedSubmit.next(true);
	}

	load() {
		if (this.mag_id) {
			this.loading = true;
			this.loaded = false;
			this.saveParamSet();
			this.objectifsService.getObjectifAction(this.mag_id, this.uti_id)
			.subscribe({
				next: (response: any) => {
					this.updateDisplay(response)
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	submit() {
		if (this.objectifForm.form.valid && this.objectifs && this.objectifs.obv_id) {
	 		this.loading = true;
			let tmp = clone(this.objectifs);

			dividePercentTauxAndEvo(tmp, 'obv_');

			this.objectifsService.putObjectifAction(tmp)
			.subscribe({
				next: (response: any) => {
					// this.updateDisplay(response);
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	updateDisplay(values: any) {
		if (values) {
			let tmp = values;
			percentTauxAndEvo(tmp, 'obv_');
			this.objectifs = tmp;
		}
	}

	getIndicateurRealCode(ind_code: string) {
		return `obv_${ind_code}`;
	}

	getCommentaireIndicateurRealCode(ind_code: string) {
		return `obv_commentaire_${ind_code}`;
	}

}
@NgModule({
	declarations: [
		PlanActionsComponent
	],
	exports: [
		PlanActionsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		InputNumberModule,
		TableModule,
		GlobalModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TablePlanActionsModule,
		TitreModuleModule,
		UtilisateurSelectorModule,
	],
})
export class PlanActionsModule { }
