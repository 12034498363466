import { NgModule, Component, Input, Output, forwardRef, OnInit, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	NgForm,
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';


import { DropdownModule, Dropdown } from '@app/primeng-overrides/dropdown';

import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { FournisseurExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';


@Component({
	selector: 'fournisseur-exposition-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FournisseurExpositionSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			[filter]="options.length > 5"
			filterBy="foe_code,foe_nom"
			[filterPlaceholder]="filterPlaceholder"
			optionValue="foe_code"
			optionLabel="foe_code"
			appendTo="body"
		>

		</p-dropdown>
	`
})
export class FournisseurExpositionSelectorComponent implements OnInit, ControlValueAccessor {

	@Input('value') innerValue: string = 'non_acquis';
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;

	@Output() onSelectionChange: EventEmitter<FournisseurExposition> = new EventEmitter<FournisseurExposition>();


	filters: {[key: string]: any} = {};
	options: FournisseurExposition[] = [];

	_selected: FournisseurExposition|undefined;


	constructor(private achatsFournisseursService: AchatsFournisseursService) {
		this.achatsFournisseursService.getFournisseursExpositions()
		.subscribe({
			next: (response: FournisseurExposition[]) => {
				this.options = response;
			}
		})
	}

	ngOnInit() {

	}

	onChange: any = () => { };
	onTouched: any = () => { };

	get value() {
		return this.innerValue;
	}

	set value(value: string) {
		this.innerValue = value;
		this._selected = this.options.find((one: FournisseurExposition) => {return one.foe_code  == value});
		this.onSelectionChange.emit(this._selected);
		this.onChange(value);
		this.onTouched();
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouched = fn;
	}

	writeValue(value: string) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [FournisseurExpositionSelectorComponent],
	declarations: [FournisseurExpositionSelectorComponent]
})
export class FournisseurExpositionSelectorModule { }
