import { Component, NgModule, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { BlockableUI } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';

import { RatingModule } from 'primeng/rating';
import { SliderModule } from 'primeng/slider';

import { TableModule, Table } from '@app/primeng-overrides/table';

import { DeclarationService } from '@app/declaration/declaration.service';
import { DeclarationMensuelle } from '@app/declaration/declaration.model';
import { EventManagerService } from '@global/event-manager.service';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'declaration-mensuelle-form',
	templateUrl: './declaration-mensuelle-form.html',
})
export class DeclarationMensuelleFormComponent implements BlockableUI {

	@Input() declaration: any;

	@Output() onDeclarationPosted: EventEmitter<any> = new EventEmitter();


	loading: boolean;

	attributsEffectif = [
		{label: 'Effectif', attr: 'def_dans_effectif'},
		{label: 'Vendeur', attr: 'def_vendeur'},
		{label: 'AG', attr: 'def_ag'},
		{label: 'Chef de ventes', attr: 'def_chef_ventes'},
		{label: 'Franchisé', attr: 'def_manager'},
	];

	constructor(
		private confirmationService: ConfirmationService,
		private declarationService: DeclarationService,
		private elementRef: ElementRef,
		private eventManager: EventManagerService,
	) {

	}

	ngOnInit() {

	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

	submit() {
		if (this.checkDeclaration()) {
			this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: 'Une fois la déclaration soumise, vous ne pourrez plus la modifier. Souhaitez-vous vraiment soumettre cette déclaration ?',
				rejectButtonStyleClass: 'p-button-text',
				accept: () => {
					this.post();
				},
				reject: () => {

				}
			});
		}
	}

	post() {
		this.loading = true;
		this.declarationService.postDeclarationMensuelle(this.declaration.mag_id, this.declaration)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Déclaration créée'});
				this.onDeclarationPosted.emit(response);
			}
		})
		.add(() => { this.loading = false; });
	}

	checkDeclaration(): boolean {

		let collaborateurInclusDansEffectif = 0;

		for (let i = 0; i < this.declaration.effectifs.length; i++) {

			let collaborateur = this.declaration.effectifs[i];

			if (collaborateur.def_dans_effectif) {

				if (
					!collaborateur.def_vendeur
					&& !collaborateur.def_ag
					&& !collaborateur.def_chef_ventes
					&& !collaborateur.def_manager
					&& !collaborateur.def_vendeur
				) {
					this.eventManager.emit('toast', {severity: 'info', summary: 'Veuillez sélectionner au moins un rôle pour chacun des collaborateurs dans l\'effectif'});
					return false;
				}

				if (collaborateur.def_presence === null) {
					this.eventManager.emit('toast', {severity: 'info', summary: 'Veuillez d\'abord confirmer la présence de tous les collaborateurs dans l\'effectif'});
					return false;
				}

				collaborateurInclusDansEffectif++;
			}
		};

		if (collaborateurInclusDansEffectif === 0) {
			this.eventManager.emit('toast', {severity: 'info', summary: 'Au moins un collaborateur doit être contenu dans l\'effectif'});
			return false;
		}

		return true;
	}


}
@NgModule({
	declarations: [
		DeclarationMensuelleFormComponent
	],
	exports: [
		DeclarationMensuelleFormComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		RatingModule,
		SliderModule,
		TableModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		NumberDisplayModule,
	],
})
export class DeclarationMensuelleFormModule { }
