import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { ProfilCompetence } from '@app/moa/referentiel-competences/referentiel-competences.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'profilCompetence'})
export class ProfilCompetencePipe implements PipeTransform {

	constructor(private referentielCompetencesService: ReferentielCompetencesService) {}

	transform(code: string): string {
		const found: ProfilCompetence|undefined = this.referentielCompetencesService.profilsCompetences.find((one: ProfilCompetence) => { return one.pco_code == code; });
		if (found) {
			return found.pco_libelle;
		}
		return code;
	}
}
