import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { EvenementService } from '@app/evenement/evenement.service';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { StorageService } from '@global/storage.service';
import {
	endOfDay,
	simpleDateToString,
	startOfDay,
} from '@helpers/utils';

@Component({
	selector: 'detail-ventes',
	templateUrl: './detail-ventes.html',
	providers: []
})
export class DetailVentesComponent implements IEventListener {

	@ViewChild('tableDetailVentes', {static: true}) table: Table;

	values: any[] = [];
	totalRecords: number = 0;
	loading: boolean;
	rows: number = 10;
	exportLoading: boolean;

	dateRange: Date[] = [];
	mag_id: number|null;
	uti_id: number|null;

	params: {[key: string]: any} = {
		search: null,
	}

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
		private storageService: StorageService,
	) {

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.refresh();
			}
		});

		this.dateRange = (this.config.data.dateRange)? this.config.data.dateRange : [];
		this.mag_id = (this.config.data.mag_id)? this.config.data.mag_id : null;
		this.uti_id = (this.config.data.uti_id)? this.config.data.uti_id : null;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('logout', this, (args: any) => {
			this.dialogRef.close();
		});
		// this.getParamSet();
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('logout', this);
	}


	getParamSet() {
		let tableParams = this.storageService.getForCurrentState('details-tableParams', undefined, true);
		if (tableParams) {
			for (let prop in this.params) {
				if (tableParams[prop]) this.params[prop] = tableParams[prop];
			}
			if (tableParams.first) this.table.first = tableParams.first;
			if (tableParams.rows) this.rows = tableParams.rows;
		}
		this.mag_id = this.storageService.getForCurrentState('details-mag_id');
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	saveParamSet() {
		if (this.table) {
			let tableParams = Object.assign({}, this.params, {
				first: this.table.first,
				rows: this.table.rows
			});
			this.storageService.setForCurrentState('details-tableParams', tableParams, true);
		}
		this.storageService.setForCurrentState('details-mag_id', this.mag_id);
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	reset() {
		this.params.search = null;
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load($event: any) {
		// il faut une date de fin
		if (this.dateRange && this.dateRange[1]) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			// this.saveParamSet();

			let tmpParams = Object.assign({
				mag_id: this.mag_id,
				uti_vendeur: this.uti_id,
				date_debut: simpleDateToString(startOfDay(this.dateRange[0])),
				date_fin: simpleDateToString(endOfDay(this.dateRange[1])),
			}, $event, this.params);

			this.evenementService.getDetailVentes(tmpParams)
			.subscribe({
				next: (response: any) => {
					this.totalRecords = response.total;
					this.values = response.evenements;
				}
			})
			.add(() => { this.loading = false; });
		}
	}

}
@NgModule({
	declarations: [
		DetailVentesComponent
	],
	exports: [
		DetailVentesComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		TableModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		UtilisateurSelectorModule,
	],
})
export class DetailVentesModule { }
