export class TrackedObject {
	aud_date_creation: Date|string;
	aud_email_creation: string;
	aud_date_modification: Date|string;
	aud_email_modification: string;
}

export class DeletableTrackedObject extends TrackedObject {
	aud_date_suppression: Date|string|null;
	aud_email_suppression: string|null;
}

