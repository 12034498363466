import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { GlobalModule } from '@global/global.module';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { EvaluationCollaborateur, ProfilCompetence } from '@app/moa/referentiel-competences/referentiel-competences.model';
import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { ProfilCompetenceSelectorModule } from '@app/moa/referentiel-competences/profil-competence-selector';
import { UtilisateurService } from '@app/utilisateur/utilisateur.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';
import { extractFieldValues } from '@app/_helpers/utils';

@Component({
	selector: 'evaluation-collaborateur-dialog',
	templateUrl: './evaluation-collaborateur-dialog.html'
})
export class EvaluationCollaborateurDialogComponent implements OnInit, IEventListener {

	loading: boolean;

	mag_id: number;
	collaborateur: Utilisateur;
	lockCollaborateur: boolean = false;
	evaluation: EvaluationCollaborateur;
	profilsEvaluables: string[] = [];
	profilsSelectionnables: string[] = [];

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private referentielCompetencesService: ReferentielCompetencesService,
		private utilisateurService: UtilisateurService
	) {


		this.mag_id = config.data.mag_id
		if (config.data.collaborateur) {
			this.collaborateur = config.data.collaborateur;
			this.profilsSelectionnables = this.utilisateurService.extractProfilsCollaborateur(this.collaborateur);
			this.lockCollaborateur = true;
			this.evaluation = new EvaluationCollaborateur(config.data.collaborateur.uti_id);
		}
		else {
			this.evaluation = new EvaluationCollaborateur();
		}

		this.referentielCompetencesService.getProfilsCompetences()
		.subscribe({
			next: (response: any) => {
				this.profilsEvaluables = extractFieldValues(response, 'pco_code');
			}
		})
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	resetForm(collaborateur: Utilisateur) {
		this.collaborateur = collaborateur;
		if (this.collaborateur) {
			this.profilsSelectionnables = this.utilisateurService.extractProfilsCollaborateur(this.collaborateur);
		}
	}

	submit() {
		this.loading = true;
		this.referentielCompetencesService.postEvaluationCollaborateur(this.mag_id, this.evaluation)
			.subscribe({
			next: (response: any) => {
				this.dialogRef.close(response);
			}
		})
		.add(() => { this.loading = false; });
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		GlobalModule,
		ProfilCompetenceSelectorModule,
		UtilisateurSelectorModule
	],
	exports: [EvaluationCollaborateurDialogComponent],
	declarations: [EvaluationCollaborateurDialogComponent]
})
export class EvaluationCollaborateurDialogModule { }

