import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from '@app/primeng-overrides/table';
import { TriStateCheckboxModule } from '@app/primeng-overrides/tristatecheckbox';

import { ConfigService } from '@global/config.service';
import { DaterangeSelectorModule } from '@app/daterange-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { ReportingService, Encaissement, MoisEncaissement, ProjectionEncaissement } from '@app/reporting/reporting.service';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { Top10Module } from '@app/reporting/top10';
import { TopParMilieuModule } from '@app/reporting/top-par-milieu';

import {
	endOfMonth,
	simpleDateToString,
	startOfDay,
	startOfMonth,
	stringSort,
} from '@helpers/utils';

@Component({
	selector: 'encaissements',
	templateUrl: './encaissements.html',

})
export class EncaissementsComponent {

	@Input() mode: 'historique'|'projection';

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;
	exportLoading: boolean = false;
	loaded: boolean = false;
	values: any[];

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	dateRange: Date[] = [];
	mag_id: number;

	minDate: Date;
	maxDate: Date;

	currentMonth: Date = startOfMonth(new Date());

	typesEncaissement: any[] = [];
	typesHistorique: any[] = [
		{ code: 'A', attr_total: 'total_acomptes', label_header: 'acomptes perçus' },
		{ code: 'S', attr_total: 'total_soldes', label_header: 'soldes perçus' },
	];
	typesProjection: any[] = [
		{ code: 'P', attr_total: 'total_projections', label_header: 'soldes à percevoir' },
	];

	title: string = 'Historique des encaissements';

	totalPeriode: number = 0;

	allToggled: boolean|null = false;


	constructor(
		private configService: ConfigService,
		private reportingService: ReportingService,
		private storageService: StorageService,
	) {
		this.title = this.configService.getCurrentModuleTitle();

		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				if (!this.loaded) {
					this.loaded = true;
				}
				else {
					this.load();
				}
			}
		});
	}

	ngOnInit() {
		this.getParamSet();
		if (this.mode == 'historique') {
			this.typesEncaissement = this.typesHistorique;
			this.title = 'Historique des encaissements';
			this.minDate = new Date(this.configService.config.annee_reference, 0, 1);
			this.maxDate = endOfMonth(new Date());
		}
		else {
			this.typesEncaissement = this.typesProjection;
			this.title = 'Projection des encaissements';
			// this.minDate = startOfMonth(new Date());
			this.minDate = new Date(this.configService.config.annee_reference, 0, 1);
			let tmpMaxDate: Date = new Date((new Date()).getFullYear() +2, 11, 30);
			this.maxDate = tmpMaxDate;
		}
	}

	getParamSet() {
		this.mag_id = this.storageService.getForCurrentState('mag_id');
		this.allToggled = this.storageService.getForCurrentState('allToggled', false);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('mag_id', this.mag_id);
		this.storageService.setForCurrentState('allToggled', this.allToggled);
	}

	onDateChange(event: any) {
		this.dateRange = event.dateRange;
		this.paramChanged();
	}

	paramChanged() {
		this.load();
	}

	load() {
		if (this.mag_id) {
			this.saveParamSet();
			let tmpParams = {
				mag_id: this.mag_id,
				date_debut: simpleDateToString(this.dateRange[0], true),
				date_fin: simpleDateToString(this.dateRange[1], true)
			};

			let apiCall: Observable<any>;
			if (this.mode == 'historique') {
				apiCall = this.reportingService.getHistoriqueEncaissements(tmpParams, this.mag_id)
			}
			else {
				apiCall = this.reportingService.getProjectionEncaissements(tmpParams, this.mag_id)
			}

			this.loading = true;
			apiCall.subscribe({
				next:	(response: any) => {
					this.prepareValues(response);
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	prepareValues(items: MoisEncaissement[]) {

		let tmpArray: any[] = [];
		items.forEach((item: any) => {
			let tmp: any = Object.assign({}, item);
			const paddedMonth: string = tmp.mois.toString().padStart(2, '0');
			tmp.annee_mois = `${tmp.annee}-${paddedMonth}`;
			let tmpDate = new Date(tmp.annee, tmp.mois -1, 1);
			startOfDay(tmpDate);
			tmp.date = tmpDate;

			tmp.expanded = {
				'A': false,
				'S': false,
				'P': false,
			}

			tmp.projection = new ProjectionEncaissement(this.mag_id, tmp.annee, tmp.mois, tmp.pje_montant);
			tmp.projection.readonly = tmp.date < this.currentMonth;
			tmp.rowSpan = this.calculateRowSpan(tmp);
			tmpArray.push(tmp);
		});

		stringSort(tmpArray, 'annee_mois');
		this.values = [...tmpArray];
		this.calculateTotalPeriode();
		this.toggleAll()
	}

	calculateTotalPeriode() {
		this.totalPeriode = 0;
		this.values.forEach((item: any) => {
			item.total_encaissements = 0;
			item.total_encaissements += item.total_acomptes;
			item.total_encaissements += item.total_soldes;
			item.total_encaissements += item.total_projections;
			if (item.projection.pje_montant) {
				item.total_encaissements += item.projection.pje_montant;
			}

			this.totalPeriode += item.total_encaissements;
		});
	}

	calculateRowSpan(mois: any) {
		let rowSpan: number = 1
		this.typesEncaissement.forEach((one: any) => {
			if (typeof mois[one.code] != 'undefined') {
				// toggler row
				rowSpan += 1;
				// saisie projection
				if (this.mode == 'projection') rowSpan += 1;
				if (mois['expanded'][one.code]) {
					// total type encaissement
					rowSpan += 1;
					// encaissements
					rowSpan += mois[one.code].length;
				}
			}
		});
		return rowSpan;
	}

	toggleRow(row: any, typeEncaissement: string) {
		row['expanded'][typeEncaissement] = !!!row['expanded'][typeEncaissement];
		row['rowSpan'] = this.calculateRowSpan(row);

		let someToggled: boolean = this.values.some((item: any) => {
			return this.typesEncaissement.some((one: any) => {
				return item['expanded'][one.code] == true;
			});
		});
		this.allToggled = someToggled? null : false;
	}

	toggleAll(value: boolean|null = this.allToggled) {
		if (value === null) {
			value = true;
			this.allToggled = true;
		}
		this.saveParamSet();
		this.values.forEach((row: any) => {
			this.typesEncaissement.forEach((one: any) => {
				if (value === true || value === false) {
					row['expanded'][one.code] = value;
				}
			});
			row['rowSpan'] = this.calculateRowSpan(row);
		})
	}

	putProjection(projection: ProjectionEncaissement) {
		this.reportingService.postProjectionEncaissements(projection)
		.subscribe()
		.add(() => { this.calculateTotalPeriode(); });
	}

	export() {
		let tmpParams = {
			mag_id: this.mag_id,
			date_debut: simpleDateToString(this.dateRange[0], true),
			date_fin: simpleDateToString(this.dateRange[1], true)
		};
		let apiCall: Observable<any>;
			if (this.mode == 'historique') {
				apiCall = this.reportingService.exportHistoriqueEncaissements(tmpParams, this.mag_id)
			}
			else {
				apiCall = this.reportingService.exportProjectionEncaissements(tmpParams, this.mag_id)
			}

			this.exportLoading = true;
			apiCall.subscribe()
			.add(() => { this.exportLoading = false; });
	}


}
@NgModule({
	declarations: [
		EncaissementsComponent
	],
	exports: [
		EncaissementsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputNumberModule,
		TableModule,
		TriStateCheckboxModule,
		GlobalModule,
		DaterangeSelectorModule,
		IndicateurValueDisplayModule,
		IndicateurTooltipModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TitreModuleModule,
		TopParMilieuModule,
		Top10Module,
	],
})
export class EncaissementsModule { }
