<form class="" #communicationForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-6">
			<label for="clo_date_debut">Date du contrat</label>
			<input
				type="text"
				pInputText
				name="clo_date_debut"
				[(ngModel)]="communication.clo_date_debut"
				maxlength="200"
				placeholder="Date du contrat"
				[disabled]="loading"
				[required]="true"
			>
		</div>

		<div class="field col-6">
			<label for="clo_date_fin">Fin du contrat</label>
			<input
				type="text"
				pInputText
				name="clo_date_fin"
				[(ngModel)]="communication.clo_date_fin"
				maxlength="200"
				[disabled]="loading"
			>
		</div>

		<div class="field col-fixed">
			<label for="dpa_date_fin">Média</label>
			<media-communication-selector
				name="clo_media"
				[(ngModel)]="communication.clo_media"
				[disabled]="loading"
			></media-communication-selector>
		</div>

		<div class="field col-12">
			<label for="clo_prestataire">Prestataire</label>
			<input
				type="text"
				pInputText
				name="clo_prestataire"
				[(ngModel)]="communication.clo_prestataire"
				[disabled]="loading"
			>
		</div>

		<div class="field col-6">
			<label for="clo_montant">Montant</label>
			<div class="p-input-icon-right input-container hide-print">
				<i class="pi ff-reset">€</i>
				<input
					type="text"
					pInputText
					name="clo_montant"
					gzInputNumberFormatter
					[(ngModel)]="communication.clo_montant"
					[currency]="true"
					[decimals]="2"
					[disabled]="loading"
					[required]="true"
					placeholder="Montant"
					class="text-right"
				>
			</div>
		</div>

		<div class="field col-6">
			<label for="clo_benefices">Bénéfices chiffrés HT</label>
			<div class="p-input-icon-right input-container hide-print">
				<i class="pi ff-reset">€</i>
				<input
					type="text"
					pInputText
					name="clo_benefices"
					gzInputNumberFormatter
					[(ngModel)]="communication.clo_benefices"
					[currency]="true"
					[decimals]="2"
					[disabled]="loading"
					class="text-right"
				>
			</div>
		</div>

		<div class="field col-12">
			<label for="clo_trafic">Génération de trafic</label>
			<input
				type="text"
				pInputText
				name="clo_trafic"
				[(ngModel)]="communication.clo_trafic"
				[disabled]="loading"
			>
		</div>

	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button" pButton
		class="btn p-button-sm"
		label="Enregistrer"
		[disabled]="!communicationForm.form.valid || loading"
		(click)="submit()"
	></button>
</div>
