<div>
	<div class="flex justify-content-center" *ngIf="!loading && !hasDeclarations">
		<p-message severity="info" text="Aucune déclaration en attente" styleClass="mr-2"></p-message>
	</div>

	<ng-container *ngIf="hasDeclarations">

		<ng-container *ngFor="let magasin of magasins">

			<p-panel
				class="block mb-2"
				[toggleable]="true"
				collapseIcon="pi pi-chevron-up"
				expandIcon="pi pi-chevron-down"
				header="{{magasin.mag_nom_court}} ({{magasin.mag_id}}) {{magasin.declaration_mensuelle.date_declaration|date:'MMM YYYY'}}"
				styleClass="declaration-panel"
				*ngIf="!!magasin.declaration_mensuelle"
			>
				<p-blockUI [blocked]="!!magasin.loading" [target]="magasinForm"></p-blockUI>
				<declaration-mensuelle-form
					#magasinForm
					[declaration]="magasin.declaration_mensuelle"
					(onDeclarationPosted)="refreshOne(magasin)"
				></declaration-mensuelle-form>
			</p-panel>

		</ng-container>

	</ng-container>


	<div class="flex justify-content-center" *ngIf="loading">
		<div class="flex">
			<i class="pi pi-spin pi-spinner mr-2"></i><span>Chargement des déclarations en attentes</span>
		</div>
	</div>

</div>
