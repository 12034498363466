import { NgModule, Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';

import { StateService } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

import { AuthService } from '@app/auth/auth.service';
import { environment, enseigne } from '@environments/environment';

@Component({
	selector: 'auth',
	templateUrl: './auth.html',
})
export class AuthComponent  implements OnInit {

	authorization_code?: string;
	redirect_state?: string;

	oauthResponse:any = null;
	me: any = null;

	urlFOC: string = environment[enseigne].foc;

	loading: boolean = false;
	access_denied: boolean = false;

	title: string = environment[enseigne].title;

	constructor(
		private stateService: StateService,
		private authService: AuthService,
		private location: Location
	) {

	}

	ngOnInit() {
		this.access_denied = this.stateService.params['error'] &&  this.stateService.params['error'] == 'access_denied';
		this.redirect_state = this.stateService.params['state'];
		this.authorization_code = this.stateService.params['code'];

		// faire sauter tous les paramètres de l'url pour permettre de refresh la page sans avoir de parasites
		let path = this.location.path();
		path = path.split('?')[0];
		this.location.replaceState(path);

		this.getAccessToken();
	}

	getAuthorizationCode() {
		this.authService.getAuthorizationCode();
	}

	getAccessToken() {
		if (this.authorization_code) {
			this.loading = true;
			this.authService.getAccessToken(this.authorization_code)
			.subscribe({
				next: (response: any) => {
					this.oauthResponse = response;
					let redirect_state = this.redirect_state || 'home';
					this.stateService.go(redirect_state);
				},
				error: (error: any) => {
					this.access_denied = true;
				}
			})
			.add(() => { this.loading = false; });
		}
	}

}

@NgModule({
	declarations: [
		AuthComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
		CardModule,
		ProgressSpinnerModule,
		ToastModule,
	],
	exports: [AuthComponent],
})
export class AuthModule { }
