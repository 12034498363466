import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StateService } from '@uirouter/core';

import { ButtonModule } from 'primeng/button';
import { StateUtilsService } from '@global/state-utils.service';

@Component({
	selector: 'back-button',
	template: `
		<button
			type="button"
			pButton
			class="p-button-text ml-2 hide-print"
			icon="pi pi-arrow-left"
			(click)="goBack()"
			title="Retour"
			style="width: 2rem"
		></button>
	`
})
export class BackButtonComponent {

	constructor(
		private stateService: StateService,
		private stateUtils: StateUtilsService
	) {

	}

	goBack() {
		let target = this.stateUtils.getFirstNonAbstractParent();
		this.stateService.go(target);
	}
}

@NgModule({
	declarations: [
		BackButtonComponent
	],
	exports: [
		BackButtonComponent
	],
	imports: [
		CommonModule,
		ButtonModule,
	],
})
export class BackButtonModule { }
