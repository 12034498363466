<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Stats Financement</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">

	<div class="field col-fixed">
		<label>Année</label>
		<annee-selector
			name="annee"
			[(ngModel)]="annee"
			[nullIfInvalid]="true"
			[autoSelectCurrent]="true"
			[reverseOrder]="true"
			[minYear]="minYear"
			(ngModelChange)="paramChanged()"
			class="hide-print"
		></annee-selector>
	</div>

	<div class="field col-fixed">
		<label for="mag_id">Magasin</label>
		<magasin-selector
			name="magasin"
			[(ngModel)]="mag_id"
			[nullOption]="false"
			[nullIfInvalid]="false"
			[mesMagasins]="false"
			[autoSelectFirst]="true"
			[silentInitialChangeIfSame]="true"
			[disabled]="loading || exportLoading"
			(ngModelChange)="paramChanged()"
		></magasin-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

</div>

<p-table
	[value]="mois"
	[lazy]="true"
	(onLazyLoad)="load($event)"
	[lazyLoadOnInit]="false"
	[loading]="loading || exportLoading"
	[paginator]="false"
	[autoLayout]="true"
	responsiveLayout="scroll"
	scrollDirection="both"
	[scrollable]="true"
	scrollHeight="flex"
	styleClass="p-datatable-sm p-datatable-gridlines table-hover surface-0"
>
	<ng-template pTemplate="emptymessage">
		<tr>
			<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="header">
		<tr class="surface-0">
			<th class="text-center" pFrozenColumn>Mois</th>
			<th
				*ngFor="let indicateur of indicateurs"
				class="text-center col-indicateur"
				[ngClass]="{'bg-primary-soft': indicateur.ind_code == 'taux_rc_accepte'}"
			>
				<indicateur-label-display [ind_code]="indicateur.ind_code" [tooltip]="true"></indicateur-label-display>
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-mois>
		<tr [ngClass]="{'disabled': annee > currentYear || annee == currentYear && mois.mois > currentMonth}">
			<td class="col-header text-left nowrap" pFrozenColumn>{{ mois.mois | monthNumberToDate | date:'MMMM' | titlecase }}</td>
			<td *ngFor="let indicateur of indicateurs" class="{{indicateur.class}}" [ngClass]="{'bg-primary-soft': indicateur.ind_code == 'taux_rc_accepte'}">
				<indicateur-value-display [ind_code]="indicateur.ind_code" [value]="mois[indicateur.ind_code]"></indicateur-value-display>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="footer">
		<tr *ngIf="total">
			<td class="col-header text-left" pFrozenColumn>Total</td>
			<td *ngFor="let indicateur of indicateurs" class="font-bold {{indicateur.class}}" [ngClass]="{'bg-primary-soft': indicateur.ind_code == 'taux_rc_accepte'}">
				<indicateur-value-display [ind_code]="indicateur.ind_code" [value]="total[indicateur.ind_code]"></indicateur-value-display>
			</td>
		</tr>
	</ng-template>

</p-table>

<div class="text-sm p-2">
	<p>* Données FRANFINANCE du {{dateDonneesFranfinance|date: 'dd/MM/yyyy'}}</p>
	<p>* Les taux peuvent évoluer quand FRANFINANCE accepte/refuse des dossiers de financement, même plusieurs semaines après la saisie du dossier.</p>
</div>
