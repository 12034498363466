import { Injectable, OnInit } from '@angular/core';

import { Observable, of, Subject, interval } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {
	delay,
	map,
	retryWhen,
	switchMap,
	takeUntil,
} from 'rxjs/operators';

import { AuthService } from '@app/auth/auth.service';
import { environment, enseigne } from '@environments/environment';

export interface WebSocketMessage<Type> {
	objet: string;
	data: Type;
}

@Injectable({ providedIn: 'root' })
export class WebSocketService implements OnInit {

	public connection$: WebSocketSubject<any>|null;
	private url: string;
	private retryDelay = 5;
	private heartbeat$: Observable<any>;
	private heartbeatStopper$: Subject<void> = new Subject<void>();
	private heartbeatInterval: number = environment.websocket_heartbeat_interval;

	constructor(private authService: AuthService) {

	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.closeConnection();
	}

	connect(): Observable<any> {
		if (this.authService.token) {
			document.cookie = 'JWT=' + this.authService.token.access_token + '; path=/';
			document.cookie = 'enseigne=' + enseigne + '; path=/';
		}
		return of(window.location.origin)
		.pipe(
			map((apiUrl: string) => {
				// http becomes ws, https becomes wss
				return apiUrl.replace(/^http/, 'ws') + '/ws';
			}),
			switchMap((wsUrl: string) => {
				if (this.connection$) {
					return this.connection$;
				}
				else {
					// console.log('Connecting to WS', wsUrl);
					this.connection$ = webSocket({
						url: wsUrl,
						openObserver: {
							next: (value: any) => {
								// console.log('WS Connection ready');
								this.startHeartbeat();
							}
						},
						closeObserver: {
							next: (closeEvent: any) => {
								// console.log('WS connection closed', closeEvent);
								this.stopHeartbeat();
							}
						}
					});
					return this.connection$;
				}
			}),
			retryWhen((errors: Observable<any>)  =>
				errors.pipe(
					// restart in x seconds
					// tap(() => {console.log(`Retryin in ${this.retryDelay}`, errors);}),
					delay(this.retryDelay * 1000)
				)
			)
		);
	}

	startHeartbeat() {
		// console.log('Starting heartbeat');

		this.heartbeat$ = interval(this.heartbeatInterval)
		.pipe(
			takeUntil(this.heartbeatStopper$),
			map(()=> {
				this.sendMessage({'action':'heartbeat'});
			})
		)

		this.heartbeat$.subscribe();
	}

	stopHeartbeat() {
		// console.log('Stopping heartbeat');
		this.heartbeatStopper$.next();
	}

	authenticate() {
		if (this.connection$) {
			const payload = {
				token: this.authService.token,
			};
			this.connection$.next(payload);
		}
	}

	sendMessage(content?: any) {
		if (this.connection$) {
			this.connection$.next(content);
		}
		else {
			// console.log('No WS. Cannot send message.');
		}
	}

	closeConnection() {
		// console.log('Have we got a connection?');
		if (this.connection$) {
			// console.log('Apparently. Closing');
			this.connection$.complete();
			this.connection$ = null;
			// console.log('Connexion is supposedly closed.');
		}
	}


}
