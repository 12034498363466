<gz-menubar class="app-menu" [model]="menuItems">
	<ng-template pTemplate="start">
		<div class="logo-enseigne logo-enseigne-topbar mr-3" title="{{version}}"></div>
	</ng-template>

	<div class="">
		<button
			type="button"
			pButton
			class="p-button-rounded p-button-text"
			icon="pi pi-user"
			(click)="userMenu.toggle($event)"
		></button>
		<p-menu #userMenu [popup]="true" [model]="userMenuItems" appendTo="body"></p-menu>
	</div>

</gz-menubar>
<div class="app-content">
	<ui-view></ui-view>
</div>
