import { Ng2StateDeclaration } from '@uirouter/angular';

import { Transition } from '@uirouter/core';

import { ActiviteMagasinComponent } from '@app/evenement/activite-magasin';
import { AppComponent } from '@app/app.component';
import { AuditConformiteComponent } from '@app/moa/audit-conformite';
import { AuditsPrestataireComponent } from '@app/moa/suivi-prestataires/audits-prestataire';
import { AuthComponent } from '@app/auth';
import { ClassementMagasinsComponent } from '@app/classement/classement-magasins';
import { ClassementSatCliComponent } from '@app/classement/classement-satcli';
import { ClassementVendeursComponent } from '@app/classement/classement-vendeurs';
import { CockpitComponent } from '@app/cockpit/cockpit';
import { CommandsComponent } from '@app/command/commands';
import { ContratsAmortissementsExpositionsComponent } from '@app/moa/achats-fournisseurs/contrats-amortissements-expositions';
import { ControleDeclarationMensuelleComponent } from '@app/declaration/controle-declaration-mensuelle';
import { DeclarationMensuelleComponent } from '@app/declaration/declaration-mensuelle';
import { EncaissementsComponent } from '@app/reporting/encaissements';
import { EvaluationCollaborateurComponent } from '@app/moa/referentiel-competences/evaluation-collaborateur';
import { EvaluationsCollaborateursComponent } from '@app/moa/referentiel-competences/evaluations-collaborateurs';
import { IncidentsPrestataireComponent } from '@app/moa/suivi-prestataires/incidents-prestataire';
import { IndicateursDigitalComponent } from '@app/reporting/indicateurs-digital';
import { MaVisionEntrepreneurComponent } from '@app/moa/ma-vision-entrepreneur';
import { ObjectifsMagasinComponent } from '@app/objectifs/objectifs-magasin';
import { ObjectifsNationauxComponent } from '@app/objectifs/objectifs-nationaux';
import { ObjectifsVendeurComponent } from '@app/objectifs/objectifs-vendeur';
import { ParrainageYuccanleadComponent } from '@app/reporting/parrainage-yuccanlead';
import { PlanActionsComponent } from '@app/plan-actions/plan-actions';
import { PrestatairesComponent } from '@app/moa/suivi-prestataires/prestataires';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { ProfilCompetencePipe } from '@app/moa/referentiel-competences/profil-competence.pipe';
import { QuiFaitQuoiComponent } from '@app/moa/qui-fait-quoi/qui-fait-quoi';
import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { RepartitionAchatsMeublesComponent } from '@app/moa/achats-fournisseurs/repartition-achats-meubles';
import { ReportingActiviteComponent } from '@app/reporting/reporting-activite';
import { ReportingResultatComponent } from '@app/reporting/reporting-resultat';
import { RevueMensueleActiviteCommercialeComponent } from '@app/reporting/revue-mensuelle-activite-commerciale';
import { RootComponent } from '@app/root';
import { ServiceUnavailableComponent } from'@global/service-unavailable.component';
import { SimulateurAtterrissageCAMagasinComponent } from '@app/simulateur/atterrissage-ca-magasin';
import { SimulateurNoteGoogleComponent } from '@app/simulateur/note-google';
import { SimulateurPotentielCAMagasinComponent } from '@app/simulateur/potentiel-ca-magasin';
import { SimulateurPreparationObjectifsCAAnnuelComponent } from '@app/simulateur/preparation-objectifs-ca-annuel';
import { StatsFinancementComponent } from '@app/reporting/stats-financement';
import { StatsParCodePostalComponent } from '@app/reporting/stats-par-code-postal';
import { SuiviCommunicationLocaleComponent } from '@app/communication-locale/suivi-communication-locale';
import { UtilisateurPipe } from '@app/utilisateur/utilisateur.pipe';

import * as resolves from '@global/app-routing.resolves';


export const states: Ng2StateDeclaration[] = [
	{
		name: 'service_unavailable',
		url: '?{error:string}',
		component: ServiceUnavailableComponent
	},

	{
		name: 'app',
		component: AppComponent,
		redirectTo: 'home',
		resolve: [

		]
	},

	{
		parent: 'app',
		name: 'auth',
		url: '/auth',
		component: AuthComponent,
		data: {
			forbiddenWhenAuth: true
		},
	},
	{
		parent: 'app',
		name: 'callback',
		url: '/callback?{code:string}{state:string}{error:string}',
		params: {
			'code': {
				dynamic: true,
				squash: true,
			},
			'state': {
				dynamic: true,
				squash: true
			},
			'error': {
				dynamic: true,
				squash: true
			},
		},
		component: AuthComponent,
		data: {
			forbiddenWhenAuth: true
		},
	},

	{
		parent: 'app',
		name: 'root',
		component: RootComponent,
		redirectTo: 'home',
		data: {
			requiresAuth: true,
			meGuard: true,
		},
		resolve: [
			resolves.configResolve,
			resolves.mesMagasinsResolve,
			resolves.indicateursResolve,
		]
	},
	{
		parent: 'root',
		name: 'home',
		url: '/home',
		redirectTo: 'cockpit'
	},

	{
		parent: 'root',
		name: 'cockpit',
		url: '/cockpit',
		component: CockpitComponent,
		data: {
			title: 'Cockpit'
		}
	},

	{
		parent: 'root',
		name: 'objectifs',
		abstract: true,
		data: {
			title: 'Objectifs'
		}
	},
	{
		parent: 'objectifs',
		name: 'objectifs-magasin',
		url: '/objectifs-magasin',
		views: {
			'@root': {
				component: ObjectifsMagasinComponent
			}
		},
		data: {
			droits: [
				'direction_reseau',
			],
			title: 'Objectifs Magasins'
		}
	},
	{
		parent: 'objectifs',
		name: 'objectifs-nationaux',
		url: '/objectifs-nationaux',
		views: {
			'@root': {
				component: ObjectifsNationauxComponent
			}
		},
		data: {
			droits: [
				'direction_reseau',
			],
			title: 'Objectifs Nationaux'
		}
	},

	{
		parent: 'root',
		name: 'activite-magasin',
		url: '/activite-magasin',
		views: {
			'@root': {
				component: ActiviteMagasinComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'manager',
				'resp_saisie',
				'vendeur',
			],
			title: 'Activité Magasin'
		}
	},

	{
		parent: 'root',
		name: 'declarations',
		abstract: true,
		data: {
			title: 'Déclarations'
		}
	},

	{
		parent: 'declarations',
		name: 'synthese-annuelle',
		url: '/synthese-annuelle',
		views: {
			'@root': {
				component: ReportingResultatComponent
			}
		},
		data: {
			droits: [
				'ag',
				'animateur_region',
				'centrale',
				'chef_ventes',
				'direction_reseau',
				'manager',
				'resp_saisie',
			],
			title: 'Synthèse Annuelle'
		}
	},

	{
		parent: 'declarations',
		name: 'declaration-mensuelle-magasin',
		url: '/declaration-mensuelle-magasin',
		views: {
			'@root': {
				component: DeclarationMensuelleComponent
			}
		},
		data: {
			droits: [
				'manager',
				'resp_saisie',
			],
			title: 'Déclaration Mensuelle'
		}
	},
	{
		parent: 'declarations',
		name: 'controle-declaration-mensuelle-magasin',
		url: '/controle-declaration-mensuelle-magasin',
		views: {
			'@root': {
				component: ControleDeclarationMensuelleComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
			],
			title: 'Contrôle Déclaration'
		}
	},

	{
		parent: 'root',
		name: 'reporting',
		abstract: true,
		data: {
			title: 'Statistiques'
		}
	},

	{
		parent: 'reporting',
		name: 'reporting-activite',
		url: '/reporting-activite',
		views: {
			'@root': {
				component: ReportingActiviteComponent
			}
		},
		data: {
			title: 'Reporting Activité'
		}
	},
	{
		parent: 'reporting',
		name: 'classement-magasins',
		url: '/classement-magasins',
		views: {
			'@root': {
				component: ClassementMagasinsComponent
			}
		},
		data: {
			title: 'Classement Magasins'
		}
	},
	{
		parent: 'reporting',
		name: 'classement-vendeurs',
		url: '/classement-vendeurs',
		views: {
			'@root': {
				component: ClassementVendeursComponent
			}
		},
		data: {
			title: 'Classement Vendeurs'
		}
	},
	{
		parent: 'reporting',
		name: 'classement-satcli',
		url: '/classement-satcli',
		views: {
			'@root': {
				component: ClassementSatCliComponent
			}
		},
		data: {
			title: 'Classement SATCLI'
		}
	},
	{
		parent: 'reporting',
		name: 'stats-financement',
		url: '/stats-financement',
		views: {
			'@root': {
				component: StatsFinancementComponent
			}
		},
		data: {
			title: 'Financement'
		}
	},
	{
		parent: 'reporting',
		name: 'indicateurs-digital',
		url: '/indicateurs-digital',
		views: {
			'@root': {
				component: IndicateursDigitalComponent
			}
		},
		data: {
			droits: [
				'ag',
				'animateur_region',
				'centrale',
				'chef_ventes',
				'direction_reseau',
				'manager',
				'resp_saisie',
			],
			title: 'Indicateurs Digital'
		}
	},

	{
		parent: 'reporting',
		name: 'parrainage-yuccanlead',
		url: '/parrainage-yuccanlead',
		views: {
			'@root': {
				component: ParrainageYuccanleadComponent
			}
		},
		data: {
			title: 'Parrainage YuccanLead'
		}
	},

	// MOA
	{
		parent: 'root',
		name: 'moa',
		url: '/moa',
		abstract: true,
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: 'MOA'
		}
	},

	// MA COMMUNICATION
	{
		parent: 'moa',
		name: 'ma-communication',
		url: '/ma-communication',
		abstract: true,
		data: {
			title: 'Ma communication'
		}
	},
	{
		parent: 'ma-communication',
		name: 'suivi-communication-locale',
		url: '/suivi-communication-locale',
		views: {
			'@root': {
				component: SuiviCommunicationLocaleComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Suivi de communication locale`
		}
	},
	{
		parent: 'ma-communication',
		name: 'stats-par-code-postal',
		url: '/stats-par-code-postal',
		views: {
			'@root': {
				component: StatsParCodePostalComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
				'resp_saisie',
			],
			title: `Statistiques communication/codes postaux`
		}
	},

	// MON PILOTAGE
	{
		parent: 'moa',
		name: 'mon-pilotage',
		url: '/mon-pilotage',
		abstract: true,
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
				'resp_saisie',
			],
			title: 'Mon pilotage'
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'qui-fait-quoi',
		url: '/qui-fait-quoi',
		views: {
			'@root': {
				component: QuiFaitQuoiComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'formation',
				'manager',
				'direction_reseau',
				'centrale',
			],
			title: 'Qui fait quoi'
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'simulateur-potentiel-ca-magasin',
		url: '/potentiel-ca-magasin',
		views: {
			'@root': {
				component: SimulateurPotentielCAMagasinComponent
			}
		},
		data: {
			title: 'Potentiel CA Magasin'
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'simulateur-preparation-objectif-ca-annuel',
		url: '/preparation-objectif-ca-annuel',
		views: {
			'@root': {
				component: SimulateurPreparationObjectifsCAAnnuelComponent
			}
		},
		data: {
			title: 'Préparation des Objectifs CA Annuel'
		},
	},
	{
		parent: 'mon-pilotage',
		name: 'objectifs-vendeur',
		url: '/objectifs-vendeur',
		views: {
			'@root': {
				component: ObjectifsVendeurComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'formation',
				'manager',
				'resp_saisie',
			],
			title: 'Objectifs Vendeurs'
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'simulateur-note-google',
		url: '/note-google',
		views: {
			'@root': {
				component: SimulateurNoteGoogleComponent
			}
		},
		data: {
			title: 'Note Google'
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'plans-actions',
		url: '/plans-actions',
		views: {
			'@root': {
				component: PlanActionsComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
				'resp_saisie',
			],
			title: `Objectifs et Plan d'actions vendeur`
		}
	},
	{
		parent: 'mon-pilotage',
		name: 'revue-mensuelle-activite-commerciale',
		url: '/revue-mensuelle-activite-commerciale',
		views: {
			'@root': {
				component: RevueMensueleActiviteCommercialeComponent
			}
		},
		data: {
			title: `Revue mensuelle d'activité commerciale`
		}
	},

	// MA FINANCE
	{
		parent: 'moa',
		name: 'ma-finance',
		url: '/ma-finance',
		abstract: true,
		data: {
			droits: [
				'ag',
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: 'Ma finance'
		}
	},
	{
		parent: 'ma-finance',
		name: 'historique-encaissements',
		url: '/historique-encaissements',
		views: {
			'@root': {
				component: EncaissementsComponent
			}
		},
		data: {
			title: `Historique des encaissements`
		},
		resolve: [
			{token: 'mode', deps: [], resolveFn: () => {return 'historique';}}
		]
	},
	{
		parent: 'ma-finance',
		name: 'projection-encaissements',
		url: '/projection-encaissements',
		views: {
			'@root': {
				component: EncaissementsComponent
			}
		},
		data: {
			title: `Projection des encaissements`
		},
		resolve: [
			{token: 'mode', deps: [], resolveFn: () => {return 'projection';}}
		]
	},
	{
		parent: 'moa',
		name: 'audit-conformite',
		url: '/audit-conformite',
		views: {
			'@root': {
				component: AuditConformiteComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Audit de conformité`,
		}
	},
	{
		parent: 'moa',
		name: 'ma-vision-entrepreneur',
		url: '/ma-vision-entrepreneur',
		views: {
			'@root': {
				component: MaVisionEntrepreneurComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Ma vision d'entrepreneur`,
		}
	},

	{
		parent: 'moa',
		name: 'suivi-prestataires',
		url: '/suivi-prestataires',
		views: {
			'@root': {
				component: PrestatairesComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Suivi prestataires`,
		}
	},
	{
		parent: 'suivi-prestataires',
		name: 'prestataire',
		url: '/{prs_id:int}',
		abstract: true,
		redirectTo: 'controle-annuel-prestataire',
		resolve: [
			{
				token: 'prestataire',
				deps: [PrestataireService, Transition],
				resolveFn: (prestataireService: PrestataireService, transition: Transition) => {
					return prestataireService.getPrestataire(transition.params().prs_id).toPromise();
				;
			}}
		],
		data: {
			title: (trans: Transition) => {
				const prestataire = trans.injector().get('prestataire');
				return `${prestataire.magasin.label} ${prestataire.prs_societe}`;
			}
		}
	},

	{
		parent: 'prestataire',
		name: 'controles-annuels-prestataire',
		url: '/controles-annuels',
		views: {
			'@root': {
				component: AuditsPrestataireComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Contrôle annuel`,
		}
	},

	{
		parent: 'prestataire',
		name: 'incidents-prestataire',
		url: '/incidents',
		views: {
			'@root': {
				component: IncidentsPrestataireComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Incidents`,
		}
	},


	{
		parent: 'moa',
		name: 'referentiel-competences',
		url: '/evaluations-collaborateurs',
		views: {
			'@root': {
				component: EvaluationsCollaborateursComponent
			}
		},
		resolve: [
			resolves.niveauxCompetences,
			resolves.profilsCompetences,
		],
		data: {
			droits: [
				'animateur_region',
				'chef_ventes',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: 'Evaluations collaborateurs'
		}
	},
	{
		parent: 'referentiel-competences',
		name: 'evaluation-collaborateur',
		url: '/{mag_id: int}/{evc_id:int}',
		views: {
			'@root': {
				component: EvaluationCollaborateurComponent
			}
		},
		resolve: [
			{
				token: 'evaluation_collaborateur',
				deps: [ReferentielCompetencesService, Transition],
				resolveFn: (referentielCompetencesService: ReferentielCompetencesService, transition: Transition) => {
					return referentielCompetencesService.getEvaluationCollaborateur(transition.params().mag_id, transition.params().evc_id).toPromise();
				;
			}}
		],
		data: {
			title: (trans: Transition) => {
				const evaluation_collaborateur = trans.injector().get('evaluation_collaborateur');
				const utilisateurPipe = trans.injector().get(UtilisateurPipe);
				const profilCompetencePipe = trans.injector().get(ProfilCompetencePipe);
				const name: string = utilisateurPipe.transform(evaluation_collaborateur.collaborateur);
				const profil: string = profilCompetencePipe.transform(evaluation_collaborateur.pco_code);
				return `${name} - ${profil} ${evaluation_collaborateur.evc_periode}` ;
			}
		}
	},

	{
		parent: 'moa',
		name: 'contrats-amortissements-expositions',
		url: '/contrats-amortissements-expositions',
		views: {
			'@root': {
				component: ContratsAmortissementsExpositionsComponent
			}
		},
		data: {
			droits: [
				'animateur_region',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Contrats d'amortissement exposition`,
		}
	},

	{
		parent: 'moa',
		name: 'repartition-achats-meubles',
		url: '/repartition-achats-meubles',
		views: {
			'@root': {
				component: RepartitionAchatsMeublesComponent
			}
		},
		resolve: [
			resolves.fournisseursExpositions
		],
		data: {
			droits: [
				'ag',
				'animateur_region',
				'centrale',
				'direction_reseau',
				'formation',
				'manager',
			],
			title: `Répartition achats meubles`,
		}
	},






	// ADMIN
	{
		parent: 'root',
		name: 'admin',
		url: '/admin',
		abstract: true,
		redirectTo: 'commands',
		data: {
			droits: [
				'informatique'
			],
			title: 'Administration'
		}
	},
	{
		// parent: 'admin',
		// name: 'commands',
		name: 'admin.commands',
		url: '/commands',
		views: {
			'@root': {
				component: CommandsComponent
			}
		},
		data: {
			title: 'Commandes'
		}
	},



];
