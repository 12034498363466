<div class="flex flex-column h-screen">
	<div class="align-self-center mt-auto mb-auto">

		<p-card class="text-center">
			<ng-template pTemplate="header">
				<div class="pt-3">
					<div class="logo-enseigne"></div>
				</div>
			</ng-template>

  	  <h1 class="mt-0">{{title}}</h1>

			<div *ngIf="access_denied">
				L'accès n'a pas été autorisé
			</div>

			<div *ngIf="loading">
				<p>Authentification en cours...</p>
				<p-progressSpinner></p-progressSpinner>
			</div>

			<div *ngIf="!loading">
				<button type="button" pButton (click)="getAuthorizationCode()" *ngIf="!authorization_code">Connexion</button>
			</div>

			<p>
				<small><a class="secondary-color" href="{{urlFOC}}">
					Retour à Franchise On Cloud
				</a></small>
			</p>
		</p-card>

	</div>
</div>

