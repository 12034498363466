<form #formPrestataire="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-12 md:col-6 flex flex-column">
			<label for="prs_adresse">Adresse <sup class="text-primary">*</sup></label>
			<textarea
				pInputTextarea
				name="prs_adresse"
				[(ngModel)]="prestataire.prs_adresse"
				[autoResize]="true"
				[rows]="5"
				[disabled]="true"
				class="flex-grow-1"
			></textarea>
		</div>

		<div class="col-12 md:col-6 grid grid-nogutter">
			<div class="field col-12" >
				<label for="prs_telephone">Téléphone <sup class="text-primary">*</sup></label>
				<input
					type="text"
					pInputText
					name="prs_telephone"
					placeholder="Téléphone"
					[(ngModel)]="prestataire.prs_telephone"
					[disabled]="true"
				>
			</div>

			<div class="field col-12" >
				<label for="prs_email">Email <sup class="text-primary">*</sup></label>
				<input
					type="email"
					pInputText
					name="prs_email"
					placeholder="Email"
					[email]="true"
					[(ngModel)]="prestataire.prs_email"
					[disabled]="true"
				>
			</div>
		</div>

		<div class="col-12"></div>

		<!-- <div class="field col-12 sm:col-6" >
			<label for="prs_telephone">Téléphone <sup class="text-primary">*</sup></label>
			<input
				type="text"
				pInputText
				name="prs_telephone"
				placeholder="Téléphone"
				[(ngModel)]="prestataire.prs_telephone"
				[disabled]="true"
			>
		</div>

		<div class="field col-12 sm:col-6" >
			<label for="prs_email">Email <sup class="text-primary">*</sup></label>
			<input
				type="email"
				pInputText
				name="prs_email"
				placeholder="Email"
				[email]="true"
				[(ngModel)]="prestataire.prs_email"
				[disabled]="true"
			>
		</div> -->

		<div class="field col-12 sm:col-6" >
			<label for="prs_interlocuteur">Interlocuteur</label>
			<input
				type="text"
				pInputText
				name="prs_interlocuteur"
				placeholder="Interlocuteur"
				[(ngModel)]="prestataire.prs_interlocuteur"
				[disabled]="loading"
				[required]="true"
			>
		</div>

		<div class="field col-12 sm:col-6">

			<label for="range">Date début prestation</label>
			<p-calendar
				name="prs_date_debut_prestation"
				[(ngModel)]="prestataire.prs_date_debut_prestation"
				[showTime]="false"
				[showIcon]="true"
				[showButtonBar]="true"
				[maxDate]="today"
				placeholder="jj/mm/aaaa"
				appendTo="body"
				[disabled]="loading"
			></p-calendar>
		</div>

		<div class="col-12"></div>
		<div class="field col-12 md:col-6">
			<label for="prs_tarifs">Tarifs</label>
			<textarea
				pInputTextarea
				name="prs_tarifs"
				[(ngModel)]="prestataire.prs_tarifs"
				[autoResize]="false"
				[rows]="5"
				class="min-w-full max-w-full"
			></textarea>
		</div>

		<div class="field col-12 md:col-6">
			<label for="prs_observations">Observations</label>
			<textarea
				pInputTextarea
				name="prs_observations"
				[(ngModel)]="prestataire.prs_observations"
				[autoResize]="false"
				[rows]="5"
				class="min-w-full max-w-full"
			></textarea>
		</div>

		<div class="col-12 text-sm">
			<div>* Les prestataires sont récupérés de votre WinnerBizz chaque jour (vers 14h00)</div>
			<div>* L'adresse postale, l'email et le téléphone sont à modifier dans WinnerBizz</div>
		</div>

	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !formPrestataire.valid"
			(click)="submit()"
		></button>
	</div>
</form>

