import { NgModule, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { FileUploadModule, FileUpload } from '@app/primeng-overrides/fileupload';

import { GlobalModule } from '@global/global.module';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { AuditPrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { PointControlePrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';

import { localeFR } from '@global/locale-fr';
import { fileTypes } from '@global/file.service';
import { FileIconModule } from '@app/_global/file-icon';
import { uid } from '@helpers/utils';

@Component({
	selector: 'piece-jointe-dialog',
	templateUrl: './piece-jointe-dialog.html'
})
export class PieceJointeDialogComponent implements OnInit, IEventListener {

	@ViewChild('uploader') uploader!: FileUpload;

	private _uuid: string = uid();
	get uuid(): string { return this._uuid; }

	loading: boolean = false;
	submitted: boolean = false;

	acceptedMimeTypes: string = '';
	acceptedExtensions: string = '';
	urlUpload: string = '';
	fileLimit: number = 1;
	// maxFileSize: number|null = 1000000;
	maxFileSize: number = 3145728; // 3145728 = 3Mo
	error: string|null = null;

	request$: Subscription|null = null;
	invalidFileLimitMessageDetail = localeFR.invalidFileLimitMessageDetail;
	invalidFileLimitMessageSummary = localeFR.invalidFileLimitMessageSummary;
	invalidFileSizeMessageDetail = localeFR.invalidFileSizeMessageDetail;
	invalidFileSizeMessageSummary = localeFR.invalidFileSizeMessageSummary;
	invalidFileTypeMessageDetail = localeFR.invalidFileTypeMessageDetail;
	invalidFileTypeMessageSummary = localeFR.invalidFileTypeMessageSummary;

	auditPrestataire: AuditPrestataire;
	pointControle: PointControlePrestataire;


	constructor(
		private config: DynamicDialogConfig,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private prestataireService: PrestataireService,
	) {
		this.auditPrestataire = config.data.auditPrestataire;
		this.pointControle = config.data.pointControle;
		this.urlUpload = this.prestataireService.getPieceUrlUpload(this.auditPrestataire, this.pointControle);

		this.acceptedMimeTypes = [
			...fileTypes.doc.mimetypes,
			...fileTypes.image.mimetypes,
			...fileTypes.pdf.mimetypes,
		].join(',');
		this.acceptedExtensions = [
			...fileTypes.doc.extensions,
			// ...fileTypes.image.extensions,
			...fileTypes.pdf.extensions,
		]
		.map(one => {return '*.' + one; }).join(', ');
		this.acceptedExtensions += ', images';
 	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.abortUpload();
		this.eventManager.unregisterEvent('close-modals', this);
	}


	upload() {
		this.request$ = this.uploader.upload();
	}

	removeFile(index: number) {
		this.uploader.files.splice(index, 1);
	}

	clearUploader() {
		this.uploader.clear();
	}

	abortUpload() {
		if (this.request$) {
			this.request$.unsubscribe();
			this.eventManager.emit('toast', {severity: 'info', summary: 'Transfert annulé'});
			this.request$ = null;
			this.uploader.uploading = false;
			this.uploader.progress = 0;
			this.uploader.cd.markForCheck();
		}
	}

	onBeforeUpload(event: any) {

	}

	onUpload(event: any) {
		this.request$ = null;
		this.eventManager.emit('toast', {severity: 'success', summary: 'Fichier uploadé'});
		this.dialogRef.close(event.originalEvent.body);
	}

	onUploadError(event: any) {
		this.request$ = null;
		if (event.error && event.error.data && event.error.data.file) {
			this.eventManager.emit('toast', {severity: 'error', summary: 'Fichier en erreur', detail: event.error.data.file});
		}
	}


}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		FileUploadModule,
		GlobalModule,
		FileIconModule
	],
	exports: [PieceJointeDialogComponent],
	declarations: [PieceJointeDialogComponent]
})
export class PieceJointeDialogModule { }

