import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { AnalyticsService } from '@global/analytics.service';
import {
	prepareQueryParams,
} from '@helpers/utils';
import { Observable, Subscriber } from 'rxjs';


export class SaisiePotentielCAMagasin {
	mag_id: number|null = null;
	nb_entrees_moyen: number|null = null;
	panier_moyen: number|null = null;
	ratio_nb_devis_nb_entrees_personnalise: number|null = null;
	ratio_nb_ventes_nb_devis_personnalise: number|null = null;
}

export type SimulationPotentielCAMagasin = {
	saisies: SaisiePotentielCAMagasin,
	objectif_nb_devis_cible: number,
	objectif_nb_ventes_cible: number,
	ca_potentiel_mensuel_cible: number,
	ca_potentiel_annuel_cible: number,
	objectif_nb_devis_performeurs: number,
	objectif_nb_ventes_performeurs: number,
	ca_potentiel_mensuel_performeurs: number,
	ca_potentiel_annuel_performeurs: number,
	objectif_nb_devis_personnalise: number,
	objectif_nb_ventes_personnalise: number,
	ca_potentiel_mensuel_personnalise: number,
	ca_potentiel_annuel_personnalise: number
}


export class SaisieAtterrissageCAMagasin {
	mag_id: number|null = null;
	estimations_ca: any[] = [];
}

export type SimulationAtterrissageCAMagasin = {
	saisies: SaisiePotentielCAMagasin,
	'N-1': {
		nb_entrees: number|null,
		oma_ca_prise_commande_htple: number|null,
		ca_prise_commande_htple: number|null,
		mois: [
			{
				ca_prise_commande_htple: number|null,
				oma_ca_prise_commande_htple: number|null,
				nb_entrees: number|null,
			}
		]
	},
	'N': {
		ca_prise_commande_htple: number|null,
		evolution_ca_prise_commande_htple_n_moins_1: number|null,
		panier_moyen: number|null,
		methode_1: number|null,
		methode_2: number|null,
		methode_3: number|null,
		methode_4: number|null,
		atterrissage_1: number|null,
		atterrissage_2: number|null,
		atterrissage_3: number|null,
		atterrissage_4: number|null,
		mois: any[],
			// {
			// 	"ca_prise_commande_htple": number|null,
			// 	"panier_moyen": number|null,
			// 	"methode_1": number|null,
			// 	"methode_2": number|null,
			// 	"methode_3": number|null,
			// 	"methode_4": number|null,
			// }

	}
}

export class SaisiePreparationObjectifsCAAnnuel {
	mag_id: number|null = null;
	contexte: string|null = null;
	ca_concurrents: string|null = null;
	objectif_nb_entrees: number|null = null;
	objectif_avg_vente_htple: number|null = null;
	objectif_taux_nb_ventes_nb_entrees: number|null = null;
	effectif_magasin: number|null = null;
	objectif_ca_prise_commande_htple: number|null = null;
	mois: any[] = [];
	equipe: any[] = [];
}

export type SimulationPreparationObjectifsCAAnnuel = {
	"N": {
		ca_prise_commande_htple: number|null,
		nb_entrees: number|null,
		avg_vente_htple: number|null,
		taux_nb_ventes_nb_entrees: number|null,
	},
	"N-1": {
		ca_prise_commande_htple: number|null,
		nb_entrees: number|null,
		avg_vente_htple: number|null,
		taux_nb_ventes_nb_entrees: number|null,
	},
	simulation_par_historique: number|null,
	simulation_par_ratios: number|null,
	simulation_par_effectif: number|null,
	saisies: SaisiePreparationObjectifsCAAnnuel
}


@Injectable({ providedIn: 'root' })
export class SimulateurService {

	constructor(
		private analyticsService: AnalyticsService,
		private http: HttpClient
	) {

	}


	getPotentielCAMagasin() {
		this.analyticsService.trackEvent('get_simulateur_potentiel_ca_magasin');
		return this.http.get<any>(`/simulateur/potentiel_ca_magasin`)
		.pipe(
			map((response: any) =>{
				return response;
			})
		);
	}

	postPotentielCAMagasin(params: SaisiePotentielCAMagasin) {
		// this.analyticsService.trackEvent('post_simulateur_potentiel_ca_magasin', params);
		return this.http.post<any>(`/simulateur/potentiel_ca_magasin`, params)
		.pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	getAtterrissageCAMagasin() {
		this.analyticsService.trackEvent('get_simulateur_atterrissage_ca_magasin');
		return this.http.get<any>(`/simulateur/atterrissage_ca_magasin`)
		.pipe(
			map((response: any) =>{
				return response;
			})
		);
	}

	postAtterrissageCAMagasin(params: SaisieAtterrissageCAMagasin) {
		// this.analyticsService.trackEvent('post_simulateur_atterrissage_ca_magasin', params);
		return this.http.post<any>(`/simulateur/atterrissage_ca_magasin`, params)
		.pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	getObjectifCAMagasin() {
		this.analyticsService.trackEvent('get_simulateur_objectif_ca_annuel_magasin');
		return this.http.get<any>(`/simulateur/objectif_ca_annuel_magasin`)
		.pipe(
			map((response: any) =>{
				return response;
			})
		);
	}

	postObjectifCAMagasin(params: SaisieAtterrissageCAMagasin) {
		// this.analyticsService.trackEvent('post_simulateur_objectif_ca_annuel_magasin', params);
		return this.http.post<any>(`/simulateur/objectif_ca_annuel_magasin`, params)
		.pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	getNoteGoogle(mag_id: number) {
		let tmpParams = prepareQueryParams({mag_id: mag_id});
		// this.analyticsService.trackEvent('get_simulateur_note_google');
		return this.http.get<any>(`/simulateur/note_google`, tmpParams)
		.pipe(
			map((response: any) =>{
				return response;
			})
		);
	}

	getPreparationObjectifsCAAnnuel(mag_id: number|null) {
		this.analyticsService.trackEvent('get_simulateur_preparation_objectifs_ca_annuel');
		let tmpParams = prepareQueryParams({mag_id: mag_id});
		return this.http.get<any>(`/simulateur/preparation_objectifs_ca_annuel`, tmpParams)
		.pipe(
			map((response: any) =>{
				return response;
			})
		);
	}

	postPreparationObjectifsCAAnnuel(params: SaisiePreparationObjectifsCAAnnuel) {
		// this.analyticsService.trackEvent('post_simulateur_preparation_objectifs_ca_annuel', params);
		return this.http.post<any>(`/simulateur/preparation_objectifs_ca_annuel`, params)
		.pipe(
			map((response: any) => {
				return response;
			})
		);
	}
}
