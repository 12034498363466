<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module></titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<label for="annee">Année</label>
		<annee-selector
			name="annee"
			[(ngModel)]="annee"
			[nullIfInvalid]="true"
			[autoSelectCurrent]="true"
			[reverseOrder]="true"
			[minYear]="minYear"
			[maxYear]="maxYear"
			[disabled]="loading || exportLoading"
			(ngModelChange)="load()"
		></annee-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<div class="p-inputgroup">
			<input pInputText type="text" [(ngModel)]="search" (onDebounce)="filter()" placeholder="Rechercher..." />
			<button
				type="button"
				class="p-inputgroup-addon"
				icon="pi pi-times"
				pButton
				label=""
				[disabled]="!search || loading || exportLoading"
				(click)="resetFilter()"
			></button>
		</div>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-treeTable
		#tableObjectifs
		[value]="objectifs"
		[rowTrackBy]="indexTracker"
		[lazy]="true"
		[loading]="loading || exportLoading"
		[columns]="columns"
		[frozenColumns]="frozen_columns"
		[scrollable]="true"
		scrollHeight="flex"
		frozenWidth="300px"
		(onNodeExpand)="onNodeExpand($event)"
		(onNodeCollapse)="onNodeCollapse($event)"
		(onEditComplete)="onEditComplete($event)"
		(onEditCancel)="onEditCancel($event)"
		(onEditInit)="onEditInit($event)"
		styleClass="p-treetable-sm p-datatable-gridlines table-hover table-objectifs table-saisie-cols-{{columns?.length}}"
	>

		<ng-template pTemplate="colgroup" let-columns>
			<colgroup>
				<col *ngFor="let col of columns; index as i; trackBy: indexTracker" [ngClass]="col.class">
			</colgroup>
		</ng-template>

		<ng-template pTemplate="frozenheader" let-columns>
			<tr class="">
				<th
					class="text-left flex justify-content-between"
					*ngFor="let col of columns; let i = index; trackBy: indexTracker"
				>
					<b><indicateur-label-display [ind_code]="ind_code" [tooltipIcon]="true"></indicateur-label-display></b>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header" let-columns>
			<tr class="">
				<th
					class="text-center"
					*ngFor="let col of columns; let i = index; trackBy: indexTracker"
				>
					{{col.header}}
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
			<ng-container *ngIf="rowData.mag_uid && !rowData.hidden">
				<!-- magasin -->
				<tr class="row-group">

					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{ rowData.mag_nom_court }} ({{rowData.mag_id}})">
						<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
						<b>{{ rowData.mag_nom_court }} ({{rowData.mag_id}})</b>
					</td>
				</tr>
				<tr class="row-restant">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<b>Reste à attribuer</b>
					</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="rowData.uti_id && !rowData.hidden">
				<!-- vendeur -->
				<tr class="row-child">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{rowData.label}}">
						{{ rowData.uti_prenom }} {{ rowData.uti_nom }}
					</td>
				</tr>
			</ng-container>
			<ng-container *ngIf="!rowData.mag_id && !rowData.uti_id">
				<!-- ligne total -->
				<tr class="row-total">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
						<b>{{ rowData.header }}</b>
					</td>
				</tr>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData" let-columns="columns">

			<ng-container *ngIf="rowData.mag_uid && !rowData.hidden">
				<!-- magasin -->
				<tr	class="row-group">
					<td
						class="col-mois"
						[ngClass]="{
							'text-right': i == 12 || rowData.objectifs_magasins[i] && rowData.objectifs_magasins[i].oma_ca_prise_commande_htple,
							'text-center': i < 12 && rowData.objectifs_magasins[i] && !rowData.objectifs_magasins[i].oma_ca_prise_commande_htple
						}"
						*ngFor="let col of columns; index as i; trackBy: indexTracker"
					>
						<ng-container *ngIf="i < 12">
							<ng-container *ngIf="rowData.objectifs_magasins && rowData.objectifs_magasins[i]">
								<ng-container *ngIf="rowData.objectifs_magasins[i].oma_ca_prise_commande_htple">
									<number-display
										[value]="rowData.objectifs_magasins[i].oma_ca_prise_commande_htple"
										type="currency"
										numberFormat=".0-0"
									></number-display>
								</ng-container>
								<ng-container *ngIf="!rowData.objectifs_magasins[i].oma_ca_prise_commande_htple">
									A définir par centrale
								</ng-container>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="i == 12">
							<number-display
								[value]="rowData.oma_ca_prise_commande_htple"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</ng-container>
					</td>
				</tr>

				<!-- magasin restant -->
				<tr	class="row-restant">
					<td
						class="text-right col-mois"
						[ngClass]="{'depassement-objectif': rowData.objectifs_magasins[i] && rowData.objectifs_magasins[i].restant < 0 || !rowData.objectifs_magasins[i] && rowData.restant < 0}"
						*ngFor="let col of columns; index as i; trackBy: indexTracker"
					>
						<ng-container *ngIf="i < 12">
							<ng-container *ngIf="rowData.objectifs_magasins && rowData.objectifs_magasins[i]">
								<number-display
									[value]="rowData.objectifs_magasins[i].restant"
									type="currency"
									numberFormat=".0-0"
								></number-display>
							</ng-container>

						</ng-container>
						<ng-container *ngIf="i == 12">
							<number-display
								[value]="rowData.restant"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</ng-container>

					</td>
				</tr>
			</ng-container>

			<ng-container *ngIf="rowData.uti_id && !rowData.hidden">
				<!-- vendeur -->
				<tr class="row-child">
					<ng-container *ngFor="let col of columns; index as i; trackBy: indexTracker">

						<ng-container *ngIf="i < 12">
							<ng-container *ngIf="
								rowData.objectifs_vendeurs[i].readonly
								||
									!rowData.objectifs_vendeurs[i].present
									&& rowData.objectifs_vendeurs[i].ove_ca_prise_commande_htple == null
							">
								<td
									class="text-right col-mois readonly"
									[ngClass]="{'invalid-cell': rowData.objectifs_vendeurs[i].invalid}"
									[title]="rowData.objectifs_vendeurs[i].invalid? 'Le collaborateur n\'est pas présent sur ce mois' : ''"
								>
									<number-display
										[value]="rowData.objectifs_vendeurs[i].ove_ca_prise_commande_htple"
										type="currency"
										numberFormat=".0-0"
									></number-display>
								</td>
							</ng-container>
							<ng-container *ngIf="
								!rowData.objectifs_vendeurs[i].readonly
								&& (
									rowData.objectifs_vendeurs[i].present
									|| rowData.objectifs_vendeurs[i].ove_ca_prise_commande_htple != null
								)
							">
								<td
									class="text-right col-mois cell-input"
									[ngClass]="{'invalid-cell': rowData.objectifs_vendeurs[i].invalid}"
									[ttEditableColumn]="rowData.objectifs_vendeurs[i]"
									[ttEditableColumnField]="rowData.objectifs_vendeurs[i].ove_mois"
								>
									<p-treeTableCellEditor>
										<ng-template pTemplate="input">
											<div class="p-input-icon-right input-container">
												<i class="pi ff-reset">€</i>
												<input
													type="text"
													pInputText
													gzInputNumberFormatter
													[(ngModel)]="rowData.objectifs_vendeurs[i].ove_ca_prise_commande_htple"
													[ngModelOptions]="{standalone: true}"
													[decimals]="2"
													[currency]="true"
													class="text-right"
												>
											</div>
										</ng-template>
										<ng-template pTemplate="output">
											<number-display
												[value]="rowData.objectifs_vendeurs[i].ove_ca_prise_commande_htple"
												type="currency"
												numberFormat=".0-0"
											></number-display>
										</ng-template>
									</p-treeTableCellEditor>

								</td>
							</ng-container>
						</ng-container>

						<ng-container *ngIf="i == 12">
							<td class="text-right col-mois">
								<number-display
									[value]="rowData.total"
									type="currency"
									numberFormat=".0-0"
								></number-display>
							</td>
						</ng-container>

					</ng-container>
				</tr>
			</ng-container>

		</ng-template>

	</p-treeTable>
</div>
