import { Pipe, PipeTransform } from '@angular/core';

import { ConfigService } from '@global/config.service';

@Pipe({name: 'typeEvenement'})
export class TypeEvenementPipe implements PipeTransform {

	constructor(private configService: ConfigService) {}

	transform(code: string): string {
		if (this.configService.config.types_evenements && this.configService.config.types_evenements[code]) {
			return this.configService.config.types_evenements[code];
		}
		return code;
	}
}
