import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { TagModule } from 'primeng/tag';

import { GlobalModule } from '@global/global.module';
import {
	Prestataire,
	AuditPrestataire,
	PointControlePrestataire,
} from '@app/moa/suivi-prestataires/prestataire.model';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { AuditPrestataireDialogComponent } from '@app/moa/suivi-prestataires/audit-prestataire-dialog';
import { PieceJointeDialogComponent } from '@app/moa/suivi-prestataires/piece-jointe-dialog';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { statutsPointControlePrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { extractFieldValues, ExtensibleObject } from '@helpers/utils';
import { AuthService } from '@app/auth/auth.service';
import { BackButtonModule } from '@app/_global/back-button';
import { EventManagerService } from '@app/_global/event-manager.service';

@Component({
	selector: 'audits-prestataire',
	templateUrl: './audits-prestataire.html',
	providers: [],
})
export class AuditsPrestataireComponent {

	@Input() prestataire: Prestataire;

	loading: boolean = false;
	loadingPieceJointe: any = {};

	droitsCanEdit: string[] = ['ag', 'chef_ventes', 'manager'];
	canEdit: boolean = false;

	values: AuditPrestataire[] = [];

	today: Date = new Date();
	currentYear: number = this.today.getFullYear();
	choixAnnee: number[] = [];

	expandedRowKeys: ExtensibleObject = {};

	statutsPointControlePrestataire: any[] = statutsPointControlePrestataire;

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private prestataireService: PrestataireService,
		private storageService: StorageService,
	) {

	}

	ngOnInit() {
		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit) && this.prestataire.prs_actif;
		this.load(true);
	}

	prepareChoixAnnee() {
		this.choixAnnee = [];
		let tmp: number[] = [];
		// si on est avant mai, N-1, N
		// si on est en mai ou après, N, N+1
		if (this.today.getMonth() < 4) {
			tmp.push(
				this.today.getFullYear() -1,
				this.today.getFullYear()
			);
		}
		else {
			tmp.push(
				this.today.getFullYear(),
				this.today.getFullYear() + 1
			);
		}

		// on enlève les années ayant déjà des audits
		let existing: number[] = extractFieldValues(this.values, 'apr_annee');
		for(let i = tmp.length -1; i >= 0; i--) {
			if (existing.includes(tmp[i])) {
				tmp.splice(i, 1);
			}
		}

		this.choixAnnee.push(...tmp);
	}

	load(showLoading: boolean = false) {
		if (showLoading) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
		}

		this.prestataireService.getAuditsPrestataire(this.prestataire.prs_id)
		.subscribe({
			next: (response: any) => {
				this.values = response;
				// ouverture auto des sous-niveaux
				this.values.forEach((one: AuditPrestataire) => {
					this.expandedRowKeys[one.apr_id] = true;
				})
				if (this.canEdit) {
					this.prepareChoixAnnee();
				}
			}
		})
		.add(() => { this.loading = false; });

	}

	showAuditPrestataireDialog(auditPrestataire?: AuditPrestataire, field?: string) {
		let header: string = auditPrestataire? `Contrôle annuel ${auditPrestataire.apr_annee}` : 'Nouveau contrôle annuel';
		const prestataire: Prestataire = this.prestataire;
		header = `${header} pour ${prestataire.prs_societe}`;
		if (!auditPrestataire) auditPrestataire = new AuditPrestataire(this.prestataire.prs_id);
		const ref = this.dialogService.open(AuditPrestataireDialogComponent, {
			data: {
				auditPrestataire: auditPrestataire,
				field: field,
				choixAnnee: this.choixAnnee,
				prestataire: this.prestataire
			},
			header: header,
			styleClass: 'max-w-30rem'
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.load();
			}
		});
	}

	deleteAuditPrestataire(auditPrestataire: AuditPrestataire) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer le contôle annuel ${auditPrestataire.apr_annee} de ${auditPrestataire.prestataire.prs_societe} ?
			<br><br>Cela supprimera également les pièces-jointes liées à ce contrôle annuel.
			`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.prestataireService.deleteAuditPrestataire(auditPrestataire)
				.subscribe({
					next: () => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Audit supprimé'});
						this.load();
					}
				})
			},
		});
	}

	updateStatut(auditPrestataire: AuditPrestataire, detail: PointControlePrestataire) {
		this.prestataireService.postDetailAuditPrestataire(auditPrestataire, detail)
		.subscribe({
			next: () => { this.load(); }
		})
	}

	downloadPieceJointe(auditPrestataire: AuditPrestataire, detail: PointControlePrestataire) {
		this.loadingPieceJointe[detail.pcp_id] = true;
		this.prestataireService.downloadPieceJointeAuditPrestataire(auditPrestataire, detail)
		.subscribe()
		.add(() => {
			delete this.loadingPieceJointe[detail.pcp_id];
		})
	}

	deletePieceJointe(auditPrestataire: AuditPrestataire, detail: PointControlePrestataire) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer cette pièce jointe ?
				<br><br>Prestataire : <b>${auditPrestataire.prestataire.prs_societe}</b>
				<br><br>Point de contrôle : <b>${detail.pcp_libelle}</b>
			`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.prestataireService.deletePieceJointeAuditPrestataire(auditPrestataire, detail)
				.subscribe({
					next: () => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Pièce-jointe supprimée'});
						this.load();
					}
				})
			},
		});
	}

	showPieceJointeDialog(auditPrestataire: AuditPrestataire, pointControle: PointControlePrestataire) {
		const header: string = 'Ajout de pièce jointe';
		const ref = this.dialogService.open(PieceJointeDialogComponent, {
			data: {
				auditPrestataire: auditPrestataire,
				pointControle: pointControle,
			},
			header: header,
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.load();
			}
		});
	}

}
@NgModule({
	declarations: [
		AuditsPrestataireComponent
	],
	exports: [
		AuditsPrestataireComponent
	],
	imports: [
		CommonModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		TagModule,
		GlobalModule,
		PrintButtonModule,
		TitreModuleModule,
		BackButtonModule,
	],
})
export class AuditsPrestataireModule { }
