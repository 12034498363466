import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment, enseigne } from '@environments/environment';

@Injectable()
export class ResponseTypeToBlobInterceptor implements HttpInterceptor {

	pattern = /(\?|&)asBlob($|&|=true)/;

	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isApiUrl = request.url.startsWith(`${environment[enseigne].api}`);
		// if url contains "asBlob" in its query params, then change the response type
		if (isApiUrl) {
			if (this.pattern.test(request.url) || request.params.has('asBlob')) {
				request = request.clone({
					responseType: 'blob',
				});
			}
		}
		return next.handle(request);
	}
}
