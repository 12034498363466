import { Component, NgModule, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { BlockableUI } from 'primeng/api';
import { TableModule } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'bloc-panier',
	templateUrl: './bloc-panier.html',
	providers: []
})
export class BlocPanierComponent implements BlockableUI {

	@Input() loading: boolean;
	@Input() valeursN: any;
	@Input() valeursPast: any;

	@Output() onShowDetailVentesClick: EventEmitter<any> = new EventEmitter();

	indicateurs = [
		'avg_vente_htple',
		'avg_devis_htple',
		'separator',
		'mt_budget_ttc',
		'mt_devis_ttc',
	];

	constructor(private elementRef: ElementRef) {

	}

	ngOnInit() {

	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}

	showDetailVentes() {
		this.onShowDetailVentesClick.emit();
	}


}
@NgModule({
	declarations: [
		BlocPanierComponent
	],
	exports: [
		BlocPanierComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		ButtonModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		NumberDisplayModule,
	],
})
export class BlocPanierModule { }
