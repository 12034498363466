import { TrackedObject, DeletableTrackedObject } from '@helpers/tracked-object';
import { Magasin } from '@app/magasin/magasin.service';


export type SeuilBareme = {
	objectif_taux_amortissement_mini: number,
	objectif_taux_amortissement_maxi: number,
	taux: number
}

export type BaremeFinContrat = SeuilBareme[];

export class FournisseurExposition {
	foe_code: string;
	foe_nom: string;
	foe_regroupement: string;
	foe_explication: string|null;
	foe_taux_amortissement_contrat: number|null;
	foe_duree_amortissement_contrat: number|null;
	foe_taux_amortissement_renouvellement: number|null;
	foe_duree_amortissement_renouvellement: number|null;
	foe_option_specifique: string|null;
	foe_taux_amortissement_option_specifique: number|null;
	foe_duree_amortissement_option_specifique: number|null;
	foe_type_fin_contrat: string|null;
	foe_bareme_fin_contrat: BaremeFinContrat|null;
	foe_actif: boolean;

	label?: string;
}


export class ContratAmortissementExposition extends DeletableTrackedObject {
	cae_id: number;
	mag_id: number;
	foe_code: string;
	cae_intitule: string;
	cae_date_debut_contrat: Date;
	cae_premier_contrat: boolean = false;
	cae_option_specifique: string|null;
	cae_mt_a_amortir: number;
	cae_coef_amortissement: number;
	cae_duree_contrat: number;
	cae_contrat_termine: boolean;
	cae_commentaire: string;
	cae_type_fin_contrat: string;
	cae_bareme_fin_contrat: BaremeFinContrat;

	magasin: Magasin;
	fournisseur: FournisseurExposition;

	date_fin_contrat: Date;
	objectif: number;
	reste_a_faire: number;
	taux_amortissement: number;
	mt_fin_contrat: number;

	pourcentageDureeRestante: number;

	constructor(mag_id: number) {
		super();
		this.mag_id = mag_id;
	}
}

export class RepartitionAchatsMeubles {
	[key: string]: any;
	mt_achats_meubles_factures_ht_nobilia: number = 0;
	mt_achats_meubles_factures_ht_schuller: number = 0;
	mt_achats_meubles_factures_ht_burger: number = 0;
	mt_achats_meubles_factures_ht_nobi_mtk: number = 0;
	mt_achats_meubles_factures_ht_nobidirekt: number = 0;
	mt_achats_meubles_factures_ht_fournisseurs_mobilier: number = 0;
	taux_achats_meubles_factures_nobilia: number = 0;
	taux_achats_meubles_factures_schuller: number = 0;
	taux_achats_meubles_factures_burger: number = 0;
	taux_achats_meubles_factures_nobi_mtk: number = 0;
	taux_achats_meubles_factures_nobidirekt: number = 0;

	label: string;
	isTotal: boolean = false;
}
