<div>
	<p-table
		[autoLayout]="true"
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines"
	>
	<ng-template pTemplate="header">
			<tr>
				<th class="clear-cell"></th>
				<th class="text-center nowrap" *ngIf="valeursN">
					Année N
				</th>
				<th class="text-center nowrap" *ngIf="valeursPast">
					Année N-1
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body">
			<ng-container *ngFor="let ind_code of indicateurs">

				<ng-container *ngIf="ind_code == 'separator'">
					<tr class="separator"><td class="clear-cell" colspan="100"></td></tr>
				</ng-container>

				<ng-container *ngIf="ind_code != 'separator'">
					<tr>
						<th class="cell-th text-right pr-3">
							<indicateur-label-display [ind_code]="ind_code"></indicateur-label-display>
						</th>
						<td class="text-right" *ngIf="valeursN">
							<number-display
								[value]="valeursN[ind_code]"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</td>
						<td class="text-right" *ngIf="valeursPast">
							<number-display
								[value]="valeursPast[ind_code]"
								type="currency"
								numberFormat=".0-0"
							></number-display>
						</td>
					</tr>
				</ng-container>

			</ng-container>
		</ng-template>
	</p-table>
</div>
