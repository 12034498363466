import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { NiveauCompetence } from '@app/moa/referentiel-competences/referentiel-competences.model';
import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';

@Injectable({providedIn: 'root'})
@Pipe({name: 'niveauCompetence'})
export class NiveauCompetencePipe implements PipeTransform {

	constructor(private referentielCompetencesService: ReferentielCompetencesService) {}

	transform(code: string): string {
		const found: NiveauCompetence|undefined = this.referentielCompetencesService.niveauxCompetences.find((one: NiveauCompetence) => { return one.nic_code == code; });
		if (found) {
			return found.nic_libelle;
		}
		return code;
	}
}
