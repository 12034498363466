import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

@Component({
	selector: 'creation-qfq-dialog',
	templateUrl: './creation-qfq-dialog.html'
})
export class CreationQuiFaitQuoiDialogComponent implements OnInit, IEventListener {

	qft_libelle: string = '';
	groupe_libelle: string;

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
	) {

		this.groupe_libelle = config.data.groupe_libelle;
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	submit() {
		this.dialogRef.close(this.qft_libelle);
	}

	cancel() {
		this.dialogRef.close();
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
	],
	exports: [CreationQuiFaitQuoiDialogComponent],
	declarations: [CreationQuiFaitQuoiDialogComponent]
})
export class CreationQuiFaitQuoiDialogModule { }

