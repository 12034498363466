import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';

import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';

import { ReferentielCompetencesService } from '@app/moa/referentiel-competences/referentiel-competences.service';
import { TitreModuleModule } from '@app/titre-module';
import { BackButtonModule } from '@global/back-button';

import { AuthService } from '@app/auth/auth.service';
import {
	CategorieCompetenceCollaborateur,
	CompetenceCollaborateur,
	CritereEvaluationCollaborateur,
	EvaluationCollaborateur,
	LigneFormulaireEvaluationCollaborateur,
	SousCategorieCompetenceCollaborateur,
} from '@app/moa/referentiel-competences/referentiel-competences.model';
import { PrintButtonModule } from '@app/print-button';

import { NiveauCompetenceSelectorModule } from '../niveau-competence-selector';
import { UIRouterGlobals } from '@uirouter/core';


@Component({
	selector: 'evaluation-collaborateur',
	templateUrl: './evaluation-collaborateur.html',
	providers: [],
})
export class EvaluationCollaborateurComponent {

	@Input('evaluation_collaborateur') evaluation: EvaluationCollaborateur;

	mag_id: number;

	loading: boolean = false;

	values: LigneFormulaireEvaluationCollaborateur[];

	droitsCanEdit: string[] = ['animateur', 'manager', 'chef_ventes'];
	canEdit: boolean = false;

	constructor(
		private authService: AuthService,
		private referentielCompetencesService: ReferentielCompetencesService,
		private uiRouterGlobals: UIRouterGlobals
	) {
		this.mag_id = uiRouterGlobals.params.mag_id;
	}

	ngOnInit() {
		this.canEdit =
			this.authService.checkIfHasRight(this.droitsCanEdit)
			&& this.evaluation.uti_id != this.authService.user!.uti_id
			&& !!this.evaluation.collaborateur.present
		;
		this.evaluationToArray();
	}

	submit() {
		this.referentielCompetencesService.putEvaluationCollaborateur(this.mag_id, this.evaluation)
		.subscribe();
	}

	evaluationToArray() {
		let tmp: LigneFormulaireEvaluationCollaborateur[] = [];
		this.evaluation.evc_questionnaire.categories.forEach((categorie: CategorieCompetenceCollaborateur, indexCategorie: number) => {
			let rowGroupCategorie: string = categorie.ordre.toString().padStart(4, '0');
			tmp.push({
				categorie: categorie,
				rowGroup: rowGroupCategorie,
				rowGroupLabel: `${categorie.libelle}`
			});

			categorie.sous_categories.forEach((sous_categorie: SousCategorieCompetenceCollaborateur, indexSousCategorie: number) => {

				sous_categorie.competences.forEach((competence: CompetenceCollaborateur, indexCompetence: number) => {

					let rowGroupSubCat: string = '';
					rowGroupSubCat += rowGroupCategorie + '-' + sous_categorie.ordre.toString().padStart(4, '0');

					competence.criteres_evaluations.forEach((critere: CritereEvaluationCollaborateur, indexCritere: number) => {
						tmp.push({
							categorie: categorie,
							sous_categorie: sous_categorie,
							competence: competence,
							critere_evaluation: critere,
							rowGroup: rowGroupSubCat,
							rowGroupLabel: `${sous_categorie.libelle}`,
							firstCritere: indexCritere == 0,
						});

					});
				});
			});
		});

		this.values = [...tmp];
	}
}


@NgModule({
	declarations: [
		EvaluationCollaborateurComponent
	],
	exports: [
		EvaluationCollaborateurComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		TableModule,
		GlobalModule,
		TitreModuleModule,
		BackButtonModule,
		PrintButtonModule,
		NiveauCompetenceSelectorModule,
	],
})
export class EvaluationCollaborateurModule { }
