<form class="" #planActionsForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-12">
			<label>Libellé</label>
			<input
				type="text"
				pInputText
				name="pla_libelle"
				[(ngModel)]="planActions.pla_libelle"
				maxlength="100"
				[disabled]="loading"
				[required]="true"
			>
		</div>

		<div class="field col-fixed">
			<label>Objectif chiffré</label>
			<input
				type="text"
				pInputText
				name="pla_chiffre"
				[(ngModel)]="planActions.pla_chiffre"
				[disabled]="loading"
				[required]="true"
			>
		</div>
	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button" pButton
		class="btn p-button-sm"
		label="Enregistrer"
		[disabled]="!planActionsForm.form.valid || loading"
		(click)="submit()"
	></button>
</div>
