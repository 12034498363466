import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

import { AchatsFournisseursService } from '@app/moa/achats-fournisseurs/achats-fournisseurs.service';
import { AuthService } from '@app/auth/auth.service';
import { ContratAmortissementExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { FournisseurExposition } from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { FournisseurExpositionSelectorModule } from '@app/moa/achats-fournisseurs/fournisseur-exposition-selector';
import { GlobalModule } from '@global/global.module';
import { clone, simpleComparison } from '@helpers/utils';


@Component({
	selector: 'contrat-amortissement-exposition-dialog',
	templateUrl: './contrat-amortissement-exposition-dialog.html'
})
export class ContratAmortissementExpositionDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	// fournisseur: FournisseurExposition;
	pristine: ContratAmortissementExposition;
	contrat: ContratAmortissementExposition;
	fournisseur: FournisseurExposition;
	mag_id: number;

	today: Date = new Date();
	minDate: Date = new Date(this.today.getFullYear(), 0, 1);

	droitsCanEdit: string[] = ['animateur', 'manager'];
	canEdit: boolean = false;

	optionsSpecifiques: any[] = [];

	constructor(
		private authService: AuthService,
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private achatsFournisseursService: AchatsFournisseursService,
	) {

		this.contrat = clone(config.data.contratAmortissementExposition);
		this.pristine = clone(this.contrat);
		this.fournisseur = clone(this.contrat.fournisseur);
		this.mag_id = config.data.mag_id;

		this.minDate.setMonth(0);
		this.minDate.setDate(1);
		// exceptionnellement, on permet de créer les contrats 2023 en 2024, pour reprendre l'historique
		if (this.today.getFullYear() == 2024) {
			this.minDate.setFullYear(2023);
		}

		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	onFournisseurSelectionChange(fournisseur: FournisseurExposition) {
		if (fournisseur) {
			this.prepareOptionsSpecifiques(fournisseur);
		}
	}

	prepareOptionsSpecifiques(fournisseur: FournisseurExposition) {
		this.contrat.cae_option_specifique = null;
		this.fournisseur = fournisseur;
		this.optionsSpecifiques = [];
		if (fournisseur.foe_option_specifique) {
			this.optionsSpecifiques.push(
				{ value: null, label: 'Sans option' },
				{ value: fournisseur.foe_option_specifique, label: fournisseur.foe_option_specifique }
			);
		}
	}

	submit() {
		if (simpleComparison(this.pristine, this.contrat)) {
			this.dialogRef.close(null);
		}
		else {
			this.loading = true;
			let apiCall: Observable<any>;

			if (!this.contrat.cae_id) {
				apiCall = this.achatsFournisseursService.postContratAmortissementExposition(this.contrat);
			}
			else {
				apiCall = this.achatsFournisseursService.putContratAmortissementExposition(this.contrat);
			}

			apiCall.subscribe({
				next: (response: any) => {
					this.dialogRef.close(this.contrat);
				}
			})
			.add(() => { this.loading = false; });
		}
	}




}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		CheckboxModule,
		DropdownModule,
		InputNumberModule,
		InputTextareaModule,
		InputTextModule,
		MessageModule,
		OverlayPanelModule,
		TooltipModule,
		GlobalModule,
		FournisseurExpositionSelectorModule,
	],
	exports: [ContratAmortissementExpositionDialogComponent],
	declarations: [ContratAmortissementExpositionDialogComponent]
})
export class ContratAmortissementExpositionDialogModule { }

