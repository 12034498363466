<form #formCreationQfq="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-12 flex flex-column">
			<label>Groupe de tâches</label>
			<input
				type="text"
				pInputText
				name="groupe_libelle"
				[(ngModel)]="groupe_libelle"
				[readOnly]="true"
				[required]="true"
			>
		</div>

		<div class="field col-12 flex flex-column">
			<label for="libelle">Libellé tâche</label>
			<input
				type="text"
				pInputText
				name="libelle"
				[(ngModel)]="qft_libelle"
				maxlength="200"
				[required]="true"
			>
		</div>
	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			[disabled]="qft_libelle.length == 0"
			label="Créer"
			(click)="submit()"
		></button>

		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Annuler"
			(click)="cancel()"
		></button>
	</div>
</form>
