<div>

	<div class="flex flex-column align-items-end">

		<div class="flex align-items-end cockpit-large" *ngIf="valeursN && valeursN.ca_prise_commande_htple">
			<label class="font-semibold">CA&nbsp;:</label>
			<div class="">
				&nbsp;
				<number-display
					[value]="valeursN?.ca_prise_commande_htple"
					type="currency"
					numberFormat=".0-0"
				></number-display>
			</div>
		</div>
		<div class="flex align-items-end cockpit-semi-large" *ngIf="valeursN && valeursN.ca_prise_commande_htple_b2b">
			<label class=""><indicateur-label-display ind_code="label_ca_prise_commande_htple_b2b"></indicateur-label-display>&nbsp;:</label>
			<div class="">
				&nbsp;
				<number-display
					[value]="valeursN.ca_prise_commande_htple_b2b"
					type="currency"
					[hideZero]="false"
					numberFormat=".0-0"
				></number-display>
			</div>
		</div>
	</div>

	<div class="taux_mt_ventes_htple_mt_objectif_htple">
		<div class="flex justify-content-between">
			<div class="flex align-items-end">
				<label class="font-semibold">Mt Ventes/Objectifs&nbsp;:</label>
				<div>
					&nbsp;
					<indicateur-value-display
						[value]="valeursN?.taux_mt_ventes_htple_mt_objectif_htple"
						ind_code="taux_mt_ventes_htple_mt_objectif_htple"
					></indicateur-value-display>
				</div>
			</div>

		</div>

		<ng-container *ngIf="canDisplayTauxVentesObjectif">
			<p-progressBar
				[value]="valeursN?.taux_mt_ventes_htple_mt_objectif_htple"
				[showValue]="false"
			></p-progressBar>
			<div class="flex justify-content-between">
				<div class="flex align-items-end">

				</div>

				<div class="flex align-items-end">
					<label class="font-semibold">Objectif&nbsp;:</label>
					<div>
						&nbsp;
						<number-display
							[value]="valeursN?.objectif_ca_prise_commande_htple"
							type="currency"
							numberFormat=".0-0"
						></number-display>
					</div>
				</div>
			</div>
		</ng-container>
	</div>


	<div class="taux_mt_ventes_htple_mt_ventes_htple_n_moins_1">
		<div class="flex justify-content-between">
			<div class="flex align-items-end">
				<label class="font-semibold">Mt Ventes/CA N-1&nbsp;:</label>
				<div>
					&nbsp;
					<indicateur-value-display
						[value]="valeursN?.taux_mt_ventes_htple_mt_ventes_htple_n_moins_1"
						ind_code="taux_mt_ventes_htple_mt_ventes_htple_n_moins_1"
						labelInfinity="Indisponible"
					></indicateur-value-display>
				</div>
			</div>

		</div>

		<ng-container *ngIf="canDisplayTauxVentesCAPast">
			<p-progressBar
				[value]="valeursN?.taux_mt_ventes_htple_mt_ventes_htple_n_moins_1"
				[showValue]="false"
			></p-progressBar>
			<div class="flex justify-content-between">
				<div class="flex align-items-end">

				</div>

				<div class="flex align-items-end">
					<label class="font-semibold">CA N-1&nbsp;:</label>
					<div>
						&nbsp;
						<number-display
							[value]="valeursPast?.ca_prise_commande_htple"
							type="currency"
							numberFormat=".0-0"
						></number-display>
					</div>
				</div>
			</div>
		</ng-container>
	</div>




	<div class="taux_mt_ventes_htple_mt_devis_htple">
		<div class="flex justify-content-between">
			<div class="flex align-items-end">
				<label class="font-semibold">Mt Ventes/Mt Devis&nbsp;:</label>
				<div>
					&nbsp;
					<indicateur-value-display
						[value]="valeursN?.taux_mt_ventes_htple_mt_devis_htple"
						ind_code="taux_mt_ventes_htple_mt_devis_htple"
						labelInfinity="Indisponible"
					></indicateur-value-display>
				</div>
			</div>

		</div>

		<ng-container *ngIf="canDisplayTauxVentesDevis">
			<p-progressBar
				[value]="valeursN?.taux_mt_ventes_htple_mt_devis_htple"
				[showValue]="false"
			></p-progressBar>

			<div class="flex justify-content-between">
				<div class="flex align-items-endn">

				</div>

				<div class="flex align-items-endn">
					<label class="font-semibold">Devis&nbsp;:</label>
					<div>
						&nbsp;
						<number-display
							[value]="valeursN?.mt_devis_htple"
							type="currency"
							numberFormat=".0-0"
						></number-display>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
