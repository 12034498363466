import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TableModule } from '@app/primeng-overrides/table';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { TagModule } from 'primeng/tag';


import { PrintButtonModule } from '@app/print-button';
import { TitreModuleModule } from '@app/titre-module';
import { GlobalModule } from '@global/global.module';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { QuiFaitQuoiService } from './qui-fait-quoi.service';
import { QuiFaitQuoi } from './qui-fait-quoi.model';
import { StorageService } from '@app/_global/storage.service';
import { EventManagerService } from '@app/_global/event-manager.service';
import { AuthService } from '@app/auth/auth.service';
import { CreationQuiFaitQuoiDialogComponent } from './creation-qfq-dialogue';

import { NumberDisplayModule } from '@helpers/number-display';
import { contrastingColor, numericSort } from '@app/_helpers/utils';


interface GroupState {
	key: number;
	qgt_id: number;
	label: string;
	group: boolean,
	children: QuiFaitQuoiState[],
	ordre: number,

	backgroundColor: string
	textColor: string
}

interface QuiFaitQuoiState extends QuiFaitQuoi {
	editing?: boolean,
	creation?: boolean
	newQftLibelle?: string
}

@Component({
	selector: 'qui-fait-quoi',
	templateUrl: './qui-fait-quoi.html',

})
export class QuiFaitQuoiComponent implements OnInit {

	loading: boolean = false;
	totalRecords: number;
	expandedRowKeys: {[key: string]: any} = {};

	mag_id: number|null = null;
	values: GroupState[];
	printing = false;

	canEdit: boolean;
	droitsCanEdit: string[] = ['manager'];

	responsibilityFields = [
		{
			name: 'qfm_franchise',
			preconisation: 'qft_preconisation_franchise',
			columnName: 'Franchisé'
		},
		{
			name: 'qfm_chef_ventes',
			preconisation: 'qft_preconisation_chef_ventes',
			columnName: 'Chef de ventes'
		},
		{
			name: 'qfm_vendeurs',
			preconisation: 'qft_preconisation_vendeurs',
			columnName: 'Vendeurs'
		},
		{
			name: 'qfm_ag',
			preconisation: 'qft_preconisation_ag',
			columnName: 'Assis. de G.'
		},
		{
			name: 'qfm_poseurs',
			preconisation: 'qft_preconisation_poseurs',
			columnName: 'Poseurs'
		},
		{
			name: 'qfm_livreurs',
			preconisation: 'qft_preconisation_livreurs',
			columnName: 'Livreurs'
		}
	]
	responsibilityDropdownChoices = [
		{
			value: 'T',
			label: 'Titulaire'
		},
		{
			value: 'S',
			label: 'Suppléant'
		},
		{
			value: null,
			label: '-'
		},
	]

	constructor(
		private quiFaitQuoiService: QuiFaitQuoiService,
		private storageService: StorageService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private authService: AuthService,
		private dialogService: DialogService
	) {
	}

	ngOnInit(){
		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);
	}

	onRowExpand(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	onRowCollapse(event: any) {
		this.storageService.setForCurrentState('expandedRowKeys', this.expandedRowKeys);
	}

	load(event?: any) {
		if (this.mag_id === null) {
			return;
		}

		this.loading = true;
		this.quiFaitQuoiService.getListe(this.mag_id)
		.subscribe({
			next: (response: QuiFaitQuoi[]) => {
				let groupsById: Record<number, GroupState> = {};

				for (let quiFaitQuoi of response) {
					let group = groupsById[quiFaitQuoi.qgt_id];
					if (group === undefined) {
						group = {
							children: [],
							group: true,
							qgt_id: quiFaitQuoi.qgt_id,
							key: quiFaitQuoi.qgt_id,
							label: quiFaitQuoi.qgt_libelle,
							ordre: quiFaitQuoi.qgt_ordre,
							textColor: quiFaitQuoi.qgt_couleur ? contrastingColor(quiFaitQuoi.qgt_couleur.toLowerCase()) : '',
							backgroundColor: quiFaitQuoi.qgt_couleur ?? '',
						};
						groupsById[quiFaitQuoi.qgt_id] = group;
					}
					group.children.push(quiFaitQuoi);
				}

				let groups = Object.values(groupsById);
				numericSort(groups, 'ordre')
				this.values = groups;

				this.expandedRowKeys = {};
				for (let group of this.values) {
					this.expandedRowKeys[group.key] = true;
				}
			},
		})
		.add(() => {
			this.loading = false;
		})
	}

	formatResponsiblity(responsibility: string){
		switch (responsibility) {
			case 'T':
				return 'Titulaire';
			case 'S':
				return 'Suppléant';
			default:
				return responsibility;
		}
	}

	updateTask(quiFaitQuoi: QuiFaitQuoi){
		if (this.loading || this.mag_id === null) {
			return;
		}
		this.quiFaitQuoiService.updateSingleQuiFaitQuoi(quiFaitQuoi, this.mag_id)
		.subscribe();
	}

	deleteTask(quiFaitQuoi: QuiFaitQuoiState){
		if (this.loading || this.mag_id === null) {
			return;
		}

		if (quiFaitQuoi.creation) {
			for (let group of this.values) {
				if (group.qgt_id == quiFaitQuoi.qgt_id) {
					group.children = group.children.filter(task => task != quiFaitQuoi);
					break;
				}
			}
			return;
		}

		let mag_id = this.mag_id;

		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Confirmez-vous la suppression de la tâche ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.quiFaitQuoiService.deleteSingleQuiFaitQuoi(quiFaitQuoi, mag_id)
				.subscribe({
					complete: () => {
						this.eventManager.emit('toast', {severity: 'success', summary: `Tâche supprimée`});
						this.load();
					}
				});
			}
		});
	}

	addSingleQuiFaitQuoi(group: GroupState){
		if (this.loading || this.mag_id === null) {
			return;
		}
		let mag_id = this.mag_id;

		const ref = this.dialogService.open(CreationQuiFaitQuoiDialogComponent, {
			data: {
				groupe_libelle: group.label,
			},
			header: 'Nouvelle tâche'
		});

		ref.onClose.subscribe((qft_libelle?: string) => {
			if (qft_libelle == undefined || qft_libelle.trim() == '') {
				return;
			}

			this.quiFaitQuoiService.createSingleQuiFaitQuoi(qft_libelle!, group.qgt_id, mag_id)
			.subscribe({
				complete: () => {
					this.eventManager.emit('toast', {severity: 'success', summary: `Tâche créée`});
					this.load();
				}
			})
		});
	}

	cancelTaskEdition(quiFaitQuoi: QuiFaitQuoiState){
		this._editTask(quiFaitQuoi, false);
	}

	editTask(quiFaitQuoi: QuiFaitQuoiState){
		this._editTask(quiFaitQuoi, true);
	}

	_editTask(quiFaitQuoi: QuiFaitQuoiState, save: boolean){
		if (this.mag_id == null) {
			return;
		}

		if (quiFaitQuoi.editing && quiFaitQuoi.newQftLibelle != undefined) {
			quiFaitQuoi.editing = false;

			if (save) {
				let previousLibelle = quiFaitQuoi.qft_libelle;
				quiFaitQuoi.qft_libelle = quiFaitQuoi.newQftLibelle;
				this.quiFaitQuoiService.updateSingleQuiFaitQuoi(quiFaitQuoi, this.mag_id)
				.subscribe({
					complete: () => {
						this.load();
					},
					error: () => {
						quiFaitQuoi.qft_libelle = previousLibelle;
					}
				});
			}
		}
		else {
			quiFaitQuoi.editing = true;
			quiFaitQuoi.newQftLibelle = quiFaitQuoi.qft_libelle;
		}
	}

	isValidResponsibility(s: string){
		return s == 'T' || s == 'S'
	}

}

@NgModule({
	declarations: [
		QuiFaitQuoiComponent
	],
	exports: [
		QuiFaitQuoiComponent
	],
	imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    TableModule,
    GlobalModule,
    IndicateurValueDisplayModule,
    IndicateurTooltipModule,
    MagasinSelectorModule,
    NumberDisplayModule,
    PrintButtonModule,
    TitreModuleModule,
    DropdownModule,
	TagModule
],
})
export class QuiFaitQuoiModule { }
