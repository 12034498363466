<p-confirmDialog
	header="Confirmation"
	icon="pi pi-exclamation-triangle"
	styleClass="p-col-12 p-md-6"
	[breakpoints]="{'960px': '75vw', '640px': '100vw'}"
	[style]="{width: '50vw'}"
></p-confirmDialog>
<p-toast [preventOpenDuplicates]="true"></p-toast>
<ui-view></ui-view>
<p-scrollTop class="hide-print"></p-scrollTop>
