import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment, enseigne } from '@environments/environment';
import { AuthService } from '@app/auth/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authService.token;
		const isApiUrl =
			request.url.startsWith(environment[enseigne].api)
			|| request.url.startsWith(environment[enseigne].foc)
		;
		if (isApiUrl && token && token.access_token) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token.access_token}`
				}
			});
		}
		return next.handle(request);
	}
}
