import { Observable, Subscriber } from 'rxjs';
import html2canvas from 'html2canvas';

export const isNumeric = function (value: any): boolean {
	value = typeof(value) === 'string' ? value.replace(',', '.') : value;
	return !isNaN(parseFloat(value)) && isFinite(value) && Object.prototype.toString.call(value).toLowerCase() !== '[object array]';
};

export const isNumber = function (value: any): boolean {
	return typeof value === 'number' && isFinite(value);
}

export const isDateString = function(value: any): boolean {
	let parsed = Date.parse(value);
	return isNaN(value) && !isNaN(parsed);
};

export const isDate = function(value: any): boolean {
	return value instanceof Date || isDateString(value);
}

export const isObject = function(value: any): boolean {
  return value !== null && typeof value === 'object' && Array.isArray(value) === false;
}

export const isDefined = function(value: any): boolean {
	return typeof value != 'undefined';
}

export class ExtensibleObject {
	[key: string]: any
}

export const networkAvailable = function(): boolean {
	// if(window.cordova) {
	// 	return $cordovaNetwork.isOnline();
	// }
	// else{// otherwise we're on a desktop web browser
		return window.navigator.onLine;
	// }
};

export const isEmpty = function(value: Object): boolean {
	if (typeof value == 'undefined' || value === null) return true;
	for (let i in value) {
		return false;
	}
	return true;
};

export const simpleComparison = function(objA: Object, objB: Object): boolean {
	return JSON.stringify(objA) === JSON.stringify(objB);
};

export const addTrailingSlash = function(str: string): string {
	return (str.endsWith('/'))? str : str + '/';
};

export const removeTrailingSlash = function(str: string): string {
	return (str.endsWith('/'))? str.substr(0, str.length-1) : str;
};

export const extractFileExtension = function(filename: string, keepDot?: boolean): string|null {
	let dot = filename.lastIndexOf('.');
	let adjust = (keepDot)? 0 : 1;
	return (dot > -1)? filename.substr(dot + adjust).toLowerCase() : null;
};

export const extractBasename = function(filename: string): string {
	let dot = filename.lastIndexOf('.');
	return (dot > -1)? filename.substr(0, dot) : filename;
};

export const extractSlug = function(url: string): string {
	let str = (typeof url != 'undefined')? url : location.pathname;
	return removeTrailingSlash(str.substring(str.lastIndexOf('/', str.length - 2) + 1));
}

export const iOS = function(): boolean {
	var iDevices = [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	];
	if (!!navigator.platform) {
		while (iDevices.length) {
			if (navigator.platform === iDevices.pop()) { return true; }
		}
	}
	return false;
};

export const isChrome = function(): boolean {
	// please note,
	// that IE11 now returns undefined again for window.chrome
	// and new Opera 30 outputs true for window.chrome
	// but needs to check if window.opr is not undefined
	// and new IE Edge outputs to true now for window.chrome
	// and if not iOS Chrome check
	// @ts-ignore
	let isChromium = window.chrome;
	let winNav = window.navigator;
	let vendorName = winNav.vendor;
	// @ts-ignore
	let isOpera = typeof window.opr != 'undefined';
	let isIEedge = winNav.userAgent.indexOf('Edg') > -1;
	let isIOSChrome = winNav.userAgent.match('CriOS');

	if (isIOSChrome) {
		// is Google Chrome on IOS
		return false;
	} else if(
		typeof isChromium != 'undefined' &&
	  isChromium !== null &&
	  vendorName === 'Google Inc.' &&
	  isOpera === false &&
	  isIEedge === false
	) {
		// is Google Chrome
		return true;
	} else {
	 	// not Google Chrome
	 	return false;
	}
}

export const hasTouch = function(): boolean {
return 'ontouchstart' in document.documentElement
	|| navigator.maxTouchPoints > 0
	|| navigator.msMaxTouchPoints > 0;
};

export const translateFilesizeToBytes = function(str: string|number): number|null {
	if (typeof str === 'string') {
		if (/^\d+k[bo]?/i.test(str)) {
			return (parseFloat(str) * 1024);
		}
		else if (/^\d+m[bo]?/i.test(str)) {
			return (parseFloat(str) * 1048576);
		}
		else if (/^\d+g[bo]?/i.test(str)) {
			return (parseFloat(str) * 1073741824);
		}
		if (isNumeric(str)) {
			return parseFloat(str);
		}
		return null
	}
	return str;
};

export const uid = function(): string {
		let d = new Date().getTime();
		let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			let r = (d + Math.random()*16)%16 | 0;
			d = Math.floor(d/16);
			return (c=='x' ? r : (r&0x3|0x8)).toString(16);
		});
		return uuid;
	};

export const clone = function(obj: any): any {
	// Handle the 3 simple types, and null or undefined
	if (null == obj || 'object' != typeof obj) return obj;
	// Handle Date
	if (obj instanceof Date) {
		let copy: Date = new Date();
		copy.setTime(obj.getTime());
		return copy;
	}
	// Handle Array
	if (obj instanceof Array) {
		let copy: any[] = [];
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = clone(obj[i]);
		}
		return copy;
	}
	// Handle Object
	if (obj instanceof Object) {
		let copy: any = {};
		for (let attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
		}
		return copy;
	}
	throw new Error('Unable to copy obj! Its type isn\'t supported: ' + typeof obj);
}

export const simpleArrayFilter = function(arrayOfObj: any[], attr: string, value: any): any[] {
	const results: any[] =  [];
	if (!!!value) return arrayOfObj;
	arrayOfObj.forEach(one => {
		if (one.hasOwnProperty(attr) && one[attr].toLowerCase().indexOf(value.toLowerCase()) >= 0) {
			results.push(one);
		}
	});
	return results;
}

export const extractFieldValues = function(arrayOfObj: any[], attr: string): any[] {
	let results: any[] = [];
	for (let i = 0; i < arrayOfObj.length ; ++i) {
		results.push(arrayOfObj[i][attr]);
	}
	return results;
}

export const numericSort = function(array: number[]|any[], attr?: string): void {
	array.sort((a, b) => {
		if (attr) {
			if (a[attr] < b[attr]) return -1;
			if (a[attr] > b[attr]) return 1;
		}
		else {
			if (a < b) return -1;
			if (a > b) return 1;
		}
		return 0;
	});
}

export const stringSort = function(array: any[], attr: string): void {
	array.sort((a, b) => {
		if (!a[attr] && b[attr]) return -1;
		if (!a[attr] && !b[attr]) return 0;
		if (a[attr] && !b[attr]) return 1;
		return a[attr].localeCompare(b[attr]);
	});
}

export const hasDuplicates = function(array: any[]): boolean {
	let duplicates = array.reduce((acc, currentValue, index, dups) => {
		if(dups.indexOf(currentValue) != index && !acc.includes(currentValue)) acc.push(currentValue);
		return acc;
	}, []);
	return !!duplicates.length;
}

export const capitalize = function(str: string|null, allWords?: boolean): string|null {
	if (str) {
		if (allWords) {
			return str.split(' ').map(x => x[0].toUpperCase() + x.substr(1)).join(' ');
		}
		return str.charAt(0).toUpperCase() + str.substr(1);
	}
	return null;
}

export const simpleEmailValidation = function(email: string): boolean {
	const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA_Z0-9-]*[a-zA-Z0-9])?$/;
	return re.test(String(email).toLowerCase());
}

export const startOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate);
}

export const endOfMonth = function(aDate: Date): Date {
	return startOrEndOfMonth(aDate, true);
}

export const startOrEndOfMonth = function(aDate: Date, end?: boolean): Date {
	if (end) {
		return new Date(aDate.getFullYear(), aDate.getMonth() + 1, 0);
	}
	return new Date(aDate.getFullYear(), aDate.getMonth(), 1);
}

export const startOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate);
}

export const endOfDay = function(aDate: Date): Date {
	return startOrEndOfDay(aDate, true);
}

export const startOrEndOfDay = function(aDate: Date, end?: boolean): Date {
	let tmp = new Date(aDate);
	if (end) tmp.setHours(23,59,59,999);
	else tmp.setHours(0,0,0,0);
	return tmp;
}

export const arrayOfMonths = function(year: number, x: number, start?: number): Date[] {
	let offset = 0;
	if (start) {
		offset = start;
	}
	return Array.from({length: x}, (v, k) => {
		return new Date(year, k + offset);
	});
}

export const arrayOfMonthsFree = function(dateStart: Date, dateEnd: Date): Date[] {
	let result: Date[] = [];
	let looper: Date = new Date(dateStart);
	while (looper < dateEnd) {
		let tmp: Date = new Date(looper);
		startOfMonth(tmp);
		startOfDay(tmp);
		result.push(tmp);
		looper.setMonth(looper.getMonth() + 1);
	}
	return result;
}

export const arrayOfMonthsAcross = function(dateStart: Date, x: number): Date[] {
	let result: Date[] = [];
	let index = 0;
	let looper: Date = new Date(dateStart);
	while (index < x) {
		let tmp: Date = new Date(dateStart);
		tmp.setMonth(tmp.getMonth() + index);
		startOfMonth(tmp);
		startOfDay(tmp);
		result.push(tmp);
		index++;
	}
	return result;
}

export const monthFromNumber = function(month: number): Date {
	const months = arrayOfMonths(1970, 12);
	return months[month -1]
}

export const simpleUTCDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = aDate.getUTCDate().toString().padStart(2, '0');
		let m = (aDate.getUTCMonth() + 1 ).toString().padStart(2, '0');
		let y = aDate.getUTCFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		return `${y}-${m}-${d}T00:00:00Z`;
	}
	return aDate;
}

export const simpleUTCDateTimeToString = function(aDate: Date|string, dmy?: boolean): string {
	if (aDate instanceof Date) {
		let d = aDate.getUTCDate().toString().padStart(2, '0');
		let m = (aDate.getUTCMonth() + 1).toString().padStart(2, '0');
		let y = aDate.getUTCFullYear().toString();
		let h = (aDate.getUTCHours()).toString().padStart(2, '0');
		let min = (aDate.getUTCMinutes()).toString().padStart(2, '0');
		let s = (aDate.getUTCSeconds()).toString().padStart(2, '0');
		let datePart ;
		datePart = (dmy)? `${d}/${m}/${y}` : `${y}-${m}-${d}`;
		return `${datePart}T${h}:${min}:${s}Z`;
	}
	return aDate;
}

export const simpleDateToString = function(aDate: Date|string, short?: boolean, dmy?: boolean, utc?: boolean): string {
	if (utc) return simpleUTCDateToString(aDate, short, dmy);
	if (aDate instanceof Date) {
		let d = aDate.getDate().toString().padStart(2, '0');
		let m = (aDate.getMonth() + 1 ).toString().padStart(2, '0');
		let y = aDate.getFullYear().toString();
		if (short) {
			if (dmy) {
				return `${d}/${m}/${y}`;
			}
			return `${y}-${m}-${d}`;
		}
		let h = (aDate.getHours()).toString().padStart(2, '0');
		let min = (aDate.getMinutes()).toString().padStart(2, '0');
		let s = (aDate.getSeconds()).toString().padStart(2, '0');
		return `${y}-${m}-${d}T${h}:${min}:${s}`;
	}
	return aDate;
}

export const dateUTCToDateCurrentTimezone = function(aDate: Date): Date|null {
	if (aDate) {
		return new Date(aDate.getUTCFullYear(), aDate.getUTCMonth(), aDate.getUTCDate());
	}
	return null;
}

export const calculateMonthPriorDay = function(currentDate: Date, end: Date, dayNumber?: number): Date {
	const tippingDay = (dayNumber && dayNumber > 0)? dayNumber : 15;
	if (currentDate < end) {
		let tippingDate = new Date(currentDate);
		tippingDate.setDate(tippingDay);
		tippingDate = startOfDay(tippingDate);
		// if current date is after tipping date, take the month that's before tipping date
		if (currentDate < tippingDate) {
			end = startOfMonth(currentDate);
			end.setDate(0);
		}
		else end = endOfMonth(currentDate);
	}
	return end;
}

export const downloadUrl = function(url: string, filename?: string): void {
	let a = document.createElement('a');
	document.body.appendChild(a);
	a.setAttribute('style', 'display: none');
	a.href = url;
	a.download = (filename)? `${filename}` : '';
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export const getFilenameFromHttpResponse = function(httpResponse: any): string {
	let contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
	let result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
	return result.replace(/"/g, '');
}

export const createDownloadFromHttpResponse = function(httpResponse: any): void {
	const url = window.URL.createObjectURL(new Blob([httpResponse.body]));
	const filename = getFilenameFromHttpResponse(httpResponse);
	downloadUrl(url, filename);
}

export const copyToClipboard = function(content: string): boolean {
	let el = document.createElement('textarea');
	document.body.appendChild(el);
	el.setAttribute('style', 'display: none');
	el.setAttribute('id', 'copy');
	el.value = content;
	try {
		let iosCopyToClipboard = function(el: any) {
			let oldContentEditable = el.contentEditable,
				oldReadOnly = el.readOnly,
				range = document.createRange();
			el.contentEditable = true;
			el.readOnly = false;
			range.selectNodeContents(el);
			let s = window.getSelection();
			if (s) {
				s.removeAllRanges();
				s.addRange(range);
			}
			el.setSelectionRange(0, 999999);
			el.contentEditable = oldContentEditable;
			el.readOnly = oldReadOnly;
			document.execCommand('copy');
		};
		let copyListener = function(event: any) {
			document.removeEventListener('copy', copyListener, true);
			event.preventDefault();
			let clipboardData = event.clipboardData;
			clipboardData.clearData();
			clipboardData.setData('text/plain', content);
			clipboardData.setData('text/html', content);
		};
		iosCopyToClipboard(document.getElementById('copy')); // iOS workaround
		document.addEventListener('copy', copyListener, true);
		document.execCommand('copy');
	}
	catch(e) {
		console.log('could not copy to clipboard', e);
		return false;
	}
	finally{
		el.remove();
	}
	return true;
}

export const prepareQueryParams = function(event?: any, forDownload?: boolean) {

	let params: {[key: string]: any} = {};
	let options: {[key: string]: any} = {};

	function addIfNotNull(key: string, value: any) {
		if (typeof value != 'undefined' && value != null) params[key] = value;
	}

	if (event) {
		for (let prop in event) {
			switch (prop) {
				case 'multiSortMeta':
				case 'isTrusted':
					continue;
				case 'first':
					addIfNotNull('offset', event[prop]);
					break;
				case 'rows':
					addIfNotNull('limit', event[prop]);
					break;
				case 'sortField':
					addIfNotNull('order_by', event[prop]);
					break;
				case 'sortOrder':
					addIfNotNull('order', (event[prop] == 1)? 'ASC' : 'DESC');
					break;

				case 'filters':
					for (let field in event.filters) {
						if (field === 'global') continue;
						if (typeof event.filters[field].value != 'undefined' && event.filters[field].value != null) {
							addIfNotNull(field, event.filters[field].value)
						}
					}
					break;
				default:
					addIfNotNull(prop, event[prop]);
					break;
			}
		}

	}
	options.params = params;
	if (forDownload) {
		options['responseType'] = 'blob';
		options['observe'] = 'response';
	}
	return options;
}

export const prepareQueryParamsForDownload = function(event?: any) {
	return prepareQueryParams(event, true);
}

export const convertDateFields = function(obj: any, toDate?: boolean, utc?: boolean, short?: boolean){
	const dateAttributesRegex: RegExp = /(^|_)date(_|$)/;
	const dateAttributes: string[] = [
		// attributes that don't match the regex
	];

	for (let attr in obj) {
		if (obj.hasOwnProperty(attr)) {
			if (obj[attr] instanceof Object) {
				convertDateFields(obj[attr], toDate, utc);
			}
			if (obj[attr] instanceof Array) {
				for (let i = 0, len = obj[attr].length; i < len; i++) {
					if (obj[attr][i] instanceof Object) {
						convertDateFields(obj[attr][i], toDate, utc);
					}
				}
			}
			else {
				if (attr.match(dateAttributesRegex) || dateAttributes.indexOf(attr) > -1) {
					if (toDate && isDateString(obj[attr])) {
						if (!utc && obj[attr].indexOf('+') > -1) {
							// remove timezone
							obj[attr] = obj[attr].substr(0, obj[attr].indexOf('+'));
						}
						obj[attr] = new Date(obj[attr]);
					}
					else if (!toDate && obj[attr] instanceof Date) {
						obj[attr] = simpleDateToString(obj[attr], short, false, utc);
					}
				}
			}
		}
	}
	return obj;
}

export const convertDateFieldsToDate = function(obj: any, utc?: boolean, short?: boolean): void {
	return convertDateFields(obj, true, utc, short);
}

export const convertDateFieldsToString = function(obj: any, utc?: boolean, short?: boolean): void {
	return convertDateFields(obj, false, utc, short);
}

export const dateRangeEqualMonth = function(dateStart: Date, dateEnd: Date, multiMonths: boolean = false): boolean {
	if (dateStart.getDate() != 1) return false;
	let lastDay: Date;
	if (multiMonths) {
		lastDay = endOfMonth(dateEnd);
	}
	else {
		lastDay = endOfMonth(dateStart);
	}
	return simpleDateToString(lastDay, true) == simpleDateToString(dateEnd, true);
}

export const calculateTaux = function(obj: any, attr1: string, attr2: string, destination?: string, hundred?: boolean): number|null {

	let finalAttr = (destination)? destination : `taux_${attr1}_${attr2}`;

	if (isNumber(obj[attr1]) && isNumber(obj[attr2])) {

		if (obj[attr2] === 0) {
			obj[finalAttr] = (obj[attr1] === 0)? null : 1;
		}
		else obj[finalAttr] = obj[attr1] / obj[attr2];
		if (hundred) {
			obj[finalAttr] = obj[finalAttr] * 100;
		}
	}
	else {
		obj[finalAttr] = null;
	}
	return obj[finalAttr];
}

export const calculateTauxMarge = function(obj: any, attr1: string, attr2: string, destination?: string, hundred?: boolean): number |null {

	let finalAttr = (destination)? destination : `taux_${attr1}_${attr2}`;

	if (isNumber(obj[attr1]) && isNumber(obj[attr2])) {

		if (obj[attr1] === 0) {
			obj[finalAttr] = 0;
		}
		else {
			let tmp = (obj[attr1] - obj[attr2]) / obj[attr1];
			// limit decimals
			tmp = Math.round((tmp + Number.EPSILON) * 1000) / 1000;
			obj[finalAttr] = tmp;
		}
		if (hundred) {
			obj[finalAttr] = obj[finalAttr] * 100;
		}
	}
	else {
		obj[finalAttr] = null;
	}
	return obj[finalAttr];
}

export const calculateEvolution = function(obj: any, attr1: string, attr2: string, destination?: string, hundred?: boolean): number |null {

	let finalAttr = (destination)? destination : `evolution_${attr1}_${attr2}`;

	if (isNumber(obj[attr1]) && isNumber(obj[attr2])) {

		if (obj[attr2] === 0) {
			obj[finalAttr] = 1;
		}
		else {
			let tmp = (obj[attr1] - obj[attr2]) / obj[attr2];
			// limit decimals
			tmp = Math.round((tmp + Number.EPSILON) * 1000) / 1000;
			obj[finalAttr] = tmp;
		}
		if (hundred) {
			obj[finalAttr] = obj[finalAttr] * 100;
		}
	}
	else {
		obj[finalAttr] = null;
	}
	return obj[finalAttr];
}

export const percentTauxAndEvo = function(value: any, prefix: string = '', divide: boolean = false) {
	if (Array.isArray(value)) {
		value.forEach((one:any)=>{
			percentTauxAndEvo(one, prefix);
		});
	}
	else {
		for (let prop in value) {
			if (
				(prop.startsWith(`${prefix}taux_`) || prop.startsWith(`${prefix}evolution_`))
				&& value[prop]
			) {
				if (divide) {
					value[prop] = value[prop] / 100;
				}
				else {
					value[prop] = value[prop] * 100;
				}
			}
		}
	}
}

export const dividePercentTauxAndEvo = function(value: any, prefix: string = '') {
	percentTauxAndEvo(value, prefix, true);
}

// returns an array of objects from array1 that are included in array2
// optionaly based on the value of an attribute
// deduplicated
export const intersect = function(array1: any[], array2: any[], attr?: string): any[] {
	let tmp = array1.filter((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] === one[attr]; });
		}
		return array2.indexOf(item) > -1;
	});
	return dedup(tmp, attr);
}

// returns true if array1 contains at least one item from array2
// optionaly based on the value of an attribute
export const intersects = function(array1: any[], array2: any[], attr?: string): boolean {
	return array1.some((item: any) => {
		if (attr) {
			return array2.some((one: any) => { return item[attr] === one[attr]; });
		}
		return array2.indexOf(item) > -1;
	});
}

// depup array
// optionaly based on the value of an attribute
export const dedup = function(array: any[], attr?: string): any[] {
	return array.filter((item: any, index: number, array: any[]) => {
		if (attr) {
			return array.findIndex((one: any) => { return item[attr] == one[attr]; }) == index;
		}
		return array.indexOf(item) === index;
	});
}

// Merge two arrays of objects
// using one or several attributes to decide if an object already exists.
// Can also make summation on one or more attributes
export const mergeArray = function(array1: any[], array2: any[], attrs: string[], sumAttrs: string[] = []): any[] {
	let result: any = clone(array1);
	array2.forEach((candidate: any) => {
		let index: number = result.findIndex((existing: any) => {
			return attrs.every((attr: string ) => {
				return typeof candidate[attr] != 'undefined'
					&& typeof existing[attr] != 'undefined'
					&& candidate[attr] == existing[attr]
			})
		});
		if (index > -1 && sumAttrs.length) {
			sumAttrs.forEach((attr: string) => {
				if (
					typeof candidate[attr] != 'undefined' && isNumber(candidate[attr])
					&& typeof result[index][attr] != 'undefined' && isNumber(result[index][attr])
				) {
					result[index][attr] += candidate[attr];
				}
			});
		}
		if (index < 0) {
			result.push(candidate);
		}
	});
	return result;
}

export const cssVarValue = function(varName: string) {
	return getComputedStyle(document.documentElement).getPropertyValue(varName);
}

export const printElement = function(querySelector: string, filename: string, clipboard?: boolean, dimensions?: any): Promise<any> {
	let element = document.querySelector(querySelector);
	if (!element) {
		return new Promise(()=> { throw new TypeError('no element to print');});
	}
	else {
		if (!dimensions) {
			dimensions = {
				windowWidth: element.scrollWidth,
				windowHeight: element.scrollHeight
			}
		}
		return html2canvas(element as HTMLElement, dimensions).then((canvas: any) => {
			if (clipboard) {
				// works on chrome 94+
				// works on FireFox 87+ if dom.events.asyncClipboard.clipboardItem is set to true in about:config
				// does not work on Safari 14
				return canvas.toBlob((blob: any) => {
					// @ts-ignore
					let data = [new ClipboardItem({'image/png': blob})];
					// @ts-ignore
					navigator.clipboard.write(data);
					return true;
				})
			}
			else {
				downloadUrl(canvas.toDataURL(), filename);
				return true;
			}
		});
	}
}

export const hexToRGBArray = function(color: string) {
	if (color.startsWith('#')) {
		color = color.slice(1);
	}
	if (color.length === 3) {
		color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
	}
	else if (color.length !== 6) {
		throw('Invalid hex color: ' + color);
	}
	let rgb = [];
	for (let i = 0; i <= 2; i++) {
		rgb[i] = parseInt(color.substring(i*2, i*2 + 2), 16);
	}
	return rgb;
}

// color can be a hx string or an array of RGB values 0-255
export const luma = function(color: string|number[]) {
	let rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
	return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}

export const contrastingColor = function(color: string|number[]) {
	if (typeof color == 'string' && !color.match(/^#[0-9a-fA-F]{3,6}$/)) {
		console.log(`Not a css color: ${color}`);
		return '';
	}
	return (luma(color) >= 165) ? '#000' : '#fff';
}

export const brighter = function(color: string, offset: number) {
	if (color.startsWith('#')) {
		color = color.slice(1);
	}
	if (color.length === 3) {
		color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
	}
	else if (color.length !== 6) {
		throw('Invalid hex color: ' + color);
	}
	let dimmed = '#';
	for (let i = 0; i <= 2; i++) {
		let n = parseInt(color.substring(i*2, i*2 + 2), 16) + offset;
		n = Math.min(255, Math.round(n));

		if (n < 16) {
			dimmed += '0';
		}
		dimmed += n.toString(16);
	}
	return dimmed;
}

export const fakeApiResponse = function(data:any, error: boolean =  false) {
	return new Observable<any>((sub: Subscriber<any>) => {
		setTimeout(() => {
			if (error) {
				sub.error(data);
			}
			else {
				sub.next(data);
			}
			sub.complete();
		}, 150);
	});
}
