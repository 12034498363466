import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
	ContratAmortissementExposition,
	FournisseurExposition,
} from '@app/moa/achats-fournisseurs/achats-fournisseurs.model';
import { MagasinService } from '@app/magasin/magasin.service';

import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	createDownloadFromHttpResponse,
	endOfMonth,
	prepareQueryParams,
	prepareQueryParamsForDownload,
	startOfMonth
} from '@helpers/utils';

@Injectable({ providedIn: 'root' })
export class AchatsFournisseursService {

	fournisseursExpositions: FournisseurExposition[] = [];

	constructor(
		private http: HttpClient,
		private magasinService: MagasinService
	) {

	}

	prepareFournisseurExpositionFromServer(fournisseur: FournisseurExposition) {
		convertDateFieldsToDate(fournisseur);

		fournisseur.label = `${fournisseur.foe_nom}`;
		if (!fournisseur.foe_actif) {
			fournisseur.label = `${fournisseur.label} (inactif)`;
		}
		return fournisseur;
	}

	prepareFournisseurExpositionsFromServer(fournisseurs: FournisseurExposition[]) {
		fournisseurs.forEach((one: FournisseurExposition) => {
			one = this.prepareFournisseurExpositionFromServer(one);
		});
	}

	// getFournisseurExposition(foe_code: string) {
	// 	return this.http.get<any>(`/fournisseurs_expositions/${foe_code}`)
	// 	.pipe(
	// 		map((response: any)=> {
	// 			return this.prepareFournisseurExpositionFromServer(response);
	// 		})
	// 	);
	// }

	getFournisseursExpositions(refresh: boolean = false) {
		if (!refresh && this.fournisseursExpositions.length) {
			return of(this.fournisseursExpositions);
		}
		return this.http.get<any>(`/fournisseurs_expositions`)
		.pipe(
			map((response: any) => {
				this.fournisseursExpositions = response;
				return this.fournisseursExpositions;
			})
		);
	}


	prepareContratAmortissementExpositionFromServer(contrat: ContratAmortissementExposition) {
		convertDateFieldsToDate(contrat, true);
		// calcul de la fin de contrat
		contrat.date_fin_contrat = new Date(contrat.cae_date_debut_contrat);
		contrat.date_fin_contrat.setMonth(contrat.date_fin_contrat.getMonth() + contrat.cae_duree_contrat -1);
		contrat.date_fin_contrat = endOfMonth(contrat.date_fin_contrat);
		// calcul du pourcentage de durée restante
		const today = new Date();
		const s = contrat.cae_date_debut_contrat.getTime();
		const e = contrat.date_fin_contrat.getTime();
		const t = today.getTime();
		contrat.pourcentageDureeRestante = Math.round(100/(e-s) * (t-s));

		if (contrat.magasin) {
			this.magasinService.prepareMagasin(contrat.magasin);
		}
		if (contrat.fournisseur) {
			this.prepareFournisseurExpositionFromServer(contrat.fournisseur);
		}
		return contrat;
	}

	prepareContratsAmortissementsExpositionsFromServer(contrats: ContratAmortissementExposition[]) {
		contrats.forEach((one: ContratAmortissementExposition) => {
			one = this.prepareContratAmortissementExpositionFromServer(one);
		});
	}

	prepareContratAmortissementExposition(contrat: ContratAmortissementExposition) {
		let tmp = clone(contrat);
		tmp.cae_date_debut_contrat = startOfMonth(tmp.cae_date_debut_contrat);
		convertDateFieldsToString(tmp);
		delete tmp.magasin;
		delete tmp.fournisseur;
		return tmp;
	}

	getContratsAmortissementsExpositions(mag_id: number, params: any = {}) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/magasins/${mag_id}/contrats_amortissements_expositions`, tmpParams)
		.pipe(
			map((response: any)=> {
				this.prepareContratsAmortissementsExpositionsFromServer(response.contrats);
				return response;
			})
		);
	}

	postContratAmortissementExposition(contrat: ContratAmortissementExposition) {
		let tmp = this.prepareContratAmortissementExposition(contrat);
		return this.http.post<any>(`/magasins/${contrat.mag_id}/contrats_amortissements_expositions`, tmp);
	}

	putContratAmortissementExposition(contrat: ContratAmortissementExposition) {
		let tmp = this.prepareContratAmortissementExposition(contrat);
		return this.http.put<any>(`/magasins/${contrat.mag_id}/contrats_amortissements_expositions/${contrat.cae_id}`, tmp);
	}

	deleteContratAmortissementExposition(contrat: ContratAmortissementExposition) {
		return this.http.delete<any>(`/magasins/${contrat.mag_id}/contrats_amortissements_expositions/${contrat.cae_id}`);
	}

	getRepartitionAchatsMeubles(params: any) {
		let tmpParams = prepareQueryParams(params);
		return this.http.get<any>(`/repartition_achats_meubles`, tmpParams);
	}

	exportRepartitionAchatsMeubles(params: any) {
		let tmpParams = prepareQueryParamsForDownload(params);
		return this.http.get<any>(`/repartition_achats_meubles/export`, tmpParams)
		.pipe(
			map((response: any) => {
				createDownloadFromHttpResponse(response);
				return true;
			})
		);
	}

}
