<h1 class="flex-shrink-0">
	<titre-module>Classement SATCLI</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			singleMode="mois"
			label="Mois"
			[maxDate]="maxDate"
			[defaultStart]="defaultDate"
			[defaultEnd]="defaultDate"
			[disabled]="loading"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="region_commerciale">Région</label>
		<region-commerciale-selector
			name="region_commerciale"
			[(ngModel)]="params.region_commerciale"
			[required]="false"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[autoSelectFirst]="false"
			[mesRegions]="true"
			[disabled]="loading || exportLoading"
			placeholder="France"
			(ngModelChange)="paramChanged()"
		></region-commerciale-selector>
	</div>

	<div class="field col-fixed">
		<label for="mode">&nbsp;</label>
		<p-selectButton
			name="mode"
			[options]="optionsMode"
			[(ngModel)]="params.mode"
			[disabled]="loading || exportLoading"
			(ngModelChange)="refresh()"
		></p-selectButton>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="refresh()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export graphiques"
			icon="pi pi-chart-line"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			(click)="showExportGraphiqueDialog()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-table
		#tableClassement
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="true"
		[rows]="rows"
		[rowsPerPageOptions]="[25,50,100,{showAll: 'Tous'}]"
		[showCurrentPageReport]="true"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-satcli"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="text-center col-rang not-last-frozen" pTooltip="Rang national" pFrozenColumn>Rang</th>
				<th class="text-center white-space-nowrap overflow-hidden text-overflow-ellipsis" pFrozenColumn>
					<ng-container *ngIf="params.mode == 'vendeur'">Vendeur</ng-container>
					<ng-container *ngIf="params.mode == 'magasin'">Magasin</ng-container>
				</th>

				<ng-container *ngFor="let col of indicateurs">
					<th
						class="text-center col-indicateur"
						[ngClass]="{
							'bg-wizville': isIndicateurWizville(col.ind_code),
							'bg-google': isIndicateurGoogle(col.ind_code)
						}"
						pSortableColumn="{{col.ind_code}}"
						*ngIf="col.modes.includes(params.mode)"
					>
						<indicateur-label-display [ind_code]="col.ind_code" [tooltipIcon]="!!col.tooltipIcon"></indicateur-label-display>
						<p-sortIcon field="{{col.ind_code}}"></p-sortIcon>
					</th>
				</ng-container>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-row>
			<tr>
				<td class="text-center col-rang not-last-frozen" pFrozenColumn>
					{{ row.rang }}
				</td>

				<ng-container *ngIf="params.mode == 'vendeur'">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{ row.uti_prenom_nom }}" pFrozenColumn>
						{{ row.uti_prenom_nom }}
					</td>
				</ng-container>
				<ng-container *ngIf="params.mode == 'magasin'">
					<td class="white-space-nowrap overflow-hidden text-overflow-ellipsis" title="{{row.mag_nom_court}} ({{ row.mag_id }})" pFrozenColumn>
						{{row.mag_nom_court}} ({{ row.mag_id }})
					</td>
				</ng-container>

				<ng-container *ngFor="let col of indicateurs">
					<ng-container *ngVar="goalReached(row[col.ind_code], col.ind_code) as goalReachedValue">
						<td
							class="text-right nowrap col-indicateur"
							[ngClass]="{'text-green-500': goalReachedValue == 1, 'text-red-500': goalReachedValue == -1}"
							*ngIf="col.modes.includes(params.mode)"
						>
							<indicateur-value-display [ind_code]="col.ind_code" [value]="row[col.ind_code]"></indicateur-value-display>
						</td>
					</ng-container>
				</ng-container>

			</tr>
		</ng-template>

	</p-table>
</div>
