import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { UIRouterGlobals } from '@uirouter/core';

import { enseigne } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {

	private configSubject = new BehaviorSubject<any>(null);

	constructor(
		private http: HttpClient,
		private uiRouterGlobals: UIRouterGlobals
	) {

	}

	public get config(): any {
		return this.configSubject.getValue();
	}

	getConfig() {
		return this.http.get<any>('/config')
		.pipe(
			map(
				(response: any) => {
					this.configSubject.next(response);
					return response;
				}
			)
		);
	}

	public getConfigEnseigne(name: string): any {
		if (
			this.config
			&& this.config[name]
			&& this.config[name][enseigne]
		) {
			return this.config[name][enseigne];
		}
		return null;
	}

	public getModuleConf(moduleName: string) {
		if (
			this.getConfigEnseigne('config_modules')
			&& this.getConfigEnseigne('config_modules')[moduleName]
		) {
			return this.getConfigEnseigne('config_modules')[moduleName]
		}
		return null;
	}

	getModuleTitle(moduleName: string, defaultValue: string = ''): string {
		const moduleConf = this.getModuleConf(moduleName);
		if (moduleConf && moduleConf.titre) {
			return moduleConf.titre;
		}
		return defaultValue;
	}

	getModuleMenuName(moduleName: string, defaultValue: string): string {
		const moduleConf = this.getModuleConf(moduleName);

		if (moduleConf && moduleConf.menu) {
			return moduleConf.menu;
		}
		return this.getModuleTitle(moduleName, defaultValue);
	}

	getCurrentModuleTitle(defaultValue: string = '') {
		if (this.uiRouterGlobals.current.name) {
			let defaultValue: string = '';
			if (this.uiRouterGlobals.current.data.hasOwnProperty('title')) {
				defaultValue = this.uiRouterGlobals.current.data.title;
			};
			return this.getModuleTitle(this.uiRouterGlobals.current.name, defaultValue);
		}
		return defaultValue;
	}

	isModuleActif(moduleName: string|undefined): boolean {
		if (!moduleName) return true;
		if (moduleName.startsWith('admin.')) return true;
		const moduleConf = this.getModuleConf(moduleName);
		if (moduleConf && moduleConf.actif) {
			return true;
		}
		return false;
	}


}
