import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableModule } from '@app/primeng-overrides/table';

import { GlobalModule } from '@global/global.module';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { NumberDisplayModule } from '@helpers/number-display';
import { StorageService } from '@global/storage.service';

@Component({
	selector: 'top-par-milieu',
	templateUrl: './top-par-milieu.html',
})
export class TopParMilieuComponent {

	@Input() values: any[] = [];

	loading: boolean = false;

	milieux: any = [
		{code: 'cuisine', label: 'Cuisines'},
		{code: 'sdb', label: 'Salles de bains'},
		{code: 'rangement', label: 'Rangements'},
	];

	indicateurs: string[] = [
		'nb_devis',
		'nb_ventes',
		'ca_prise_commande_htple',
		'avg_vente_htple',
	];

	columns: any = [];

	order_by: string = 'ca_prise_commande_htple';
	order: number = -1;

	constructor(
		private storageService: StorageService,
	) {

		this.indicateurs.forEach((indicateur: string) => {
			this.columns.push({attr: indicateur, ind_code: indicateur});
		});
		this.milieux.forEach((milieu: any)=> {
			this.indicateurs.forEach((indicateur: string) => {
				this.columns.push({attr: `${indicateur}_${milieu.code}`, ind_code: indicateur});
			});
		});

	}

	ngOnInit() {
		this.getParamSet();
	}

	getParamSet() {
		this.order_by = this.storageService.getForCurrentState('order_by', this.order_by);
		this.order = this.storageService.getForCurrentState('order', this.order);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('order_by', this.order_by);
		this.storageService.setForCurrentState('order', this.order);
	}

	onSort(event: any) {
		this.order_by = event.field;
		this.order = event.order;
		this.saveParamSet();
	}


}
@NgModule({
	declarations: [
		TopParMilieuComponent
	],
	exports: [
		TopParMilieuComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		GlobalModule,
		IndicateurValueDisplayModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		NumberDisplayModule
	],
})
export class TopParMilieuModule { }
