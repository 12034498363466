<h1 class="flex-shrink-0">
	<titre-module>Répartition des achats meubles</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[disabled]="loading || exportLoading"
			[useStorage]="true"
			[rangeMode]="true"
			singleMode="mois"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="regroupement">Magasin</label>
		<magasin-selector
			name="magasin"
			[(ngModel)]="mag_id"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[mesMagasins]="false"
			[silentInitialChangeIfSame]="true"
			[disabled]="loading || exportLoading"
			placeholder="Tous"
			(ngModelChange)="onMagasinChange()"
			class="hide-print"
		></magasin-selector>
	</div>

	<div class="field col-fixed">
		<label for="regroupement">Regroupement</label>
		<p-selectButton
			name="regroupement"
			[options]="optionsRegroupement"
			[(ngModel)]="regroupement"
			[disabled]="loading || exportLoading"
			(ngModelChange)="onRegroupementChange()"
		></p-selectButton>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="loading"
			(click)="load()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		#reportingActivite
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-reporting-activite"
		dataKey="label"
		[expandedRowKeys]="expandedRowKeys"
		(onRowExpand)="onRowExpand($event)"
		(onRowCollapse)="onRowCollapse($event)"
	>
		<ng-template pTemplate="emptymessage">
			<tr class="text-center">
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr class="">
				<th class="" rowspan="2" pFrozenColumn ></th>
				<th class="text-center" colspan="7">Fournisseur MOBILIER</th>
				<th class="text-center" colspan="4">Fournisseur NOBILIA</th>
			</tr>

			<tr>
				<!-- gauche -->
				<ng-container *ngFor="let col of fournisseursLeft">
					<th class="text-center" colspan="2">{{col.key|fournisseurExposition}}</th>
				</ng-container>


				<th class="text-center bg-primary-soft" colspan="1">Total</th>

				<!-- droite -->
				<ng-container *ngFor="let col of fournisseursRight">
					<th class="text-center" colspan="2">{{col.key|fournisseurExposition}}</th>
				</ng-container>
			</tr>

			<tr>
				<th class="text-center" pFrozenColumn>Magasin</th>
				<th
					class="text-center"
					[ngClass]="{'bg-primary-soft': col.key == 'mt_achats_meubles_factures_ht_fournisseurs_mobilier'}"
					*ngFor="let col of columns"
				>
					{{col.ind_code_label | indicateur:'libelle'}}
				</th>

			</tr>
		</ng-template>


		<ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-expanded="expanded">

			<tr [ngClass]="{'row-total': row.isTotal, 'row-group': row.group}">

				<td class="text-left nowrap" [ngClass]="{'cell-th': row.magasins}" pFrozenColumn>
					<div class="flex align-items-center">
						<ng-container *ngIf="row.magasins">
							<button
								type="button"
								pButton
								pRipple
								[pRowToggler]="row"
								class="p-button-text p-button-rounded p-button-sm"
								[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
							></button>
						</ng-container>
						{{ row.label }}
					</div>
				</td>


				<td
					class="text-right"
					[ngClass]="{'bg-primary-soft': col.key == 'mt_achats_meubles_factures_ht_fournisseurs_mobilier'}"
					*ngFor="let col of columns; first as first"
				>
					<indicateur-value-display
						[value]="row[col.key]"
						[ind_code]="col.ind_code"
						[hideZero]="true"
					></indicateur-value-display>
				</td>


			</tr>

		</ng-template>

		<ng-template pTemplate="rowexpansion" let-group>
			<tr class="surface-0" *ngFor="let row of group.magasins">
				<td class="text-left" pFrozenColumn >{{row.label}}</td>

				<td
					class="text-right"
					[ngClass]="{'bg-primary-soft': col.key == 'mt_achats_meubles_factures_ht_fournisseurs_mobilier'}"
					*ngFor="let col of columns"
				>
					<indicateur-value-display
						[value]="row[col.key]"
						[ind_code]="col.ind_code"
						[hideZero]="true"
					></indicateur-value-display>
				</td>


			</tr>
		</ng-template>

	</p-table>
	<div class="text-sm p-2" *ngIf="date_donnees_repartition_achats">
	* Date de mise à jour des données : {{date_donnees_repartition_achats|date:'mediumDate'}}
	</div>
</div>

