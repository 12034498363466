import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';

import { EvenementService, Evenement } from '@app/evenement/evenement.service';
import { MilieuSelectorModule } from '@app/evenement/milieu-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';

@Component({
	selector: 'milieu-dialog',
	templateUrl: './milieu-dialog.html'
})
export class MilieuDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	evenement: Evenement;

	currentMilieu: string = 'Aucun';
	selected: string|null = null;

	showChoice: boolean = false;

	urlImage: string = '';
	urlImageLoading: boolean = false;

	constructor(
		private config: DynamicDialogConfig,
		private confirmationService: ConfirmationService,
		private dialogRef: DynamicDialogRef,
		private evenementService: EvenementService,
		private eventManager: EventManagerService,
	) {
		this.evenement = config.data.evenement;
		// on reset pour forcer l'utilisateur à sélectionner un milieu pour la confirmation
		if (!this.evenement.eve_milieu_modifie_le) {
			if (this.evenement.eve_milieu) {
				this.currentMilieu = this.evenement.eve_milieu;
			}
		}
		else {
			this.selected = this.evenement.eve_milieu;
		}

		this.showChoice = !!this.evenement.eve_milieu_modifie_le || !this.evenement.option_evenement?.ope_milieu_ia;

		// récupération d'une url présignée pour l'image de perspective
		if (this.evenement.option_evenement?.ope_url_image_perspective_3d_vignette) {
			this.urlImageLoading = true;
			this.evenementService.getPresignedUrlImageOptionEvenement(this.evenement.mag_id, this.evenement.eve_option_id)
			.subscribe({
				next: (response) => {
					this.urlImage = response;
				}
			})
			.add(() => { this.urlImageLoading = false; });
		}

	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	confirmSubmit() {
		this.selected = this.evenement.option_evenement!.ope_milieu_ia;
		this.confirmationService.confirm({
				defaultFocus: 'reject',
				message: `Souhaitez-vous vraiment confirmer le milieu <b>${this.evenement.option_evenement!.ope_milieu_ia}</b> ?`,
				rejectButtonStyleClass: 'p-button-text',
				accept: () => {
					this.submit();
				},
				reject: () => {
					this.selected = null;
				}
			});
	}

	submit() {
		if (this.selected) {
			this.loading = true;
			if (!this.evenement.eve_milieu_modifie_le) {
				this.evenement['confirmation_milieu'] = true;
			}
			this.evenement.eve_milieu = this.selected;
			this.evenementService.putEvenement(this.evenement)
			.subscribe({
				next: (response: Evenement) => {
					this.dialogRef.close(response.evenement);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		TooltipModule,
		MilieuSelectorModule,
	],
	exports: [MilieuDialogComponent],
	declarations: [MilieuDialogComponent]
})
export class MilieuDialogModule { }

