import { Injectable } from '@angular/core';

import { MimeTypePipe } from '@app/_helpers/mime-type.pipe';

export const fileTypes: any = {
	doc: {
		label: 'Doc',
		extensions: ['doc', 'docx', 'odt'],
		mimetypes: [
			'application/msword',
			'application/vnd.oasis.opendocument.text',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		]
	},
	excel: {
		label: 'Excel',
		extensions: ['xls', 'xlsx', 'ods'],
		mimetypes: [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.oasis.opendocument.spreadsheet',
		]
	},
	powerpoint: {
		label: 'PowerPoint',
		extensions: ['ppt', 'pptx', 'odp'],
		mimetypes: [
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			'application/vnd.oasis.opendocument.presentation',
		]
	},
	pdf: {
		label: 'PDF',
		extensions: ['pdf'],
		mimetypes: ['application/pdf']
	},
	image: {
		label: 'Image',
		extensions: ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp', 'tif', 'ico'],
		mimetypes: ['image/*']
	},
	video: {
		label: 'Vidéo',
		extensions: ['mp4', 'avi', 'mkv', 'av1', 'mpeg'],
		mimetypes: ['video/*']
	},
	texte: {
		label: 'Texte',
		extensions: ['txt'],
		mimetypes: ['text/*']
	},
	lien: {
		label: 'Lien',
		extensions: [],
		mimetypes: []
	},
	autre: {
		label: 'Autre',
		extensions: [],
		mimetypes: []
	}
};

export const displayableImageExtensions: string[] = [
	'bmp',
	'gif',
	'ico',
	'jpg', 'jpeg',
	'png',
	'svg',
	'webp'
];

import { extractFileExtension } from '@helpers/utils';


@Injectable({ providedIn: 'root' })
export class FileService {

	constructor(
		// private mimeTypePipe: MimeTypePipe,
	) {

	}

	public isDisplayableImage(file: string|File|null): boolean {
		if (!file) return false;
		if (file instanceof File) file = file.name;
		let extension: string|null = extractFileExtension(<string>file);
		return !!extension && !!displayableImageExtensions.find((one: string) => {return one == extension; });
	}

	public getFileType(file: File|string) {
		if (this.isImage(file)) return 'image';
		if (this.isExcel(file)) return 'excel';
		if (this.isPdf(file)) return 'pdf';
		if (this.isVideo(file)) return 'video';
 		else return 'autre';
	}

	public isImage(file: File|string): boolean {
		if (file instanceof File) {
			return /^image\//.test(file.type);
		}
		return fileTypes.image.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isExcel(file: File|string): boolean {
		if (file instanceof File) {
			return /ms-excel|spreadsheetml|spreadsheet/.test(file.type);
		}
		return fileTypes.xls.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isPdf(file: File|string): boolean {
		if (file instanceof File) {
			return /\/pdf/.test(file.type);
		}
		return fileTypes.pdf.extensions.indexOf(extractFileExtension(file)) > -1;
	}

	public isVideo(file: File|string): boolean {
		if (file instanceof File) {
			return /^video\//.test(file.type);
		}
		return fileTypes.video.extensions.indexOf(extractFileExtension(file)) > -1;
	}

}
