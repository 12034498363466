import { Component, NgModule, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalModule } from '@global/global.module';
import { TooltipModule } from 'primeng/tooltip';

import { IndicateurPipe } from '@app/indicateur/indicateur.pipe';

@Component({
	selector: 'indicateur-label-display',
	template: `
		<ng-container *ngIf="tooltip">
			<span [pTooltip]="description" [escape]="false" tooltipPosition="top">
				{{ind_code|indicateur:'libelle'}}
				<ng-container *ngIf="nMoins1"> N-1</ng-container>
				<ng-container *ngIf="tooltipIcon && description">&nbsp;<i class="pi pi-question-circle text-sm"></i></ng-container>
			</span>
		</ng-container>
		<ng-container *ngIf="!tooltip">
			<span>
				{{ind_code|indicateur:'libelle'}}
				<ng-container *ngIf="nMoins1"> N-1</ng-container>
			</span>
		</ng-container>
	`,
	providers: [IndicateurPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndicateurLabelDisplayComponent implements OnChanges {

	@Input() ind_code: string;
	@Input() tooltip: boolean = true;
	@Input() tooltipIcon: boolean = false;
	@Input() nMoins1: boolean = false;

	description: string;

	constructor(private indicateurPipe: IndicateurPipe) {

	}

	ngOnChanges(changes: any) {
		this.description = this.indicateurPipe.transform(this.ind_code, 'description');
		if (this.nMoins1) {
			this.description = this.description + ' N-1';
		}
	}

}
@NgModule({
	declarations: [
		IndicateurLabelDisplayComponent
	],
	exports: [
		IndicateurLabelDisplayComponent
	],
	imports: [
		CommonModule,
		GlobalModule,
		TooltipModule
	],
})
export class IndicateurLabelDisplayModule { }
