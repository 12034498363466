import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AnneeSelectorModule } from '@app/annee-selector';
import { CommunicationLocaleService, BudgetCommunication, CommunicationLocale } from '@app/communication-locale/communication-locale.service';
import { CommunicationLocaleDialogComponent } from '@app/communication-locale/communication-locale-dialog';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { NumberDisplayModule } from '@helpers/number-display';

import {
	clone,
} from '@helpers/utils';

@Component({
	selector: 'table-communications-locales',
	templateUrl: './table-communications-locales.html',
	providers: [],
})
export class TableCommunicationsLocalesComponent {

	@Input() mag_id: number;
	@Input() annee: number;
	@Input() buc_budget: number|null;

	@ViewChild('tableCommunicationsLocales') table: Table;

	values: any[] = [];

	loading: boolean = false;
	today: Date = new Date();

	cumul: number = 0;
	reste: number = 0;

	constructor(
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private communicationLocaleService: CommunicationLocaleService,
	) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (
			changes.mag_id && changes.mag_id.currentValue != changes.mag_id.previousValue
			|| changes.annee && changes.annee.currentValue != changes.annee.previousValue
		) {
			this.load()
		}
		this.calculateSums();
	}

	load() {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		this.communicationLocaleService.getCommunicationsLocales(this.mag_id, this.annee)
		.subscribe({
			next: (response: any) => {
				this.values = response.communications_locales;
				this.calculateSums();
			}
		})
		.add(() => { this.loading = false; });
	}

	calculateSums() {
		this.cumul = this.values.reduce((accumulator: number, currentValue: CommunicationLocale) => {
			return accumulator + currentValue.clo_montant;
		}, 0);
		const budget = this.buc_budget !== null ? this.buc_budget : 0;
		this.reste = budget - this.cumul;
	}


	showCommunicationLocaleDialog(communication?: CommunicationLocale) {
		let tmp: CommunicationLocale;
		let header: string;
		if (!communication) {
			tmp = new CommunicationLocale(this.mag_id, this.annee);
			header = `Nouvelle action de communication locale`;
		}
		else {
			tmp = clone(communication);
			header = `Action n° ${communication.clo_id}`;
		}

		const ref = this.dialogService.open(CommunicationLocaleDialogComponent, {
			data: {
				communicationLocale: tmp
			},
			header: header,
			style: {'max-width': '500px'}
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.load();
			}
		});
	}

	deleteCommunicationLocale(communication: CommunicationLocale) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer cette action de communication locale ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.communicationLocaleService.deleteCommunicationLocale(communication)
				.subscribe({
					next: (ok:any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: `Action supprimée`});
						this.load();
					}
				})
			}
		});
	}



}
@NgModule({
	declarations: [
		TableCommunicationsLocalesComponent
	],
	exports: [
		TableCommunicationsLocalesComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		TableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		AnneeSelectorModule,
		NumberDisplayModule,
	],
})
export class TableCommunicationsLocalesModule { }
