import { NgModule, Component, Input, forwardRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
	FormsModule,
	ControlValueAccessor,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';


@Component({
	selector: 'statut-vendeur-selector',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => StatutVendeurSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			optionValue="libelle"
			optionLabel="libelle"
			appendTo="body"
		>
		</p-dropdown>
	`
})
export class StatutVendeurSelectorComponent extends ControlValueAccessorBase<string|null|undefined> implements ControlValueAccessor {

	@Input('value') innerValue: string|null|undefined;
	@Input() name: string;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() options: any[] = [];

	constructor() {
		super();
	}

	ngOnInit() {
		this.setInitialValue();
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (!this.options.length) {
			this.innerValue = null;
		}
		else {
			if (this.options.findIndex(one => {return one.libelle == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectFirst || this.autoSelectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].libelle;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [StatutVendeurSelectorComponent],
	declarations: [StatutVendeurSelectorComponent]
})
export class StatutVendeurSelectorModule { }
