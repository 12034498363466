import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AuditConformite, PointAuditConformite } from '@app/moa/moa.model';
import { GlobalModule } from '@global/global.module';
import { EventManagerService } from '@global/event-manager.service';
import { LienDocumentationModule } from '@app/lien-documentation';
import { MagasinSelectorModule, MagasinSelectorComponent } from '@app/magasin/magasin-selector';
import { MOAService } from '@app/moa/moa.service';
import { PrintButtonModule } from '@app/print-button';
import { TitreModuleModule } from '@app/titre-module';
import { StorageService } from '@global/storage.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { clone, isEmpty } from '@helpers/utils';
import { AuthService } from '@app/auth/auth.service';

@Component({
	selector: 'audit-conformite',
	templateUrl: './audit-conformite.html',
	providers: [],
})
export class AuditConformiteComponent {

	@ViewChild('magasinSelector') magasinSelector: MagasinSelectorComponent;

	loading: boolean = false;
	loaded: boolean = false;
	submitReady: boolean = false;
	deletionLoading: boolean = false;

	droitsCreateAudit: string[] = ['chef_ventes', 'manager'];
	canCreateAudit: boolean = false;
	canEdit: boolean = false;

	mag_id: number;
	creation: boolean = false;

	groupesPointsAuditConformite: any = [
		{pac_groupe: 1, label: `L'extérieur`},
		{pac_groupe: 2, label: `Le magasin`},
	];

	audit: AuditConformite|null;
	splitPointsAudit: {[key: string]: PointAuditConformite[]} = {}

	optionsTrueFalse: any[] = [
		{label: 'Non', value: false, icon: 'pi pi-times'},
		{label: 'Oui', value: true, icon: 'pi pi-check'},
	];

	debouncedSubmit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private eventManager: EventManagerService,
		private moaService: MOAService,
		private storageService: StorageService,
	) {

		this.canCreateAudit = this.authService.checkIfHasRight(this.droitsCreateAudit);

		// this.canEdit = false; // dépend de l'utilisateur et de l'audit

		this.debouncedSubmit
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				if (!this.submitReady) {
					this.submitReady = true;
				}
				else {
					this.submit();
				}
			}
		});
	}

	ngOnInit() {
		// this.load();
	}

	load() {
		if (this.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);
			this.loaded = false;

			this.moaService.getAuditConformite(this.mag_id)
			.subscribe({
				next: (response: any) => {
					this.prepareAudit(response);
					this.loaded = true;
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	prepareAudit(audit: AuditConformite) {
		this.canEdit = false;
		this.splitPointsAudit = {};
		this.audit = null;
		if (!isEmpty(audit)) {
			this.audit = audit;
			// peut éditer si l'utilisateur courant est celui qui a créé l'audit
			this.canEdit = this.canCreateAudit && (!this.audit.uti_id_auditeur || this.audit.uti_id_auditeur == this.authService.user?.uti_id);
			// si pas de date, c'est une création
			if (!this.audit.mag_date_audit) {
				this.creation = true;
				this.audit.uti_auditeur = clone(this.authService.user);
				this.audit.mag_date_audit = new Date();
			}
			// séparation des points
			this.groupesPointsAuditConformite.forEach((groupe: any) => {
				this.splitPointsAudit[groupe.pac_groupe] = this.audit!.points_audits_conformite.filter((point: PointAuditConformite) => {
					return point.pac_groupe == groupe.pac_groupe;
				});
			});
		}
	}

	saisieChanged() {
		this.debouncedSubmit.next(true);
	}

	submit() {
		this.moaService.postAuditConformite(this.mag_id, this.audit)
		.subscribe({
			next: (response: any) => {
				this.creation = false;
				this.prepareAudit(response);
			}
		})
	}

	confirmDeleteAudit() {
		let mag_nom = this.magasinSelector.getEntityName();
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Toutes les saisies de l'audit du magasin <b>${mag_nom}</b> vont êtres supprimées. Souhaitez-vous vraiment supprimer cet audit ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.deleteAudit();
			}
		});
	}

	deleteAudit() {
		this.deletionLoading = true;
		this.moaService.deleteAuditConformite(this.mag_id)
		.subscribe({
			next: (response: any) => {
				this.eventManager.emit('toast', {severity: 'success', summary: 'Audit supprimé'});
				this.load();
			}
		})
		.add(() => { this.deletionLoading = false; });
	}



}
@NgModule({
	declarations: [
		AuditConformiteComponent
	],
	exports: [
		AuditConformiteComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		GlobalModule,
		LienDocumentationModule,
		MagasinSelectorModule,
		PrintButtonModule,
		TitreModuleModule,
		UtilisateurSelectorModule,
	],
})
export class AuditConformiteModule { }
