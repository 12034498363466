<div class="formgrid grid p-fluid">

	<div class="field col-fixed">
		<label for="ope_commentaire">Mes prochaines actions pour {{evenement.eve_client}}</label>
		<textarea
			pInputTextarea
			name="ope_commentaire"
			[(ngModel)]="optionEvenement.ope_commentaire"
			[autoResize]="true"
			[rows]="5"
		></textarea>
	</div>

</div>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button"
		pButton
		class="btn p-button-sm"
		label="Enregistrer"
		[disabled]="loading"
		(click)="submit()"
	></button>
</div>

