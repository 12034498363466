<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Evaluations collaborateurs</titre-module>
	<lien-documentation prm_code="documentation_referentiel_competences_entretien" label="Matrice entretien professionnel"></lien-documentation>
	<lien-documentation prm_code="documentation_referentiel_competences_evaluation" label="Matrice évaluation professionnel"></lien-documentation>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					<magasin-selector
						#magasinSelector
						name="magasin"
						[(ngModel)]="mag_id"
						[nullOption]="false"
						[nullIfInvalid]="false"
						[mesMagasins]="false"
						[autoSelectFirst]="true"
						(ngModelChange)="load()"
						class="hide-print"
					></magasin-selector>
					<span class="show-print">{{magasinSelector.getEntityName(true)}}</span>
				</td>
				<td class="clear-cell">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text"
						[disabled]="loading"
						[loading]="loading"
						(click)="load()"
					></button>
				</td>
			</tr>

		</ng-template>
	</p-table>

</div>

<div class="flex flex-shrink-0 justify-content-between hide-print">
	<div class="field p-field-checkbox flex flex-column justify-content-end mb-0">
		<div>
			<p-checkbox
				name="showCollaborateursAbsents"
				inputId="showCollaborateursAbsents"
				[(ngModel)]="showCollaborateursAbsents"
				[binary]="true"
				(ngModelChange)="saveParamSet()"
				label="Afficher les évaluations des collaborateurs partis"
			></p-checkbox>
		</div>
	</div>

</div>

<div class="min-table-height flex flex-column">
	<p-table
		[value]="values"
		[lazy]="true"
		[loading]="loading"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover"
		dataKey="uti_id"
		[expandedRowKeys]="expandedRowKeys"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th class="nowrap"></th>
				<th class="text-center">Nom</th>
				<th class="text-center">Prénom</th>
				<th class="text-center">Profil</th>
				<th class="text-center max-w-15rem">

				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-collaborateur let-rowIndex="rowIndex" let-expanded="expanded">
			<tr [ngClass]="{'disabled': !collaborateur.present}" *ngIf="collaborateur.present || showCollaborateursAbsents">
				<td class="text-center nowrap">
					<button
						type="button"
						pButton
						pRipple
						[pRowToggler]="collaborateur"
						class="p-button-text p-button-rounded p-button-xs"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
					></button>
				</td>
				<td class="text-left font-bold">{{collaborateur.uti_prenom}}</td>
				<td class="text-left font-bold">{{collaborateur.uti_nom}}</td>
				<td class="text-center font-bold">
					{{collaborateur|profilCollaborateur}}
				</td>

				<td class="text-center nowrap" *ngIf="collaborateur.present">
					<button
						type="button"
						pButton
						class="p-button-sm"
						icon="pi pi-plus"
						label="Ajouter une évaluation"
						(click)="showEvaluationCollaborateurDialog(collaborateur)"
						*ngIf="canEdit && collaborateur.present"
					></button>
				</td>
				<td class="text-center" *ngIf="!collaborateur.present">
					<span class="text-sm">{{collaborateur|utilisateur}} n'est plus lié•e au magasin</span>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="rowexpansion" let-collaborateur>
			<ng-container *ngIf="collaborateur.present || showCollaborateursAbsents">
				<tr *ngFor="let evaluation of collaborateur.evaluations">
					<td class="clear-cell border-none"></td>
					<td>Période : {{evaluation.evc_periode}}</td>
					<td>
						<div>Réalisé par {{evaluation.aud_email_creation}}</div>
						<div>Le {{evaluation.aud_date_creation|date:'medium'}}</div>
					</td>
					<td class="text-center">
						{{evaluation.pco_code | profilCompetence}}
					</td>
					<td class="text-center ">
						<button
							type="button"
							pButton
							class="p-button-sm p-button-text p-button-rounded"
							icon="pi pi-eye"
							uiSref="evaluation-collaborateur"
							[uiParams]="{mag_id: mag_id, evc_id: evaluation.evc_id}"
						></button>
					</td>

				</tr>
			</ng-container>

		</ng-template>

	</p-table>

</div>

