<div class="min-table-height">
	<p-table
		#tableCommunicationsLocales
		[value]="values"
		[lazyLoadOnInit]="false"
		[loading]="loading"
		[paginator]="false"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-plan-actions"
	>
		<ng-template pTemplate="header" >
			<tr class="">
				<th class="text-center nowrap">Date du contrat</th>
				<th class="text-center nowrap">Fin du contrat</th>
				<th class="text-center">Média</th>
				<th class="text-center">Prestataires</th>
				<th class="text-center col-indicateur">Montant HT</th>
				<th class="text-center nowrap">Génération de trafic</th>
				<th class="text-center col-indicateur">Bénéfices chiffres HT</th>
				<th class="col-print-0"></th>
			</tr>
		</ng-template>

		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">
					Aucune action définie
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-communication let-rowIndex="rowIndex">
			<tr>
				<td class="text-center">{{communication.clo_date_debut}}</td>
				<td class="text-center">{{communication.clo_date_fin}}</td>
				<td class="">{{communication.clo_media}}</td>
				<td class="">{{communication.clo_prestataire}}</td>
				<td class="text-right">
					<number-display [value]="communication.clo_montant" type="currency"></number-display>
				</td>
				<td class="">{{communication.clo_trafic}}</td>
				<td class="text-right">
					<number-display [value]="communication.clo_benefices" type="currency"></number-display>
				</td>
				<td class="nowrap col-print-0">
					<div class="hide-print">
						<button
							type="button"
							pButton
							icon="pi pi-pencil"
							class="p-button-xs p-button-text"
							(click)="showCommunicationLocaleDialog(communication)"
						></button>
						<button
							type="button"
							pButton
							icon="pi pi-trash"
							class="p-button-xs p-button-text p-button-danger"
							(click)="deleteCommunicationLocale(communication)"
						></button>
					</div>
				</td>

			</tr>

		</ng-template>

		<ng-template pTemplate="footer">
			<tr class="">
				<td colspan="3" class="clear-cell"></td>
				<td class="cell-th text-right cell-darker">
					Cumul
				</td>
				<td class="text-right cell-darker">
					<number-display [value]="cumul" type="currency"></number-display>
				</td>
				<!-- <td class="clear-cell"></td> -->
				<td colspan="3" class="text-right clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						label="Ajouter une action de communication locale"
						class="p-button-sm"
						(click)="showCommunicationLocaleDialog()"
					></button>
				</td>
			</tr>

			<tr class="">
				<td colspan="3" class="clear-cell"></td>
				<td class="cell-th text-right" [ngClass]="{'cell-warning': reste > 0, 'cell-error': reste < 0}">
					Reste
				</td>
				<td class="text-right" [ngClass]="{'cell-warning': reste > 0, 'cell-error': reste < 0}">
					<number-display [value]="reste" type="currency"></number-display>
				</td>
				<td colspan="100" class="clear-cell"></td>
			</tr>
		</ng-template>

	</p-table>
</div>
