import { NgModule, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { Observable } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from '@app/primeng-overrides/calendar';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { DynamicDialogConfig } from '@app/primeng-overrides/dynamicdialog';
import { DynamicDialogRef } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { IncidentPrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { Prestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import { EventManagerService, IEventListener } from '@global/event-manager.service';
import { clone, simpleComparison } from '@helpers/utils';


@Component({
	selector: 'incident-prestataire-dialog',
	templateUrl: './incident-prestataire-dialog.html'
})
export class IncidentPrestataireDialogComponent implements OnInit, IEventListener {

	loading: boolean;
	prestataire: Prestataire;
	pristine: IncidentPrestataire;
	incidentPrestataire: IncidentPrestataire;

	dateDebutVendeur: Date = new Date(1970, 0, 1); // pour récupérer tous les vendeurs rattachés au magasin
	today: Date = new Date();
	minDate: Date = new Date();

	constructor(
		private dialogRef: DynamicDialogRef,
		private eventManager: EventManagerService,
		private config: DynamicDialogConfig,
		private prestataireService: PrestataireService,
	) {

		this.incidentPrestataire = clone(config.data.incidentPrestataire);
		this.pristine = clone(this.incidentPrestataire);
		this.prestataire = clone(config.data.prestataire);
		this.minDate.setFullYear(this.minDate.getFullYear() - 1);
	}

	ngOnInit(): void {
		this.eventManager.registerEvent('close-modals', this, (args: any) => {
			this.dialogRef.close();
		});
	}

	ngOnDestroy(): void {
		this.eventManager.unregisterEvent('close-modals', this);
	}

	cancel() {
		this.dialogRef.close();
	}

	submit() {
		if (simpleComparison(this.pristine, this.incidentPrestataire)) {
			this.dialogRef.close(null);
		}
		else {
			this.loading = true;
			let apiCall: Observable<any>;

			if (!this.incidentPrestataire.ipr_id) {
				apiCall = this.prestataireService.postIncidentPrestataire(this.incidentPrestataire);
			}
			else {
				apiCall = this.prestataireService.putIncidentPrestataire(this.incidentPrestataire);
			}

			apiCall.subscribe({
				next: (response: any) => {
					this.dialogRef.close(this.incidentPrestataire);
				}
			})
			.add(() => { this.loading = false; });
		}
	}
}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CalendarModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		UtilisateurSelectorModule,
	],
	exports: [IncidentPrestataireDialogComponent],
	declarations: [IncidentPrestataireDialogComponent]
})
export class IncidentPrestataireDialogModule { }

