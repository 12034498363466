import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import {
	clone,
	convertDateFieldsToDate,
	convertDateFieldsToString,
	prepareQueryParams
} from '@helpers/utils';

import {
	nbLignesVisionEntrepreneur,
	typesVisionEntrepreneur,
	TypeVisionEntrepreneur,
	VisionEntrepreneur
} from '@app/moa/moa.model';

@Injectable({ providedIn: 'root' })
export class MOAService {

	constructor(
		private http: HttpClient,
	) {

	}

	prepareMaVisionEntrepreneur(data: any) {
		let tmp = clone(data);
		typesVisionEntrepreneur.forEach((one: TypeVisionEntrepreneur) => {
			let typeVision: string = one.attr;
			if (!tmp[typeVision]) {
				tmp[typeVision] = [];
			}
			for (let i = 0; i < nbLignesVisionEntrepreneur; i++) {
				if (typeof tmp[typeVision][i] == 'undefined') {
					tmp[typeVision][i] = new VisionEntrepreneur();
				}
			}
		});
		convertDateFieldsToDate(tmp);

		return tmp;
	}

	getMaVisionEntrepreneur(uti_id: number) {
		return this.http.get<any>(`/ma_vision_entrepreneur/${uti_id}`)
			.pipe(map((response: any) => {
				return this.prepareMaVisionEntrepreneur(response);
			}));
	}

	postMaVisionEntrepreneur(uti_id: number, data: any) {
		let tmp = clone(data);
		convertDateFieldsToString(tmp);
		return this.http.post<any>(`/ma_vision_entrepreneur/${uti_id}`, tmp)
		.pipe(map((response: any) => {
			return this.prepareMaVisionEntrepreneur(response);
		}));
	}

	getAuditConformite(mag_id: number) {
		return this.http.get<any>(`/magasins/${mag_id}/audits_conformite`);
	}

	postAuditConformite(mag_id: number, data: any) {
		return this.http.post<any>(`/magasins/${mag_id}/audits_conformite`, data);
	}

	deleteAuditConformite(mag_id: number) {
		return this.http.delete<any>(`/magasins/${mag_id}/audits_conformite`);
	}

}
