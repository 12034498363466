import { NgModule, Component, Input, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileService } from '@global/file.service';

@Component({
	selector: 'file-icon',
	providers: [],
	template: `
		<ng-container [ngSwitch]="getFileType(file)">
			<ng-container *ngSwitchCase="'image'">
				<img [src]="$any(file).objectURL" *ngIf="isFile" loading="lazy"/>
				<span><i class="pi pi-icon pi-image" *ngIf="!isFile"></i></span>
			</ng-container>
			<span><i class="pi pi-icon pi-file-pdf" *ngSwitchCase="'pdf'"></i></span>
			<span><i class="pi pi-icon pi-file-excel" *ngSwitchCase="'excel'"></i></span>
			<span><i class="pi pi-icon pi-file" *ngSwitchDefault></i></span>
		</ng-container>
	`
})
export class FileIconComponent implements OnInit {

	@Input() file: File;

	isFile: boolean;

	constructor(private fileService: FileService) {

	}

	getFileType(file: File) {
		return this.fileService.getFileType(file);
	}

	ngOnInit() {
		this.isFile = this.file instanceof File;
	}
}

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [FileIconComponent],
	declarations: [FileIconComponent]
})
export class FileIconModule { }
