import { NgModule, Component, Input, forwardRef, OnInit, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormsModule,
	NG_VALUE_ACCESSOR
} from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DropdownModule } from '@app/primeng-overrides/dropdown';

import { MagasinService } from '@app/magasin/magasin.service';
import { simpleDateToString } from '@helpers/utils';
import { ControlValueAccessorBase } from '@app/_helpers/control-value-accessor-base';

@Component({
	selector: 'magasin-selector',
	 providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MagasinSelectorComponent),
			multi: true
		}
	],
	template: `
		<p-dropdown
			[name]="name"
			[required]="required"
			[options]="options"
			[(ngModel)]="value"
			[placeholder]="placeholder"
			[disabled]="disabled"
			[showClear]="nullOption && options.length > 1"
			optionValue="mag_id"
			[filter]="options.length > 5"
			filterBy="mag_nom,mag_nom_court,mag_id"
			[filterPlaceholder]="filterPlaceholder"
			appendTo="body"
		>
			<ng-template let-item pTemplate="selectedItem">
				<div class="selector-item">
					<div>{{item.mag_nom_court || item.label}} ({{item.mag_id}})</div>
				</div>
			</ng-template>
			<ng-template let-item pTemplate="item">
				<div class="selector-item">
					<div>{{item.mag_nom_court || item.label}} ({{item.mag_id}})</div>
				</div>
			</ng-template>
		</p-dropdown>
	`
})
export class MagasinSelectorComponent extends ControlValueAccessorBase<number|null|undefined> implements OnInit, OnChanges {

	@Input('value') innerValue: number|null|undefined;
	@Input() name: string;
	@Input() activeOnly: boolean;
	@Input() placeholder: string = 'Sélectionnez';
	@Input() filterPlaceholder: string = 'Filtrer...';
	@Input() required: boolean;
	@Input() nullOption: boolean;
	@Input() nullIfInvalid: boolean;
	@Input() disabled: boolean;
	@Input() autoSelectIfSingle: boolean;
	@Input() autoSelectFirst: boolean;
	@Input() silentInitialChangeIfSame: boolean;
	@Input() mesMagasins: boolean;
	@Input() periode: boolean;
	@Input() date_debut: string|Date;
	@Input() date_fin: string|Date;

	filters: {[key: string]: any} = {};
	options: any[] = [];
	initialized: boolean = false;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private magasinService: MagasinService) {
		super();
		this.debouncedLoad
		.pipe(debounceTime(50))
		.subscribe({
			next: (response: any) => {
				this.load();
			}
		});
	}

	ngOnInit() {
		if (!this.periode) {
			this.debouncedLoad.next(true);
		}
	}

	ngOnChanges(changes: any) {
		if (
			typeof changes.date_debut != 'undefined' && simpleDateToString(changes.date_debut.currentValue) != simpleDateToString(changes.date_debut.previousValue)
			|| typeof changes.date_fin != 'undefined' && simpleDateToString(changes.date_fin.currentValue) != simpleDateToString(changes.date_fin.previousValue)
		) {
			this.debouncedLoad.next(true);
		}
	}

	getEntityName(full: boolean = false) {
		const found = this.options.find(one => { return one.mag_id == this.innerValue; });
		if (found) {
			return full? this.magasinService.getMagasinLabel(found): found.mag_nom_court;
		}
		return null;
	}

	load() {
		if (this.mesMagasins)	{
			this.setOptions(this.magasinService.mesMagasins);
		}
		else {
			let date_debut: Date|string = (this.date_debut)? this.date_debut : new Date();
			let date_fin: Date|string = (this.date_fin)? this.date_fin : new Date();
			let params = {
				date_debut: simpleDateToString(date_debut, true),
				date_fin: simpleDateToString(date_fin, true)
			}

			this.magasinService.getList(params)
			.subscribe({
				next: (response: any) => {
					this.setOptions(response);
				}
			});
		}
	}

	setOptions(options: any[]) {
		this.options = options;
		setTimeout(() => {
			this.setInitialValue();
		}, 0)
	}

	setInitialValue() {
		const incoming = this.innerValue;
		if (!this.options.length) {
			this.innerValue = null;
		}
		else {
			if (this.options.findIndex(one => {return one.mag_id == this.innerValue; }) < 0) {
				if (this.nullIfInvalid) {
					this.innerValue = null;
				}
				if (this.autoSelectFirst || this.autoSelectIfSingle && this.options.length === 1) {
					this.innerValue = this.options[0].mag_id;
				}
			}
		}
		if (incoming != this.innerValue || !this.silentInitialChangeIfSame) {
			this.value = this.innerValue;
		}
	}

}


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		DropdownModule,
	],
	exports: [MagasinSelectorComponent],
	declarations: [MagasinSelectorComponent]
})
export class MagasinSelectorModule { }
