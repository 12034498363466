import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { ConfigService } from '@global/config.service';
import { CommunicationLocaleService, BudgetCommunication, CommunicationLocale } from '@app/communication-locale/communication-locale.service';
import { AnneeSelectorModule } from '@app/annee-selector';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { MagasinSelectorModule } from '@app/magasin/magasin-selector';
import { NumberDisplayModule } from '@helpers/number-display';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TableCommunicationsLocalesModule } from '@app/communication-locale/table-communications-locales';
import { TitreModuleModule } from '@app/titre-module';
import { UtilisateurSelectorModule } from '@app/utilisateur/utilisateur-selector';

import {
	clone,
	simpleComparison,
} from '@helpers/utils';

@Component({
	selector: 'suivi-communication-locale',
	templateUrl: './suivi-communication-locale.html',
	providers: [],
})
export class SuiviCommunicationLocaleComponent {


	previousBudget: BudgetCommunication;
	budget: BudgetCommunication;
	loading: boolean;

	debouncedLoad: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	debouncedSubmit: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	minYear: number;

	annee: number;
	mag_id: number;

	taux_budget_communication: number;
	taux_budget_communication_nationale: number;
	taux_budget_communication_locale: number;

	dateDebut: Date = new Date();
	dateFin: Date = new Date();

	constructor(
		private configService: ConfigService,
		private communicationLocaleService: CommunicationLocaleService,
		private storageService: StorageService,
	) {
		this.debouncedLoad
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.load();
			}
		});

		this.debouncedSubmit
		.pipe(debounceTime(500))
		.subscribe({
			next: (response: any) => {
				this.submit();
			}
		});

		this.minYear = this.configService.config.annee_reference;
		this.taux_budget_communication = this.configService.config.taux_budget_communication;
		this.taux_budget_communication_nationale = this.configService.config.taux_budget_communication_nationale;
		this.taux_budget_communication_locale = this.configService.config.taux_budget_communication_locale;
	}

	ngOnInit() {
		this.getParamSet();
	}

	saveParamSet() {
		this.storageService.setForCurrentState('annee', this.annee);
		this.storageService.setForCurrentState('mag_id', this.mag_id);
	}

	getParamSet() {
		this.annee =  this.storageService.getForCurrentState('annee', this.annee);
		this.mag_id =  this.storageService.getForCurrentState('mag_id', this.mag_id);
	}

	anneeChange(event: any) {
		this.dateDebut = new Date(event, 0, 1);
		this.dateFin = new Date(event +1, 0, -1);
	}

	paramChanged() {
		this.debouncedLoad.next(true);
	}

	caPrevuChanged() {
		this.budget.buc_budget = null;
		this.saisieChanged();
	}

	saisieChanged() {
		this.debouncedSubmit.next(true);
	}

	load() {
		if (this.mag_id) {
			// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
			Promise.resolve(null).then(() => this.loading = true);

			this.saveParamSet();
			this.communicationLocaleService.getBudgetCommunication(this.mag_id, this.annee)
			.subscribe({
				next: (response: any) => {
					this.budget = response;
					this.previousBudget = clone(this.budget)
				}
			})
			.add(() => { this.loading = false; });
		}
	}

	submit() {
		if (!simpleComparison(this.previousBudget, this.budget) && !this.loading) {
			this.loading = true;
			this.communicationLocaleService.putBudgetCommunication(this.budget)
			.subscribe({
				next: (response: any) => {
					this.load();
				}
			})
			.add(() => { this.loading = false; });
		}
	}

}
@NgModule({
	declarations: [
		SuiviCommunicationLocaleComponent
	],
	exports: [
		SuiviCommunicationLocaleComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		InputTextModule,
		TableModule,
		AnneeSelectorModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		MagasinSelectorModule,
		NumberDisplayModule,
		PrintButtonModule,
		TableCommunicationsLocalesModule,
		TitreModuleModule,
		UtilisateurSelectorModule,
	],
})
export class SuiviCommunicationLocaleModule { }
