import { Component, NgModule, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { Observable, concat, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { BlockUIModule } from 'primeng/blockui'
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';

import { MagasinService, Magasin } from '@app/magasin/magasin.service';
import { DeclarationService } from '@app/declaration/declaration.service';
import { DeclarationMensuelleFormModule } from './declaration-mensuelle-form';

import { clone } from '@helpers/utils';

@Component({
	selector: 'declaration-mensuelle',
	templateUrl: './declaration-mensuelle.html',
	providers: []
})
export class DeclarationMensuelleComponent {

	loading: boolean;
	hasDeclarations: boolean;
	magasins: Magasin[];

	constructor(
		private magasinService: MagasinService,
		private declarationService: DeclarationService
	) {

	}

	ngOnInit() {
		this.fetchDeclarations();
	}

	fetchDeclarations() {
		this.loading = true;
		this.magasins = clone(this.magasinService.mesMagasins);

		if (!this.magasins.length) {
			this.loading = false;
		}

		let requests: Observable<any>[] = [];

		this.magasins.forEach((magasin: Magasin) => {
			requests.push(this.fetchOne(magasin))
		});

		forkJoin(
			concat(...requests)
			.pipe(map(
				(declaration: any) => {
					if (declaration) {
						let mag = this.magasins.find((magasin: Magasin) => {
							return magasin.mag_id == declaration.mag_id;
						});
						if (mag) {
							mag.declaration_mensuelle = declaration;
						}
					}
					return true;
				}
			))
		).subscribe(
			(all: any) => {
				this.loading = false;
			},
			(error: any) => {
				console.log('fetchDeclarations error', error);
				this.loading = false;
			}
		);
	}

	fetchOne(magasin: Magasin) {
		magasin.loading = true;
		return this.declarationService.getDeclarationMensuelleACreer(magasin.mag_id)
		.pipe(map(
			(declaration: any) => {
				magasin.declaration_mensuelle = declaration;

				this.countDeclarations();
				magasin.loading = false;
				return true;
			},
			(error: any) => {
				magasin.loading = false;
			}
		));
	}

	countDeclarations() {
		this.hasDeclarations = false;
		this.magasins.forEach((magasin: Magasin) => {
			if (magasin.declaration_mensuelle) {
				this.hasDeclarations = true;
			}
		});
	}

	refreshOne(magasin: Magasin) {
		this.loading = true;
		this.fetchOne(magasin)
		.subscribe(
			(all: any) => {
				this.loading = false;
			},
			(error: any) => {
				console.log('fetchDeclarations error', error);
				this.loading = false;
			}
		);
	}


}
@NgModule({
	declarations: [
		DeclarationMensuelleComponent
	],
	exports: [
		DeclarationMensuelleComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		BlockUIModule,
		MessageModule,
		PanelModule,
		DeclarationMensuelleFormModule,
	],
})
export class DeclarationMensuelleModule { }
