<div class="mb-3">
	<div><span class="font-bold">Date&nbsp;:</span> {{ evenement.eve_date_evenement | date:'dd/MM/yyyy HH:mm'}}</div>
	<div><span class="font-bold">Mis en attente le&nbsp;:</span> {{ evenement.eve_date_attente_debut | date:'dd/MM/yyyy HH:mm'}}</div>
	<div><span class="font-bold">N° Option WINNER&nbsp;:</span> {{ evenement.eve_option_id }}</div>
	<div><span class="font-bold">Vendeur&nbsp;:</span> {{ evenement.uti_prenom }} {{ evenement.uti_nom }}</div>
	<div><span class="font-bold">Client&nbsp;:</span> {{ evenement.eve_client }}</div>
	<div><span class="font-bold">Milieu&nbsp;:</span> {{ evenement.eve_milieu }}</div>

	<div><span class="font-bold">Montant budget&nbsp;TTC&nbsp;:</span>
		<number-display
			[value]="evenement.eve_mt_budget_ttc"
			type="currency"
			numberFormat=".0-0"
		></number-display>
	</div>
</div>

<form class="" #terminerAttenteForm="ngForm">
	<div class="formgrid grid p-fluid">
		<div class="field col-fixed">
			<label for="eve_date_attente_fin">Sélectionnez une date de validation</label>
			<div class="flex">
				<p-calendar
					name="eve_date_attente_fin"
					[(ngModel)]="eve_date_attente_fin"
					[showTime]="false"
					[minDate]="minDate"
					[maxDate]="maxDate"
					[showIcon]="true"
					appendTo="body"
					[required]="true"
				></p-calendar>
			</div>
		</div>

	</div>

</form>

<div class="p-dialog-footer text-right">
	<button
		type="button"
		pButton
		class="btn p-button-sm p-button-text"
		label="Annuler"
		[disabled]="loading"
		(click)="cancel()"
	></button>
	<button
		type="button" pButton
		class="btn p-button-sm"
		label="Valider"
		[disabled]="!terminerAttenteForm.form.valid || loading"
		(click)="submit()"
	></button>
</div>
