import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, Table } from '@app/primeng-overrides/table';

import { AnneeSelectorModule } from '@app/annee-selector';
import { DetailPlanActionsDialogComponent } from '@app/plan-actions/detail-plan-actions-dialog';
import { EventManagerService } from '@global/event-manager.service';
import { GlobalModule } from '@global/global.module';
import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { NumberDisplayModule } from '@helpers/number-display';
import { ObjectifsService, PlanActions, DetailPlanActions } from '@app/objectifs/objectifs.service';
import { PlanActionsDialogComponent } from '@app/plan-actions/plan-actions-dialog';
import { StorageService } from '@global/storage.service';

import { clone } from '@helpers/utils';

@Component({
	selector: 'table-plan-actions',
	templateUrl: './table-plan-actions.html',
	providers: [],
})
export class TablePlanActionsComponent {

	@Input() mag_id: number;
	@Input() uti_id: number|null;

	@ViewChild('tablePlanActions') table: Table;

	plansActions: any[] = [];
	values: any[] = [];

	loading: boolean = false;
	exportLoading: boolean = false;
	expandedRows: any = {};

	showTermines: boolean;

	today: Date = new Date();

	constructor(
		private confirmationService: ConfirmationService,
		private dialogService: DialogService,
		private eventManager: EventManagerService,
		private objectifsService: ObjectifsService,
		private storageService: StorageService,
	) {

	}

	ngOnInit() {
		this.getParamSet();
		// this.load();
	}

	ngOnChanges(changes: any) {
		if (
			changes.uti_id && changes.uti_id.currentValue != changes.uti_id.previousValue
			|| changes.mag_id && changes.mag_id.currentValue != changes.mag_id.previousValue
		) {
			this.load()
		}
	}

	getParamSet() {
		this.showTermines = this.storageService.getForCurrentState('showTermines', false);
	}

	saveParamSet() {
		this.storageService.setForCurrentState('showTermines', this.showTermines);
	}

	load() {
		// // https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		this.objectifsService.getPlanActions(this.mag_id, this.uti_id)
		.subscribe({
			next: (response: any) => {
				this.plansActions = response.plans_actions;
				this.prepareFromServer(this.plansActions);
				this.values = this.plansActions;
			}
		})
		.add(() => { this.loading = false; });
	}

	prepareFromServer(values: any[]) {
		values.forEach((one: any) => {
			this.expandedRows[one.pla_id] = true;
		})
	}

	showPlanActionsDialog(planActions?: PlanActions) {
		let tmp: PlanActions;
		let header: string;
		if (!planActions) {
			tmp = new PlanActions(this.mag_id, this.uti_id);
			header = `Nouveau plan d'actions`;
		}
		else {
			tmp = clone(planActions);
			header = planActions.pla_libelle;
		}

		const ref = this.dialogService.open(PlanActionsDialogComponent, {
			data: {
				planActions: tmp
			},
			header: header
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.load();
			}
		});
	}

	deletePlanActions(planActions: PlanActions) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer ce plan d'actions ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.objectifsService.deletePlanActions(planActions)
				.subscribe({
					next: (ok:any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: `Plan d'actions supprimé`});
						this.load();
					}
				})
			}
		});
	}

	newDetailPlanActions(planActions: PlanActions) {
		let detail: DetailPlanActions = new DetailPlanActions(planActions.pla_id!, planActions.mag_id);
		this.showDetailPlanActionsDialog(detail);
	}

	showDetailPlanActionsDialog(detail: DetailPlanActions) {
		let header: string;
		if (!detail.dpa_id) {
			header = `Nouvelle action`;
		}
		else {
			header = detail.dpa_libelle;
		}

		const ref = this.dialogService.open(DetailPlanActionsDialogComponent, {
			data: {
				detailPlanActions: clone(detail)
			},
			header: header,
			style: {'max-width': '500px'}
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.load();
			}
		});
	}

	deleteDetailPlanActions(detail: DetailPlanActions) {
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: 'Souhaitez-vous vraiment supprimer cette action ?',
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.objectifsService.deleteDetailPlanActions(detail)
				.subscribe({
					next: (ok:any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Action supprimée'});
						this.load();
					}
				})
			}
		});
	}

	isDone(planActions: PlanActions) {
		return !planActions.details.some((detail: DetailPlanActions) => {
			return !detail.dpa_date_terminee;
		})
	}

	toggleEtatDetailPlanActions(detail: DetailPlanActions) {
		// let message: string = 'Souhaitez-vous vraiment marquer cette action comme terminée ?';
		// if (!terminer) {
		// 	message = 'Souhaitez-vous vraiment marquer cette action comme terminée ?'
		// }
		// this.confirmationService.confirm({
		// 	defaultFocus: 'reject',
		// 	message: message,
		// 	rejectButtonStyleClass: 'p-button-text',
		// 	accept: () => {
				let tmp: DetailPlanActions = clone(detail);
				tmp.dpa_date_terminee = tmp.dpa_date_terminee? null : new Date();

				this.objectifsService.putDetailPlanActions(tmp)
				.subscribe({
					next: (ok:any) => {
						this.eventManager.emit('toast', {severity: 'success', summary: 'Modification effectuée'});
						this.load();
					}
				})
		// 	}
		// });
	}


}
@NgModule({
	declarations: [
		TablePlanActionsComponent
	],
	exports: [
		TablePlanActionsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		InputTextModule,
		TableModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		AnneeSelectorModule,
		NumberDisplayModule,
	],
})
export class TablePlanActionsModule { }
