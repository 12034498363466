import { TrackedObject } from '@helpers/tracked-object';
import { Utilisateur } from '@app/utilisateur/utilisateur.model';
import { ExtensibleObject } from '@app/_helpers/utils';

export type NiveauCompetence = {
	nic_code: string,
	nic_libelle: string,
	nic_ordre: number,
}

export class CategorieCompetenceCollaborateur {
	libelle: string;
	ordre: number;
	couleur: string;
	couleur_texte: string;
	sous_categories: SousCategorieCompetenceCollaborateur[];
}

export class SousCategorieCompetenceCollaborateur {
	libelle: string;
	ordre: number;
	competences: CompetenceCollaborateur[];
}

export class CompetenceCollaborateur {
	libelle: string;
	ordre: number;
	criteres_evaluations: CritereEvaluationCollaborateur[];
}

export class CritereEvaluationCollaborateur {
	uid: string;
	libelle: string;
	ordre: number;
	actif: boolean;
	niveau_competence: string;
	plan_action: string;
}

export class LigneFormulaireEvaluationCollaborateur extends ExtensibleObject {
	categorie?: CategorieCompetenceCollaborateur;
	sous_categorie?: SousCategorieCompetenceCollaborateur;
	competence?: CompetenceCollaborateur;
	critere_evaluation?: CritereEvaluationCollaborateur;
	rowGroup?: string;
	rowGroupLabel?: string;
	firstCritere?: boolean;
	separator?: boolean
}

export type QuestionnaireEvaluationCollaborateur = {
	profil: string, // correspondant au pco_code
	categories: [
		{
			libelle: string,
			ordre: number,
			couleur: string,
			couleur_texte: string,
			sous_categories: [
				{
					libelle: string,
					ordre: number,
					competences: [
						{
							libelle: string,
							ordre: number,
							criteres_evaluations: [
								{
									uid: string,
									libelle: string,
									ordre: number,
									actif: boolean,
									niveau_competence: string,
									plan_action: string
								}
							]
						}
					]
				}
			]
		}
	]
}

export class ProfilCompetence {
	pco_code: string;
	pco_libelle: string;
	pco_actif: boolean;
	pco_questionnaire: QuestionnaireEvaluationCollaborateur;
}


export class EvaluationCollaborateur extends TrackedObject {
	evc_id: number;
	uti_id: number;
	pco_code: string;
	evc_periode: string;
	evc_questionnaire: QuestionnaireEvaluationCollaborateur;

	collaborateur: Utilisateur;

	constructor(uti_id?: number) {
		super();
		if (uti_id) this.uti_id = uti_id;
	}
}
