<h1 class="sm:flex align-items-center flex-shrink-0">
	<titre-module>Ma vision entrepreneur</titre-module>
	<print-button-simple [track]="true"></print-button-simple>
	<lien-documentation prm_code="documentation_vision_entrepreneur"></lien-documentation>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Franchisé</td>
				<td class="text-right">
					<ng-container *ngIf="isManager">
						<span>{{userName}}</span>
					</ng-container>
					<ng-container *ngIf="!isManager">
						<utilisateur-selector
							#utilisateurSelector
							name="utilisateur"
							[(ngModel)]="uti_id"
							[nullOption]="false"
							[nullIfInvalid]="false"
							[silentInitialChangeIfSame]="true"
							[franchisesOnly]="true"
							(ngModelChange)="paramChanged()"
							class="hide-print"
						></utilisateur-selector>
						<span class="show-print">{{utilisateurSelector.getEntityName()}}</span>
					</ng-container>
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>



	<p-table
		[value]="[1]"
		[loading]="loading"
		responsiveLayout="stack"
		[autoLayout]="true"
		breakpoint="576px"
		class=""
		styleClass="p-datatable-sm p-datatable-gridlines table-full-w"
	>
		<ng-template pTemplate="header">

		</ng-template>

		<ng-template pTemplate="body">
			<ng-container *ngIf="saisies">

				<ng-container *ngFor="let typeVision of typesVisionEntrepreneur">
					<tr>
						<td class="clear-cell cell-spacer" colspan="100"></td>
					</tr>

					<tr>
						<td class="text-center clear-cell cell-th" colspan="3">{{typeVision.titre}}</td>
					</tr>
					<tr>
						<td class="nowrap clear-cell"></td>
						<td class="md:min-w-20rem w-3 max-w-30rem text-center cell-th">{{typeVision.headerColumnTitre}}</td>
						<td class="text-center cell-th">{{typeVision.headerColumnDetail}}</td>
					</tr>
					<tr *ngFor="let item of [].constructor(nbLignesVisionEntrepreneur); let i = index">
						<td class="text-center">{{i+1}}</td>
						<ng-container *ngIf="!isManager">
							<td>
								{{saisies[typeVision.attr][i].titre}}
							</td>
							<td>
								<pre class="font-normal">{{saisies[typeVision.attr][i].detail}}</pre>
							</td>
						</ng-container>
						<ng-container *ngIf="isManager">
							<td class="vertical-align-top">
								<textarea
									pInputTextarea
									[(ngModel)]="saisies[typeVision.attr][i].titre"
									[ngModelOptions]="{standalone: true}"
									[autoResize]="true"
									(blur)="saisieChanged()"
									[readOnly]="!isManager"
									class=" w-full hide-print"
								></textarea>
								<pre class="show-print">{{saisies[typeVision.attr][i].titre}}</pre>
							</td>
							<td>
								<textarea
									pInputTextarea
									[(ngModel)]="saisies[typeVision.attr][i].detail"
									[ngModelOptions]="{standalone: true}"
									[autoResize]="true"
									(blur)="saisieChanged()"
									[readOnly]="!isManager"
									class="w-full hide-print"
								></textarea>
								<pre class="show-print">{{saisies[typeVision.attr][i].detail}}</pre>
							</td>
							<td class="hide-print nowrap">
								<button
									type="button"
									pButton
									icon="pi pi-sort-up"
									class="p-button-rounded p-button-text p-button-sm"
									(click)="up(typeVision.attr, i)"
									[disabled]="i == 0"
								></button>
								<button
									type="button"
									pButton
									icon="pi pi-sort-down"
									class="p-button-rounded p-button-text p-button-sm"
									(click)="down(typeVision.attr, i)"
									[disabled]="i == nbLignesVisionEntrepreneur - 1"
								></button>
							</td>
						</ng-container>
					</tr>

				</ng-container>
			</ng-container>
		</ng-template>

		<ng-template pTemplate="footer">
			<tr>
				<td class="clear-cell cell-spacer" colspan="100"></td>
			</tr>
			<ng-container *ngIf="saisies">
				<tr>
					<td class="clear-cell font-normal text-sm" colspan="100" *ngIf="saisies.date_modification">Modifié le {{saisies.date_modification|date:'medium'}}</td>
				</tr>
			</ng-container>
		</ng-template>
	</p-table>

