<div>

	<p-table
		[autoLayout]="true"
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines mb-3"
	>
		<ng-template pTemplate="header">
			<tr>
				<th class="text-center" *ngFor="let indicateur of indicateurs">
					<indicateur-label-display [ind_code]="indicateur.ind_code" [tooltipIcon]="indicateur.tooltipIcon"></indicateur-label-display>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body">
			<tr>
				<td class="text-center" *ngFor="let indicateur of indicateurs">
					<ng-container *ngIf="valeursN">
						<!-- {{ valeursN[indicateur.ind_code] }} -->
						<indicateur-value-display [value]="valeursN[indicateur.ind_code]" [ind_code]="indicateur.ind_code"></indicateur-value-display>
					</ng-container>
				</td>
				<td class="nowrap">
					<button
						type="button"
						pButton
						icon="pi pi-external-link"
						class="p-button-text p-button-xs"
						(click)="showDetailVentes()"
						title="Détails des ventes"
					></button>
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>
