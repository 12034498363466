import {
	Directive,
	Input,
	HostListener,
	ElementRef,
	OnInit,
	forwardRef
} from '@angular/core';

import {
	NG_VALUE_ACCESSOR,
	Validator,
	NG_VALIDATORS,
	ValidationErrors,
	AbstractControl
} from '@angular/forms';

import { formatNumber } from '@angular/common';

import { ControlValueAccessorBase } from './control-value-accessor-base';

import { isNumeric } from '@helpers/utils';

@Directive({
	selector: 'input[gzInputNumberFormatter]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => GZInputNumberFormatterDirective),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => GZInputNumberFormatterDirective),
			multi: true
		}
	]
})
export class GZInputNumberFormatterDirective extends ControlValueAccessorBase<string|number|null> implements OnInit, Validator {

	@Input() name: string;
	@Input('value') innerValue: string|number|null;
	@Input() currency: boolean;
	@Input() decimals: number;

	private el: HTMLInputElement;

	locale = 'fr';
	decimalMarker: string = '.';
	acceptedDecimalMarkers: RegExp = /(,|\.)/g;
	thousandSeparator: string = ' ';

	constructor(private elementRef: ElementRef) {
		super();
		this.el = this.elementRef.nativeElement;
	}

	ngOnInit() {
		if (this.currency && typeof this.decimals == 'undefined') {
			this.decimals = 2;
		}
		// determine separators from locale
		const [thousandSeparator, decimalMarker] = formatNumber(1000.99, this.locale).replace(/\d/g, '');
		this.thousandSeparator = thousandSeparator;
		this.decimalMarker = decimalMarker;

	}

	override writeValue(value: string|number|null) {
		if (typeof value != 'undefined') {
			this.innerValue = value;
			this.formatValue(this.value);
		}
	}

	@HostListener('blur')
	_onBlur() {
		this.formatValue(this.innerValue);
	}

	@HostListener('focus', ['$event'])
	onFocus($event: any) {
		this.unFormatValue();
		$event.srcElement.select();
	}


	@HostListener('keydown', ['$event'])
	onKeyDown($event: any) {
		let e = <KeyboardEvent> $event;

		let value = $event.target.value;

		// console.log(
		// 	'code:' + e.code,
		// 	'key:' + e.key,
		// 	'keycode:' + e.keyCode,
		// 	'ctrlKey:' + e.ctrlKey,
		// 	'shiftKey:' + e.shiftKey,
		// 	'altKey:' + e.altKey,
		// 	'metaKey:' + e.metaKey
		// );

		if (
			[
				'alt',
				'arrowdown',
				'arrowleft',
				'arrowright',
				'arrowup',
				'backspace',
				'control',
				'delete',
				'end',
				'enter',
				'escape',
				'home',
				'insert',
				'meta',
				'shift',
				'tab',
			].indexOf(e.key.toLowerCase()) !== -1
			|| (e.key.toUpperCase() == 'A' && (e.ctrlKey || e.metaKey))
			|| (e.key.toUpperCase() == 'C' && (e.ctrlKey || e.metaKey))
			|| (e.key.toUpperCase() == 'V' && (e.ctrlKey || e.metaKey))
			|| (e.key.toUpperCase() == 'X' && (e.ctrlKey || e.metaKey))
		) {
			// Allow
			return;
		}

		const isAllSelected = $event.target.selectionStart == 0 && $event.target.selectionEnd == $event.target.value.length;

		// Prevent double separators
		if (e.key == '.' || e.key == ',') {
			const hasDecimalSeparator = /(,|\.)/.test(value);
			if (!isAllSelected && (hasDecimalSeparator || this.decimals == 0)) {
				e.preventDefault();
			}
			return;
		}

		// Prevent misplaced minus
		if (e.key == '-') {
			const hasMinus = /-/.test(value);
			if (!isAllSelected && (hasMinus || $event.target.selectionStart != 0)) {
				e.preventDefault();
			}
			return;
		}

		// Ensure that it is a number
		if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(e.key) == -1) {
			e.preventDefault();
		}

	}

	@HostListener('input', ['$event'])
	input($event: any) {

		let value = $event.target.value;
		// console.log('input event, inner, value', $event, this.innerValue, value);
		// replace decimal markers
		value = value.replace(this.acceptedDecimalMarkers, this.decimalMarker);

		// Find all numerics, decimal marker(, or .) and -
		const regExp = new RegExp(`[^\\d${this.decimalMarker}-]`, 'g');
		// Separate value on before and after decimal marker
		let [integer, decimal] = value.replace(regExp, '').split(this.decimalMarker);

		// Limit decimals length
		if (decimal && decimal.length > this.decimals) decimal = decimal.substring(0, this.decimals);

		// Send non localized value, with dot as decimalMarker to API
		this.innerValue = (decimal) ? integer.concat('.', decimal) : integer;

		// If decimal separator is last character don't update
		// because it will delete . || ,
		if (
			this.innerValue
			&& this.isLastCharacterDecimalSeparator(this.value)) {
			this.innerValue = this.innerValue.toString().substring(0, this.innerValue.toString().length -1);
			// return;
		}
		this.innerValue = (this.innerValue && isNumeric(this.innerValue))? Number.parseFloat(this.innerValue.toString()) : null;
		// here to notify Angular Validators
		this.onChange(this.innerValue);
	}

	isLastCharacterDecimalSeparator(value: any) {
		return /(,|\.)$/g.test(value);
	}

	formatValue(value: string|number|null) {
		// console.log('format el, value', this.el.value, value);
		if (value === null) {
			this.el.value = '';
			return;
		}

		if (this.isLastCharacterDecimalSeparator(value)) {
			this.el.value = value.toString();
			return;
		}

		// Replace decimal markers
		value = value.toString().replace(this.acceptedDecimalMarkers, this.decimalMarker);

		let [integer, decimal] = value.split(this.decimalMarker);

		//Group every three elements, and add thousandSeparator after them
		this.el.value = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);

		//Add decimals and decimalMarker if any
		if (decimal) {
			if (!this.el.value) this.el.value = '0';
			else if (this.el.value == '-') this.el.value = '-0';
			this.el.value = this.el.value.concat(this.decimalMarker, decimal);
			if (typeof this.decimals != 'undefined') {
				const pad = this.decimals - decimal.length;
				for (let i=0; i<pad; i++) {
					this.el.value += '0';
				}
			}
		}

	}

	unFormatValue() {

		// console.log('unformat inner, value', this.innerValue, this.el.value);

		const value = this.el.value.toString();
		if (this.isLastCharacterDecimalSeparator(value)) {
			return;
		}
		const regExp = new RegExp(`[^\\d${this.decimalMarker}-]`, 'g');
		const [integer, decimal] = value.replace(regExp, '').split(this.decimalMarker);

		if (decimal) {
			this.innerValue = integer.concat(this.decimalMarker, decimal);
		}
		else {
			this.innerValue = integer;
		}
		// console.log('unformated ', this.innerValue);
		if (value) {
			this.el.value = this.innerValue;
		} else {
			this.el.value = '';
		}
	}

	validate(control: AbstractControl): ValidationErrors|null {
		const pattern = /^-?\d*(,|\.)?\d*$/;
		let result = true;
		if (typeof this.innerValue != 'undefined' && this.innerValue !== null) {
			result = pattern.test(this.innerValue.toString());
		}
		return (!result)? {'pattern': true} : null;
	}

}
