import { Component, NgModule, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';

import { NgForm, FormsModule } from '@angular/forms';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from '@app/primeng-overrides/dynamicdialog';
import { DropdownModule } from '@app/primeng-overrides/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { LazyLoadEvent } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule, Table } from '@app/primeng-overrides/table';
import { TagModule } from 'primeng/tag';


import { GlobalModule } from '@global/global.module';

import {
	Prestataire,
	IncidentPrestataire,
} from '@app/moa/suivi-prestataires/prestataire.model';
import { PrestataireService } from '@app/moa/suivi-prestataires/prestataire.service';
import { IncidentPrestataireDialogComponent } from '@app/moa/suivi-prestataires/incident-prestataire-dialog';
import { PrintButtonModule } from '@app/print-button';
import { StorageService } from '@global/storage.service';
import { TitreModuleModule } from '@app/titre-module';
import { statutsIncidentPrestataire } from '@app/moa/suivi-prestataires/prestataire.model';
import { AuthService } from '@app/auth/auth.service';
import { BackButtonModule } from '@app/_global/back-button';

@Component({
	selector: 'incidents-prestataire',
	templateUrl: './incidents-prestataire.html',
	providers: [],
})
export class IncidentsPrestataireComponent {

	@Input() prestataire: Prestataire;

	@ViewChild('tableIncidents', {static: true}) table: Table;

	loading: boolean = false;

	droitsCanEdit: string[] = ['ag', 'chef_ventes', 'manager'];
	canEdit: boolean = false;

	totalRecords: number = 0;
	rows: number = 20;
	values: IncidentPrestataire[] = [];

	statutsIncidentPrestataire: any[] = statutsIncidentPrestataire;
	params: any = {
		resolu: null
	}

	constructor(
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private datePipe: DatePipe,
		private dialogService: DialogService,
		private prestataireService: PrestataireService,
		private storageService: StorageService,
	) {

		this.canEdit = this.authService.checkIfHasRight(this.droitsCanEdit);

	}

	ngOnInit() {
		this.refresh();
	}

	refresh(stayOnPage?: boolean) {
		if (!stayOnPage) this.table._first = 0;
		this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
	}

	load(event: LazyLoadEvent) {
		// https://github.com/primefaces/primeng/issues/8465#issuecomment-617887919
		Promise.resolve(null).then(() => this.loading = true);

		let tmpParams = Object.assign({}, event, this.params);

		this.prestataireService.getIncidentsPrestataire(this.prestataire.prs_id, tmpParams)
		.subscribe({
			next: (response: any) => {
				this.totalRecords = response.total;
				this.values = response.incidents;
			}
		})
		.add(() => { this.loading = false; });

	}

	showIncidentPrestataireDialog(incident?: IncidentPrestataire, field?: string) {
		let header: string = incident? `Incident ` : 'Nouvel incident';
		if (incident) {
			const dateIncident = this.datePipe.transform(incident.ipr_date, 'mediumDate');
			header += `du ${dateIncident}`;
		}
		const prestataire: Prestataire = this.prestataire;
		header = `${header} pour ${prestataire.prs_societe}`;
		if (!incident) incident = new IncidentPrestataire(this.prestataire.prs_id);
		const ref = this.dialogService.open(IncidentPrestataireDialogComponent, {
			data: {
				incidentPrestataire: incident,
				prestataire: this.prestataire
			},
			header: header,
			styleClass: 'max-w-50rem'
		});

		ref.onClose.subscribe((dialogResult: any) =>{
			if (dialogResult) {
				this.refresh();
			}
		});
	}

	deleteIncidentPrestataire(incident: IncidentPrestataire) {
		const dateIncident = this.datePipe.transform(incident.ipr_date, 'mediumDate');
		this.confirmationService.confirm({
			defaultFocus: 'reject',
			message: `Souhaitez-vous vraiment supprimer l'incident du ${dateIncident} de ${incident.prestataire.prs_societe} ?`,
			rejectButtonStyleClass: 'p-button-text',
			accept: () => {
				this.prestataireService.deleteIncidentPrestataire(incident)
				.subscribe({
					next: () => { this.refresh(); }
				})
			},
		});
	}




}
@NgModule({
	declarations: [
		IncidentsPrestataireComponent
	],
	exports: [
		IncidentsPrestataireComponent
	],
	imports: [
		CommonModule,
		UIRouterModule,
		FormsModule,
		ButtonModule,
		CheckboxModule,
		DropdownModule,
		InputTextModule,
		InputTextareaModule,
		MessageModule,
		RadioButtonModule,
		SelectButtonModule,
		TableModule,
		TagModule,
		GlobalModule,
		PrintButtonModule,
		TitreModuleModule,
		BackButtonModule,
	],
})
export class IncidentsPrestataireModule { }
