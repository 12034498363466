import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Utilisateur } from '@app/utilisateur/utilisateur.model';

@Injectable({providedIn: 'root'})
@Pipe({name: 'utilisateur'})
export class UtilisateurPipe implements PipeTransform {

	constructor() {}

	transform(utilisateur: Utilisateur|null): string {
		if (utilisateur) {
			return `${utilisateur.uti_prenom} ${utilisateur.uti_nom}`;
		}
		return '';
	}
}
