import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import {
	prepareQueryParams,
} from '@helpers/utils';


export class BudgetCommunication {
	mag_id: number;
	buc_annee: number;
	buc_ca_prevu: number;
	buc_budget: number|null;

	constructor(mag_id: number, buc_annee: number) {
		this.mag_id = mag_id;
		this.buc_annee = buc_annee;
	}
}

export class CommunicationLocale {
	clo_id: number;
	mag_id: number;
	clo_annee: number;
	clo_date_debut: string;
	clo_date_fin: string|null;
	clo_media: string;
	clo_prestataire: string|null;
	clo_montant: number;
	clo_trafic: string;
	clo_benefices: number|null;

	constructor(mag_id: number, clo_annee: number) {
		this.mag_id = mag_id;
		this.clo_annee = clo_annee;
	}
}


@Injectable({ providedIn: 'root' })
export class CommunicationLocaleService {


	constructor(private http: HttpClient) {

	}

	getBudgetCommunication(mag_id: number, annee: number) {
		return this.http.get<any>(`/magasins/${mag_id}/budget_communication/${annee}`);
	}

	putBudgetCommunication(budget: BudgetCommunication) {
		return this.http.put<any>(`/magasins/${budget.mag_id}/budget_communication/${budget.buc_annee}`, budget);
	}

	getCommunicationsLocales(mag_id: number, annee: number) {
		let tmpParams: any = prepareQueryParams({annee: annee});
		return this.http.get<any>(`/magasins/${mag_id}/communications_locales`, tmpParams);
	}

	postCommunicationLocale(communication: CommunicationLocale) {
		return this.http.post<any>(`/magasins/${communication.mag_id}/communications_locales`, communication);
	}

	putCommunicationLocale(communication: CommunicationLocale) {
		return this.http.put<any>(`/magasins/${communication.mag_id}/communications_locales/${communication.clo_id}`, communication);
	}

	deleteCommunicationLocale(communication: CommunicationLocale) {
		return this.http.delete<any>(`/magasins/${communication.mag_id}/communications_locales/${communication.clo_id}`);
	}

}
