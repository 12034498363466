<h1 class="flex-shrink-0">
	<titre-module>Classement Magasins</titre-module>
</h1>

<div class="formgrid grid p-fluid flex-shrink-0">
	<div class="field col-fixed">
		<daterange-selector
			class="formgrid grid p-fluid"
			[useStorage]="true"
			[rangeMode]="true"
			[modes]="['mois','libre']"
			[maxDate]="maxDate"
			[defaultEnd]="maxDate"
			[disabled]="loading || exportLoading"
			(onDateChange)="onDateChange($event)"
		></daterange-selector>
	</div>

	<div class="field col-fixed">
		<label for="region_commerciale">Région</label>
		<region-commerciale-selector
			name="region_commerciale"
			[(ngModel)]="params.region_commerciale"
			[required]="false"
			[nullOption]="true"
			[nullIfInvalid]="true"
			[autoSelectFirst]="false"
			[disabled]="loading || exportLoading"
			placeholder="France"
			(ngModelChange)="paramChanged()"
		></region-commerciale-selector>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end">
		<button
			type="button"
			pButton
			icon="pi pi-refresh"
			class="p-button-text"
			[loading]="loading"
			[disabled]="loading || exportLoading"
			(click)="refresh()"
		></button>
	</div>

	<div class="field col-fixed flex flex-column justify-content-end" *ngIf="canExport">
		<button
			type="button"
			pButton
			label="Export"
			icon="pi pi-download"
			class="p-button-text"
			[disabled]="loading || exportLoading"
			[loading]="exportLoading"
			(click)="export()"
		></button>
	</div>
</div>

<div class="min-table-height">
	<p-table
		#tableClassement
		[value]="values"
		[lazy]="true"
		(onLazyLoad)="load($event)"
		[lazyLoadOnInit]="false"
		[loading]="loading || exportLoading"
		[paginator]="false"
		[rows]="rows"
		[showCurrentPageReport]="false"
		[totalRecords]="totalRecords"
		currentPageReportTemplate="{totalRecords} élément(s)"
		[autoLayout]="true"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover surface-0"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun résultat pour ces critères</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr class="surface-0">
				<th class="text-center col-rang not-last-frozen" pFrozenColumn>Rang</th>
				<th class="text-center " pFrozenColumn>Magasin</th>
				<th
					class="text-center col-indicateur"
					[ngClass]="{'bg-primary-soft': ind_code == 'ca_prise_commande_htple'}"
					*ngFor="let ind_code of indicateurs"
				>
					<indicateur-label-display [ind_code]="ind_code" [tooltip]="true"></indicateur-label-display>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-magasin>
			<tr>
				<td class="text-center col-rang not-last-frozen" pFrozenColumn>
					{{ magasin.rang }}
				</td>
				<td class="" pFrozenColumn>
					{{ magasin.mag_nom_court }} ({{magasin.mag_id}})
				</td>

				<td
					class="text-right col-indicateur"
					[ngClass]="{'bg-primary-soft': ind_code == 'ca_prise_commande_htple'}"
					*ngFor="let ind_code of indicateurs"
				>
					<ng-container *ngIf="magasin.masque">
						<i class="pi pi-ban" title="Vous n'êtes pas autorisé à voir cette valeur"></i>
					</ng-container>
					<ng-container *ngIf="!magasin.masque">
						<ng-container [ngSwitch]="ind_code">

						<ng-container *ngSwitchCase="'ecart_realisation_objectif'">
							<i class="pi pi-minus-circle" *ngIf="magasin.objectif_ca_prise_commande_htple <= 0"></i>
							<indicateur-value-display [ind_code]="ind_code" [value]="magasin[ind_code]" *ngIf="magasin.objectif_ca_prise_commande_htple > 0"></indicateur-value-display>
						</ng-container>

						<ng-container *ngSwitchCase="'taux_mt_ventes_htple_mt_objectif_htple'">
							<i class="pi pi-minus-circle" *ngIf="magasin.objectif_ca_prise_commande_htple <= 0"></i>
							<indicateur-value-display [ind_code]="ind_code" [value]="magasin[ind_code]" *ngIf="magasin.objectif_ca_prise_commande_htple > 0"></indicateur-value-display>
						</ng-container>

						<ng-container *ngSwitchDefault>
							<indicateur-value-display [ind_code]="ind_code" [value]="magasin[ind_code]"></indicateur-value-display>
						</ng-container>

						</ng-container>
					</ng-container>

				</td>

			</tr>
		</ng-template>


		<ng-template pTemplate="footer">
			<tr class="surface-0" *ngIf="total">
				<th class="text-center" colspan="2" pFrozenColumn>
					<b>TOTAL</b>
				</th>

				<td
					class="text-right font-bold"
					[ngClass]="{'bg-primary-soft': ind_code == 'ca_prise_commande_htple'}"
					*ngFor="let ind_code of indicateurs"
				>
					<indicateur-value-display [ind_code]="ind_code" [value]="total[ind_code]"></indicateur-value-display>
				</td>

			</tr>
		</ng-template>
	</p-table>
</div>
