<form #milieuForm="ngForm">
	<div class="formgrid grid p-fluid">

		<ng-container *ngIf="!evenement.eve_milieu_modifie_le">

			<div class="field col-12">
				<label>Milieu WinnerBizz :</label> {{currentMilieu}}
			</div>

			<div class="field col-12" *ngIf="evenement.option_evenement?.ope_milieu_ia">
				<label pTooltip="Identification par analyse de l'image de la perspective 3D.<br><br>Attention, l'IA peut commettre des erreurs." [escape]="false" tooltipPosition="bottom" tooltipStyleClass="tooltip-large">Milieu identifié par intelligence artificielle <i class="pi pi-question-circle"></i> :</label> {{evenement.option_evenement!.ope_milieu_ia}}
			</div>

		</ng-container>

		<div
			class="flex w-full justify-content-center mb-3"
			*ngIf="evenement.option_evenement?.ope_url_image_perspective_3d_vignette"
		>
			<span class="pi pi-spin pi-spinner" *ngIf="urlImageLoading"></span>
			<img src="{{urlImage}}" *ngIf="urlImage">
		</div>

		<div class="field col-12" *ngIf="showChoice">
			<label for="milieu" *ngIf="!evenement.eve_milieu_modifie_le">Votre confirmation</label>
			<label for="milieu" *ngIf="evenement.eve_milieu_modifie_le">Sélectionnez un milieu</label>
			<milieu-selector
				name="milieu"
				[(ngModel)]="selected"
				[nullIfInvalid]="true"
				placeholder="Choisir un milieu..."
				[required]="true"
				[disabled]="loading"
			></milieu-selector>
		</div>

	</div>

	<div class="p-dialog-footer text-center" *ngIf="!evenement.eve_milieu_modifie_le && !showChoice">
		<div class="mb-2">
			<button
				type="button"
				pButton
				class="btn p-button-sm"
				label="Valider le milieu {{evenement.option_evenement!.ope_milieu_ia}}"
				[loading]="loading"
				(click)="confirmSubmit()"
			></button>
		</div>

		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Choisir un autre milieu"
			[disabled]="loading"
			(click)="showChoice = !showChoice"
		></button>
	</div>

	<div class="p-dialog-footer text-center" *ngIf="showChoice">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="!milieuForm.form.valid"
			[loading]="loading"
			(click)="submit()"
		></button>
	</div>
</form>
