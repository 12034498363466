import { NgModule, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TooltipModule } from 'primeng/tooltip';

import { GlobalModule } from '@global/global.module';
import { IndicateurPipe } from '@app/indicateur/indicateur.pipe';

@Component({
	selector: 'indicateur-tooltip',
	template: `
		<ng-container *ngIf="content">
			<i class="pi pi-question-circle" [pTooltip]="content"></i>
		</ng-container>
	`,
	providers: [IndicateurPipe]
})
export class IndicateurTooltipComponent implements OnChanges {

	@Input() ind_code: string;

	content: string;

	constructor(private indicateurPipe: IndicateurPipe) {

	}

	ngOnChanges(changes: any) {
		this.content = this.indicateurPipe.transform(this.ind_code, 'description');
	}

}


@NgModule({
	imports: [
		CommonModule,
		TooltipModule,
		GlobalModule
	],
	exports: [IndicateurTooltipComponent],
	declarations: [IndicateurTooltipComponent]
})
export class IndicateurTooltipModule { }
