<form #formAuditPrestataire="ngForm">
	<div class="formgrid grid p-fluid">

		<div class="field col-12" *ngIf="!auditPrestataire.apr_id">
			<label for="prs_tarifs">Année</label>
			<p-dropdown
				name="apr_annee"
				[options]="choixAnnee"
				[(ngModel)]="auditPrestataire.apr_annee"
			></p-dropdown>
		</div>

		<div class="field col-12" >
			<label for="apr_representant">Représentant du prestataire</label>
			<input
				type="text"
				pInputText
				name="apr_representant"
				[required]="true"
				placeholder="Représentant"
				[(ngModel)]="auditPrestataire.apr_representant"
			>
		</div>

		<div class="field col-fixed" *ngIf="auditPrestataire.apr_id">
			<label for="apr_date_fin_contrat">Date de fin de contrat</label>
			<div class="flex">
				<p-calendar
					name="apr_date_fin_contrat"
					[(ngModel)]="auditPrestataire.apr_date_fin_contrat"
					[showTime]="false"
					[showIcon]="true"
					[showButtonBar]="true"
					appendTo="body"
				></p-calendar>
			</div>
		</div>

		<div class="field col-12"  *ngIf="auditPrestataire.apr_id">
			<label for="apr_cause_fin_contrat">Cause de fin de contrat</label>
			<textarea
				pInputTextarea
				name="apr_cause_fin_contrat"
				[(ngModel)]="auditPrestataire.apr_cause_fin_contrat"
				[autoResize]="true"
				[rows]="5"
			></textarea>
		</div>

	</div>

	<div class="p-dialog-footer text-right">
		<button
			type="button"
			pButton
			class="btn p-button-sm p-button-text"
			label="Annuler"
			[disabled]="loading"
			(click)="cancel()"
		></button>
		<button
			type="button"
			pButton
			class="btn p-button-sm"
			label="Enregistrer"
			[disabled]="loading || !formAuditPrestataire.valid"
			(click)="submit()"
		></button>
	</div>

</form>
