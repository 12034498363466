export const environment = {
	production: true,
	aviva: {
		title: 'AvaNet (preprod)',
		api: `https://${window.location.hostname}/api`, // https://avanet.aviva-preprod.grizzlydev.com
		foc: 'https:///aviva.franchiseoncloud.fr',
		client_id: '6194bfe8f80b7f2f0ea6b6a2',
		client_secret: '5JhUQr8o4VgZQPicpcgnNb3W29r6gr',
		analyticsId: ''
	},
	elton: {
		title: 'EltonNet (preprod)',
		api: `https://${window.location.hostname}/api`, // https://eltonnet.aviva-preprod.grizzlydev.com
		foc: 'https:///elton.franchiseoncloud.fr',
		client_id: '6194c17cf80b7f2f0ea6b6ac',
		client_secret: 'bU3S6a2iPri6THkmDG3cCwDHvWLyKp',
		analyticsId: ''
	},
	oauth_redirect: `https://${window.location.hostname}/callback`,
	refresh_token_minimal_timeout: 900000,
	websocket_heartbeat_interval: 60000
};

export const enseigne = window.location.hostname.includes('elton') ? 'elton' : 'aviva';
