import { Component, NgModule, ElementRef, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { BlockableUI } from 'primeng/api';
import { TableModule } from '@app/primeng-overrides/table';

import { IndicateurLabelDisplayModule } from '@app/indicateur/indicateur-label-display';
import { IndicateurTooltipModule } from '@app/indicateur/indicateur-tooltip';
import { IndicateurValueDisplayModule } from '@app/indicateur/indicateur-value-display';
import { GlobalModule } from '@global/global.module';
import { NumberDisplayModule } from '@helpers/number-display';

import { clone } from '@helpers/utils';

@Component({
	selector: 'bloc-indicateurs-par-milieu',
	templateUrl: './bloc-indicateurs-par-milieu.html',
	providers: []
})
export class BlocIndicateursParMilieuComponent implements BlockableUI {

	@Input() loading: boolean;
	@Input() valeursN: any;
	@Input() valeursPast: any;

	indicateurs: any[] = [
		{ind_code: 'nb_ventes', tooltipIcon: false},
		{ind_code: 'ca_prise_commande_htple', tooltipIcon: false},
	];

	milieux = [
		'cuisine',
		'rangement',
		'sdb'
	];

	_valeursN: any;

	// nb_ventes_cuisine
	// taux_nb_ventes_cuisine_nb_ventes_total
	// ca_prise_commande_htple_cuisine
	// taux_ca_prise_commande_htple_cuisine

	// nb_ventes_rangement
	// taux_nb_ventes_rangement_nb_ventes_total
	// ca_prise_commande_htple_rangement
	// taux_ca_prise_commande_htple_rangement

	// nb_ventes_sdb
	// taux_nb_ventes_sdb_nb_ventes_total
	// ca_prise_commande_htple_sdb
	// taux_ca_prise_commande_htple_sdb

	constructor(private elementRef: ElementRef) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (changes.valeursN.currentValue) {
			this._valeursN = clone(changes.valeursN.currentValue);
			this.milieux.forEach((milieu: string) => {
				this._valeursN[`taux_nb_ventes_${milieu}_nb_ventes_total`] *= 100;
				this._valeursN[`taux_ca_prise_commande_htple_${milieu}`] *= 100;
			});
		}
	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}



}
@NgModule({
	declarations: [
		BlocIndicateursParMilieuComponent
	],
	exports: [
		BlocIndicateursParMilieuComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		TableModule,
		ButtonModule,
		IndicateurLabelDisplayModule,
		IndicateurTooltipModule,
		IndicateurValueDisplayModule,
		GlobalModule,
		NumberDisplayModule,
	],
})
export class BlocIndicateursParMilieuModule { }
