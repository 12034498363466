<div>

	<p-table
		[autoLayout]="true"
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines mb-3"
	>
		<ng-template pTemplate="header">
			<tr>
				<th class="text-center">
					<indicateur-label-display
						[ind_code]="indicateurNombreRendezVousWebDemandes.ind_code"
						[tooltipIcon]="indicateurNombreRendezVousWebDemandes.tooltipIcon"
					></indicateur-label-display>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body">
			<tr>
				<td class="text-center">
					<ng-container *ngIf="valeursN">
						<!-- {{ valeursN[indicateur.ind_code] }} -->
						<indicateur-value-display
							[value]="valeursN[indicateurNombreRendezVousWebDemandes.ind_code]"
							[ind_code]="indicateurNombreRendezVousWebDemandes.ind_code"
						></indicateur-value-display>
					</ng-container>
				</td>
			</tr>
		</ng-template>
	</p-table>

	<p-table
		[autoLayout]="true"
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines mb-3"
	>
		<ng-template pTemplate="header">
			<tr>
				<th class="text-center" *ngFor="let indicateur of indicateurs">
					<indicateur-label-display [ind_code]="indicateur.ind_code" [tooltipIcon]="indicateur.tooltipIcon"></indicateur-label-display>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body">
			<tr>
				<td class="text-center" *ngFor="let indicateur of indicateurs">
					<ng-container *ngIf="valeursN">
						<!-- {{ valeursN[indicateur.ind_code] }} -->
						<indicateur-value-display [value]="valeursN[indicateur.ind_code]" [ind_code]="indicateur.ind_code"></indicateur-value-display>
					</ng-container>
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>
