import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DeclarationMensuelle } from '@app/declaration/declaration.model';
import { isEmpty } from '@helpers/utils';
import { AnalyticsService } from '@app/_global/analytics.service';
import { QuiFaitQuoi } from './qui-fait-quoi.model';

export type Magasin = {
	mag_id: number;
	ens_id: number;
	mag_uid: string;
	mag_nom: string;
	mag_nom_court: string;
	mag_region_commerciale: string;

	label: string;
	loading?: boolean;
	declaration_mensuelle?: DeclarationMensuelle;
}


@Injectable({ providedIn: 'root' })
export class QuiFaitQuoiService {


	constructor(
		private http: HttpClient,
		private analyticsService: AnalyticsService,
	) {

	}

	public getListe(mag_id: number): Observable<QuiFaitQuoi[]> {
		this.analyticsService.trackEvent('get_qui_fait_quoi', {mag_id: mag_id});
		return this.http.get<any>(`/magasins/${mag_id}/qui_fait_quoi`)
		.pipe(map(
			(response: any) => {
				if (!isEmpty(response)) {
					return response as QuiFaitQuoi[];
				}
				return [];
			}
		));
	}

	public updateSingleQuiFaitQuoi(quiFaitQuoi: QuiFaitQuoi, mag_id: number){
		this.analyticsService.trackEvent('update_single_qui_fait_quoi', {mag_id: mag_id});
		return this.http.put<any>(`/magasins/${mag_id}/qui_fait_quoi/${quiFaitQuoi.qft_id}`, {
			qfm_franchise: quiFaitQuoi.qfm_franchise,
			qfm_chef_ventes: quiFaitQuoi.qfm_chef_ventes,
			qfm_vendeurs: quiFaitQuoi.qfm_vendeurs,
			qfm_ag: quiFaitQuoi.qfm_ag,
			qfm_poseurs: quiFaitQuoi.qfm_poseurs,
			qfm_livreurs: quiFaitQuoi.qfm_livreurs,
			... !quiFaitQuoi.common ? {qft_libelle: quiFaitQuoi.qft_libelle} : {}
		})
	}

	public deleteSingleQuiFaitQuoi(quiFaitQuoi: QuiFaitQuoi, mag_id: number){
		this.analyticsService.trackEvent('delete_single_qui_fait_quoi', {mag_id: mag_id});
		return this.http.delete<any>(`/magasins/${mag_id}/qui_fait_quoi/${quiFaitQuoi.qft_id}`);
	}

	public createSingleQuiFaitQuoi(libelle: string, qgt_id: number, mag_id: number){
		this.analyticsService.trackEvent('create_single_qui_fait_quoi', {mag_id: mag_id});
		return this.http.post<any>(`/magasins/${mag_id}/qui_fait_quoi`, {
			qft_libelle: libelle,
			qgt_id: qgt_id
		})
	}
}

