<div>

	<p-table
		[autoLayout]="true"
		[value]="[1]"
		responsiveLayout="scroll"
		styleClass="p-datatable-sm p-datatable-gridlines mb-3"
	>
		<ng-template pTemplate="header">
			<tr>
				<th class="clear-cell"></th>
				<th class="text-center" *ngFor="let indicateur of indicateurs">
					<indicateur-label-display
						[ind_code]="indicateur.ind_code"
						[tooltipIcon]="indicateur.tooltipIcon"
						*ngIf="indicateur.ind_code"
					></indicateur-label-display>
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body">
			<ng-container *ngIf="_valeursN">
				<tr>
					<td class="cell-th text-right">Cuisine</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['nb_ventes_cuisine']" ind_code="nb_ventes_cuisine"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_nb_ventes_cuisine_nb_ventes_total']" ind_code="taux_nb_ventes_cuisine_nb_ventes_total"></indicateur-value-display>)
					</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['ca_prise_commande_htple_cuisine']" ind_code="ca_prise_commande_htple_cuisine"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_ca_prise_commande_htple_cuisine']" ind_code="taux_ca_prise_commande_htple_cuisine"></indicateur-value-display>)
					</td>
				</tr>

				<tr>
					<td class="cell-th text-right">Salle de bains</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['nb_ventes_sdb']" ind_code="nb_ventes_sdb"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_nb_ventes_sdb_nb_ventes_total']" ind_code="taux_nb_ventes_sdb_nb_ventes_total"></indicateur-value-display>)
					</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['ca_prise_commande_htple_sdb']" ind_code="ca_prise_commande_htple_sdb"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_ca_prise_commande_htple_sdb']" ind_code="taux_ca_prise_commande_htple_sdb"></indicateur-value-display>)
					</td>
				</tr>

				<tr>
					<td class="cell-th text-right">Rangement</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['nb_ventes_rangement']" ind_code="nb_ventes_rangement"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_nb_ventes_rangement_nb_ventes_total']" ind_code="taux_nb_ventes_rangement_nb_ventes_total"></indicateur-value-display>)
					</td>
					<td class="text-center">
						<indicateur-value-display [value]="_valeursN['ca_prise_commande_htple_rangement']" ind_code="ca_prise_commande_htple_rangement"></indicateur-value-display> (<indicateur-value-display [value]="_valeursN['taux_ca_prise_commande_htple_rangement']" ind_code="taux_ca_prise_commande_htple_rangement"></indicateur-value-display>)
					</td>
				</tr>
			</ng-container>
		</ng-template>
	</p-table>

</div>
