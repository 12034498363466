<h1 class="sm:flex align-items-center flex-shrink-0">
	<!-- <titre-module>Contrôle annuel</titre-module> -->
	<back-button class="mr-2 flex-shrink-0"></back-button>
	<span class="flex-shrink-0">
		Prestataires > Contrôle annuel
	</span>
	<print-button-simple [track]="true" [track_mag_id]="prestataire.mag_id"></print-button-simple>
</h1>

<div class="grid flex-shrink-0">
	<p-table
		[value]="[1]"
		responsiveLayout="scroll"
		class="col-fixed mb-2"
		styleClass="p-datatable-sm p-datatable-gridlines table-auto"
	>
		<ng-template pTemplate="body">
			<tr>
				<td class="cell-th">Magasin</td>
				<td class="text-right">
					{{prestataire.magasin.label}}
				</td>
				<td class="clear-cell hide-print">
					<button
						type="button"
						pButton
						icon="pi pi-refresh"
						class="p-button-text hide-print"
						[disabled]="loading"
						[loading]="loading"
						(click)="load(true)"
					></button>
				</td>
			</tr>
			<tr>
				<td class="cell-th">{{prestataire.prs_type | typePrestataire}}</td>
				<td>
					{{prestataire.label}}
				</td>
			</tr>
		</ng-template>
	</p-table>

</div>


<div class="min-table-height">
	<p-table
		[value]="values"
		[lazy]="true"
		[loading]="loading"
		responsiveLayout="scroll"
		class=""
		[autoLayout]="true"
		responsiveLayout="scroll"
		responsiveLayout="scroll"
		scrollDirection="both"
		[scrollable]="true"
		scrollHeight="flex"
		styleClass="p-datatable-sm p-datatable-gridlines table-hover table-controles-annuels-prestataire"
		dataKey="apr_id"
		[expandedRowKeys]="expandedRowKeys"
	>
		<ng-template pTemplate="emptymessage">
			<tr>
				<th class="text-center p-3" colspan="100">Aucun audit pour ce prestataire</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th></th>
				<th class="text-center nowrap">Année</th>
				<!-- <th class="text-center">Représentant</th> -->
				<th class="text-center">Point de contrôle</th>
				<th class="text-center">Statut</th>
				<th class="text-center nowrap">Pièce-jointe</th>
				<th class="text-center">Date fin de contrat</th>
				<th class="text-center">Cause fin de contrat</th>
				<th class="text-center">Date dernière saisie</th>
				<th class="text-center hide-print" *ngIf="canEdit && prestataire.prs_actif">
					<button
						type="button"
						pButton
						icon="pi pi-plus"
						class="p-button p-button-sm"
						label="Ajouter"
						(click)="showAuditPrestataireDialog()"
						*ngIf="choixAnnee.length"
					></button>
				</th>
			</tr>
		</ng-template>

		<ng-template pTemplate="body" let-audit let-rowIndex="rowIndex" let-expanded="expanded">
			<tr class="row-group">
				<td class="text-center nowrap">
					<button
						type="button"
						pButton
						pRipple
						[pRowToggler]="audit"
						class="p-button-text p-button-rounded p-button-xs"
						[icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
					></button>
				</td>
				<td class="text-center font-bold">{{audit.apr_annee}}</td>
				<td class="text-left font-bold">Représentant : {{audit.apr_representant}}</td>
				<td class="text-center">
					<p-tag class="mb-1 inline-block" severity="success" value="OK : {{audit.nb_statut_ok}}" *ngIf="audit.nb_statut_ok"></p-tag>
					<p-tag class="mb-1 inline-block" severity="danger" value="KO : {{audit.nb_statut_ko}}" *ngIf="audit.nb_statut_ko"></p-tag>
					<p-tag severity="warning" value="A définir : {{audit.nb_statut_inconnu}}" *ngIf="audit.nb_statut_inconnu"></p-tag>
				</td>
				<td class="text-center nowrap">
					<p-tag [severity]="audit.nb_piece_jointe_possible && audit.nb_piece_jointe < audit.nb_piece_jointe_possible? 'warning':'success'" value="{{audit.nb_piece_jointe}}/{{audit.nb_piece_jointe_possible}}" *ngIf="audit.nb_piece_jointe_possible"></p-tag>
					<!-- {{audit.nb_piece_jointe}}/{{audit.nb_piece_jointe_possible}} -->
				</td>

				<td class="text-center nowrap">
					{{audit.apr_date_fin_contrat | date:'mediumDate'}}
					<div class="text-sm text-center" *ngIf="audit.apr_date_fin_contrat">
						({{audit.apr_date_fin_contrat | timeAgo}})
					</div>
				</td>
				<td class="text-left">{{audit.apr_cause_fin_contrat}}</td>
				<td class="text-center nowrap" title="{{audit.apr_date_derniere_saisie |date:'medium'}}">
					{{audit.apr_date_derniere_saisie |date:'mediumDate'}}
				</td>

				<td class="text-center nowrap hide-print" *ngIf="canEdit && audit.editable">
					<button
						type="button"
						pButton
						icon="pi pi-pencil"
						class="p-button-xs p-button-rounded p-button-text"
						title="Editer"
						(click)="showAuditPrestataireDialog(audit)"
					></button>
					<button
						type="button"
						pButton
						icon="pi pi-trash"
						class="p-button-xs p-button-rounded p-button-text p-button-danger"
						title="Supprimer"
						(click)="deleteAuditPrestataire(audit)"
					></button>
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="rowexpansion" let-audit>
			<tr class="" *ngFor="let pointControle of audit.details">
				<td class="clear-cell border-none"></td>
				<td colspan="2" class="min-w-20rem">{{pointControle.pcp_libelle}}</td>
				<td class="text-center">
					<ng-container *ngIf="canEdit && audit.editable">
						<p-dropdown
							[options]="statutsPointControlePrestataire"
							[(ngModel)]="pointControle.dap_statut_ok"
							(ngModelOptions)="{standalone: true}"
							class="block w-full text-left hide-print"
							styleClass="w-full text-left"
							(ngModelChange)="updateStatut(audit, pointControle)"
						></p-dropdown>
						<span class="show-print">
							{{pointControle.dap_statut_ok|statutPointControlePrestataire}}
						</span>
					</ng-container>
					<ng-container *ngIf="!canEdit || !audit.editable">
						{{pointControle.dap_statut_ok|statutPointControlePrestataire}}
					</ng-container>
				</td>
				<td class="text-center nowrap">
					<ng-container *ngIf="pointControle.pcp_piece_jointe_possible || pointControle.dap_piece_jointe">
						<i class="pi pi-check show-print" *ngIf="pointControle.dap_piece_jointe"></i>
						<div class="hide-print">
							<button
								type="button"
								pButton
								icon="pi pi-download"
								class="p-button p-button-xs p-button-rounded p-button-text"
								title="Télécharger la pièce-jointe {{pointControle.pcp_libelle}}"
								(click)="downloadPieceJointe(audit, pointControle)"
								[loading]="loadingPieceJointe[pointControle.pcp_id]"
								*ngIf="pointControle.dap_piece_jointe"
							></button>
							<button
								type="button"
								pButton
								icon="pi pi-plus"
								class="p-button-xs p-button-rounded p-button-text"
								title="Ajouter une pièce-jointe"
								(click)="showPieceJointeDialog(audit, pointControle)"
								*ngIf="!pointControle.dap_piece_jointe && canEdit && audit.editable"
							></button>
							<button
								type="button"
								pButton
								icon="pi pi-trash"
								class="p-button-xs p-button-rounded p-button-text p-button-danger"
								title="Supprimer la pièce-jointe"
								(click)="deletePieceJointe(audit, pointControle)"
								*ngIf="pointControle.dap_piece_jointe && canEdit && audit.editable"
							></button>
						</div>
					</ng-container>
				</td>
				<td></td>
				<td></td>
				<td class="text-center nowrap" title="{{pointControle.aud_date_modification | date:'medium'}}">
					{{pointControle.aud_date_modification | date:'mediumDate'}}
				</td>
				<td class="hide-print" *ngIf="canEdit"></td>
			</tr>
		</ng-template>

	</p-table>

</div>

