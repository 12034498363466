import { Component, NgModule, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockableUI } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';

import { GlobalModule } from '@global/global.module';
import { IndicateurService } from '@app/indicateur/indicateur.service';
import { IndicateurLabelDisplayModule } from '@app/indicateur';
import { IndicateurValueDisplayModule } from '@app/indicateur';
import { NumberDisplayModule } from '@helpers/number-display';

@Component({
	selector: 'bloc-objectifs',
	templateUrl: './bloc-objectifs.html',
	providers: [],
	encapsulation: ViewEncapsulation.None,
	styles: [`
		.taux_mt_ventes_htple_mt_objectif_htple .p-progressbar .p-progressbar-value {
			background: var(--color-taux_mt_ventes_htple_mt_objectif_htple);
		}
		.taux_mt_ventes_htple_mt_ventes_htple_n_moins_1 .p-progressbar .p-progressbar-value {
			background: var(--color-taux_mt_ventes_htple_mt_ventes_htple_n_moins_1);
		}
		.taux_mt_ventes_htple_mt_devis_htple .p-progressbar .p-progressbar-value {
			background: var(--color-taux_mt_ventes_htple_mt_devis_htple);
		}
	`]
})
export class BlocObjectifsComponent implements OnChanges, BlockableUI {

	@Input() valeursN: any;
	@Input() valeursPast: any;

	taux_mt_ventes_htple_mt_ventes_htple_n_moins_1: number;

	canDisplayTauxVentesObjectif: boolean;
	canDisplayTauxVentesCAPast: boolean;
	canDisplayTauxVentesDevis: boolean;

	indicateurs: string[] = [
		'taux_mt_ventes_htple_mt_objectif_htple',
		'taux_mt_ventes_htple_mt_ventes_htple_n_moins_1',
		'taux_mt_ventes_htple_mt_devis_htple',
	];

	constructor(
		private elementRef: ElementRef,
		private indicateurService: IndicateurService,
	) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: any) {
		if (changes.valeursN.currentValue && changes.valeursPast.currentValue) {
			if (changes.valeursPast.currentValue.ca_prise_commande_htple != 0) {
				this.valeursN.taux_mt_ventes_htple_mt_ventes_htple_n_moins_1 = changes.valeursN.currentValue.ca_prise_commande_htple / changes.valeursPast.currentValue.ca_prise_commande_htple * 100;
			}
			else {
				this.valeursN.taux_mt_ventes_htple_mt_ventes_htple_n_moins_1 = Infinity;
			}

			this.canDisplayTauxVentesObjectif = changes.valeursN.currentValue.objectif_ca_prise_commande_htple != 0;
			this.canDisplayTauxVentesCAPast = changes.valeursPast.currentValue.ca_prise_commande_htple != 0;
			this.canDisplayTauxVentesDevis = changes.valeursN.currentValue.mt_devis_htple != 0;

			// set progress bar colors
			for (const ind_code of this.indicateurs) {
				const cssValue = this.indicateurService.getColorValue(ind_code, this.valeursN[ind_code], true);
				this.elementRef.nativeElement.style.setProperty(`--color-${ind_code}`, cssValue);
			}
		}
	}

	getBlockableElement(): HTMLElement {
		return this.elementRef.nativeElement.children[0];
	}


}
@NgModule({
	declarations: [
		BlocObjectifsComponent
	],
	exports: [
		BlocObjectifsComponent,
	],
	imports: [
		CommonModule,
		ProgressBarModule,
		GlobalModule,
		IndicateurLabelDisplayModule,
		IndicateurValueDisplayModule,
		NumberDisplayModule,
	],
})
export class BlocObjectifsModule { }
